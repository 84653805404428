import axios from "axios";
import { endpointInfosEmployeur } from 'util/api';
var moment = require('moment');

/**
 * R�cup�ration des infos employeur d'un com�dien
 * @param {*} payload 
 */
export const getInfosEmployeurRequest = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  let { idUser } = payload;

  const wsResponse = await axios.request({
    method: 'get',
    url: `${endpointInfosEmployeur.GET_INFOS}?access_token=${accessToken}`,
    params:{
      idUser
    }
  });

  return wsResponse;
}

/**
 * Modification des infos employeur d'un com�dien
 * @param {*} payload 
 */
export const updateInfosEmployeurRequest = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';
  
  const { idUser, values } = payload;

  const formData = new FormData();
    
  formData.append("idUser", idUser);
  formData.append("nom", values.nom);
  formData.append("prenom", values.prenom);
  formData.append("nomNaissance", values.nomNaissance);
  formData.append("pseudo", values.pseudo);
  formData.append("adresse", values.adresse);
  formData.append("codePostal", values.codePostal);
  formData.append("ville", values.ville);
  formData.append("pays", values.pays);
  formData.append("dateNaissance", moment(values.dateNaissance).format("YYYY-MM-DD"));
  formData.append("lieuNaissance", values.lieuNaissance);
  formData.append("departement", values.departement);
  formData.append("paysNaissance", values.paysNaissance);
  formData.append("numeroSecuriteSociale", values.numeroSecuriteSociale);
  formData.append("numeroCongeSpectacle", values.numeroCongeSpectacle);
  formData.append("abattement", values.abattement);
  formData.append("derniereVisiteMedicale", moment(values.derniereVisiteMedicale).format("YYYY-MM-DD"));
  formData.append("email", values.email);
  formData.append("nomBanque", values.nomBanque);
  formData.append("iban", values.iban);
  formData.append("bic", values.bic);
    
  const wsResponse = await axios.request({
    method: 'post',
    url: `${endpointInfosEmployeur.UPDATE_INFOS}?access_token=${accessToken}`,
    data: formData,
    config: {
      headers: {
        'Content-Type': `multipart/form-data`,
        'Accept': 'application/json',
      }
    }
  });

  return wsResponse;
}