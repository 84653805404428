import axios from "axios";
import { endpointUser } from 'util/api';

/**
 * R�cup�ration des stats du site
 */
export const getAdminStatsRequest = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  const { filtre } = payload;

  const wsResponse = await axios.request({
    method: 'get',
    url: `${endpointUser.GET_STATS}?access_token=${accessToken}`,
    params: {
      filtre
    }
  });

  return wsResponse;
}

/**
 * R�cup�ration de l'historique de stats du site
 * @param {*} payload 
 */
export const getAdminHistoriqueStatsRequest = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  const { pageSize, page, action, filtre } = payload;

  const wsResponse = await axios.request({
    method: 'get',
    url: `${endpointUser.GET_HISTORIQUE_STATS}?access_token=${accessToken}`,
    params: {
      pageSize,
      page,
      action,
      filtre
    }
  });

  return wsResponse;
}


