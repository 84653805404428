import {
  SHOW_ALERT,
  HIDE_ALERT,
  SHOW_REQUEST_LOADER,
  HIDE_REQUEST_LOADER,
  SHOW_NOTIFICATION,
  HIDE_NOTIFICATION,
  MESSAGE_SUCCESS,
  NOTIFICATION_SUCCESS,
  SET_REFRESH_LIST,
  UNSET_REFRESH_LIST,
  GET_FICHE_COMEDIEN,
  SHOW_FICHE_COMEDIEN,
  GET_DERNIERS_NEWS_COMEDIEN,
  SHOW_DERNIERS_NEWS_COMEDIEN,
  GET_SLIDES_PHOTOS_XL,
  SHOW_SLIDES_PHOTOS_XL,
  GET_SLIDES_BLOGS,
  SHOW_SLIDES_BLOGS,
  GET_SLIDES_TEMOIGNAGES_COMEDIENS,
  SHOW_SLIDES_TEMOIGNAGES_COMEDIENS,
  COMMON_SET_STATUS_MODAL_SONNEZ_ALFRED,
  COMMON_SET_STATUS_MODAL_VOIX_ETRANGERES,
  COMMON_SET_STATUS_MODAL_PROJET,
  CMS_SHOW_CGU,
  CMS_SHOW_RGPD,
  COMMON_SEARCH,
  COMMON_TOGGLE_MOBILE_SEARCH,
  COMMON_ALGOLIA_STATE_CHANGE,
  COMMON_SHOW_LIST_MESSAGES,
  COMMON_PLAY_MP3,
  COMMON_SET_FORM_RESET,
  COMMON_UNSET_FORM_RESET,
} from "constants/ActionTypes";

import {
  STATUT_SONNEZ_ALFRED_EN_ATTENTE,
  STATUT_VOIX_ETRANGERES_EN_ATTENTE,
  STATUT_PROJET_EN_ATTENTE,
} from "constants/General";

const INIT_STATE = {
  showRequestLoader: false,
  alertMessage: '',
  showAlert: false,
  showNotification: false,
  notificationMessage: '',
  alertMessageStatus: MESSAGE_SUCCESS,
  notificationMessagStatus: NOTIFICATION_SUCCESS,
  refreshList: false,
  ficheComedien: {},
  dataDerniersNewsComediens: null,
  dataSlidesPhotosXL: null,
  dataSlidesBlogs: null,
  dataSlidesTemoignages: null,
  statusSonnezAlfred: STATUT_SONNEZ_ALFRED_EN_ATTENTE,
  statusVoixEtrangeres: STATUT_VOIX_ETRANGERES_EN_ATTENTE,
  statusProjet: STATUT_PROJET_EN_ATTENTE,
  dataCGU: null,
  dataRGPD: null,
  dataSearchKeyword: '',
  showMobileSearch: false,
  algoliaStateChange: false,
  dataListMessages: [],
  MP3ToPlay: null,
  resetForm: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {

    ///////////////////////////////////////////////////////////////////////
    // Request Loader
    ///////////////////////////////////////////////////////////////////////

    case SHOW_REQUEST_LOADER: {
      return {
        ...state,
        showRequestLoader: true
      }
    }

    case HIDE_REQUEST_LOADER: {
      return {
        ...state,
        showRequestLoader: false
      }
    }

    ///////////////////////////////////////////////////////////////////////
    // Alert
    ///////////////////////////////////////////////////////////////////////

    case SHOW_ALERT: {
      return {
        ...state,
        showAlert: true,
        alertMessage: action.payload,
        alertMessageStatus: action.status,
      }
    }

    case HIDE_ALERT: {
      return {
        ...state,
        alertMessage: '',
        showAlert: false,
      }
    }

    ///////////////////////////////////////////////////////////////////////
    // Notofication
    ///////////////////////////////////////////////////////////////////////

    case SHOW_NOTIFICATION: {
      return {
        ...state,
        showNotification: true,
        notificationMessage: action.payload,
        notificationMessagStatus: action.status,
      }
    }

    case HIDE_NOTIFICATION: {
      return {
        ...state,
        showNotification: false,
        notificationMessage: '',
      }
    }

    ///////////////////////////////////////////////////////////////////////
    // Refresh List
    ///////////////////////////////////////////////////////////////////////

    case SET_REFRESH_LIST: {
      return {
        ...state,
        refreshList: true,
      }
    }

    case UNSET_REFRESH_LIST: {
      return {
        ...state,
        refreshList: false,
      }
    }

    ////////////////////////////////////////////////////////////////////////////
    // Fiche comedien
    ////////////////////////////////////////////////////////////////////////////

    case GET_FICHE_COMEDIEN: {
      return {
        ...state,
      }
    }
    case SHOW_FICHE_COMEDIEN: {
      return {
        ...state,
        ficheComedien: action.ficheComedien
      }
    }

    ////////////////////////////////////////////////////////////////////////////
    // Fiche comedien
    ////////////////////////////////////////////////////////////////////////////

    case GET_DERNIERS_NEWS_COMEDIEN: {
      return {
        ...state,
      }
    }
    case SHOW_DERNIERS_NEWS_COMEDIEN: {
      return {
        ...state,
        dataDerniersNewsComediens: action.dataDerniersNewsComediens
      }
    }

    ////////////////////////////////////////////////////////////////////////////
    // Slides Photos XL
    ////////////////////////////////////////////////////////////////////////////

    case GET_SLIDES_PHOTOS_XL: {
      return {
        ...state
      }
    }
    case SHOW_SLIDES_PHOTOS_XL: {
      return {
        ...state,
        dataSlidesPhotosXL: action.dataSlidesPhotosXL
      }
    }

    ////////////////////////////////////////////////////////////////////////////
    // Blogs
    ////////////////////////////////////////////////////////////////////////////

    case GET_SLIDES_BLOGS: {
      return {
        ...state
      }
    }
    case SHOW_SLIDES_BLOGS: {
      return {
        ...state,
        dataSlidesBlogs: action.dataSlidesBlogs
      }
    }

    ////////////////////////////////////////////////////////////////////////////
    // Slides Temoignages Com�diens
    ////////////////////////////////////////////////////////////////////////////

    case GET_SLIDES_TEMOIGNAGES_COMEDIENS: {
      return {
        ...state
      }
    }
    case SHOW_SLIDES_TEMOIGNAGES_COMEDIENS: {
      return {
        ...state,
        dataSlidesTemoignages: action.dataSlidesTemoignages
      }
    }

    ///////////////////////////////////////////////////////////////////////
    // Sonnez Alfred
    ///////////////////////////////////////////////////////////////////////

    case COMMON_SET_STATUS_MODAL_SONNEZ_ALFRED: {
      return {
        ...state,
        statusSonnezAlfred: action.payload,
      }
    }

    ///////////////////////////////////////////////////////////////////////
    // Voix �trang�res
    ///////////////////////////////////////////////////////////////////////

    case COMMON_SET_STATUS_MODAL_VOIX_ETRANGERES: {
      return {
        ...state,
        statusVoixEtrangeres: action.payload,
      }
    }

    ///////////////////////////////////////////////////////////////////////
    // Projet
    ///////////////////////////////////////////////////////////////////////

    case COMMON_SET_STATUS_MODAL_PROJET: {
      return {
        ...state,
        statusProjet: action.payload,
      }
    }

    ///////////////////////////////////////////////////////////////////////
    // CMS
    ///////////////////////////////////////////////////////////////////////

    //CGU
    case CMS_SHOW_CGU: {
      return {
        ...state,
        dataCGU: action.dataCGU
      }
    }
    //RGPD
    case CMS_SHOW_RGPD: {
      return {
        ...state,
        dataRGPD: action.dataRGPD
      }
    }

    ///////////////////////////////////////////////////////////////////////
    // Search
    ///////////////////////////////////////////////////////////////////////

    case COMMON_SEARCH: {
      return {
        ...state,
        dataSearchKeyword: action.dataSearchKeyword
      }
    }

    ///////////////////////////////////////////////////////////////////////
    // Mobile search
    ///////////////////////////////////////////////////////////////////////

    case COMMON_TOGGLE_MOBILE_SEARCH: {
      return {
        ...state,
        showMobileSearch: action.showMobileSearch
      }
    }

    ///////////////////////////////////////////////////////////////////////
    // Algolia State Change
    ///////////////////////////////////////////////////////////////////////

    case COMMON_ALGOLIA_STATE_CHANGE: {
      return {
        ...state,
        algoliaStateChange: action.algoliaStateChange
      }
    }

    ///////////////////////////////////////////////////////////////////////
    // SMS
    ///////////////////////////////////////////////////////////////////////

    //List des SMS
    case COMMON_SHOW_LIST_MESSAGES: {
      return {
        ...state,
        dataListMessages: action.dataListMessages
      }
    }

    ///////////////////////////////////////////////////////////////////////
    // Lecture d'un MP3
    ///////////////////////////////////////////////////////////////////////

    case COMMON_PLAY_MP3: {
      return {
        ...state,
        MP3ToPlay: action.MP3ToPlay
      }
    }

    ///////////////////////////////////////////////////////////////////////
    // Form reset
    ///////////////////////////////////////////////////////////////////////

    case COMMON_SET_FORM_RESET: {
      return {
        ...state,
        resetForm: true,
      }
    }

    case COMMON_UNSET_FORM_RESET: {
      return {
        ...state,
        resetForm: false,
      }
    }


    default:
      return state;
  }
}
