
import valueList from './cm_value_list.json';

export const groupesInterpretations = [
  'complicite',
  'elegance',
  'nervosite',
  'neutre',
  'comedie',
  'sourire',
  'sensualite',
  'energie',
  'tristesse',
  'surprise',
  'peur'
];

export function getValueList (listName=null) {
    let values = [];

    let cpt=0;
    for(cpt=0;cpt<valueList.length;cpt++){
        if(!listName || (listName && valueList[cpt].list_name === listName)){
          valueList[cpt].id_value = Number(valueList[cpt].id_value);
          valueList[cpt].tri = Number(valueList[cpt].tri);
          values[valueList[cpt].id_value] = valueList[cpt];
        }
    }

    return values;
}


export function getGroupeInterpretation (group) {

    switch(group){
        case 'sourire':
            return{icon:'0x1F600',title:'Sourire', label:'interpretation.sourire'};
        case 'complicite':
            return{icon:'0x1F609',title:'Complicit&eacute;',label:'interpretation.complicite'};
        case 'neutre':
            return{icon:'0x1F610',title:'Neutre',label:'interpretation.neutre'};
        case 'sensualite':
            return{icon:'0x1F60D',title:'Sensualit&eacute;',label:'interpretation.sensualite'};
        case 'energie':
            return{icon:'0x1F4A5',title:'Energie',label:'interpretation.energie'};
        case 'elegance':
            return{icon:'0x1F3A9',title:'El&eacute;gance',label:'interpretation.elegance'};
        case 'comedie':
            return{icon:'0x1F61C',title:'Com&eacute;die',label:'interpretation.comedie'};
        case 'nervosite':
            return{icon:'0x1F62C',title:'Nervosit&eacute;',label:'interpretation.nervosite'};
        case 'tristesse':
            return{icon:'0x1F641',title:'Tristesse',label:'interpretation.tristesse'};
        case 'peur':
            return{icon:'0x1F631',title:'Peur',label:'interpretation.peur'};
        case 'surprise':
            return{icon:'0x1F62F',title:'Surprise', label:'interpretation.surprise'};
        default:
            return null;
    }
}

