import axios from "axios";
import {
  endpointUser,
} from 'util/api';

/**
 * Connexion d'un utilisateur
 * @param {*} payload 
 */
export const signInRequest = async (payload) => {
  const { sourceSignIn, email, password, idUserFacebook } = payload;

  // call WS Loopback
  const wsResponse = await axios.request({
    method: 'post',
    url: `${endpointUser.SIGNIN}`,
    data: {
      sourceSignIn,
      email,
      password,
      idUserFacebook,
      ttl: 86400
    }
  });

  return wsResponse;
}

/**
 * D�connexion d'un utilisateur
 */
export const signOutRequest = async () => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  // call WS Loopback
  const wsResponse = await axios.request({
    method: 'post',
    url: `${endpointUser.SIGNOUT}?access_token=${accessToken}`
  });

  return wsResponse;
}

/**
 * Inscription d'un utilisateur
 * @param {*} payload 
 */
export const signUpRequest = async (payload) => {
  const {
    sourceInscription,
    email,
    password,
    nom,
    prenom,
    infoSociete,
    idUserFacebook,
    avatarFacebook
  } = payload;

  // call WS Loopback
  const wsResponse = await axios.request({
    method: 'post',
    url: `${endpointUser.SIGNUP}`,
    data: {
      sourceInscription,
      id: '',
      email,
      password: sourceInscription === 'form' ? password : 'facebook',
      nom,
      prenom,
      infoSociete,
      idUserFacebook: idUserFacebook || '',
      avatarFacebook: avatarFacebook || '',
    }
  });

  return wsResponse;
}

/**
 * Confirmation d'une inscription
 * @param {*} payload 
 */
export const signUpConfirmRequest = async (payload) => {
  let { idUser, verificationToken } = payload;

  const wsResponse = await axios.request({
    method: 'GET',
    url: `${endpointUser.SIGNUP_CONFIRM}`,
    params: {
      idUser,
      verificationToken
    }
  });

  return wsResponse;
}

/**
 * Envoi d'une requ�te de r�initialisation de mot de passe
 */
export const requestResetPasswordRequest = async (payload) => {
  const { values } = payload;

  // call WS Loopback
  const wsResponse = await axios.request({
    method: 'post',
    url: `${endpointUser.FORGOT_PASSWORD}`,
    data: {
      email: values.email,
    }
  });

  return wsResponse;
}

/**
 * R�initialisation du mot de passe
 * @param {*} payload 
 */
export const resetPasswordRequest = async (payload) => {
  const { values, accessToken } = payload;

  // call WS Loopback
  const wsResponse = await axios.request({
    method: 'post',
    url: `${endpointUser.RESET_PASSWORD}?access_token=${accessToken}`,
    data: {
      newPassword: values.newPassword,
    }
  });


  return wsResponse;
}

/**
 * R�cup�ration des informations d'authentification d'un utilisateur
 * @param {*} payload 
 */
export const getIdentifiantsRequest = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  const { idUser } = payload;

  const wsResponse = await axios.request({
    method: 'get',
    url: `${endpointUser.GET_IDENTIFIANTS}?access_token=${accessToken}`,
    params: {
      idUser
    }
  });

  return wsResponse;
}

/**
 * Modification des identifiants d'un utilisateur
 * @param {*} payload 
 */
export const updateIdentifiantsRequest = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  const { idUser, currentPassword, values } = payload;

  const wsResponse = await axios.request({
    method: 'post',
    url: `${endpointUser.UPDATE_IDENTIFIANTS}?access_token=${accessToken}`,
    data: {
      idUser: idUser,
      currentPassword: currentPassword,
      email: values.email,
    }
  });

  return wsResponse;
}

/**
 * Modification du mot de passe d'un utilisateur
 * @param {*} payload 
 */
export const updatePasswordRequest = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  const { idUser, currentPassword, values } = payload;

  const wsResponse = await axios.request({
    method: 'post',
    url: `${endpointUser.UPDATE_PASSWORD}?access_token=${accessToken}`,
    data: {
      idUser: idUser,
      currentPassword: currentPassword,
      password: values.password,
    }
  });

  return wsResponse;
}

/**
 * Admin/Liste des utilisateurs
 * @param {*} payload 
 */
export const getListUtilisateursRequest = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  var { pageSize, page, keyword } = payload;

  const wsResponse = await axios.request({
    method: 'get',
    url: `${endpointUser.GET_LIST_UTILISATEURS}?access_token=${accessToken}`,
    params: {
      pageSize,
      page,
      keyword
    }
  });

  return wsResponse;
}

/**
 * Admin/Connexion par un compte utilisateur
 * @param {*} payload 
 */
export const switchUtilisateurRequest = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  let { idUser } = payload;

  const wsResponse = await axios.request({
    method: 'GET',
    url: `${endpointUser.SWITCH_UTILISATEUR}?access_token=${accessToken}`,
    params: {
      id: idUser
    }
  });

  return wsResponse;
}

/**
 * Suppression d'un utilisateur
 * @param {*} payload 
 */
export const deleteUtilisateurRequest = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  let { idUser } = payload;

  const wsResponse = await axios.request({
    method: 'POST',
    url: `${endpointUser.DELETE_UTILISATEUR}?access_token=${accessToken}`,
    data: {
      id: idUser
    }
  })

  return wsResponse;
}

/**
 * D�sactivation d'un utilisateur
 * @param {*} payload 
 */
export const desactivationUtilisateurRequest = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  let { idUser } = payload;

  const wsResponse = await axios.request({
    method: 'POST',
    url: `${endpointUser.DESACTIVATION_UTILISATEUR}?access_token=${accessToken}`,
    data: {
      id: idUser
    }
  });

  return wsResponse;
}

/**
 * Activation d'un utilisateur
 * @param {*} payload 
 */
export const activationUtilisateurRequest = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  let { idUser } = payload;

  const wsResponse = await axios.request({
    method: 'POST',
    url: `${endpointUser.ACTIVATION_UTILISATEUR}?access_token=${accessToken}`,
    data: {
      id: idUser
    }
  });

  return wsResponse;
}

/**
 * V�rification de l'email d'un utilisateur
 * @param {*} payload 
 */
export const verificationEmailUtilisateurRequest = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  let { idUser } = payload;

  const wsResponse = await axios.request({
    method: 'POST',
    url: `${endpointUser.VERIF_EMAIL_UTILISATEUR}?access_token=${accessToken}`,
    data: {
      id: idUser
    }
  });

  return wsResponse;
}

/**
 * Profil Utilisateur
 * @param {*} payload 
 */
export const getUserProfilRequest = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  let { idUser } = payload;

  // call WS Loopback
  const wsResponse = await axios.request({
    method: 'get',
    url: `${endpointUser.GET_PROFIL}?access_token=${accessToken}`,
    params: {
      idUser
    }
  });

  return wsResponse;
}

/**
 * Modification du profil d'un utilisateur 
 * @param {*} payload 
 */
export const updateUserProfilRequest = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  const { idUser, values, photoProfil, photoProfilCropped } = payload;

  const formData = new FormData();

  formData.append("idUser", idUser);
  formData.append("photoProfil", photoProfil);
  formData.append("photoProfilCropped", photoProfilCropped);

  const wsResponse = await axios.request({
    method: 'post',
    url: `${endpointUser.UPDATE_PROFIL}?access_token=${accessToken}`,
    data: formData,
    config: {
      headers: {
        'Content-Type': `multipart/form-data`,
        'Accept': 'application/json',
      }
    }
  });
  return wsResponse;
}

/**
 * Coordonn�es d'un utilisateur
 * @param {*} payload 
 */
export const getUserCoordonneesRequest = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  let { idUser } = payload;

  const wsResponse = await axios.request({
    method: 'get',
    url: `${endpointUser.GET_COORDONNEES}?access_token=${accessToken}`,
    params: {
      idUser
    }
  });

  return wsResponse;
}

/**
 * Modification des coordonn�es d'un utilisateur
 */
export const updateUserCoordonneesRequest = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  const { idUser, values } = payload;

  const formData = new FormData();
  formData.append("idUser", idUser);
  formData.append("prenom", values.prenom);
  formData.append("nom", values.nom);
  formData.append("infoSociete", values.infoSociete);
  formData.append("infoActivite", values.infoActivite);
  formData.append("infoSecteur", values.infoSecteur);
  formData.append("coordPortable", values.coordPortable);
  formData.append("coordAdresse", values.coordAdresse);
  formData.append("coordCp", values.coordCp);
  formData.append("coordVille", values.coordVille);
  formData.append("coordPays", values.coordPays);

  const wsResponse = await axios.request({
    method: 'post',
    url: `${endpointUser.UPDATE_COORDONNEES}?access_token=${accessToken}`,
    data: formData,
    config: {
      headers: {
        'Content-Type': `application/x-www-form-urlencoded`,
        'Accept': 'application/json',
      }
    }
  });
  return wsResponse;
}

/**
 * R�cup�ration de l'historique de partages d'un utilsiateur
 * @param {*} payload 
 */
export const getUserHistoriquePartagesRequest = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  const { pageSize, page, action } = payload;

  const wsResponse = await axios.request({
    method: 'get',
    url: `${endpointUser.GET_HISTORIQUE_PARTAGES}?access_token=${accessToken}`,
    params: {
      pageSize,
      page,
      action
    }
  });

  return wsResponse;
}


/**
 * Profil Admin
 * @param {*} payload 
 */
export const getAdminProfilRequest = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  let { idUser } = payload;

  // call WS Loopback
  const wsResponse = await axios.request({
    method: 'get',
    url: `${endpointUser.GET_PROFIL_ADMIN}?access_token=${accessToken}`,
    params: {
      idUser
    }
  });

  return wsResponse;
}

/**
 * Modification du profil d'un Admin 
 * @param {*} payload 
 */
export const updateAdminProfilRequest = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  const { idUser, values, photoProfil, photoProfilCropped } = payload;

  const formData = new FormData();

  formData.append("idUser", idUser);
  formData.append("prenom", values.prenom);
  formData.append("nom", values.nom);
  formData.append("photoProfil", photoProfil);
  formData.append("photoProfilCropped", photoProfilCropped);

  const wsResponse = await axios.request({
    method: 'post',
    url: `${endpointUser.UPDATE_PROFIL_ADMIN}?access_token=${accessToken}`,
    data: formData,
    config: {
      headers: {
        'Content-Type': `multipart/form-data`,
        'Accept': 'application/json',
      }
    }
  });
  return wsResponse;
}

/**
 * Modification des coordonn�es d'un utilisateur
 */
export const updateUserInfosBeforePayementRequest = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  const formData = new FormData();
  formData.append("civilite", payload.civilite);
  formData.append("prenom", payload.prenom);
  formData.append("nom", payload.nom);
  formData.append("coordPortable", payload.telMobile);
  formData.append("coordAdresse", payload.adresse);
  formData.append("coordCp", payload.codePostal);
  formData.append("coordVille", payload.ville);
  formData.append("coordPays", payload.pays);

  const wsResponse = await axios.request({
    method: 'post',
    url: `${endpointUser.UPDATE_INFOS_BEFORE_PAYEMENT}?access_token=${accessToken}`,
    data: formData,
    config: {
      headers: {
        'Content-Type': `application/x-www-form-urlencoded`,
        'Accept': 'application/json',
      }
    }
  });
  return wsResponse;
}

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////














export const getUserRoleRequest = async () => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';
  // call WS Loopback
  const wsResponse = await axios.request({
    method: 'get',
    url: `${endpointUser.GET_ROLE}?access_token=${accessToken}`,
  });
  return wsResponse;
}







export const newsLetterRequest = async (email) => {
  email = email.email
  const wsResponse = await axios.request({
    method: 'put',
    url: `${endpointUser.ADD_USER_TO_NEWSLETTER}`,
    data: {
      email
    }
  });
  return wsResponse;
}

export const getUserConnecteRequest = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  const { idUser } = payload;

  // call WS Loopback
  const wsResponse = await axios.request({
    method: 'get',
    url: `${endpointUser.GET_INFOS_USER}?access_token=${accessToken}`,
    params: {
      idUser: idUser || 0
    }
  });

  return wsResponse;
}



















