import axios from "axios";
import { endpointDemandesPhotoXL } from 'util/api'

/**
 * AJout d'une demande dephoto XL
 * @param {*} payload 
 */
export const addDemandePhotoXLRequest = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  const { values, photo } = payload;

  const formData = new FormData();

  formData.append("phrase", values.phrase);
  formData.append("file", photo);

  const wsResponse = await axios.request({
    method: 'post',
    url: `${endpointDemandesPhotoXL.ADD_DEMANDE}?access_token=${accessToken}`,
    data: formData,
    config: {
      headers: {
        'Content-Type': `multipart/form-data`,
        'Accept': 'application/json',
      }
    }
  });

  return wsResponse;
}

/**
 * R�cup�ration de la derni�re demande d'un com�dien
 */
export const getDemandePhotoXLRequest = async () => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  // call WS Loopback
  const wsResponse = await axios.request({
    method: 'get',
    url: `${endpointDemandesPhotoXL.GET_DEMANDE_COMEDIEN}?access_token=${accessToken}`,
  });

  return wsResponse;
}

/**
 * Liste des demandes de photos XL
 * @param {*} payload 
 */
export const getListDemandesPhotosXLRequest = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';
  
  var { pageSize, page, keyword } = payload;

  const wsResponse = await axios.request({
    method: 'get',
    url: `${endpointDemandesPhotoXL.GET_LIST_DEMANDES}?access_token=${accessToken}`,
    params: {
      pageSize,
      page,
      keyword
    }
  });

  return wsResponse;
}

/**
 * Validation d'une demande de photo XL
 * @param {*} payload 
 */
export const validationDemandePhotoXLRequest = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';
  
  const { idDemande } = payload;

  // call Loopback WS
  const wsResponse = await axios.request({
    method: 'post',
    url: `${endpointDemandesPhotoXL.VALIDATION_DEMANDE}?access_token=${accessToken}`,
    data: { idDemande },
  });

  return wsResponse;
}

/**
 * Refus d'une demande de photo XL
 * @param {*} payload 
 */
export const RefusDemandePhotoXLRequest = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';
  
  const { idDemande } = payload;

  // call Loopback WS
  const wsResponse = await axios.request({
    method: 'post',
    url: `${endpointDemandesPhotoXL.REFUS_DEMANDE}?access_token=${accessToken}`,
    data: { idDemande },
  });

  return wsResponse;
}


