import axios from "axios";
import { endpointConfig } from 'util/api';

/**
 * Mise à jour des coéfficients du Ranking Algolia
 * @param {*} values 
 */
export const updateConfigRankingAlgoliaRequest = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  const {values} = payload;

  const formData = new FormData();

  formData.append("A", values.A);
  formData.append("B", values.B);
  formData.append("C", values.C);
  formData.append("D", values.D);
  formData.append("E", values.E);
  formData.append("F", values.F);
  formData.append("G", values.G);
  formData.append("H", values.H);
  formData.append("I", values.I);

  const wsResponse = await axios.request({
    method: 'post',
    url: `${endpointConfig.UPDATE_COEF_RANKING_ALGOLIA}?access_token=${accessToken}`,
    data: formData,
    config: {
      headers: {
        'Content-Type': `multipart/form-data`,
        'Accept': 'application/json',
      }
    }
  });

  return wsResponse;
}

/**
 * Coéfficients du Ranking Algolia
 * @param {*} payload 
 */
export const getConfigRankingAlgoliaRequest = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  const wsResponse = await axios.request({
    method: 'get',
    url: `${endpointConfig.GET_COEF_RANKING_ALGOLIA}?access_token=${accessToken}`,
  });

  return wsResponse;
}