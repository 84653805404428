import { all } from "redux-saga/effects";
import authSagas from "./Auth";
import comedienSagas from "./Comedien";
import userSagas from "./User";
import administrateurSagas from './Administrateur';
import PlaylistSagas from './Playlist';
import PartageSagas from './Partage';
import LogSagas from './Log';
import CommonSagas from "./Common";

export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    comedienSagas(),
    userSagas(),
    administrateurSagas(),
    PlaylistSagas(),
    PartageSagas(),
    LogSagas(),
    CommonSagas(),
  ]);
}
