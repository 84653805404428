import axios from "axios";
import { endpointAccesConfidentialite } from 'util/api';


/**
 * R�cup�ration de la liste de demandes d'acc�s pour un com�dien
 */
export const getListDemandesAccesRequest = async () => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  // call WS Loopback
  const wsResponse = await axios.request({
    method: 'get',
    url: `${endpointAccesConfidentialite.GET_LIST_DEMANDES}?access_token=${accessToken}`,
  });

  return wsResponse;
}

/**
 * Modification de l'acc�s au num�ro de t�l�phone
 * @param {*} payload 
 */
export const switchAccesTelephoneRequest = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  const { idConfidentialite } = payload;

  // call WS Loopback
  const wsResponse = await axios.request({
    method: 'post',
    url: `${endpointAccesConfidentialite.SWITCH_ACCES_TELEPHONE}?access_token=${accessToken}`,
    data: { idConfidentialite },
    config: {
      headers: {
        'Content-Type': `multipart/form-data`,
        'Accept': 'application/json',
      }
    }
  });

  return wsResponse;
}

/**
 * Modification de l'acc�s aux infos employeur
 * @param {*} payload 
 */
export const switchAccesEmployeurRequest = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  const { idConfidentialite } = payload;

  // call WS Loopback
  const wsResponse = await axios.request({
    method: 'post',
    url: `${endpointAccesConfidentialite.SWITCH_ACCES_EMPLOYEUR}?access_token=${accessToken}`,
    data: { idConfidentialite },
    config: {
      headers: {
        'Content-Type': `multipart/form-data`,
        'Accept': 'application/json',
      }
    }
  });

  return wsResponse;
}

/**
 * Suppression d'une demande d'acc�s
 * @param {*} payload 
 */
export const deleteAccesConfidentialiteRequest = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  const { idConfidentialite } = payload;

  // call WS Loopback
  const wsResponse = await axios.request({
    method: 'post',
    url: `${endpointAccesConfidentialite.DELETE_ACCES}?access_token=${accessToken}`,
    data: { idConfidentialite },
    config: {
      headers: {
        'Content-Type': `multipart/form-data`,
        'Accept': 'application/json',
      }
    }
  });

  return wsResponse;
}

/**
 * R�cup�ration de la liste de demandes d'acc�s pour un utilisateur
 */
export const getUserListDemandesAccesRequest = async () => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  const wsResponce = await axios.request({
    method: 'get',
    url: `${endpointAccesConfidentialite.UTILISATEUR_GET_ACCES_CONFIDENTIALITE}?access_token=${accessToken}`
  });

  return wsResponce;
}

//////////////////////////////////////////////////
/////////////////////////////////////////////////
//////////////////////////////////////////////////


export const demandeAccesTelephoneRequest = async (idComedien) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  // call WS Loopback
  const wsResponse = await axios.request({
    method: 'put',
    url: `${endpointAccesConfidentialite.DEMANDE_ACCES_TELEPHONE}?access_token=${accessToken}`,
    data: { idComedien },
    config: {
      headers: {
        'Content-Type': `multipart/form-data`,
        'Accept': 'application/json',
      }
    }
  });
  return wsResponse;
}

export const demandeAccesEmployeurRequest = async (idComedien) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  // call WS Loopback
  const wsResponse = await axios.request({
    method: 'put',
    url: `${endpointAccesConfidentialite.DEMANDE_ACCES_EMPLOYEUR}?access_token=${accessToken}`,
    data: { idComedien },
    config: {
      headers: {
        'Content-Type': `multipart/form-data`,
        'Accept': 'application/json',
      }
    }
  });
  return wsResponse;
}




