import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  PARTAGE_ADD_EXTRAIT,
  PARTAGE_ADD_PLAYLIST,
  PARTAGE_GET_EXTRAIT_BY_LINK
} from "constants/ActionTypes";

import { 
  showPartageExtraitAction, 
  showPartageExtraitByLinkAction,
  showPartagePlaylistAction
} from "appRedux/actions/Partage";

import {
  addPartageExtraitRequest,
  getPartageExtraitByLinkRequest,
  addPartagePlaylistRequest
} from 'appRedux/services/Partage';

import {
  partageExtraitRequest
} from 'appRedux/services/Log';

//////////////////////////////////////////////////////////////
// Partage d'extrait
/////////////////////////////////////////////////////////////

function* addPartageExtrait({payload}) {
    try {
      const response = yield call(addPartageExtraitRequest,payload);
      if (response.status === 200) {
        yield call(partageExtraitRequest,payload);
        yield put(showPartageExtraitAction(response.data));
      }
    } catch (error) {
    }
  }
  
export function* addPartageExtraitFork() {
  yield takeEvery(PARTAGE_ADD_EXTRAIT, addPartageExtrait);
}


//////////////////////////////////////////////////////////////
// R�cup�rtion d'un partage d'extrait
/////////////////////////////////////////////////////////////

function* getPartageExtraitByLink({ payload }) {
  try {
    const response = yield call(getPartageExtraitByLinkRequest, payload);
    if (response.status && response.status === 200 && response.data) {
      yield put(showPartageExtraitByLinkAction(response.data));
    }
  } catch (error) {
  }
}

export function* getPartageExtraitByLinkFork() {
  yield takeEvery(PARTAGE_GET_EXTRAIT_BY_LINK, getPartageExtraitByLink);
}


//////////////////////////////////////////////////////////////
// Partage dr playlist
/////////////////////////////////////////////////////////////

function* addPartagePlaylist({payload}) {
  try {
    const response = yield call(addPartagePlaylistRequest,payload);
    if (response.status === 200) {
      yield put(showPartagePlaylistAction(response.data));
    }
  } catch (error) {
  }
}

export function* addPartagePlaylistFork() {
  yield takeEvery(PARTAGE_ADD_PLAYLIST, addPartagePlaylist);
}

////////////////////////////////////////////////////////////////////////////
// Exports
////////////////////////////////////////////////////////////////////////////

export default function* rootSaga() {
  yield all([
    fork(getPartageExtraitByLinkFork),
    fork(addPartageExtraitFork),
    fork(addPartagePlaylistFork),
  ]);
}