import React from "react";
import valueList from './cm_value_list.json';
import { getValueList, getGroupeInterpretation } from 'util/ValueList/Values';
import { displayEmoji } from '../displayEmoji';
import { Select, TreeSelect } from "antd";
import Parser from 'html-react-parser';

const Option = Select.Option;
const TreeNode = TreeSelect.TreeNode;

export function getTypeExtraitOptions(params = {}) {
  //const qfType = getValueList('qf_type');
  return (
    <Select
      showSearch
      optionFilterProp="children"
      placeholder={params.placeholder || ''}
    >
      {valueList.map((item, index) => {
        if (item.list_name === 'qf_type') {
          return (
            <Option value={Number(item.id_value)} key={item.id_value}>{(item.icon) ? displayEmoji(item.icon) : ""} {item.fr}</Option>
          );
        }
      })}
    </Select>
  );
}

export function getLangueOptions(params = {}) {
  //const qfLangue = getValueList('qf_langue');
  return (
    <Select
      showSearch
      optionFilterProp="children"
      placeholder={params.placeholder || ''}
      mode={params.multiple ? 'multiple' : ''}
    >
      {valueList.map((item, index) => {
        if (item.list_name === 'qf_langue') {
          return (
            <Option value={Number(item.id_value)} key={item.id_value}>
              <div style={{ display: "flex", alignItems: "center" }}>
                {(item.icon) ? <i className={`flag flag-16 gx-mr-2 ${item.icon}`} /> : ""} {item.fr}
              </div>
            </Option>
          );
        }
      })}
    </Select>
  );
}

export function getAgeExtraitOptions() {
  //var qfAge = getValueList('qf_age');
  // qfAge.sort((a, b) => (Number(a.tri) > Number(b.tri)) ? 1 : -1);

  return (
    <Select
      showSearch
      optionFilterProp="children"
    >
      {valueList.map((item, index) => {
        if (item.list_name === 'qf_age') {
          return (
            <Option value={Number(item.id_value)} key={item.id_value}>{(item.icon) ? displayEmoji(item.icon) : ""} {item.fr}</Option>
          );
        }
      })}
    </Select>
  );
}

export function getTimbreExtraitOptions() {
  //const qfTimbre = getValueList('qf_timbre');
  return (
    valueList.map((item, index) => {
      if (item.list_name === 'qf_timbre') {
        return (
          <Option value={Number(item.id_value)} key={item.id_value}>{(item.icon) ? displayEmoji(item.icon) : ""} {item.fr}</Option>
        );
      }
    })
  );
}

export function getInterpretationExtraitNode(group) {
  return (
    <TreeNode title={displayEmoji(getGroupeInterpretation(group).icon) + "  " + Parser(getGroupeInterpretation(group).title)} key={group}>
      {valueList.map((item, index) => {
        if (item.list_name === 'qf_interpretation') {
          return (
            (item.list_group === group) ?
              <TreeNode value={Number(item.id_value)} title={item.fr} key={item.id_value} isLeaf/>
            : null
          );
        }
      })}
    </TreeNode>
  )

}
export function getInterpretationExtraitOptions() {
  //const qfInterpretation = getValueList('qf_interpretation');
  return (
    <TreeSelect className="gx-w-100"
      showSearch={false}
      dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
      placeholder="Please select"
      allowClear
      treeDefaultExpandAll={false}
    >
      { /* Complicité */}
      <TreeNode selectable={false} value="complicite" title={displayEmoji("0x1F609") + "Complicité"} key="complicite">
        {valueList.map((item, index) => {
          if (item.list_name === 'qf_interpretation') {
            return (
              (item.list_group === "complicite") ?
                <TreeNode value={Number(item.id_value)} title={item.fr} key={item.id_value} />
                : null
            );
          }
        })}
      </TreeNode>

      { /* Elégance */}
      <TreeNode selectable={false} value="elegance" title={displayEmoji("0x1F3A9") + "Elégance"} key="elegance">
        {valueList.map((item, index) => {
          if (item.list_name === 'qf_interpretation') {
            return (
              (item.list_group === "elegance") ?
                <TreeNode value={Number(item.id_value)} title={item.fr} key={item.id_value} />
                : null
            );
          }
        })}
      </TreeNode>

      { /* Nervosité */}
      <TreeNode selectable={false} value="nervosite" title={displayEmoji("0x1F62C") + "Nervosité"} key="nervosite">
        {valueList.map((item, index) => {
          if (item.list_name === 'qf_interpretation') {
            return (
              (item.list_group === "nervosite") ?
                <TreeNode value={Number(item.id_value)} title={item.fr} key={item.id_value} />
                : null
            );
          }
        })}
      </TreeNode>

      { /* Neutre */}
      <TreeNode selectable={false} value="neutre" title={displayEmoji("0x1F610") + "Neutre"} key="neutre">
        {valueList.map((item, index) => {
          if (item.list_name === 'qf_interpretation') {
            return (
              (item.list_group === "neutre") ?
                <TreeNode value={Number(item.id_value)} title={item.fr} key={item.id_value} />
                : null
            );
          }
        })}
      </TreeNode>

      { /* Comédie */}
      <TreeNode selectable={false} value="comedie" title={displayEmoji("0x1F61C") + "Comédie"} key="comedie">
        {valueList.map((item, index) => {
          if (item.list_name === 'qf_interpretation') {
            return (
              (item.list_group === "comedie") ?
                <TreeNode value={Number(item.id_value)} title={item.fr} key={item.id_value} />
                : null
            );
          }
        })}
      </TreeNode>

      { /* Sourire */}
      <TreeNode selectable={false} value="sourire" title={displayEmoji("0x1F600") + "Sourire"} key="sourire">
        {valueList.map((item, index) => {
          if (item.list_name === 'qf_interpretation') {
            return (
              (item.list_group === "sourire") ?
                <TreeNode value={Number(item.id_value)} title={item.fr} key={item.id_value} />
                : null
            );
          }
        })}
      </TreeNode>

      { /* Sensualité */}
      <TreeNode selectable={false} value="sensualite" title={displayEmoji("0x1F60D") + "Sensualité"} key="sensualite">
        {valueList.map((item, index) => {
          if (item.list_name === 'qf_interpretation') {
            return (
              (item.list_group === "sensualite") ?
                <TreeNode value={Number(item.id_value)} title={item.fr} key={item.id_value} />
                : null
            );
          }
        })}
      </TreeNode>

      { /* Energie */}
      <TreeNode selectable={false} value="energie" title={displayEmoji("0x1F4A5") + "Energie"} key="energie">
        {valueList.map((item, index) => {
          if (item.list_name === 'qf_interpretation') {
            return (
              (item.list_group === "energie") ?
                <TreeNode value={Number(item.id_value)} title={item.fr} key={item.id_value} />
                : null
            );
          }
        })}
      </TreeNode>

      { /* Tristesse */}
      <TreeNode selectable={false} value="tristesse" title={displayEmoji("0x1F641") + "Tristesse"} key="tristesse">
        {valueList.map((item, index) => {
          if (item.list_name === 'qf_interpretation') {
            return (
              (item.list_group === "tristesse") ?
                <TreeNode value={Number(item.id_value)} title={item.fr} key={item.id_value} />
                : null
            );
          }
        })}
      </TreeNode>

      { /* Surprise */}
      <TreeNode selectable={false} value="surprise" title={displayEmoji("0x1F62F") + "Surprise"} key="surprise">
        {valueList.map((item, index) => {
          if (item.list_name === 'qf_interpretation') {
            return (
              (item.list_group === "surprise") ?
                <TreeNode value={Number(item.id_value)} title={item.fr} key={item.id_value} />
                : null
            );
          }
        })}
      </TreeNode>

      { /* Peur */}
      <TreeNode selectable={false} value="peur" title={displayEmoji("0x1F631") + "Peur"} key="peur">
        {valueList.map((item, index) => {
          if (item.list_name === 'qf_interpretation') {
            return (
              (item.list_group === "peur") ?
                <TreeNode value={Number(item.id_value)} title={item.fr} key={item.id_value} />
                : null
            );
          }
        })}
      </TreeNode>
    </TreeSelect>
  );
}

export function getDiffusionOptions(params = {}) {
  //const qfType = getValueList('qf_diffusion');
  return (
    <Select
      showSearch
      optionFilterProp="children"
      placeholder={params.placeholder || ''}
    >
      {valueList.map((item, index) => {
        if (item.list_name === 'qf_diffusion') {
          return (
            <Option value={Number(item.id_value)} key={item.id_value}>{item.fr}</Option>
          );
        }
      })}
    </Select>
  )
}

export function getCachetOptions(params = {}) {
  const qfType = getValueList('qf_cachet');
  return (
    <Select
      showSearch
      optionFilterProp="children"
      placeholder={params.placeholder || ''}
    >
      {qfType.map((item, index) => {
        return (
          <Option value={Number(item.id_value)} key={item.id_value}>{Parser(item.fr)}</Option>
        );
      })}
    </Select>
  )
}

