import axios from "axios";
import { endpointNews } from 'util/api';

/**
 * Ajout d'une News
 * @param {*} payload 
 */
export const addNewsRequest = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  const { values } = payload;
  const formData = new FormData();

  formData.append("note", values.note);

  // call Loopback WS
  const wsResponse = await axios.request({
    method: 'post',
    url: `${endpointNews.ADD_NEWS}?access_token=${accessToken}`,
    data: formData,
    config: {
      headers: {
        'Content-Type': `multipart/form-data`,
        'Accept': 'application/json',
      }
    }
  });

  return wsResponse;
}

/**
 * R�cup�ration de la liste des News d'un com�dien
 * @param {*} payload 
 */
export const getListNewsRequest = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  let { idUser } = payload;

  // call Loopback WS
  const wsResponse = await axios.request({
    method: 'get',
    url: `${endpointNews.GET_LIST_NEWS}?access_token=${accessToken}`,
    params: {
      idUser
    }
  });

  return wsResponse;
}

/**
 * Liste de News de tous les com�diens
 * @param {*} payload 
 */
export const getListNewsComediensRequest = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  var { pageSize, page, keyword } = payload;

  const wsResponse = await axios.request({
    method: 'get',
    url: `${endpointNews.GET_ALL_NEWS}?access_token=${accessToken}`,
    params: {
      pageSize,
      page,
      keyword
    }
  });

  return wsResponse;
}

/**
 * Suppression d'une News
 * @param {*} payload 
 */
export const deleteNewsRequest = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  var { idNews } = payload;

  // call Loopback WS
  const wsResponse = await axios.request({
    method: 'delete',
    url: `${endpointNews.DELETE_NEWS}?access_token=${accessToken}`,
    data: {
      idNews
    }
  });

  return wsResponse;
}

/**
 * R�cup�ration d'une News 
 * @param {*} payload 
 */
export const getNewsRequest = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  const { idNews } = payload

  const wsResponse = await axios.request({
    method: 'get',
    url: `${endpointNews.GET_NEWS}?idNews=${idNews}&access_token=${accessToken}`,
  });

  return wsResponse;
}

/**
 * Modification d'une News
 * @param {*} payload 
 */
export const updateNewsRequest = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  const { values, idNews } = payload;

  const formData = new FormData();

  formData.append("contenu", values.news);
  formData.append("idNews", idNews);

  const wsResponse = await axios.request({
    method: 'post',
    url: `${endpointNews.UPDATE_NEWS}?access_token=${accessToken}`,
    data: formData,

  });
  return wsResponse;
}

/////////////////////////////////////////////////////
////////////////////////////////////////////////////
///////////////////////////////////////////////////







/////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////
















export const getDerniersNewsComediensRequest = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  // call Loopback WS
  const wsResponse = await axios.request({
    method: 'get',
    url: `${endpointNews.GET_DERNIERS_NEWS_COMEDIENS}?access_token=${accessToken}`,
  });

  return wsResponse;
}