import {
  SWITCH_LANGUAGE, 
  TOGGLE_COLLAPSED_NAV, 
  TOGGLE_COLLAPSED_MOBILE_MENU,
  WINDOW_WIDTH
} from "constants/ActionTypes";

import {
  LAYOUT_TYPE_FULL,
  NAV_STYLE_FIXED,
  THEME_COLOR_SELECTION_PRESET,
  THEME_TYPE_SEMI_DARK
} from "constants/ThemeSetting";

const initialSettings = {
  navCollapsed: true,
  mobileMenuCollapsed: true,
  navStyle: NAV_STYLE_FIXED,
  layoutType: LAYOUT_TYPE_FULL,
  themeType: THEME_TYPE_SEMI_DARK,
  colorSelection: THEME_COLOR_SELECTION_PRESET,
  pathname: '',
  width: window.innerWidth,
  isDirectionRTL: false,
  locale: {
    languageId: 'french',
    locale: 'fr',
    name: 'French',
    icon: 'fr'
  }
};

const settings = (state = initialSettings, action) => {
  switch (action.type) {

    case '@@router/LOCATION_CHANGE':
      return {
        ...state,
        pathname: action.payload.pathname,
        navCollapsed: false,
        mobileMenuCollapsed: false,
      };

    case TOGGLE_COLLAPSED_NAV:
      return {
        ...state,
        navCollapsed: action.navCollapsed,
      };

    case TOGGLE_COLLAPSED_MOBILE_MENU:
      return {
        ...state,
        mobileMenuCollapsed: action.mobileMenuCollapsed
      };

    case WINDOW_WIDTH:
      return {
        ...state,
        width: action.width,
      };
    
    case SWITCH_LANGUAGE:
      return {
        ...state,
        locale: action.payload,

      };

    default:
      return state;
  }
};

export default settings;
