import axios from "axios";
import { endpointArticle } from 'util/api'

/**
 * Récupération de la liste des articles
 * @param {*} payload 
 */
export const getListeArticlesRequest = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  var { keyword } = payload;

  const wsResponse = await axios.request({
    method: 'get',
    url: `${endpointArticle.GET_LIST_ARTICLES}?access_token=${accessToken}`,
    params: {
      keyword
    }
  })

  return wsResponse;
}

/**
 * Ajout d'un article
 * @param {*} payload 
 */
export const addArticleRequest = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  const { values, contenu } = payload;

  const formData = new FormData();

  formData.append("actif", values.actif ? 1 : 0);
  formData.append("titre", values.titre);
  formData.append("contenu", contenu);

  const wsResponse = await axios.request({
    method: 'post',
    url: `${endpointArticle.ADD_ARTICLE}?access_token=${accessToken}`,
    data: formData,
    config: {
      headers: {
        'Content-Type': `multipart/form-data`,
        'Accept': 'application/json',
      }
    }
  });

  return wsResponse;
}

/**
 * Récupération d'une instance article
 * @param {*} payload 
 */
export const getArticleRequest = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  var { idArticle } = payload;

  const wsResponse = await axios.request({
    method: 'get',
    url: `${endpointArticle.GET_ARTICLE}?access_token=${accessToken}`,
    params: {
      idArticle
    }
  })

  return wsResponse;
}

/**
 * Ajout d'un article
 * @param {*} payload 
 */
export const updateArticleRequest = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  const { idArticle, values, contenu } = payload;

  const formData = new FormData();
  
  formData.append("idArticle", idArticle);
  formData.append("actif", values.actif ? 1 : 0);
  formData.append("titre", values.titre);
  formData.append("contenu", contenu);

  const wsResponse = await axios.request({
    method: 'post',
    url: `${endpointArticle.UPDATE_ARTICLE}?access_token=${accessToken}`,
    data: formData,
    config: {
      headers: {
        'Content-Type': `multipart/form-data`,
        'Accept': 'application/json',
      }
    }
  });

  return wsResponse;
}

/**
 * Suppression d'un article
 * @param {*} payload 
 */
export const deleteArticleRequest = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  const { idArticle} = payload;

  const wsResponse = await axios.request({
    method: 'delete',
    url: `${endpointArticle.DELETE_ARTICLE}?access_token=${accessToken}`,
    data: {
      idArticle
    }
  });

  return wsResponse;
}






