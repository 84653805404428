import {

  PLAYLIST_GET_LIST,
  PLAYLIST_SHOW_LIST,
  PLAYLIST_CREATE,
  PLAYLIST_CREATE_ADD_EXTRAIT,
  PLAYLIST_UPDATE,
  PLAYLIST_COPY,
  PLAYLIST_DELETE,
  PLAYLIST_SELECT,
  PLAYLIST_SORT_EXTRAITS,
  PLAYLIST_ADD_EXTRAIT,
  PLAYLIST_RETRAIT_EXTRAIT,

  //////////////////////////////



  PLAYLISTS_GET_LISTE_DECOUVERTE,
  PLAYLISTS_SHOW_LISTE_DECOUVERTE,
  PLAYLISTS_GET_PARTAGE,
  PLAYLISTS_SHOW_PARTAGE,

} from "constants/ActionTypes";

//////////////////////////////////////////////////////////////
// List des playists
//////////////////////////////////////////////////////////////

export function getPlayListsAction() {
  return {
    type: PLAYLIST_GET_LIST
  };
};

export function showPlayListsAction(playlists) {
  return {
    type: PLAYLIST_SHOW_LIST,
    playlists
  };
}

//////////////////////////////////////////////////////////////
// Cr�ation d'une Playlist
//////////////////////////////////////////////////////////////

export const createPlaylistAction = (values, photo) => {
  return {
    type: PLAYLIST_CREATE,
    payload: {
      values,
      photo
    }
  };
};

//////////////////////////////////////////////////////////////
// Cr�ation d'une Playlist et ajout de l'extrait
//////////////////////////////////////////////////////////////

export const createPlaylistAddExtraitAction = (idExtrait, nomPlaylist) => {
  return {
    type: PLAYLIST_CREATE_ADD_EXTRAIT,
    payload: {
      idExtrait,
      nomPlaylist
    }
  };
};

//////////////////////////////////////////////////////////////
// Modification d'une Playlist
//////////////////////////////////////////////////////////////

export const updatePlaylistAction = (idPlaylist, values, photo) => {
  return {
    type: PLAYLIST_UPDATE,
    payload: {
      idPlaylist,
      values,
      photo
    }
  };
};

//////////////////////////////////////////////////////////////
// Copie d'une Playlist
//////////////////////////////////////////////////////////////

export const copyPlaylistAction = (idPlaylist) => {
  return {
    type: PLAYLIST_COPY,
    payload: {
      idPlaylist
    }
  };
};

//////////////////////////////////////////////////////////////
// Suppression d'une Playlist
//////////////////////////////////////////////////////////////

export const deletePlaylistAction = (idPlaylist) => {
  return {
    type: PLAYLIST_DELETE,
    payload: {
      idPlaylist,
    }
  };
};

//////////////////////////////////////////////////////////////
// Playists decouvertes
//////////////////////////////////////////////////////////////

export function getPlayListsDecouverteAction(withMP3=0) {
  return {
    type: PLAYLISTS_GET_LISTE_DECOUVERTE,
    payload: {
      withMP3,
    }
  };
};

export function showPlayListsDecouverteAction(playlistsDecouverte) {
  return {
    type: PLAYLISTS_SHOW_LISTE_DECOUVERTE,
    playlistsDecouverte
  };
}


//////////////////////////////////////////////////////////////
// Playists partag�
//////////////////////////////////////////////////////////////

export function getPlayListPartageAction(lien) {
  return {
    type: PLAYLISTS_GET_PARTAGE,
    payload: {
      lien
    }
  };
};

export function showPlayListPartageAction(playlistPartage) {
  return {
    type: PLAYLISTS_SHOW_PARTAGE,
    playlistPartage
  };
}

//////////////////////////////////////////////////////////////
// Selection d'une Playlist � partir du r�sultat de recherche
//////////////////////////////////////////////////////////////

export const selectPlaylistAction = (idSelectedPlaylist) => {
  return {
    type: PLAYLIST_SELECT,
    idSelectedPlaylist,
  };
};

//////////////////////////////////////////////////////////////
// Tri d'extraits d'une playlist
//////////////////////////////////////////////////////////////

export const sortExtraitsAction = (idPlaylist, sortString) => {
  return {
    type: PLAYLIST_SORT_EXTRAITS,
    payload: {
      idPlaylist,
      sortString,
    },
  };
};

//////////////////////////////////////////////////////////////
// Ajout d'un extrait � une playlist
//////////////////////////////////////////////////////////////

export const addExtraitToPlaylistAction = (idSon, idPlaylist) => {
  return {
    type: PLAYLIST_ADD_EXTRAIT,
    payload: {
      idSon,
      idPlaylist
    }
  };
};

//////////////////////////////////////////////////////////////
// Retrait d'un extrait d'une playlist
//////////////////////////////////////////////////////////////

export const retraitExtraitFromPlaylistAction = (idSon, idPlaylist) => {
  return {
    type: PLAYLIST_RETRAIT_EXTRAIT,
    payload: {
      idSon,
      idPlaylist
    }
  };
};