import axios from "axios";
import { endpointAbonnement } from 'util/api';


/**
 * R�cup�ration de la liste des factures
 */
export const getFacturesRequest = async () => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  const wsResponse = await axios.request({
    method: 'get',
    url: `${endpointAbonnement.GET_LIST_FACTURES}?access_token=${accessToken}`
  });
  
  return wsResponse;
}