import {
    USER_ROLE_COMEDIEN,
    USER_ROLE_UTILISATEUR,
    USER_ROLE_ADMIN,
    USER_ROLE_ANONYMOUS
} from "../constants/General";
  
export function getCurrentUserRole() {
    const user = localStorage.user;
    if(!user){
      return USER_ROLE_ANONYMOUS;
    }
    let userObject = JSON.parse(user);
    return Number(userObject.userRole);
}


export function getCurrentUser() {
    const user = localStorage.user;
    if(!user){
        return null;
    }
    let userObject = JSON.parse(user);
    userObject.userId   = Number(userObject.userId);
    userObject.userRole = Number(userObject.userRole);
    return userObject;
}
