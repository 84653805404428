import React, { Component } from "react";
import { connect } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import { ConfigProvider } from "antd";
import { IntlProvider } from "react-intl";
import AppLocale from "lngProvider";
import MainApp from "./MainApp";
import FullWidthApp from "./FullWidthApp";
import loadable from '@loadable/component';

/////////////////////////////////////////////////////////////
// Routes Publiques
/////////////////////////////////////////////////////////////

const Home = loadable(() =>
  import(/* webpackChunkName: 'home' */ 'routes/Pages/Home'));

const Equipe = loadable(() =>
  import(/* webpackChunkName: 'equipe' */ 'routes/Pages/Equipe'));

const CGU = loadable(() =>
  import(/* webpackChunkName: 'cgu' */ 'routes/Pages/CGU'));

const RGPD = loadable(() =>
  import(/* webpackChunkName: 'rgpd' */ 'routes/Pages/RGPD'));

const Aide = loadable(() =>
  import(/* webpackChunkName: 'aide' */ 'routes/Pages/Aide'));

const Article = loadable(() =>
  import(/* webpackChunkName: 'article' */ 'routes/Pages/Article'));

const HomeComedien = loadable(() =>
  import(/* webpackChunkName: 'home_comedien' */ 'routes/Pages/HomeComedien'));

const Recherche = loadable(() =>
  import(/* webpackChunkName: 'recherche' */ 'routes/Recherche'));

const UrlPropreComedien = loadable(() =>
  import(/* webpackChunkName: 'url_propre_comedien' */ 'routes/UrlPropreComedien'));

const Decouverte = loadable(() =>
  import(/* webpackChunkName: 'decouverte' */ 'routes/Pages/Decouverte'));

const ResetPassword = loadable(() =>
  import(/* webpackChunkName: 'reset_password' */ 'routes/Pages/Auth/ResetPassword'));

const PartageExtrait = loadable(() =>
  import(/* webpackChunkName: 'partage_extrait' */ 'routes/Pages/Partage/PartageExtrait'));

const PartagePlaylist = loadable(() =>
  import(/* webpackChunkName: 'partage_playlist' */ 'routes/Pages/Partage/PartagePlaylist'));

const Error404 = loadable(() =>
  import(/* webpackChunkName: 'error_404' */ 'routes/ErrorPages/Error404'));

/////////////////////////////////////////////////////////////
// Routes Admin
/////////////////////////////////////////////////////////////

const GestionUtilisateurs = loadable(() =>
  import(/* webpackChunkName: 'admin_gestion_utilisateurs' */ 'routes/Administrateur/GestionUtilisateurs'));

const GestionComediens = loadable(() =>
  import(/* GestionComediens: 'admin_gestion_comediens' */ 'routes/Administrateur/GestionComediens'));

const DemandesInscriptionsComediens = loadable(() =>
  import(/* webpackChunkName: 'admin_demandes_inscriptions_comediens' */ 'routes/Administrateur/DemandesInscriptionsComediens'));

const Statistiques = loadable(() =>
  import(/* webpackChunkName: 'admin_statistiques' */ 'routes/Administrateur/Statistiques'));

const NewsComediens = loadable(() =>
  import(/* webpackChunkName: 'admin_news_comediens' */ 'routes/Administrateur/NewsComediens'));

const ExtraitsMP3 = loadable(() =>
  import(/* webpackChunkName: 'admin_extraits_mp3' */ 'routes/Administrateur/ExtraitsMP3'));

const MesPlaylistsAdmin = loadable(() =>
  import(/* webpackChunkName: 'admin_mes_playlists' */ 'routes/Administrateur/MesPlaylists'));

const DemandesPhotosXL = loadable(() =>
  import(/* webpackChunkName: 'admin_demandes_photos_xl' */ 'routes/Administrateur/GestionPhotosXL/Demandes'));

const PhotosXL = loadable(() =>
  import(/* webpackChunkName: 'admin_photos_xl' */ 'routes/Administrateur/GestionPhotosXL/Photos'));

const Blogs = loadable(() =>
  import(/* webpackChunkName: 'admin_blogs' */ 'routes/Administrateur/Blogs'));

const TemoignagesComediens = loadable(() =>
  import(/* webpackChunkName: 'admin_temoignages_comediens' */ 'routes/Administrateur/TemoignagesComediens'));

const AdminCGU = loadable(() =>
  import(/* webpackChunkName: 'admin_cgu' */ 'routes/Administrateur/CMS/AdminCGU'));

const AdminRGPD = loadable(() =>
  import(/* webpackChunkName: 'admin_rgpd' */ 'routes/Administrateur/CMS/AdminRGPD'));

const AdminArticles = loadable(() =>
  import(/* webpackChunkName: 'admin_articles' */ 'routes/Administrateur/CMS/Articles'));

const AdminMedias = loadable(() =>
  import(/* webpackChunkName: 'admin_medias' */ 'routes/Administrateur/CMS/Medias'));

const AdminAide = loadable(() =>
  import(/* webpackChunkName: 'admin_aide' */ 'routes/Administrateur/Aide'));

const StatsAdmin = loadable(() =>
  import(/* webpackChunkName: 'admin_stats' */ 'routes/Administrateur/Stats'));

const AlgoliaRanking = loadable(() =>
  import(/* webpackChunkName: 'admin_algolia_ranking' */ 'routes/Administrateur/Config/AlgoliaRanking'));

const MonCompteAdmin = loadable(() =>
  import(/* webpackChunkName: 'admin_mon_compte' */ 'routes/Administrateur/MonCompte'));

const CampagnesCom = loadable(() =>
  import(/* webpackChunkName: 'admin_campagnes_com' */ 'routes/Administrateur/CampagnesCom'));

/////////////////////////////////////////////////////////////
// Routes Utilisateur
/////////////////////////////////////////////////////////////

const MesPlaylistsUser = loadable(() =>
  import(/* webpackChunkName: 'user_playlists' */ 'routes/User/MesPlaylists'));

const CompteUtilisateur = loadable(() =>
  import(/* webpackChunkName: 'user_mon_compte' */ 'routes/User/MonCompte'));

const MessagesUser = loadable(() =>
  import(/* webpackChunkName: 'user_messages' */ 'routes/User/Messages'));

const AccesConfidentialiteUser = loadable(() =>
  import(/* webpackChunkName: 'user_acces_confidentialite' */ 'routes/User/AccesConfidentialite'));

const ConfirmationInscription = loadable(() =>
  import(/* webpackChunkName: 'user_confirmation_inscription' */ 'routes/User/Inscription/ConfirmationInscription'));

const PaiementInscriptionUtilisateur = loadable(() =>
  import(/* webpackChunkName: 'user_paiement_inscription' */ 'routes/User/Inscription/PaiementInscriptionUtilisateur'));

const ReponsePayBoxInscription = loadable(() =>
  import(/* webpackChunkName: 'user_reponse_paybox_inscriptions' */ 'routes/User/Inscription/ReponsePayBoxInscription'));

/////////////////////////////////////////////////////////////
// Routes Comediens
/////////////////////////////////////////////////////////////

const MonProfil = loadable(() =>
  import(/* webpackChunkName: 'comedien_mon_profil' */ 'routes/Comedien/MonProfil'));

const MonCv = loadable(() =>
  import(/* webpackChunkName: 'comedien_mon_cv' */ 'routes/Comedien/MonCv'));

const MesPhotos = loadable(() =>
  import(/* webpackChunkName: 'comedien_mes_photos' */ 'routes/Comedien/MesPhotos'));

const MesExtraisMP3 = loadable(() =>
  import(/* webpackChunkName: 'comedien_mes_extrais_mp3' */ 'routes/Comedien/MesExtraisMP3'));

const MesVideos = loadable(() =>
  import(/* webpackChunkName: 'comedien_mes_videos' */ 'routes/Comedien/MesVideos'));

const MesNews = loadable(() =>
  import(/* webpackChunkName: 'comedien_mes_news' */ 'routes/Comedien/MesNews'));

const MesInfosEmployeur = loadable(() =>
  import(/* webpackChunkName: 'comedien_mes_infos_employeur' */ 'routes/Comedien/MesInfosEmployeur'));

const MonEquipement = loadable(() =>
  import(/* webpackChunkName: 'comedien_mon_equipement' */ 'routes/Comedien/MonEquipement'));

const MonCompte = loadable(() =>
  import(/* webpackChunkName: 'comedien_mon_compte' */ 'routes/Comedien/MonCompte'));

const MessagesComedien = loadable(() =>
  import(/* webpackChunkName: 'comedien_messages' */ 'routes/Comedien/Messages'));

const MesPlaylistsComedien = loadable(() =>
  import(/* webpackChunkName: 'comedien_mes_playlists' */ 'routes/Comedien/MesPlaylists'));

const ResponsePayBox = loadable(() =>
  import(/* webpackChunkName: 'comedien_response_paybox' */ 'routes/Comedien/MonCompte/ResponsePayBox'));

const MesStats = loadable(() =>
  import(/* webpackChunkName: 'comedien_mes_stats' */ 'routes/Comedien/MesStats'));

const AccesConfidentialiteComedien = loadable(() =>
  import(/* webpackChunkName: 'user_messages' */ 'routes/Comedien/AccesConfidentialite'));

const GestionHistoriquePartage = loadable(() =>
  import(/* webpackChunkName: 'comedien_gestion_historique_partage' */ 'routes/Comedien/gestionHistoriquePartage'));

const ReInscription = loadable(() =>
  import(/* webpackChunkName: 'comedien_reinscription' */ 'routes/Comedien/ReInscription/ReInscription'));

const ReponsePayBoxReInscription = loadable(() =>
  import(/* webpackChunkName: 'comedien_reponse_paybox_reinscription' */ 'routes/Comedien/ReInscription/ReponsePayBoxReInscription'));

class App extends Component {

  setLayoutType = (layoutType) => {
    document.body.classList.remove('boxed-layout');
    document.body.classList.remove('framed-layout');
    document.body.classList.add('full-layout');
  };

  setNavStyle = (navStyle) => {
    document.body.classList.remove('full-scroll');
    document.body.classList.remove('horizontal-layout');
  };

  componentDidMount() {
  }

  render() {
    const { layoutType, navStyle, locale, initURL } = this.props;

    if (window.location.pathname === '') {
      if (localStorage.getItem('user') !== "") {
        return (<Navigate to={'/'} />);
      } else if (initURL === '' || initURL === '/') {
        return (<Navigate to={'/Comedien/MonProfil'} />);
      } else {
        return (<Navigate to={initURL} />);
      }

    }

    this.setLayoutType(layoutType);

    this.setNavStyle(navStyle);

    const currentAppLocale = AppLocale[locale.locale];
    return (

      <ConfigProvider locale={currentAppLocale.antd}>
        <IntlProvider
          locale={currentAppLocale.locale}
          messages={currentAppLocale.messages}>
          <Routes>
            <Route path='/' element={<FullWidthApp componentToRender={<Home />} />} />
            <Route path='/aide' element={<FullWidthApp componentToRender={<Aide />} />} />
            <Route path='/article/:idArticle' element={<FullWidthApp componentToRender={<Article />} />} />
            <Route path='/reset-password' element={<FullWidthApp componentToRender={<ResetPassword />} />} />
            <Route path='/cgu' element={<FullWidthApp componentToRender={<CGU />} />} />
            <Route path='/decouverte' element={<FullWidthApp componentToRender={<Decouverte />} />} />
            <Route path='/decouverte/:selectedPlaylist' element={<FullWidthApp componentToRender={<Decouverte />} />} />
            <Route path='/equipe' element={<FullWidthApp componentToRender={<Equipe />} />} />
            <Route path='/HomeComedien' element={<FullWidthApp componentToRender={<HomeComedien />} />} />
            <Route path='/extrait/:link' element={<FullWidthApp componentToRender={<PartageExtrait />} />} />
            <Route path='/casting/:link' element={<FullWidthApp componentToRender={<PartagePlaylist />} />} />
            <Route path='/rgpd' element={<FullWidthApp componentToRender={<RGPD />} />} />
            <Route path='/PaiementInscription' element={<FullWidthApp componentToRender={<PaiementInscriptionUtilisateur />} />} />
            <Route path='/ReInscription' element={<FullWidthApp componentToRender={<ReInscription />} />} />
            <Route path='/error-404' element={<FullWidthApp componentToRender={<Error404 />} />} />
            <Route path='/ReponsePayBoxInscription' element={<FullWidthApp componentToRender={<ReponsePayBoxInscription />} />} />
            <Route path='/ReponsePayBoxReInscription' element={<FullWidthApp componentToRender={<ReponsePayBoxReInscription />} />} />
            <Route path='/confirmation-inscription' element={<FullWidthApp componentToRender={<ConfirmationInscription />} />} />
            <Route path='/:urlComedien' element={<FullWidthApp componentToRender={<UrlPropreComedien />} />} />

            {/**** Anciens URL (Garder temporairement pour l'indexation */}
            <Route path='/conditions-generales-utilisation' element={<FullWidthApp componentToRender={<CGU />} />} />
            <Route path='/comedien-voix-off/:urlComedien' element={<FullWidthApp componentToRender={<UrlPropreComedien />} />} />

            <Route path='/recherche' element={<MainApp componentToRender={<Recherche page="recherche" />} />} />

            <Route path='/Administrateur'>
              <Route path='GestionUtilisateurs' element={<MainApp componentToRender={<GestionUtilisateurs />} />} />
              <Route path='GestionComediens' element={<MainApp componentToRender={<GestionComediens />} />} />
              <Route path='DemandesInscriptionsComediens' element={<MainApp componentToRender={<DemandesInscriptionsComediens />} />} />
              <Route path='Statistiques' element={<MainApp componentToRender={<Statistiques />} />} />
              <Route path='NewsComediens' element={<MainApp componentToRender={<NewsComediens />} />} />
              <Route path='ExtraitsMP3' element={<MainApp componentToRender={<ExtraitsMP3 />} />} />
              <Route path='MesPlaylists' element={<MainApp componentToRender={<MesPlaylistsAdmin />} />} />
              <Route path='DemandesPhotosXL' element={<MainApp componentToRender={<DemandesPhotosXL />} />} />
              <Route path='PhotosXL' element={<MainApp componentToRender={<PhotosXL />} />} />
              <Route path='Blogs' element={<MainApp componentToRender={<Blogs />} />} />
              <Route path='TemoignagesComediens' element={<MainApp componentToRender={<TemoignagesComediens />} />} />
              <Route path='AdminCGU' element={<MainApp componentToRender={<AdminCGU />} />} />
              <Route path='AdminRGPD' element={<MainApp componentToRender={<AdminRGPD />} />} />
              <Route path='AdminAide' element={<MainApp componentToRender={<AdminAide />} />} />
              <Route path='AdminArticles' element={<MainApp componentToRender={<AdminArticles />} />} />
              <Route path='AdminMedias' element={<MainApp componentToRender={<AdminMedias />} />} />
              <Route path='Stats' element={<MainApp componentToRender={<StatsAdmin />} />} />
              <Route path='AlgoliaRanking' element={<MainApp componentToRender={<AlgoliaRanking />} />} />
              <Route path='MonCompteAdmin' element={<MainApp componentToRender={<MonCompteAdmin />} />} />
              <Route path='CampagnesCom' element={<MainApp componentToRender={<CampagnesCom />} />} />
            </Route>

            <Route path='/Comedien'>
              <Route path='mon-profil-comedien' element={<MainApp componentToRender={<MonProfil />} />} />
              <Route path='MonCv' element={<MainApp componentToRender={<MonCv />} />} />
              <Route path='MesPhotos' element={<MainApp componentToRender={<MesPhotos />} />} />
              <Route path='MesExtraisMP3' element={<MainApp componentToRender={<MesExtraisMP3 />} />} />
              <Route path='MesVideos' element={<MainApp componentToRender={<MesVideos />} />} />
              <Route path='MesNews' element={<MainApp componentToRender={<MesNews />} />} />
              <Route path='MesInfosEmployeur' element={<MainApp componentToRender={<MesInfosEmployeur />} />} />
              <Route path='MonEquipement' element={<MainApp componentToRender={<MonEquipement />} />} />
              <Route path='MonCompte' element={<MainApp componentToRender={<MonCompte />} />} />
              <Route path='MesPlaylists' element={<MainApp componentToRender={<MesPlaylistsComedien />} />} />
              <Route path='MesStats' element={<MainApp componentToRender={<MesStats />} />} />
              <Route path='AccesConfidentialite' element={<MainApp componentToRender={<AccesConfidentialiteComedien />} />} />
              <Route path='gestionHistoriquePartage' element={<MainApp componentToRender={<GestionHistoriquePartage />} />} />
              <Route path='messages' element={<MainApp componentToRender={<MessagesComedien />} />} />
              <Route path='ResponsePayBox' element={<MainApp componentToRender={<ResponsePayBox />} />} />
            </Route>

            <Route path='/User'>
              <Route path='MonCompte' element={<MainApp componentToRender={<CompteUtilisateur />} />} />
              <Route path='AcceConfidentialite' element={<MainApp componentToRender={<AccesConfidentialiteUser />} />} />
              <Route path='MesPlaylists' element={<MainApp componentToRender={<MesPlaylistsUser />} />} />
              <Route path='messages' element={<MainApp componentToRender={<MessagesUser />} />} />
            </Route>

          </Routes >
        </IntlProvider>
      </ConfigProvider>
    )
  }
}

const mapStateToProps = ({ settings, auth }) => {
  const {
    locale,
    navStyle,
    layoutType
  } = settings;

  const {
    initURL
  } = auth;

  return {
    locale,
    navStyle,
    layoutType,
    initURL
  }
};
export default connect(
  mapStateToProps, {
})(App);
