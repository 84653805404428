import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  MESSAGE_SUCCESS,
  MESSAGE_FAIL,
  DEMANDE_INSCRIPTION_COMEDIEN,
  VERIF_PAIEMENT_DEMANDE_INSCRIPTION_COMEDIEN,
  UTILISATEUR_GET_FORM_INSCRIPTION,
  UTILISATEUR_PAIEMENT_INSCRIPTION_CHEQUE,


  UTILISATEUR_GET_PROFIL,
  UTILISATEUR_UPDATE_PROFIL,
  UTILISATEUR_GET_COORDONNEES,
  UTILISATEUR_UPDATE_COORDONNEES,

  GET_ACCE_CONFIDENTIALITE_USER,

  USER_GET_HISTORIQUE_PARTAGES,

  GET_ROLE_UTILISATEUR,
} from "constants/ActionTypes";

import {
  STATUS_PAIEMENT_DEMANDE_INSCRIPTION_COMEDIEN_SUCCESS,
  STATUS_PAIEMENT_DEMANDE_INSCRIPTION_COMEDIEN_ERROR,
  STATUS_INSCRIPTION_COMEDIEN_SUCCESS,
  STATUS_INSCRIPTION_COMEDIEN_ERROR,
  STATUS_INSCRIPTION_COMEDIEN_CHEQUE_SUCCESS,
  STATUS_INSCRIPTION_COMEDIEN_CHEQUE_ERROR,
} from "constants/General";

import {
  setStatutInscriptionComedienAction,
  setStatusPaiementInscriptionComedien,
  showFormInscriptionAction,
  setStatusPaiementInscriptionComedienCheque,
  showProfilAction,
  showUserCoordonneesAction,
  showRoleUtilisateur,
  showUserListDemandesAccesAction,
  showUserHistoriquePartagesAction
} from "appRedux/actions/User";

import {
  showAlertAction,
  showRequestLoaderAction,
  hideRequestLoaderAction
} from "appRedux/actions/Common";

import {
  inscriptionComedienRequest,
  paiementInscriptionComedienParChequeRequest,
  getFormInscriptionRequest,
  verifPaiementInscriptionComedienRequest
} from 'appRedux/services/DemandeInscriptionComedien';

import {
  getUserListDemandesAccesRequest
} from 'appRedux/services/AccesConfidentialite';

import {
  getUserProfilRequest,
  updateUserProfilRequest,
  getUserCoordonneesRequest,
  updateUserCoordonneesRequest,
  getUserHistoriquePartagesRequest,
} from 'appRedux/services/User';

///////////////////////////////////////////////////////////////////////
// Demande d'inscription comedien
///////////////////////////////////////////////////////////////////////

function* inscriptionComedien({ payload }) {
  try {
    yield put(showRequestLoaderAction());
    const response = yield call(inscriptionComedienRequest, payload);
    yield put(hideRequestLoaderAction());
    if (response.status && response.status === 200) {
      yield put(setStatutInscriptionComedienAction(STATUS_INSCRIPTION_COMEDIEN_SUCCESS));
    } else {
      yield put(setStatutInscriptionComedienAction(STATUS_INSCRIPTION_COMEDIEN_ERROR));
    }
  } catch (error) {
    yield put(hideRequestLoaderAction());
    yield put(setStatutInscriptionComedienAction(STATUS_INSCRIPTION_COMEDIEN_ERROR));
  }
}

export function* inscriptionComedienFork() {
  yield takeEvery(DEMANDE_INSCRIPTION_COMEDIEN, inscriptionComedien);
}


function* verifPaiementInscriptionComedien({ payload }) {
  try {
    yield put(showRequestLoaderAction());
    const response = yield call(verifPaiementInscriptionComedienRequest, payload);

    if (response.status && response.status === 200) {
      yield put(setStatusPaiementInscriptionComedien(STATUS_PAIEMENT_DEMANDE_INSCRIPTION_COMEDIEN_SUCCESS));
    } else {
      yield put(setStatusPaiementInscriptionComedien(STATUS_PAIEMENT_DEMANDE_INSCRIPTION_COMEDIEN_ERROR));
    }
  } catch (error) {
    yield put(setStatusPaiementInscriptionComedien(STATUS_PAIEMENT_DEMANDE_INSCRIPTION_COMEDIEN_ERROR));
  }
}

export function* verifPaiementInscriptionComedienFork() {
  yield takeEvery(VERIF_PAIEMENT_DEMANDE_INSCRIPTION_COMEDIEN, verifPaiementInscriptionComedien);
}


///////////////////////////////////////////////////////////////////////
// Inscription comédien : Paybox Form
///////////////////////////////////////////////////////////////////////

function* getFormInscription({ payload }) {
  try {
    const paie = yield call(getFormInscriptionRequest, payload);
    if (paie.status && paie.status === 200 && paie.data) {
      yield put(showFormInscriptionAction(paie.data));
    }
  } catch (error) {
  }
}

export function* getFormInscriptionFork() {
  yield takeEvery(UTILISATEUR_GET_FORM_INSCRIPTION, getFormInscription);
}

///////////////////////////////////////////////////////////////////////
// Inscription comédien : Payement par chèque bancaire
///////////////////////////////////////////////////////////////////////

function* paiementInscriptionComedienParCheque({ payload }) {
  try {
    const response = yield call(paiementInscriptionComedienParChequeRequest, payload);
    if (response.status && response.status === 200) {
      yield put(setStatusPaiementInscriptionComedienCheque(STATUS_INSCRIPTION_COMEDIEN_CHEQUE_SUCCESS));
    } else {
      yield put(setStatusPaiementInscriptionComedienCheque(STATUS_INSCRIPTION_COMEDIEN_CHEQUE_ERROR));
    }
  } catch (error) {
    yield put(setStatusPaiementInscriptionComedienCheque(STATUS_INSCRIPTION_COMEDIEN_CHEQUE_ERROR));
  }
}

export function* paiementInscriptionComedienParChequeFork() {
  yield takeEvery(UTILISATEUR_PAIEMENT_INSCRIPTION_CHEQUE, paiementInscriptionComedienParCheque);
}

///////////////////////////////////////////////////////////////////////
// Profil
///////////////////////////////////////////////////////////////////////

function* getUserProfil({ payload }) {
  try {
    const response = yield call(getUserProfilRequest, payload);
    if (response.status === 200 && response.data) {
      yield put(showProfilAction(response.data));
    }
  } catch (error) {
  }
}

export function* getUserProfilFork() {
  yield takeEvery(UTILISATEUR_GET_PROFIL, getUserProfil);
}

function* updateUserProfil({ payload }) {
  try {
    const response = yield call(updateUserProfilRequest, payload);
    if (response.status === 200) {
      yield put(showAlertAction("user.monCompte.photoProfil.update.success", MESSAGE_SUCCESS));
    } else {
      yield put(showAlertAction("user.monCompte.photoProfil.update.error", MESSAGE_FAIL));
    }
  } catch (error) {
    yield put(showAlertAction("user.monCompte.photoProfil.update.error", MESSAGE_FAIL));
  }
}

export function* UpdateUserProfilFork() {
  yield takeEvery(UTILISATEUR_UPDATE_PROFIL, updateUserProfil);
}

///////////////////////////////////////////////////////////////////////
// getCoordonnees
///////////////////////////////////////////////////////////////////////

function* getUserCoordonnees({ payload }) {
  try {
    const coordonnees = yield call(getUserCoordonneesRequest, payload);
    if (coordonnees.status === 200 && coordonnees.data) {
      yield put(showUserCoordonneesAction(coordonnees.data));
    }
  } catch (error) {

  }
}

export function* getUserCoordonneesFork() {
  yield takeEvery(UTILISATEUR_GET_COORDONNEES, getUserCoordonnees);
}

function* updateUserCoordonnees({ payload }) {
  try {
    const response = yield call(updateUserCoordonneesRequest, payload);
    if (response.status === 200) {
      yield put(showAlertAction("user.monCompte.coordonnes.update.success", MESSAGE_SUCCESS));
    } else {
      yield put(showAlertAction("user.monCompte.coordonnes.update.error", MESSAGE_FAIL));
    }
  } catch (error) {
    yield put(showAlertAction("user.monCompte.coordonnes.update.error", MESSAGE_FAIL));
  }
}

export function* updateUserCoordonneesFork() {
  yield takeEvery(UTILISATEUR_UPDATE_COORDONNEES, updateUserCoordonnees);
}

function* getUserListDemandesAcces() {
  try {
    const confidentialite = yield call(getUserListDemandesAccesRequest);
    if (confidentialite.status === 200 && confidentialite.data) {
      yield put(showUserListDemandesAccesAction(confidentialite.data));
    }
  } catch (error) {

  }
}

export function* getUserListDemandesAccesFork() {
  yield takeEvery(GET_ACCE_CONFIDENTIALITE_USER, getUserListDemandesAcces);
}

//Historique de partage

function* getUserHistoriquePartages({ payload }) {
  try {
    const response = yield call(getUserHistoriquePartagesRequest, payload);
    if (response.status && response.status === 200 && response.data) {
      yield put(showUserHistoriquePartagesAction(response.data));
    }
  } catch (error) {
  }
}

export function* getUserHistoriquePartagesFork() {
  yield takeEvery(USER_GET_HISTORIQUE_PARTAGES, getUserHistoriquePartages);
}

///////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////



//******************************************Get Post Mon Compte *******************************************/

//****** get type utilisateur *******/
function* getRole() {
  /*try {
      const roleUtilisateur = yield call(getUserRoleRequest);
      if (roleUtilisateur.status === 200 && roleUtilisateur.data) {
          localStorage.setItem('roleUtilisateur', JSON.stringify(roleUtilisateur.data));
          yield put(showRoleUtilisateur(roleUtilisateur.data));
      }
  } catch (error) {

  }*/
}

//****** get compte *******/

//******Update compte *******/


//******************************************Get Post Coordonnées Utilisateur *******************************************/

//****** get coordonnées *******/


//******Update coordonnées *******/


//******************************************Get Post Historique de partage user*******************************************/
//****** get Historique de partage user *******/



//******************************************Get Post Accè confidentialité user*******************************************/
//****** get Accè confidentialité user *******/


/*******************************************************************************************************/


export function* getRoleUtilisateur() {
  yield takeEvery(GET_ROLE_UTILISATEUR, getRole);
}





export default function* rootSaga() {
  yield all([
    fork(inscriptionComedienFork),
    fork(verifPaiementInscriptionComedienFork),
    fork(getFormInscriptionFork),
    fork(paiementInscriptionComedienParChequeFork),



    fork(getUserProfilFork),
    fork(UpdateUserProfilFork),
    fork(getUserCoordonneesFork),
    fork(updateUserCoordonneesFork),
    fork(getUserListDemandesAccesFork),

    fork(getUserHistoriquePartagesFork),

    fork(getRoleUtilisateur),



  ]);
}