import React, { Component } from 'react';
import { withRouter } from "util/withRouter";

import {
  InstantSearch
} from 'react-instantsearch-dom';

import algoliasearch from 'algoliasearch/lite';
import Autocomplete from './Autocomplete';
import {algolia} from 'util/config';

/************Alogolia APi Key */

const algoliaClient  = algoliasearch(
  algolia.applicationID,
  algolia.adminAPIKey
);

const searchClient = {
  search(requests) {
    const shouldSearch = requests.some(({ params: { query }}) => query !== '');
    if (shouldSearch) {
      return algoliaClient.search(requests);
    }
    return Promise.resolve({
      results: [{ hits: [] }],
    });
  },
  searchForFacetValues: algoliaClient.searchForFacetValues,
};

class SearchForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      placeholder:'Ex:comédien,amical,documentaire.......',
    };
    this.handleChangealgolia = this.handleChangealgolia.bind(this)
}


  handleChangealgolia(event) {
    this.setState({Handlevalue: event.target.value});
  }

  render() {
    const params = new URLSearchParams(window.location.search);
    if (params.has("keyword")) {
     var defaultRefinement = params.get('keyword');
    }else{
      var defaultRefinement = '';
    }

    return(
      <InstantSearch
        indexName={algolia.indexAutoCompleteName}
        searchClient={searchClient}
        refresh={true}
      >
        <Autocomplete 
          defaultRefinement={defaultRefinement}
          onSuggestionSelected={this.onSuggestionSelected}
          displayOn={this.props.displayOn}
        />
      </InstantSearch>
    )
  }
}

export default withRouter(SearchForm);