
export const APPLICATION_PATHS = {
    HOME: '/',
    RECHERCHE: '/recherche',
    PAGE_COMEDIEN: '/PageComedien',
    INSCRIPTION_COMEDIEN: '/HomeComedien',
    HOME_UTILISATEUR: '/User/MonCompte',
    HOME_COMEDIEN: '/Comedien/MonProfil',
    HOME_ADMIN: '/Administrateur/GestionUtilisateurs',
    DECOUVERTE: '/decouverte',
    PATH_CONFIRMATION_INSCRIPTION: '/confirmation-inscription',
    CGU: '/cgu',
    RGPD: '/rgpd',
    AIDE: '/aide',
    EQUIPE:'/equipe',
    
    COMEDIEN:{
      MON_PROFIL: '/Comedien/mon-profil-comedien',
      MES_EXTRAITS_MP3: '/Comedien/MesExtraisMP3',
      MES_VIDEOS: '/Comedien/MesVideos',
      MES_PHOTOS: '/Comedien/MesPhotos',
      MESSAGES: '/Comedien/messages',
    },

    USER:{
      MESSAGES: '/User/messages',
    }
};


