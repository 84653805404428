import React, { Component } from "react";
import { connect } from "react-redux";
import { Menu } from "antd";
import { NavLink } from "react-router-dom";
import IntlMessages from "util/IntlMessages";
import { injectIntl } from 'react-intl';
import { getInfosUserAction } from "appRedux/actions/Auth";
import { withRouter } from "util/withRouter";
import { frontBaseUrl } from "util/config";
import Swal from 'sweetalert2';
import { PHONE_SIZE } from "constants/ThemeSetting";

class MenuComedien extends Component {
  constructor() {
    super();
    this.state = {
      dataInfosUser: {},
    }
  }

  componentDidMount() {
    this.props.getInfosUserAction();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.dataInfosUser !== prevState.dataInfosUser && nextProps.dataInfosUser) {
      return { dataInfosUser: nextProps.dataInfosUser };
    } else return null;
  }

  copyToClipboard = (str) => {
    var intl = this.props.intl;

    const el = document.createElement('textarea');
    el.value = str;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);

    Swal.fire({
      title: intl.formatMessage({ id: "sider.userProfile.copierLien.success" }),
      icon: 'success',
      buttonsStyling: false,
      customClass: { confirmButton: 'ant-btn ant-btn-primary', cancelButton: 'ant-btn' },
    });
  };

  render() {

    const { pathname } = this.props;
    const selectedKeys = pathname.substr(1);
    const defaultOpenKeys = selectedKeys.split('/')[1];

    let width = window.innerWidth;
    
    return (
      <div>

        <div className="cm-sider-user-link">
          <a href={`${frontBaseUrl}/${this.state.dataInfosUser.url}`} target="_blank">
            {/*`${frontBaseUrl}/${this.state.dataInfosUser.url}`*/}
            <IntlMessages id="sider.userProfile.voirFiche" />
          </a>
        </div>

        <div className="cm-sider-user-link">
          <span href='' onClick={event => this.copyToClipboard(`${frontBaseUrl}/${this.state.dataInfosUser.url}`)}>
            {/*`${frontBaseUrl}/${this.state.dataInfosUser.url}`*/}
            <IntlMessages id="sider.userProfile.copierLien" />
          </span>
        </div>

        <div className={'cm-side-bar-menu-separator'}></div>

          <Menu
            defaultOpenKeys={[defaultOpenKeys]}
            selectedKeys={[selectedKeys]}
            theme='dark'
            mode="inline"
          >

            <Menu.Item key="/Comedien/mon-profil-comedien">
              <NavLink to="/Comedien/mon-profil-comedien" className={({ isActive }) => (isActive ? 'ant-menu-item-selected' : '')}>
                <IntlMessages id="menu.comedien.monProfil" />
              </NavLink>
            </Menu.Item>

            <Menu.Item key="Comedien/MonCv">
              <NavLink to="/Comedien/MonCv" className={({ isActive }) => (isActive ? 'ant-menu-item-selected' : '')}>
                <IntlMessages id="menu.comedien.monCV" />
              </NavLink>
            </Menu.Item>

            <Menu.Item key="Comedien/MesPhotos">
              <NavLink to="/Comedien/MesPhotos" className={({ isActive }) => (isActive ? 'ant-menu-item-selected' : '')}>
                <IntlMessages id="menu.comedien.mesPhotos" />
              </NavLink>
            </Menu.Item>

            {width > PHONE_SIZE &&
              <Menu.Item key="Comedien/MesExtraisMP3">
                <NavLink to="/Comedien/MesExtraisMP3" className={({ isActive }) => (isActive ? 'ant-menu-item-selected' : '')}>
                  <IntlMessages id="menu.comedien.mesExtraitsMP3" />
                </NavLink>
              </Menu.Item>
            }

            {(this.state.dataInfosUser.formuleCompte === "premium")  && (width > PHONE_SIZE) ?
              <Menu.Item key="Comedien/MesVideos">
                <NavLink to="/Comedien/MesVideos" className={({ isActive }) => (isActive ? 'ant-menu-item-selected' : '')}>
                  <IntlMessages id="menu.comedien.mesVideos" />
                </NavLink>
              </Menu.Item>
            : 
            null
            }

            <Menu.Item key="Comedien/MesPlaylists">
              <NavLink to="/Comedien/MesPlaylists" className={({ isActive }) => (isActive ? 'ant-menu-item-selected' : '')}>
                <IntlMessages id="menu.comedien.mesPlaylists" />
              </NavLink>
            </Menu.Item>

            <Menu.Item key="Comedien/MesNews">
              <NavLink to="/Comedien/MesNews" className={({ isActive }) => (isActive ? 'ant-menu-item-selected' : '')}>
                <IntlMessages id="menu.comedien.mesNews" />
              </NavLink>
            </Menu.Item>

            <Menu.Item key="Comedien/MesInfosEmployeur">
              <NavLink to="/Comedien/MesInfosEmployeur" className={({ isActive }) => (isActive ? 'ant-menu-item-selected' : '')}>
                <IntlMessages id="menu.comedien.mesInfosEmployeur" />
              </NavLink>
            </Menu.Item>

            <Menu.Item key="Comedien/MonEquipement">
              <NavLink to="/Comedien/MonEquipement" className={({ isActive }) => (isActive ? 'ant-menu-item-selected' : '')}>
                <IntlMessages id="menu.comedien.monEquipement" />
              </NavLink>
            </Menu.Item>

            <div className={'cm-side-bar-menu-separator'}></div>

            <Menu.Item key="Comedien/MonCompte">
              <NavLink to="/Comedien/MonCompte" className={({ isActive }) => (isActive ? 'ant-menu-item-selected' : '')}>
                <IntlMessages id="menu.comedien.monCompte" />
              </NavLink>
            </Menu.Item>

            <Menu.Item key="Comedien/MesStats">
              <NavLink to="/Comedien/MesStats" className={({ isActive }) => (isActive ? 'ant-menu-item-selected' : '')}>
                <IntlMessages id="menu.comedien.MesStats" />
              </NavLink>
            </Menu.Item>

            <Menu.Item key="Comedien/messages">
              <NavLink to="/Comedien/messages" className={({ isActive }) => (isActive ? 'ant-menu-item-selected' : '')}>
                <IntlMessages id="menu.comedien.messages" />
              </NavLink>
            </Menu.Item>

            <Menu.Item key="Comedien/AccesConfidentialite">
              <NavLink to="/Comedien/AccesConfidentialite" className={({ isActive }) => (isActive ? 'ant-menu-item-selected' : '')}>
                <IntlMessages id="menu.comedien.accesConfidentialite" />
              </NavLink>
            </Menu.Item>

          </Menu>
      </div>
    );
  }

}

const mapStateToProps = ({ settings, auth }) => {
  const {
    pathname
  } = settings;

  const {
    dataInfosUser
  } = auth;

  return {
    pathname,
    dataInfosUser
  }
};


export default withRouter(
  injectIntl(
    connect(
      mapStateToProps,
      {
        getInfosUserAction
      }
    )(MenuComedien)
  )
);