import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { push } from 'react-router-redux'
import {
  SIGNIN,
  SIGNIN_STATUS_SUCCESS,
  SIGNIN_STATUS_FAIL,
  SIGNIN_STATUS_ACCOUNT_EXPIRED,
  SIGNOUT,
  SIGNUP,
  SIGNUP_STATUS_SUCCESS,
  SIGNUP_STATUS_FAIL,
  SIGNUP_STATUS_FAIL_EMAIL_EXIST,
  SIGNUP_STATUS_FAIL_COMPTE_FACEBOOK_EXIST,
  SIGNUP_CONFIRM,
  AUTH_REQUEST_RESET_PASSWORD,
  AUTH_RESET_PASSWORD,
  COMEDIEN_GET_AUTH,
  COMEDIEN_UPDATE_AUTH,
  COMEDIEN_UPDATE_PASSWORD,

  /////////////////

  GET_INFOS_USER,


  MESSAGE_SUCCESS,
  MESSAGE_FAIL
} from "constants/ActionTypes";

import {
  SIGNUP_ERROR_EMAIL_EXIST,
  SIGNUP_ERROR_COMPTE_FACEBOOK_EXIST,
  SIGNIN_ERROR_ACCOUNT_EXPIRED,
  STATUS_REQUEST_RESET_PASSWORD_ERROR,
  STATUS_REQUEST_RESET_PASSWORD_SUCCESS,
  STATUS_RESET_PASSWORD_SUCCESS,
  STATUS_RESET_PASSWORD_ERROR,
  STATUS_UPDATE_AUTH_ERROR,
  STATUS_UPDATE_AUTH_CURRENT_PASSWORD_INCORRECT,
  AUTH_UPDATE_CURRENT_PASSWORD_INCORRECT,
  STATUS_UPDATE_PASSWORD_ERROR,
  STATUS_UPDATE_PASSWORD_CURRENT_PASSWORD_INCORRECT,
  PASSWORD_UPDATE_CURRENT_PASSWORD_INCORRECT
} from "constants/General";

import {
  showAlertAction
} from "appRedux/actions/Common";

import {
  setStatusSignInAction,
  signInSuccessAction,
  signOutSuccessAction,
  setStatusSignUpAction,
  signUpSuccessAction,
  signUpConfirmSuccessAction,
  setStatusRequestResetPasswordAction,
  setStatusResetPasswordAction,
  showIdentifiantsAction,
  updateIdentifiantsSuccessAction,
  updateIdentifiantsErrorAction,
  updatePasswordSuccessAction,
  updatePasswordErrorAction,

  ////////////
  showInfosUserAction,
} from "appRedux/actions/Auth";

import {
  signInRequest,
  signOutRequest,
  signUpRequest,
  signUpConfirmRequest,
  requestResetPasswordRequest,
  resetPasswordRequest,
  getIdentifiantsRequest,
  updateIdentifiantsRequest,
  updatePasswordRequest,
  ///////////////////////

  getUserRoleRequest,
  getUserConnecteRequest,
} from 'appRedux/services/User';

///////////////////////////////////////////////////////////////////////
// Sign In
///////////////////////////////////////////////////////////////////////

function* signIn({ payload }) {
  try {
    const response = yield call(signInRequest, payload);

    if (response.status === 200) {
      if (response.data === SIGNIN_ERROR_ACCOUNT_EXPIRED) {
        yield put(setStatusSignInAction(SIGNIN_STATUS_ACCOUNT_EXPIRED));
      } else {
        yield put(signInSuccessAction(response.data));
        yield put(setStatusSignInAction(SIGNIN_STATUS_SUCCESS));
      }

    } else {
      yield put(setStatusSignInAction(SIGNIN_STATUS_FAIL));
    }
  } catch (error) {
    yield put(setStatusSignInAction(SIGNIN_STATUS_FAIL));
  }
}

export function* signInFork() {
  yield takeEvery(SIGNIN, signIn);
}

///////////////////////////////////////////////////////////////////////
// Sign Out
///////////////////////////////////////////////////////////////////////

function* signOut() {
  try {
    const response = yield call(signOutRequest);
    yield put(signOutSuccessAction());
    if (response.status === 200) {
    } else {
    }
  } catch (error) {
    yield put(signOutSuccessAction());
  }
}

export function* signOutFork() {
  yield takeEvery(SIGNOUT, signOut);
}

///////////////////////////////////////////////////////////////////////
// Sign Up
///////////////////////////////////////////////////////////////////////

function* signUp({ payload }) {
  try {
    const response = yield call(signUpRequest, payload);

    if (response.status === 200) {
      if (response.data === SIGNUP_ERROR_EMAIL_EXIST) {
        yield put(setStatusSignUpAction(SIGNUP_STATUS_FAIL_EMAIL_EXIST));
      } else if (response.data === SIGNUP_ERROR_COMPTE_FACEBOOK_EXIST) {
        yield put(setStatusSignUpAction(SIGNUP_STATUS_FAIL_COMPTE_FACEBOOK_EXIST));
      } else {
        yield put(signUpSuccessAction(response.data));
        //yield put(setStatusSignUpAction(SIGNUP_STATUS_SUCCESS));
      }
    } else {
      yield put(setStatusSignUpAction(SIGNUP_STATUS_FAIL));
    }
  } catch (error) {
    yield put(setStatusSignUpAction(SIGNUP_STATUS_FAIL));
  }
}

export function* signUpFork() {
  yield takeEvery(SIGNUP, signUp);
}

///////////////////////////////////////////////////////////////////////
// Sign Up Confirm
///////////////////////////////////////////////////////////////////////

function* signUpConfirm({ payload }) {
  try {
    const response = yield call(signUpConfirmRequest, payload);

    if (response.status === 200) {
      yield put(signInSuccessAction(response.data));
      yield put(signUpConfirmSuccessAction());
    } else {
      yield put(showAlertAction("signUpConfirm.error", MESSAGE_FAIL));
    }
  } catch (error) {
    yield put(showAlertAction("signUpConfirm.error", MESSAGE_FAIL));
  }
}

export function* signUpConfirmFork() {
  yield takeEvery(SIGNUP_CONFIRM, signUpConfirm);
}

///////////////////////////////////////////////////////////////////////
// Forgot Password
///////////////////////////////////////////////////////////////////////

//Request Password Reset

function* requestResetPassword({ payload }) {
  try {
    const response = yield call(requestResetPasswordRequest, payload);

    if ((response.status === 204) || (response.status === 200)) {
      yield put(setStatusRequestResetPasswordAction(STATUS_REQUEST_RESET_PASSWORD_SUCCESS));
    } else {
      yield put(setStatusRequestResetPasswordAction(STATUS_REQUEST_RESET_PASSWORD_ERROR));
    }
  }
  catch (error) {
    yield put(setStatusRequestResetPasswordAction(STATUS_REQUEST_RESET_PASSWORD_ERROR));
  }
}

export function* requestResetPasswordFork() {
  yield takeEvery(AUTH_REQUEST_RESET_PASSWORD, requestResetPassword);
}

//Reset Password

function* resetPassword({ payload }) {
  try {
    const response = yield call(resetPasswordRequest, payload);

    if ((response.status === 204) || (response.status === 200)) {
      yield put(setStatusResetPasswordAction(STATUS_RESET_PASSWORD_SUCCESS));
    } else {
      yield put(setStatusResetPasswordAction(STATUS_RESET_PASSWORD_ERROR));
    }
  } catch (error) {
    yield put(setStatusResetPasswordAction(STATUS_RESET_PASSWORD_ERROR));
  }
}

export function* resetPasswordFork() {
  yield takeEvery(AUTH_RESET_PASSWORD, resetPassword);
}

/////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////


///////////////////////////////////////////////////////////////////////
// Identifiants
///////////////////////////////////////////////////////////////////////

function* getIdentifiants({ payload }) {
  try {
    const response = yield call(getIdentifiantsRequest, payload);
    if (response.status && response.status === 200 && response.data) {
      yield put(showIdentifiantsAction(response.data));
    }
  } catch (error) {
  }
}

export function* getIdentifiantsFork() {
  yield takeEvery(COMEDIEN_GET_AUTH, getIdentifiants);
}

function* updateIdentifiants({ payload }) {
  try {
    const response = yield call(updateIdentifiantsRequest, payload);
    if (response.status && response.status === 200) {
      if (response.data === AUTH_UPDATE_CURRENT_PASSWORD_INCORRECT) {
        yield put(updateIdentifiantsErrorAction(STATUS_UPDATE_AUTH_CURRENT_PASSWORD_INCORRECT));
      } else {
        yield put(updateIdentifiantsSuccessAction());
      }
    } else {
      yield put(updateIdentifiantsErrorAction(STATUS_UPDATE_AUTH_ERROR));
    }
  } catch (error) {
    yield put(updateIdentifiantsErrorAction(STATUS_UPDATE_AUTH_ERROR));
  }
}

export function* updateIdentifiantsFork() {
  yield takeEvery(COMEDIEN_UPDATE_AUTH, updateIdentifiants);
}


///////////////////////////////////////////////////////////////////////
// Mot de passe
///////////////////////////////////////////////////////////////////////

export function* updatePassword({ payload }) {
  try {
    const response = yield call(updatePasswordRequest, payload);

    if (response.status && response.status === 200) {
      if (response.data === AUTH_UPDATE_CURRENT_PASSWORD_INCORRECT) {
        yield put(updatePasswordErrorAction(STATUS_UPDATE_PASSWORD_CURRENT_PASSWORD_INCORRECT));
      } else {
        yield put(updatePasswordSuccessAction());
      }
    } else {
      yield put(updatePasswordErrorAction(STATUS_UPDATE_PASSWORD_ERROR));
    }
  } catch (error) {
    yield put(updatePasswordErrorAction(STATUS_UPDATE_PASSWORD_ERROR));
  }
}

export function* updatePasswordFork() {
  yield takeEvery(COMEDIEN_UPDATE_PASSWORD, updatePassword);
}

///////////////////////////////////////////////////////////////////////
// Infos user
///////////////////////////////////////////////////////////////////////

function* getInfosUser({ payload }) {
  try {
    const user = yield call(getUserConnecteRequest, payload);
    if (user.status === 200) {
      yield put(showInfosUserAction(user.data));
    }
  } catch (error) {
  }
}

export function* getInfosUserFork() {
  yield takeEvery(GET_INFOS_USER, getInfosUser);
}

////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////









////////////////////////////////////////////////////////////////////////////
// Exports
////////////////////////////////////////////////////////////////////////////

export default function* rootSaga() {
  yield all([
    fork(signInFork),
    fork(signOutFork),
    fork(signUpFork),
    fork(signUpConfirmFork),
    fork(requestResetPasswordFork),
    fork(resetPasswordFork),
    fork(getIdentifiantsFork),
    fork(updateIdentifiantsFork),
    fork(updatePasswordFork),
    ////////////////////////////////////



    fork(getInfosUserFork),









  ]);
}
