import React, { Component } from "react";
import { connect } from "react-redux";
import { Popover } from "antd";
import { withRouter } from "util/withRouter";
import noAvatar from "assets/images/no-avatar.webp"
import Auxiliary from "util/Auxiliary";
import Avatar from "components/Avatar";
import IntlMessages from "util/IntlMessages";
import { getCurrentUser } from 'util/Auth';
import { APPLICATION_PATHS } from "constants/ApplicationPaths";

import { 
  signOutAction 
} from "appRedux/actions/Auth";

import { 
  getInfosUserAction 
} from "appRedux/actions/Auth";

import {
  getSlidesPhotosXLAction
} from "appRedux/actions/Common";

import {
  USER_ROLE_COMEDIEN,
  USER_ROLE_UTILISATEUR,
  USER_ROLE_ADMIN,
} from "../../constants/General";

class UserProfile extends Component {
  constructor() {
    super();
    this.state = {
      showPopover:false,
      dataInfosUser: null,
    }
  }

  componentDidMount() {
    this.props.getInfosUserAction();
    //this.props.getSlidesPhotosXLAction();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.dataInfosUser !== prevState.dataInfosUser && nextProps.dataInfosUser) {
      return { dataInfosUser: nextProps.dataInfosUser };
    } else{
      return null;
    } 
  }

  handleVisiblePopoverChange = showPopover => {
    this.setState({ showPopover });
  };

  signOut = () => {
    this.props.signOutAction();
  };

  redirectToCompte = () => {
    this.setState({
      showPopover:false,
    });

    let currentUser = getCurrentUser();

    switch(currentUser.userRole){
      case USER_ROLE_UTILISATEUR :
        window.location.replace(APPLICATION_PATHS.HOME_UTILISATEUR);
        break;
      case USER_ROLE_COMEDIEN :
        window.location.replace(APPLICATION_PATHS.COMEDIEN.MON_PROFIL);
        break;
      case USER_ROLE_ADMIN:
        window.location.replace(APPLICATION_PATHS.HOME_ADMIN);
        break;
      default:
        window.location.replace(APPLICATION_PATHS.HOME);
    }
  };

  render() {
    const { dataInfosUser } = this.state;

    const userMenuOptions = (
      <ul className="gx-user-popover cm-top-bar-popover">
        <li>
          <a href={APPLICATION_PATHS.AIDE}>
            <IntlMessages id="topBar.userProfile.popover.aide" />
          </a>
        </li>
        <li onClick={this.redirectToCompte}>
          <IntlMessages id="topBar.userProfile.popover.compte" />
        </li>
        <li onClick={this.signOut}>
          <IntlMessages id="topBar.userProfile.popover.deconnexion" />
        </li>
      </ul>
    );

    var avatarSrc;
    if(dataInfosUser && dataInfosUser.photo){
      avatarSrc = dataInfosUser.photo;
    }else{
      avatarSrc = noAvatar;
    }

    return (
      <Auxiliary>
        <Popover 
          placement="bottomRight" 
          content={userMenuOptions} 
          trigger="click"
          visible={this.state.showPopover}
          onVisibleChange={this.handleVisiblePopoverChange}
        >
          <div style={{display:"flex",alignItems: "center"}}>
            <div>
              <Avatar
                src={avatarSrc}
                width="30px"
                height="30px"
                style={{cursor:"pointer", marginRight:"8px"}}
              />
            </div>
            <div className="cm-top-bar-col-avatar-text">
              {dataInfosUser ?
                <Auxiliary>
                  <IntlMessages id="topBar.userProfile.bonjour"/><br/>
                  {dataInfosUser.prenom}
                </Auxiliary>
              :null}
            </div>
          </div>
        </Popover>
      </Auxiliary>
    )
  }
}

const mapStateToProps = ({ auth }) => {
  const { 
    dataInfosUser 
  } = auth;

  return { 
    dataInfosUser 
  }
};

export default withRouter(
  connect(
    mapStateToProps, 
    {
      signOutAction,
      getInfosUserAction,
      getSlidesPhotosXLAction

    }
  )(UserProfile)
);