import axios from "axios";
import {
  endpointLog
} from 'util/api';

export const lectureExtraitRequest = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  var { idMedia } = payload;

  const wsResponse = await axios.request({
    method: 'post',
    url: `${endpointLog.ADD_PLAY}?access_token=${accessToken}`,
    data: {
      idSon: idMedia
    }

  });
  return wsResponse;
}

export const partageExtraitRequest = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  var { idSon } = payload;

  const wsResponse = await axios.request({
    method: 'post',
    url: `${endpointLog.ADD_PARTAGE_EXTRAIT}?access_token=${accessToken}`,
    data: {
      idSon
    }

  });
  return wsResponse;
}

export const ajoutExtraitPlaylistRequest = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  var { idMedia, idPlaylist } = payload;

  const wsResponse = await axios.request({
    method: 'post',
    url: `${endpointLog.ADD_AJOUT_EXTRAIT_PLAYLIST}?access_token=${accessToken}`,
    data: {
      idSon: idMedia,
      idPlaylist
    }

  });
  return wsResponse;
}

export const affichageTelephoneRequest = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  var { idComedien } = payload;

  const wsResponse = await axios.request({
    method: 'post',
    url: `${endpointLog.ADD_AFFICHAGE_TELEPHONE}?access_token=${accessToken}`,
    data: {
      idComedien
    }

  });
  return wsResponse;
}

export const visiteFicheRequest = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  var { idComedien } = payload;

  const wsResponse = await axios.request({
    method: 'post',
    url: `${endpointLog.ADD_VISITE_FICHE}?access_token=${accessToken}`,
    data: {
      idComedien
    }

  });
  return wsResponse;
}


