// import enLang from "./entries/en-US";
import frLang from "./entries/fr_FR";
import {addLocaleData} from "react-intl";

const AppLocale = {
  fr: frLang,
  // en: enLang
};
addLocaleData(AppLocale.fr.data);
// addLocaleData(AppLocale.en.data);

export default AppLocale;
