import axios from "axios";
import {
  endpointDemandeInscriptionComedien
} from 'util/api';


/**
 * Liste des demandes d'inscription
 * @param {*} payload 
 */
export const getDemandesInscriptionsComediensRequest = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  var { pageSize, page, keyword } = payload;

  const wsResponse = await axios.request({
    method: 'get',
    url: `${endpointDemandeInscriptionComedien.GET_LIST_DEMANDES}?access_token=${accessToken}`,
    params: {
      pageSize,
      page,
      keyword
    }
  })

  return wsResponse;
}

/**
 * Suppression d'une demande d'inscription
 * @param {*} payload 
 */
export const deleteDemandeInscriptionComedienRequest = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  var { idDemande } = payload;

  const wsResponse = await axios.request({
    method: 'POST',
    url: `${endpointDemandeInscriptionComedien.DELETE_DEMANDE}?access_token=${accessToken}`,
    data: { idDemande }
  });

  return wsResponse;
}

/**
 * R�cup�ration d'une demande d'inscription
 * @param {*} payload 
 */
export const getDemandeInscriptionComedienRequest = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  const { idDemande } = payload;

  const wsResponse = await axios.request({
    method: 'get',
    url: `${endpointDemandeInscriptionComedien.GET_DEMANDE}?access_token=${accessToken}`,
    params: {
      idDemande
    }
  });

  return wsResponse;
}

/**
 * Validation d'une demande d'inscription
 * @param {*} payload 
 */
export const validationDemandeInscriptionComedienRequest = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  const { idDemande, commentaires } = payload;

  const wsResponse = await axios.request({
    method: 'POST',
    url: `${endpointDemandeInscriptionComedien.VALIDATION_DEMANDE}?access_token=${accessToken}`,
    data: { 
      idDemande, 
      commentaires }
  });

  return wsResponse;
}

/**
 * Refus d'une demande d'inscription
 * @param {*} payload 
 */
export const refusDemandeInscriptionComedienRequest = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  const { idDemande, commentaires } = payload;

  const wsResponse = await axios.request({
    method: 'POST',
    url: `${endpointDemandeInscriptionComedien.REFUS_DEMANDE}?access_token=${accessToken}`,
    data: { 
      idDemande, 
      commentaires 
    }
  });

  return wsResponse;
}

/**
 * Validation du paiement d'une inscription com�dien par ch�que bancaire
 * @param {*} payload 
 */
export const validationPaiementChequeRequest = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  var { idDemande } = payload;

  const wsResponse = await axios.request({
    method: 'POST',
    url: `${endpointDemandeInscriptionComedien.VALIDATION_PAIEMENT_CHEQUE}?access_token=${accessToken}`,
    data: {
      idDemande
    }
  });

  return wsResponse;
}

/////////////////////////////////////////////////////
/////////////////////////////////////////////////////
////////////////////////////////////////////////////


export const inscriptionComedienRequest = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  const { idUser, formule, values, file1, file2, file3 } = payload;

  const formData = new FormData();

  formData.append("idUser", idUser);
  formData.append("formule", formule);
  formData.append("message", values.message);
  formData.append("file1", file1);
  formData.append("file2", file2);
  formData.append("file3", file3);

  const wsResponse = await axios.request({
    method: 'post',
    url: `${endpointDemandeInscriptionComedien.ADD_DEMANDE}?access_token=${accessToken}`,
    data: formData,
    config: {
      headers: {
        'Content-Type': `multipart/form-data`,
        'Accept': 'application/json',
      }
    }
  });

  return wsResponse;
}









export const getFormInscriptionRequest = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  var { formule } = payload;

  // call WS Loopback
  const wsResponse = await axios.request({
    method: 'get',
    url: `${endpointDemandeInscriptionComedien.GET_FORM_INSCRIPTION}?access_token=${accessToken}`,
    params: {
      formule
    }
  });

  return wsResponse;
}

/**
 * V�rification du paiement d'une inscription com�dien
 * @param {*} payload 
 */
export const verifPaiementInscriptionComedienRequest = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  let { dataUtilisateur, dataPaybox } = payload;

  const { civilite, prenom, nom, telMobile, adresse, codePostal, ville, pays } = dataUtilisateur;
  const { refCommande, erreur, montant, modePaiement, typeCarte, idTransaction, numTransaction, dateTransaction, numAutorisation, signature } = dataPaybox;

  const formData = new FormData();

  formData.append('civilite', civilite);
  formData.append('prenom', prenom);
  formData.append('nom', nom);
  formData.append('telMobile', telMobile);
  formData.append('adresse', adresse);
  formData.append('codePostal', codePostal);
  formData.append('ville', ville);
  formData.append('pays', pays);
  formData.append('refCommande', refCommande);
  formData.append('erreur', erreur);
  formData.append('montant', montant);
  formData.append('modePaiement', modePaiement);
  formData.append('typeCarte', typeCarte);
  formData.append('idTransaction', idTransaction);
  formData.append('numTransaction', numTransaction);
  formData.append('dateTransaction', dateTransaction);
  formData.append('numAutorisation', numAutorisation);
  formData.append('signature', signature);

  const wsResponse = await axios.request({
    method: 'POST',
    url: `${endpointDemandeInscriptionComedien.VERIF_PAIEMENT}?access_token=${accessToken}`,
    data: formData,
    config: {
      headers: {
        'Content-Type': `multipart/form-data`,
        'Accept': 'application/json',
      }
    }
  });

  return wsResponse;
}

export const paiementInscriptionComedienParChequeRequest = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  let { formule, values } = payload;

  const formData = new FormData();

  formData.append('formule', formule);
  formData.append('civilite', values.civilite);
  formData.append('prenom', values.prenom);
  formData.append('nom', values.nom);
  formData.append('telMobile', values.telMobile);
  formData.append('adresse', values.adresse);
  formData.append('codePostal', values.codePostal);
  formData.append('ville', values.ville);
  formData.append('pays', values.pays);

  const wsResponse = await axios.request({
    method: 'POST',
    url: `${endpointDemandeInscriptionComedien.PAIEMENT_CHEQUE}?access_token=${accessToken}`,
    data: formData,
    config: {
      headers: {
        'Content-Type': `multipart/form-data`,
        'Accept': 'application/json',
      }
    }
  });

  return wsResponse;
}

