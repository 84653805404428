import React, { Component } from "react";
import { connect } from "react-redux";
import CustomScrollbars from "util/CustomScrollbars";
import IntlMessages from "util/IntlMessages";
import { injectIntl } from 'react-intl';
import { getValueList, getGroupeInterpretation } from 'util/ValueList/Values';
import { connectRefinementList, connectCurrentRefinements } from 'react-instantsearch-dom';
import { connectRange } from 'react-instantsearch-dom';
import RangeSliderAge from "components/Algolia/RangeSliderAge"
import RangeSliderTimbre from "components/Algolia/RangeSliderTimbre"
import orderBy  from 'lodash/orderBy';
import { displayEmoji } from 'util/displayEmoji.js';
import { getCurrentUserRole } from 'util/Auth';
import { withRouter } from "util/withRouter";

import {
  RefinementList,
  connectStateResults
} from 'react-instantsearch-dom';

const StateResults = ({ searchResults }) => {
  const hasResults = searchResults && searchResults.nbHits !== 0;
  const nbHits = searchResults && searchResults.nbHits;
  return (
    <div>
      <div hidden={!hasResults}><span className="cm-search-resultat-count">{nbHits}</span></div>
      <div hidden={hasResults}></div>
    </div>
  );
};

const CustomStateResults = connectStateResults(StateResults);

const _GenreRefinementList = ({
  items,
  isFromSearch,
  refine,
  searchForItems,
  createURL,
}) => (
  <div className="ais-RefinementList">

    <ul className="cm-genre-ais-RefinementList-list">
      {items.map(item => (
        <li
          key={item.label}
          className={item.isRefined ? "cm-genre-ais-RefinementList-item cm-genre-ais-RefinementList-item--selected" : "cm-genre-ais-RefinementList-item"}
        >
          <label className="cm-genre-ais-RefinementList-label">
            <span className="cm-genre-ais-RefinementList-icon" value={item.label.substr(0, 1)} onClick={event => {
              event.preventDefault();
              refine(item.value);
            }}>
              {item.label.substr(0, 1) === "F" ?
                <i className="fa-solid fa-venus fa-fw"></i>
                :
                <i className="fa-solid fa-mars fa-fw"></i>
              }
            </span>
            <span className="cm-genre-ais-RefinementList-labelText">{item.label}</span>
          </label>
        </li>
      ))}
    </ul>
  </div>
);

const GenreRefinementList = connectRefinementList(_GenreRefinementList);

const _ClearRefinements = ({ items, refine, handleResetAgeRange, handleResetTimbreRange }) => (
  <div className="ais-ClearRefinements">
    <button
      className="ais-ClearRefinements-button"
      onClick={() => { refine(items); handleResetAgeRange(); handleResetTimbreRange(); }}
      disabled={!items.length}
    >
      <IntlMessages id="recherche.filtres.reinitialiser" />
    </button>
  </div>
);

const CustomClearRefinements = connectCurrentRefinements(_ClearRefinements);
const CustomRangeSliderAge = connectRange(RangeSliderAge);
const CustomRangeSliderTimbre = connectRange(RangeSliderTimbre);

class AlgoliaFilter extends Component {
  constructor() {
    super();
    this.state = {
      minAgeRange: 0,
      maxAgeRange: 65,
      minToneRange: 1,
      maxToneRange: 5,
      resetAgeRange: false,
      resetTimbreRange: false,
    }
  }

  setResetAgeRange = () => {
    this.setState(prevState => ({ resetAgeRange: !prevState.resetAgeRange }));
  };

  setResetTimbreRange = () => {
    this.setState(prevState => ({ resetTimbreRange: !prevState.resetTimbreRange }));
  };

  handleResetAgeRange = () => {
    if (!this.state.resetAgeRange) {
      this.setResetAgeRange();
    }
  }

  handleResetTimbreRange = () => {
    if (!this.state.resetTimbreRange) {
      this.setResetTimbreRange();
    }
  }

  componentDidMount() {
    this.userRole = getCurrentUserRole();
  }

  sortRefinement = (items, refinement) => {
    try {
      var values = getValueList(refinement);

      for (var i = 0; i < items.length; i++) {
        items[i].tri = values[Number(items[i].label)] ? values[Number(items[i].label)].tri : 0;

        if (!values[Number(items[i].label)]) {
        }
      }

      items.sort((a, b) => (a.tri > b.tri) ? 1 : -1)
    } catch (e) {

    }
    return items;
  }


  sortIntentions = (items) => {

    var intentionsSorted = ['complicite', 'sourire', 'neutre', 'elegance', 'nervosite', 'comedie', 'sensualite', 'energie', 'surprise', 'peur', 'tristesse']
    try {
      for (var i = 0; i < items.length; i++) {
        items[i].tri = intentionsSorted.indexOf(items[i].label);
      }

      items.sort((a, b) => (a.tri > b.tri) ? 1 : -1)
    } catch (e) {

    }
    return items;
  }

  render() {
    const valueList = getValueList();

    return (
      <div className="cm-sidebar-algolia-content">
        <CustomScrollbars className="gx-layout-sider-scrollbar">
          <div className="cm-search-filter-container">
            <div className="cm-search-filter-header">
              <div className="cm-search-filterTitle">
                <IntlMessages id="recherche.filtres.titre" />
              </div>
              <div className="cm-search-filterReset">
                <i className="fa fa-sync cm-search-filterReset-icon"></i>
                {/*
                  <ClearRefinements
                    translations={{
                          reset: <IntlMessages id="recherche.filtres.reinitialiser" />,
                    }}
                  />
                  */}

                <CustomClearRefinements
                  handleResetAgeRange={this.handleResetAgeRange}
                  handleResetTimbreRange={this.handleResetTimbreRange}
                />
              </div>
            </div>

            <div className="cm-search-filter-options">
              <div className="cm-search-filterBlock cm-search-genre">
                <div className="cm-search-filtre-title">
                  <IntlMessages id="recherche.filtres.genre" />
                </div>
                <GenreRefinementList
                  attribute="gender"
                  limit={2}
                  showMore={false}
                  transformItems={items => orderBy(items, "label", "asc")}
                />
              </div>


              <div className="cm-search-filterBlock">
                <div className="cm-search-filterBlock-extraits">Extraits</div>
                <div className="cm-search-filtre-title cm-search-filtre-slider-title">
                  <IntlMessages id="recherche.filtres.age" />
                  <span className="cm-search-filtre-slider-count">
                    <CustomStateResults />
                  </span>
                </div>

                <div style={{ width: "160px", display: "flex" }}>
                  <CustomRangeSliderAge
                    attribute="ageIndex"
                    min={this.state.minAgeRange}
                    max={this.state.maxAgeRange}
                    resetAgeRange={this.state.resetAgeRange}
                    setResetAgeRange={this.setResetAgeRange}
                  />
                </div>
              </div>

              <div className="cm-search-filterBlock">
                <div className="cm-search-filtre-title">
                  <IntlMessages id="recherche.filtres.langue" />
                </div>
                <RefinementList
                  attribute="languageId"
                  limit={3}
                  showMore={true}
                  transformItems={
                    items => items = this.sortRefinement(items, 'qf_langue')
                      .map(item => ({
                        ...item,
                        label: (valueList[item.label] ? valueList[item.label].fr : ''),
                      }))
                  }
                  translations={{
                    showMore(expanded) {
                      return expanded ? <IntlMessages id="recherche.filtres.langue.moins" /> : <IntlMessages id="recherche.filtres.langue.plus" />;
                    },
                    noResults: <IntlMessages id="recherche.filtres.pasDeResultat" />,
                  }}
                />
              </div>

              <div className="cm-search-filterBlock">
                <div className="cm-search-filtre-title">
                  <IntlMessages id="recherche.filtres.typeExtrait" />
                </div>
                <RefinementList
                  attribute="typeId"
                  limit={3}
                  showMore={true}
                  showMoreLimit={40}
                  transformItems={
                    items => items = this.sortRefinement(items, 'qf_type')
                      .map(item => ({
                        ...item,
                        label: (valueList[item.label] ? (valueList[item.label].fr) : ''),
                      }))
                  }
                  translations={{
                    showMore(expanded) {
                      return expanded ? <IntlMessages id="recherche.filtres.typeExtrait.moins" /> : <IntlMessages id="recherche.filtres.typeExtrait.plus" />;
                    },
                    noResults: <IntlMessages id="recherche.filtres.pasDeResultat" />,
                  }}
                />
              </div>

              <div className="cm-search-filterBlock">
                <div className="cm-search-filtre-title">
                  <IntlMessages id="recherche.filtres.intention" />
                </div>
                <RefinementList
                  attribute="interpretations"
                  limit={3}
                  showMore={true}
                  transformItems={
                    items => items = this.sortIntentions(items)
                      .map(item => ({
                        ...item,
                        label: (getGroupeInterpretation(item.label) ? (displayEmoji(getGroupeInterpretation(item.label).icon) + ' ' + this.props.intl.formatMessage({ id: getGroupeInterpretation(item.label).label })) : ''),
                      }))
                  }
                  translations={{
                    showMore(expanded) {
                      return expanded ? <IntlMessages id="recherche.filtres.intention.moins" /> : <IntlMessages id="recherche.filtres.intention.plus" />;
                    },
                    noResults: <IntlMessages id="recherche.filtres.pasDeResultat" />,
                  }}
                />
              </div>

              <div className="cm-search-filterBlock">
                <div className="cm-search-filtre-title cm-search-filtre-slider-title" style={{ paddingBottom: "12px" }}>
                  <IntlMessages id="recherche.filtres.timbre" />
                  <span className="cm-search-filtre-slider-count">
                    <CustomStateResults />
                  </span>
                </div>

                <div style={{ width: "160px", display: "flex" }}>
                  <CustomRangeSliderTimbre
                    attribute="toneIndex"
                    min={this.state.minToneRange}
                    max={this.state.maxToneRange}
                    resetTimbreRange={this.state.resetTimbreRange}
                    setResetTimbreRange={this.setResetTimbreRange}
                  />
                </div>

              </div>

              <div className="cm-search-filterBlock">
                <div className="cm-search-filtre-title">
                  <IntlMessages id="recherche.filtres.localisation" />
                </div>
                <div className="refinement-list-regions">
                  <RefinementList
                    attribute="regionsIds"
                    limit={3}
                    showMore={true}
                    transformItems={
                      items => items = this.sortRefinement(items, 'qf_region')
                        .map(item => ({
                          ...item,
                          label: (valueList[item.label] ? valueList[item.label].fr : ''),
                        }))
                    }
                    translations={{
                      showMore(expanded) {
                        return expanded ? <IntlMessages id="recherche.filtres.localisation.moins" /> : <IntlMessages id="recherche.filtres.localisation.plus" />;
                      },
                      noResults: <IntlMessages id="recherche.filtres.pasDeResultat" />,
                    }}
                  />
                </div>

                <div className="refinement-list-travail-distant" style={{ marginTop: "15px" }}>
                  <RefinementList
                    attribute="WorkRemotely"
                    limit={1}
                    showMore={false}
                    transformItems={
                      items => //orderBy(items, "label", "asc")
                        items.map(item => ({
                          ...item,
                          label: <span style={{ fontSize: "12px" }}><IntlMessages id="recherche.filtres.travailleDistance" /></span>,
                        }))
                    }
                    translations={{
                      showMore(expanded) {
                        return expanded ? <IntlMessages id="recherche.filtres.localisation.moins" /> : <IntlMessages id="recherche.filtres.localisation.plus" />;
                      },
                      noResults: '',
                    }}
                  />
                </div>
              </div>

            </div>
          </div>
        </CustomScrollbars>
      </div>
    );
  }
}

const mapStateToProps = ({ }) => {
  return {}
};

export default withRouter(
  injectIntl(
    connect(
      mapStateToProps,
      {

      }
    )(AlgoliaFilter)
  )
);