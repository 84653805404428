import {
  PARTAGE_ADD_EXTRAIT,
  PARTAGE_GET_EXTRAIT_BY_LINK,
  PARTAGE_SHOW_EXTRAIT_BY_LINK,

  //////////////////////
  PARTAGE_SHOW_EXTRAIT,
  PARTAGE_ADD_PLAYLIST,
  PARTAGE_SHOW_PLAYLIST,
} from "constants/ActionTypes";

//////////////////////////////////////////////////////////////
// Partage d'extrait
/////////////////////////////////////////////////////////////

export const addPartageExtraitAction = (idSon) => {
  return {
    type: PARTAGE_ADD_EXTRAIT,
    payload: { idSon }
  };
};

export function showPartageExtraitAction(dataPartageExtrait) {
  return { 
    type: PARTAGE_SHOW_EXTRAIT, 
    dataPartageExtrait 
  };
}

//////////////////////////////////////////////////////////////
// R�cup�rtion d'un partage d'extrait
/////////////////////////////////////////////////////////////

export const getPartageExtraitByLinkAction = (link) => {
  return {
    type: PARTAGE_GET_EXTRAIT_BY_LINK,
    payload:{
      link
    }
  };
};

export function showPartageExtraitByLinkAction(dataPartageExtraitByLink) {
  return { 
    type: PARTAGE_SHOW_EXTRAIT_BY_LINK, 
    dataPartageExtraitByLink 
  };
};

//////////////////////////////////////////////////////////////
// Partage de playlist
/////////////////////////////////////////////////////////////

export const addPartagePlaylistAction = (idPlaylist) => {
  return {
    type: PARTAGE_ADD_PLAYLIST,
    payload: { idPlaylist }
  };
};

export function showPartagePlaylistAction(dataPartagePlaylist) {
  return { 
    type: PARTAGE_SHOW_PLAYLIST, 
    dataPartagePlaylist 
  };
}

