import {combineReducers} from "redux";
import {routerReducer} from "react-router-redux";
import Settings from "./Settings";
import Common from './Common';
import Auth from "./Auth";
import Comedien from "./Comedien";
import User from "./User";
import Administrateur from './Administrateur';
import Playlist from './Playlist';
import Partage from './Partage';
import Log from './Log';

const reducers = combineReducers({
  routing: routerReducer,
  settings: Settings,
  common:Common,
  auth: Auth,
  comedien: Comedien,
  user:User,
  administrateur: Administrateur,
  playlist:Playlist,
  partage:Partage,
  log:Log
});

export default reducers;
