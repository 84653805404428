import React, { Component } from "react";
import { connect } from "react-redux";
import { Drawer } from "antd";
import MobileMenuContent from "./MobileMenuContent";

import {
  toggleCollapsedMobileMenu,
  updateWindowWidth
} from "appRedux/actions/Setting";

import {
  THEME_TYPE_LITE
} from "constants/ThemeSetting";


export class MobileMenu extends Component {

  onToggleCollapsedMobileMenu = () => {
    this.props.toggleCollapsedMobileMenu(!this.props.mobileMenuCollapsed);
  };

  componentDidMount() {
    window.addEventListener('resize', () => {
      this.props.updateWindowWidth(window.innerWidth)
    });
  }

  render() {
    const { themeType, mobileMenuCollapsed } = this.props;

    return (
      <Drawer
        className={`cm-drawer-mobile-menu ${themeType !== THEME_TYPE_LITE ? 'gx-drawer-sidebar-dark' : null}`}
        placement="right"
        closable={false}
        onClose={this.onToggleCollapsedMobileMenu.bind(this)}
        visible={mobileMenuCollapsed}>
        <MobileMenuContent
          onSignUp={this.props.onSignUp}
          onSignIn={this.props.onSignIn}
          onShowPlaylists={this.props.onShowPlaylists}
          onDisplayModalProjet={this.props.onDisplayModalProjet}
        />
      </Drawer>
    );

  }
}

const mapStateToProps = ({ settings }) => {
  const {
    themeType,
    mobileMenuCollapsed,
    width,
    locale
  } = settings;

  return {
    themeType,
    mobileMenuCollapsed,
    width,
    locale
  }
};
export default connect(
  mapStateToProps, {
  toggleCollapsedMobileMenu,
  updateWindowWidth
}
)(MobileMenu);
