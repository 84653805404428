import React, { Component } from "react";

import { withRouter } from "util/withRouter";
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Row, Col } from "antd";
import { newsLetterRequest } from "appRedux/services/User";
import {APPLICATION_PATHS} from "constants/ApplicationPaths";
import IntlMessages from "util/IntlMessages";
import { decodeUrl } from 'util/Url';
import { injectIntl } from 'react-intl';

import social1 from "../../assets/images/footer/social1.webp";
import social2 from "../../assets/images/footer/social2.webp";
import social3 from "../../assets/images/footer/social3.webp";
import social4 from "../../assets/images/footer/social4.webp";


class FooterContent extends Component {
  constructor() {
    super();
    this.state = {
      email: ''
    }
  }

  handleCancel = () => {
  };
  //*********************handleSubmit********************************/
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      newsLetterRequest(values);
      this.setState({ loading: true });
      setTimeout(() => {
        this.setState({ loading: false });
      }, 3000);
    });
  };

  visibleModal = () => {

  };


  returnToAdmin = () =>{
    let switchUser = localStorage.switchUser;
    if(switchUser){ 
      let objSwitchUser = JSON.parse(switchUser);  
      let admin = localStorage.admin;
      if(admin){ 
        localStorage.setItem('user', admin);
        localStorage.removeItem('admin');
        localStorage.removeItem('switchUser');
        window.location = decodeUrl(objSwitchUser.urlAdmin);
      }
    }
  }

  render() {
    var intl = this.props.intl;

    let switchUser = localStorage.switchUser;
    if(switchUser){ 
      var objSwitchUser = JSON.parse(switchUser);
    }else{
      var objSwitchUser = null;
    }

    return(
      <div className="cm-footer">
        <div className="cm-footer-content">
          <Row className="cm-footer-content-row1">

            <Col xl={8} lg={8} md={8} sm={8} xs={24}>
              <h2><IntlMessages id="footer.contact.titre" /></h2>

              <ul className="block-items">
                <li>
                  <div className="block-items-icon">
                    <span><i className="fas fa-map-marker-alt"></i></span>
                  </div>
                  <div>
                    <span style={{lineHeight:"23px"}}>
                      <IntlMessages id="footer.contact.adresse.1" />
                      <br />
                      <IntlMessages id="footer.contact.adresse.2" />
                      <br/>
                      <IntlMessages id="footer.contact.adresse.3" />
                      <br />
                      <IntlMessages id="footer.contact.adresse.4" />
                      <br />
                      <IntlMessages id="footer.contact.adresse.5" />
                    </span>
                  </div>
                </li>
                <li>
                  <div className="block-items-icon">
                    <span><i className="fas fa-phone-alt"></i></span>
                  </div>
                  <div>
                    <span>+ 33 (0)1 49 00 05 40</span>
                  </div>
                </li>
                <li>
                  <div className="block-items-icon">
                    <span><i className="fa fa-envelope"></i></span>
                  </div>
                  <div>
                  <span><a href="mailto:info@castingmachine.com">info@castingmachine.com</a></span>
                  </div>
                </li>
              </ul>

            </Col>

            <Col xl={8} lg={8} md={8} sm={8} xs={24}>
              <h2><IntlMessages id="footer.acces.titre" /></h2>

              <ul className="block-items">
                <li>
                  <a href="/equipe"> 
                    <IntlMessages id="footer.acces.equipe" /> 
                  </a>
                </li>
                <li>
                  <a href={APPLICATION_PATHS.DECOUVERTE}>
                    <IntlMessages id="footer.acces.voix-off" />
                  </a>
                </li>
                <li>
                  <a href={APPLICATION_PATHS.INSCRIPTION_COMEDIEN}> 
                    <IntlMessages id="footer.acces.comedien" />
                  </a>
                </li>
                <li>
                  <a href={APPLICATION_PATHS.CGU}> 
                    <IntlMessages id="footer.acces.cgu" />
                  </a>
                  &nbsp; - &nbsp;
                  <a href={APPLICATION_PATHS.RGPD}> 
                    <IntlMessages id="footer.acces.rgpd" />
                  </a>
                </li>
                <li>
                  <a href={APPLICATION_PATHS.AIDE}> 
                    <IntlMessages id="footer.acces.aide" />
                  </a>
                </li>

              </ul>

            </Col>

            <Col xl={8} lg={8} md={8} sm={8} xs={24}>
        
              <h2>
                <a href="https://instagram.com/castingmachine/" aria-label="Instagram" target="_blank">
                  <i className="fab fa-instagram"></i>
                </a>
                <a href="https://fr-fr.facebook.com/castingmachine/" aria-label="Facebook" target="_blank">
                  <i className="fab fa-facebook-square"></i>
                </a>
                <IntlMessages id="footer.social.titre" />
              </h2>

              <div className="cm-footer-block-social-images">
                
                <div style={{display: "flex",flexDirection: "row",marginBottom:"5px"}}>
                  <div style={{marginRight:"5px"}}>
                    <img loading="lazy" fetchpriority="low" src={social1} width="106" height="106" alt={intl.formatMessage({ id: "footer.img.alt.socialImages" })} />
                  </div>
                  <div style={{paddingRight: "0px"}}>
                    <img loading="lazy" fetchpriority="low" src={social2} width="106" height="106" alt={intl.formatMessage({ id: "footer.img.alt.socialImages" })} />
                  </div>
                </div>

                <div style={{display: "flex",flexDirection: "row"}}>
                  <div style={{marginRight:"5px"}}>
                    <img loading="lazy" fetchpriority="low" src={social3} width="106" height="106" alt={intl.formatMessage({ id: "footer.img.alt.socialImages" })} />
                  </div>
                  <div>
                    <img loading="lazy" fetchpriority="low" src={social4} width="106" height="106" alt={intl.formatMessage({ id: "footer.img.alt.socialImages" })} />
                  </div>
                </div>
              </div>
              <div className="cm-footer-newsletter">
                  <i className="far fa-envelope"></i>
                  <IntlMessages id="footer.newsletter" />
              </div>

            </Col>
          </Row>

          <div className="cm-footer-content-row2">
            <span className="cm-footer-copyright"><IntlMessages id="footer.copyright" /></span>
          </div>

        </div>

        {objSwitchUser ?
          <div className="cm-footer-switch-user-bar">

            <button onClick={this.returnToAdmin}>
                Revenir &agrave; votre compte administrateur
            </button>

          </div>
        : null}
      </div>
    );
  }
}


const FooterContentForm = Form.create()(FooterContent);
export default injectIntl(withRouter(FooterContentForm));
