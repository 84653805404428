import axios from "axios";
import { endpointTemoignageComedien } from 'util/api'

/**
 * Liste des t�moignages
 */
export const getListeTemoignagesComediensRequest = async () => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  // call WS Loopback
  const wsResponse = await axios.request({
    method: 'get',
    url: `${endpointTemoignageComedien.GET_TEMOIGNAGES}?access_token=${accessToken}`,
  });

  return wsResponse;
}

/**
 * T�moignages
 */
export const getTemoignageComedienRequest = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  const { idTemoignage } = payload;
  // call WS Loopback
  const wsResponse = await axios.request({
    method: 'get',
    url: `${endpointTemoignageComedien.GET_TEMOIGNAGE}?access_token=${accessToken}`,
    params: {
      idTemoignage
    }
  });

  return wsResponse;
}

/**
 * Ajout d'un t�moignage
 * @param {*} payload 
 */
export const addTemoignageComedienRequest = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  const { values } = payload;

  const formData = new FormData();

  formData.append("idComedien", values.idComedien);
  formData.append("textTemoignage", values.textTemoignage);

  const wsResponse = await axios.request({
    method: 'post',
    url: `${endpointTemoignageComedien.ADD_TEMOIGNAGE}?access_token=${accessToken}`,
    data: formData,
    config: {
      headers: {
        'Content-Type': `multipart/form-data`,
        'Accept': 'application/json',
      }
    }
  });

  return wsResponse;
}

/**
 * Suppression d'un t�moignage
 * @param {*} payload 
 */
export const deleteTemoignageComedienRequest = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  const { idTemoignage } = payload;

  const wsResponse = await axios.request({
    method: 'delete',
    url: `${endpointTemoignageComedien.DELETE_TEMOIGNAGE}?access_token=${accessToken}`,
    data: {
      idTemoignage
    }
  });

  return wsResponse;
}

/**
 * Modification d'un t�moignage
 * @param {*} payload 
 */
export const updateTemoignageComedienRequest = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  const { idTemoignage, values } = payload;

  const formData = new FormData();

  formData.append("idTemoignage", idTemoignage);
  formData.append("idComedien", values.idComedien);
  formData.append("textTemoignage", values.textTemoignage);

  const wsResponse = await axios.request({
    method: 'post',
    url: `${endpointTemoignageComedien.UPDATE_TEMOIGNAGE}?access_token=${accessToken}`,
    data: formData,
    config: {
      headers: {
        'Content-Type': `multipart/form-data`,
        'Accept': 'application/json',
      }
    }
  });

  return wsResponse;
}


////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////






export const getSlidesTemoignagesComediensRequest = async () => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  // call WS Loopback
  const wsResponse = await axios.request({
    method: 'get',
    url: `${endpointTemoignageComedien.GET_SLIDES}?access_token=${accessToken}`,
  });

  return wsResponse;
}



