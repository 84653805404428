import React, {Component} from "react";
import {connect} from "react-redux";
//import SweetAlert from "react-bootstrap-sweetalert";
import Swal from 'sweetalert2';
import { 
  hideAlertAction,
  hideNotificationAction 
} from "appRedux/actions/Common";

import IntlMessages from "util/IntlMessages";
import {
  MESSAGE_SUCCESS,
  NOTIFICATION_SUCCESS,
} from "constants/ActionTypes";

import { injectIntl } from 'react-intl';
//import { NotificationContainer, NotificationManager } from "react-notifications";
import Parser from 'html-react-parser';
export class AlertMessage extends Component {

  constructor() {
    super();
    this.state = {
      //alertMessage: "",
      //notificationMessage: "",
      //alertSuccess: false,
      //alertWarning: false,
    };
  }

  componentDidUpdate() {
    var intl = this.props.intl;
    if (this.props.showAlert && this.props.alertMessage) {
      if (this.props.alertMessageStatus === MESSAGE_SUCCESS) {
        //this.setState({ alertSuccess: true });
        //this.setState({ alertMessage: this.props.alertMessage });

        Swal.fire({
          title: Parser(intl.formatMessage({id: this.props.alertMessage})),
          //text: "You clicked the button!",
          icon: "success"
        });
        // To clean message props
        this.props.hideAlertAction();
      } else {
        //this.setState({ alertWarning: true });
        //this.setState({ alertMessage: this.props.alertMessage });

        Swal.fire({
          title: Parser(intl.formatMessage({id: this.props.alertMessage})),
          //text: "You clicked the button!",
          icon: "warning"
        });

        // To clean message props
        this.props.hideAlertAction();
      }
      
    }/*else if (this.props.showNotification && this.props.notificationMessage) {
      if (this.props.notificationMessagStatus === NOTIFICATION_SUCCESS) {
        //this.setState({ alertSuccess: true });
        //this.setState({ notificationMessage: this.props.notificationMessage });
        //NotificationManager.success(<IntlMessages id={this.props.notificationMessage}/>,"");
        // To clean message props
        this.props.hideNotificationAction();
      } else {
        //this.setState({ alertWarning: true });
        //this.setState({ notificationMessage: this.props.notificationMessage });
        //NotificationManager.error(<IntlMessages id={this.props.notificationMessage}/>,"");
        // To clean message props
        this.props.hideNotificationAction();
      }
      
    }*/
  }


  onConfirmAlertMessage = () => {
    this.setState({
      //alertSuccess: false,
      //alertWarning: false,
    });
  };
  
  render(){
    var intl = this.props.intl;
    const { /*alertSuccess,*/ /*alertWarning,*/ /*alertMessage*/ } = this.state;

    return(
      <div>
        {/*<SweetAlert
          show={alertSuccess}
          success
          title={alertMessage !== "" ?(Parser(intl.formatMessage({id: alertMessage}))):""}
          onConfirm={this.onConfirmAlertMessage}>
        </SweetAlert>
        

        <SweetAlert show={alertWarning}
          warning
          title={alertMessage !== "" ?(intl.formatMessage({id: alertMessage})):""}
          onConfirm={this.onConfirmAlertMessage}>
        </SweetAlert>
    */}

      </div>
    );
  }
}

const mapStateToProps = ({ common }) => {
  const {
    alertMessage,
    showAlert,
    alertMessageStatus,
    showNotification,
    notificationMessage,
    notificationMessagStatus,
    
  } = common;

  return {
    alertMessage,
    showAlert,
    alertMessageStatus,
    showNotification,
    notificationMessage,
    notificationMessagStatus,
  }
};

export default injectIntl(connect(mapStateToProps,{hideAlertAction,hideNotificationAction})(AlertMessage));

