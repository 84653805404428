import {
  DEMANDE_INSCRIPTION_COMEDIEN,
  SET_STATUT_DEMANDE_INSCRIPTION_COMEDIEN,
  VERIF_PAIEMENT_DEMANDE_INSCRIPTION_COMEDIEN,
  SET_STATUS_PAIEMENT_DEMANDE_INSCRIPTION_COMEDIEN,
  UTILISATEUR_PAIEMENT_INSCRIPTION_CHEQUE,
  SET_STATUS_PAIEMENT_INSCRIPTION_COMEDIEN_CHEQUE,
  UTILISATEUR_GET_FORM_INSCRIPTION,
  UTILISATEUR_SHOW_FORM_INSCRIPTION,
  UTILISATEUR_GET_PROFIL,
  UTILISATEUR_SHOW_PROFIL,
  UTILISATEUR_UPDATE_PROFIL,
  UTILISATEUR_GET_COORDONNEES,
  UTILISATEUR_SHOW_COORDONNEES,
  UTILISATEUR_UPDATE_COORDONNEES,
  USER_GET_HISTORIQUE_PARTAGES,
  USER_SHOW_HISTORIQUE_PARTAGES,
  
    SHOW_REQUEST_LOADER,
    SHOW_ALERT,
    HIDE_ALERT,
    
    GET_ROLE_UTILISATEUR,
    SHOW_ROLE_UTILISATEUR,
    
    
    
   
    GET_ACCE_CONFIDENTIALITE_USER,
    SHOW_ACCE_CONFIDENTIALITE_USER ,

} from "constants/ActionTypes";

///////////////////////////////////////////////////////////////////////
// Demande d'inscription comedien
///////////////////////////////////////////////////////////////////////

export const inscriptionComedienAction = (idUser, formule,values,file1,file2,file3) => {
  return {
    type: DEMANDE_INSCRIPTION_COMEDIEN,
    payload: {
      idUser,
      formule,
      values,
      file1,
      file2,
      file3,
    },
  };
};

export const setStatutInscriptionComedienAction = (status) => {
  return {
    type: SET_STATUT_DEMANDE_INSCRIPTION_COMEDIEN,
    payload: status,
  };
};

export const verifPaiementInscriptionComedienAction = (dataUtilisateur, dataPaybox) => {
  return {
    type: VERIF_PAIEMENT_DEMANDE_INSCRIPTION_COMEDIEN,
    payload: {
      dataUtilisateur, dataPaybox
    },
  };
};

export const setStatusPaiementInscriptionComedien = (status) => {
  return {
    type: SET_STATUS_PAIEMENT_DEMANDE_INSCRIPTION_COMEDIEN,
    payload: status,
  };
};

///////////////////////////////////////////////////////////////////////
// Inscription comédien : Paybox Form
///////////////////////////////////////////////////////////////////////

export const getFormInscriptionAction=(formule)=>{
  return {
    type: UTILISATEUR_GET_FORM_INSCRIPTION,
    payload:{
      formule
    }
  };
}

export function showFormInscriptionAction(dataPayboxFormInscriptionComedien) {
  return { 
    type: UTILISATEUR_SHOW_FORM_INSCRIPTION,
    dataPayboxFormInscriptionComedien 
  };
}

///////////////////////////////////////////////////////////////////////
// Inscription comédien : Payement par chèque bancaire
///////////////////////////////////////////////////////////////////////

export const paiementInscriptionComedienParChequeAction = (formule, values) => {
  return {
    type: UTILISATEUR_PAIEMENT_INSCRIPTION_CHEQUE,
    payload: {
      formule,
      values
    },
  };
};

export const setStatusPaiementInscriptionComedienCheque = (status) => {
  return {
    type: SET_STATUS_PAIEMENT_INSCRIPTION_COMEDIEN_CHEQUE,
    payload: status,
  };
};

///////////////////////////////////////////////////////////////////////
// Mon Compte
///////////////////////////////////////////////////////////////////////

//Profil

export function getUserProfilAction(idUser) {
  return {
    type: UTILISATEUR_GET_PROFIL,
    payload:{
      idUser
    }
  };
}

export function showProfilAction(dataProfil) {
  return { 
    type: UTILISATEUR_SHOW_PROFIL, 
    dataProfil 
  };
}

export const updateUserProfilAction = (idUser, values, photoProfil, photoProfilCropped) => {
  return {
    type: UTILISATEUR_UPDATE_PROFIL,
    payload: {
      idUser,
      values,
      photoProfil,
      photoProfilCropped
    }
  };
};

//Coordonnées

export const getUserCoordonneesAction=(idUser)=>{
  return {
    type: UTILISATEUR_GET_COORDONNEES,
    payload:{
      idUser
    }
  };
}

export function showUserCoordonneesAction(dataCoordonnees) {
  return { 
    type: UTILISATEUR_SHOW_COORDONNEES, 
    dataCoordonnees 
  };
}

export const updateUserCoordonneesAction = (idUser, values) => {
  return {
    type: UTILISATEUR_UPDATE_COORDONNEES,
    payload:{
      idUser,
      values
    } 
  };
};

//Accès et confidentialité

export const getUserListDemandesAccesAction=()=>{
  return{
    type:GET_ACCE_CONFIDENTIALITE_USER
  }
}

export function showUserListDemandesAccesAction(confidentialite) {
  return { 
    type: SHOW_ACCE_CONFIDENTIALITE_USER, 
    confidentialite 
  };
}

//Historique
export const getUserHistoriquePartagesAction = (pageSize = 10, page = 1, action = '') => {
  return {
    type: USER_GET_HISTORIQUE_PARTAGES,
    payload: {
      pageSize,
      page,
      action
    }
  }
}

export const showUserHistoriquePartagesAction = (dataHistoriquePartage) => {
  return {
    type: USER_SHOW_HISTORIQUE_PARTAGES,
    dataHistoriquePartage
  }
}


/////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////



//**************************************Action :: "Loader/Message"**************************************/

  


//**************************************Action :: "Menu: Mon Compte"**************************************/


/************** get type compte *************/

export function getRoleUtilisateur() {
  return {
    type: GET_ROLE_UTILISATEUR,
  };
}

export function showRoleUtilisateur(roleUtilisateur) {
  return { type: SHOW_ROLE_UTILISATEUR, roleUtilisateur };
}

/************** get et set compte *************/



  /************** get et set coordonnées *************/


  


  /************ Get Acce Confidentialite **************/
  

 /************ Get mes castings **************/




