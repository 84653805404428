// ⚠️ This example only works with the version 2.x of Rheostat

import React, { Component } from "react";
import Rheostat from 'rheostat';
import IntlMessages from "util/IntlMessages";

import{
  ALGOLIA_INDEX_AGE_BEBE,
  ALGOLIA_INDEX_AGE_ENFANT,
  ALGOLIA_INDEX_AGE_ADO,
  ALGOLIA_INDEX_AGE_JEUNE,
  ALGOLIA_INDEX_AGE_ADULTE,
  ALGOLIA_INDEX_AGE_SENIOR
}from "constants/General";

class RangeSliderAge extends Component {
  state = {
    minValue: 0,
    maxValue: 65,
    min: this.props.min,
    max: this.props.max,
  }

  componentDidUpdate(prevProps) {
    const { currentRefinement } = prevProps;
    if (
      this.props.canRefine &&
      ( 
        currentRefinement.min !== this.props.currentRefinement.min ||
        currentRefinement.max !== this.props.currentRefinement.max
      )
    ) {
      this.setState({
        ...currentRefinement,
      });
    }
  }

  getAlgoliaIndexValue = (value) => {
    if(value < 4 ){
      return ALGOLIA_INDEX_AGE_BEBE;
    }else if(value < 12 ){
      return ALGOLIA_INDEX_AGE_ENFANT;
    }else if(value < 15  ){
      return ALGOLIA_INDEX_AGE_ADO;
    }else if(value < 25 ){
      return ALGOLIA_INDEX_AGE_JEUNE;
    }else if(value < 55 ){
      return ALGOLIA_INDEX_AGE_ADULTE;
    }else{
      return ALGOLIA_INDEX_AGE_SENIOR;
    }
  }

  onChange = ({ values: [min, max] }) => {
    const { currentRefinement, refine } = this.props;

    let minAlgolia = this.getAlgoliaIndexValue(min);
    let maxAlgolia = this.getAlgoliaIndexValue(max);

    //if (currentRefinement.min !== minAlgolia || currentRefinement.max !== maxAlgolia) {
    refine({
      min:minAlgolia,
      max:maxAlgolia,
    });
    //}
  }

  onValuesUpdated = ({ values: [min, max] }) => {
    
    this.setState({
      min:min,
      max:max,
      minValue:min,
      maxValue:max
    });
  }

  render() {
    const { min, max, currentRefinement } = this.props;

    if(this.props.resetAgeRange) {
      this.setState({
        minValue : 0,
        maxValue : 65
      });
      this.props.setResetAgeRange();
    } 

    if (min === max) {
      return null;
    }

    let minAlgolia = this.getAlgoliaIndexValue(this.state.minValue);
    let maxAlgolia = this.getAlgoliaIndexValue(this.state.maxValue);

    var classBebe   = 'rheostat-value-selected';
    var classEnfant = 'rheostat-value-selected';
    var classAdo    = 'rheostat-value-selected';
    var classJeune  = 'rheostat-value-selected';
    var classAdulte = 'rheostat-value-selected';
    var classSenior = 'rheostat-value-selected';

    if(minAlgolia === ALGOLIA_INDEX_AGE_BEBE){
      //Nothing to do
    }else if(minAlgolia === ALGOLIA_INDEX_AGE_ENFANT){
      classBebe   = '';
    }else if(minAlgolia === ALGOLIA_INDEX_AGE_ADO){
      classBebe   = '';
      classEnfant = '';
    }else if(minAlgolia === ALGOLIA_INDEX_AGE_JEUNE){
      classBebe   = '';
      classEnfant = '';
      classAdo    = '';
    }else if(minAlgolia === ALGOLIA_INDEX_AGE_ADULTE){
      classBebe   = '';
      classEnfant = '';
      classAdo    = '';
      classJeune  = '';
    }else if(minAlgolia === ALGOLIA_INDEX_AGE_SENIOR){
      classBebe   = '';
      classEnfant = '';
      classAdo    = '';
      classJeune  = '';
      classAdulte = '';
    }

    if(maxAlgolia == ALGOLIA_INDEX_AGE_SENIOR){
      //Nothing to do
    }else if(maxAlgolia == ALGOLIA_INDEX_AGE_ADULTE){
      classSenior = '';
    }else if(maxAlgolia == ALGOLIA_INDEX_AGE_JEUNE){
      classSenior = '';
      classAdulte = '';
    }else if(maxAlgolia == ALGOLIA_INDEX_AGE_ADO){
      classSenior = '';
      classAdulte = '';
      classJeune  = '';
    }else if(maxAlgolia == ALGOLIA_INDEX_AGE_ENFANT){
      classSenior = '';
      classAdulte = '';
      classJeune  = '';
      classAdo    = '';
    }else if(maxAlgolia == ALGOLIA_INDEX_AGE_BEBE){
      classSenior = '';
      classAdulte = '';
      classJeune  = '';
      classAdo    = '';
      classEnfant = '';
    }

    return (
      <div className="cm-search-rangeslider-age">
          <div style={{marginBottom:"10px"}}>
            <div 
                className="rheostat-value"
                style={{ float:"left" }}
            >
                {this.state.minValue} ans
            </div>

            <div 
              className="rheostat-value"
              style={{ float:"right" }}
            >
              {this.state.maxValue != 65 ? this.state.maxValue : this.state.maxValue + "+"} ans
            </div>
          </div>


        <Rheostat
          className="ais-RangeSlider"
          min={min}
          max={max}
          values={[this.state.minValue, this.state.maxValue]}
          onChange={this.onChange}
          onValuesUpdated={this.onValuesUpdated}
        >
          
          <div
            className="rheostat-marker rheostat-marker--large"
            style={{ left: 0 }}
          >
          </div>

          <div
            className="rheostat-marker rheostat-marker--large"
            style={{ right: 0 }}
          >
          </div>
        </Rheostat>

        <div style={{width:"100%",display:"flex",marginTop:"15px"}}>
          <div className={"cm-rheostat-interval " + classEnfant} style={{width:"20%",textAlign:"center"}}>
            <IntlMessages id="rangeSliderAge.enfant" />
          </div>
          <div className={"cm-rheostat-interval " + classAdo} style={{width:"20%",textAlign:"center"}}>
            <IntlMessages id="rangeSliderAge.ado" />
          </div>
          <div className={"cm-rheostat-interval " + classJeune} style={{width:"20%",textAlign:"center"}}>
            <IntlMessages id="rangeSliderAge.jeune" />
          </div>
          <div className={"cm-rheostat-interval " + classAdulte} style={{width:"20%",textAlign:"center"}}>
            <IntlMessages id="rangeSliderAge.adute" />
          </div>
          <div className={"cm-rheostat-interval " + classSenior} style={{width:"20%",textAlign:"right"}}>
            <IntlMessages id="rangeSliderAge.senior" />
          </div>
        </div>
      </div>
    );
  }
}

 export default RangeSliderAge;