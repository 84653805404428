import React, { Component } from "react";
import { connect } from "react-redux";
import { Modal } from "antd";
import SignIn from "./SignIn";
import SignUp from "./SignUp";
import { withRouter } from "util/withRouter";
import { injectIntl } from 'react-intl';
import Swal from 'sweetalert2';

import {
  SIGNIN_STATUS_INIT,
  SIGNUP_STATUS_INIT,
} from "constants/ActionTypes";

import {
  STATUS_REQUEST_RESET_PASSWORD_INIT,
} from "constants/General";

import { 
  setStatusSignInAction,
  setStatusSignUpAction,
  setStatusRequestResetPasswordAction
} from "appRedux/actions/Auth";

import logoCastingMachine from "assets/images/logo-casting-machine.webp";

class Auth extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
        visible: false,
        vue: 'signIn',
        msgAccountVerified: false,
    };
  }

  componentDidMount() {
  }

  componentDidUpdate(prevProps) {
    if (prevProps.visible !== this.props.visible) {
      this.setState({ visible: this.props.visible });
    }

    if (prevProps.vue !== this.props.vue) {
      this.setState({ vue: this.props.vue });
    }
  }

  onCancelModal = (e) => {
    this.props.setStatusSignInAction(SIGNIN_STATUS_INIT);
    this.props.setStatusSignUpAction(SIGNUP_STATUS_INIT);
    this.props.setStatusRequestResetPasswordAction(STATUS_REQUEST_RESET_PASSWORD_INIT);
    this.setState({visible:false})
    this.props.onCancel();
  };

  onShowSignUp = () => {
    this.setState({
        vue:'signUp'
    });
  };

  onShowSignIn = () => {
    this.setState({
        vue:'signIn'
    })
  };

  onSignInSuccess = () => {
    this.props.setStatusSignInAction(SIGNIN_STATUS_INIT);
    this.props.setStatusSignUpAction(SIGNUP_STATUS_INIT);
    this.props.setStatusRequestResetPasswordAction(STATUS_REQUEST_RESET_PASSWORD_INIT);
    
    if(this.onSignInSuccess){
      this.props.onSignInSuccess();
    }
    
  }

  onSignUpSuccess = () => {  
    this.props.setStatusSignInAction(SIGNIN_STATUS_INIT);
    this.props.setStatusSignUpAction(SIGNUP_STATUS_INIT);
    this.props.setStatusRequestResetPasswordAction(STATUS_REQUEST_RESET_PASSWORD_INIT);
    
    if(this.props.onSignUpSuccess){
      this.props.onSignUpSuccess();
    }
    

    this.setState({
      visible: false
    });
  }

  onForgotPasswordSuccess = () => {
    var intl = this.props.intl;
    
    this.onCancelModal();

    Swal.fire({
      imageUrl: logoCastingMachine,
      imageWidth: 180,
      buttonsStyling: false,
      customClass: { confirmButton: 'ant-btn ant-btn-primary', cancelButton: 'ant-btn' },
      title: intl.formatMessage({id: "forgotPassword.success.title"}),
      text: intl.formatMessage({id: 'forgotPassword.success.text'}),
    });
  }

  render() {
    return (
      <Modal
        wrapClassName="cm-auth-modal"
        visible={this.state.visible}
        onCancel={this.onCancelModal}
        destroyOnClose={true}
        footer={[]}
      >
        {(() => {
          if(this.state.vue === 'signIn'){
            return(
              <SignIn 
                onSignInSuccess={this.onSignInSuccess}
                onShowSignUp={this.onShowSignUp}
                onForgotPasswordSuccess={this.onForgotPasswordSuccess}
              />
            );
          }else if(this.state.vue === 'signUp'){
            return(
              <SignUp
                onShowSignIn={this.onShowSignIn}
                onSignUpSuccess={this.onSignUpSuccess}
                signUpButtonText={this.props.signUpButtonText}
              />
            )
          }else{
            return null;
          }
        })()}
      </Modal>
    );
  }
}

const mapStateToProps = ({}) => {
  return {}
};

export default injectIntl(withRouter(
  connect(
    mapStateToProps, 
    {
      setStatusSignInAction,
      setStatusSignUpAction,
      setStatusRequestResetPasswordAction
    }
  )(Auth))
);
