import {
  COMEDIEN_GET_IDENTITE,
  COMEDIEN_SHOW_IDENTITE,
  COMEDIEN_UPDATE_IDENTITE,
  COMEDIEN_GET_ACTIVITE_FACTURATION,
  COMEDIEN_SHOW_ACTIVITE_FACTURATION,
  COMEDIEN_UPDATE_ACTIVITE_FACTURATION,
  COMEDIEN_GET_CONTACT,
  COMEDIEN_SHOW_CONTACT,
  COMEDIEN_UPDATE_CONTACT,
  COMEDIEN_GET_CV,
  COMEDIEN_SHOW_CV,
  COMEDIEN_UPDATE_CV,
  COMEDIEN_ADD_PHOTO,
  COMEDIEN_GET_LISTE_PHOTOS,
  COMEDIEN_SHOW_LISTE_PHOTOS,
  COMEDIEN_DELETE_PHOTO,
  COMEDIEN_SORT_PHOTO,
  COMEDIEN_ADD_DEMANDE_PHOTO_XL,
  COMEDIEN_GET_DEMANDE_PHOTO_XL,
  COMEDIEN_SHOW_DEMANDE_PHOTO_XL,
  COMEDIEN_GET_STATS_MP3,
  COMEDIEN_SHOW_STATS_MP3,
  COMEDIEN_GET_LISTE_MP3,
  COMEDIEN_SHOW_LISTE_MP3,
  COMEDIEN_SORT_LISTE_MP3,
  COMEDIEN_UPDATE_VISIBILITE_MP3,
  COMEDIEN_DELETE_MP3,
  COMEDIEN_GET_EXTRAIT_MP3,
  COMEDIEN_SHOW_EXTRAIT_MP3,
  COMEDIEN_UPDATE_MP3,
  COMEDIEN_ADD_MP3,
  COMEDIEN_GET_STATS_VIDEOS,
  COMEDIEN_SHOW_STATS_VIDEOS,
  COMEDIEN_GET_LISTE_VIDEOS,
  COMEDIEN_SHOW_LISTE_VIDEOS,
  COMEDIEN_SORT_LISTE_VIDEOS,
  COMEDIEN_UPDATE_VISIBILITE_VIDEO,
  COMEDIEN_DELETE_VIDEO,
  COMEDIEN_GET_EXTRAIT_VIDEO,
  COMEDIEN_SHOW_EXTRAIT_VIDEO,
  COMEDIEN_UPDATE_VIDEO,
  COMEDIEN_ADD_VIDEO,
  COMEDIEN_ADD_NEWS,
  COMEDIEN_GET_LIST_NEWS,
  COMEDIEN_SHOW_LIST_NEWS,
  COMEDIEN_GET_INFOS_EMPLOYEUR,
  COMEDIEN_SHOW_INFOS_EMPLOYEUR,
  COMEDIEN_UPDATE_INFOS_EMPLOYEUR,
  COMEDIEN_GET_EQUIPEMENT,
  COMEDIEN_SHOW_EQUIPEMENT,
  COMEDIEN_UPDATE_EQUIPEMENT,
  COMEDIEN_GET_TDB_MON_COMPTE,
  COMEDIEN_SHOW_TDB_MON_COMPTE,
  COMEDIEN_GET_ADRESSE_FACTURATION,
  COMEDIEN_SHOW_ADRESSE_FACTURATION,
  COMEDIEN_UPDATE_ADRESSE_FACTURATION,
  COMEDIEN_GET_FACTURES,
  COMEDIEN_SHOW_FACTURES,
  COMEDIEN_GET_STATS,
  COMEDIEN_SHOW_STATS,
  COMEDIEN_GET_TOP_EXTRAITS_STATS,
  COMEDIEN_SHOW_TOP_EXTRAITS_STATS,
  COMEDIEN_GET_HISTORIQUE_STATS,
  COMEDIEN_SHOW_HISTORIQUE_STATS,
  COMEDIEN_GET_LIST_DEMANDES_ACCES,
  COMEDIEN_SHOW_LIST_DEMANDES_ACCES,
  COMEDIEN_SWITCH_ACCES_TELEPHONE,
  COMEDIEN_SWITCH_ACCES_EMPLOYEUR,
  COMEDIEN_DELETE_ACCES_CONFIDENTIALITE,
  COMEDIEN_GET_RE_INSCRIPTION_FORM,
  COMEDIEN_SHOW_RE_INSCRIPTION_FORM,
  COMEDIEN_VERIF_PAIEMENT_RE_INSCRIPTION,
  COMEDIEN_SET_STATUS_PAIEMENT_RE_INSCRIPTION,
  COMEDIEN_PAIEMENT_RE_INSCRIPTION_CHEQUE,
  COMEDIEN_SET_STATUS_PAIEMENT_RE_INSCRIPTION_CHEQUE,
  COMEDIEN_SEND_DEMANDE_CONTACT,
  COMEDIEN_SET_SEND_DEMANDE_CONTACT_STATUS,
  COMEDIEN_SEND_DEMANDE_CONTACT_SUCCESS,
  COMEDIEN_SEND_DEMANDE_CONTACT_ERROR,
  COMEDIEN_GET_DEMANDE_CONTACT_DATA,
  COMEDIEN_SHOW_DEMANDE_CONTACT_DATA,
  COMEDIEN_GET_COMEDIEN_BY_URL,
  COMEDIEN_SHOW_COMEDIEN_BY_URL,
  COMEDIEN_GET_FICHE_COMEDIEN_MP3,
  COMEDIEN_SHOW_FICHE_COMEDIEN_MP3,
  COMEDIEN_GET_FICHE_COMEDIEN_VIDEOS,
  COMEDIEN_SHOW_FICHE_COMEDIEN_VIDEOS
} from "constants/ActionTypes";

///////////////////////////////////////////////////////////////////////
// Mon Profil
///////////////////////////////////////////////////////////////////////

//Identité

export function getIdentiteAction(idUser) {
  return {
    type: COMEDIEN_GET_IDENTITE,
    payload: {
      idUser: idUser
    }
  };
}

export function showIdentiteAction(dataIdentite) {
  return {
    type: COMEDIEN_SHOW_IDENTITE,
    dataIdentite
  };
}

export const updateIdentiteAction = (
  idUser,
  values,
  photoProfil,
  photoProfilCropped,
  photoFond
) => {
  return {
    type: COMEDIEN_UPDATE_IDENTITE,
    payload: {
      idUser,
      values,
      photoProfil,
      photoProfilCropped,
      photoFond
    },
  };
};

//Activité et facturation

export function getActiviteFacturationAction(idUser) {
  return {
    type: COMEDIEN_GET_ACTIVITE_FACTURATION,
    payload: {
      idUser
    }
  };
}

export function showActiviteActionFacturation(dataActivite) {
  return {
    type: COMEDIEN_SHOW_ACTIVITE_FACTURATION,
    dataActivite
  };
}

export const updateActiviteFacturationAction = (
  idUser,
  regions,
  travailDistant,
  paiementCachet,
  peutFacturer
) => {
  return {
    type: COMEDIEN_UPDATE_ACTIVITE_FACTURATION,
    payload: {
      idUser,
      regions,
      travailDistant,
      paiementCachet,
      peutFacturer
    }
  };
};

//Contact

export function getContactAction() {
  return {
    type: COMEDIEN_GET_CONTACT,
  };
}
export function showContactAction(dataContact) {
  return {
    type: COMEDIEN_SHOW_CONTACT,
    dataContact
  };
}

export const updateContactAction = (values) => {
  return {
    type: COMEDIEN_UPDATE_CONTACT,
    payload: {
      values
    }
  };
};

///////////////////////////////////////////////////////////////////////
// Mon CV
///////////////////////////////////////////////////////////////////////

export function getCVAction(idUser) {
  return {
    type: COMEDIEN_GET_CV,
    payload: {
      idUser
    }
  };
}

export function showCVAction(dataCV) {
  return {
    type: COMEDIEN_SHOW_CV,
    dataCV
  };
}

export const updateCVAction = (
  idUser,
  values,
  competences,
  infosComplementaires,
  experiences,
  formation
) => {
  return {
    type: COMEDIEN_UPDATE_CV,
    payload: {
      idUser,
      values,
      competences,
      infosComplementaires,
      experiences,
      formation
    }
  };
};

///////////////////////////////////////////////////////////////////////
// Mes photos
///////////////////////////////////////////////////////////////////////

//Photos

export const addPhotoAction = (values, photo) => {
  return {
    type: COMEDIEN_ADD_PHOTO,
    payload: {
      values,
      photo
    },
  };
};

export function getListePhotosAction() {
  return {
    type: COMEDIEN_GET_LISTE_PHOTOS
  };
}

export function showListePhotosAction(dataPhotos) {
  return {
    type: COMEDIEN_SHOW_LISTE_PHOTOS,
    dataPhotos
  };
}

export const deletePhotoAction = (idPhoto) => {
  return {
    type: COMEDIEN_DELETE_PHOTO,
    payload: {
      idPhoto
    },
  };
};

export const sortPhotosAction = (sortString) => {
  return {
    type: COMEDIEN_SORT_PHOTO,
    payload: {
      sortString
    },
  };
};

//Photo XL

export const addDemandePhotoXLAction = (values, photo) => {
  return {
    type: COMEDIEN_ADD_DEMANDE_PHOTO_XL,
    payload: {
      values,
      photo
    },
  };
};

export function getDemandePhotoXLAction() {
  return {
    type: COMEDIEN_GET_DEMANDE_PHOTO_XL
  };
}

export function showDemandePhotoXLAction(dataDemandePhotoXL) {
  return {
    type: COMEDIEN_SHOW_DEMANDE_PHOTO_XL,
    dataDemandePhotoXL
  };
}

///////////////////////////////////////////////////////////////////////
// Mes extraits MP3
///////////////////////////////////////////////////////////////////////

//Stats MP3
export function getStatsMP3Action() {
  return {
    type: COMEDIEN_GET_STATS_MP3,
  };
}

export function showStatsMP3Action(dataStatsMP3) {
  return {
    type: COMEDIEN_SHOW_STATS_MP3,
    dataStatsMP3
  };
}

//Liste des MP3

export function getListeMP3Action(idUser) {
  return {
    type: COMEDIEN_GET_LISTE_MP3,
    payload: {
      idUser
    }
  };
}

export function showListeMP3Action(dataListeMP3) {
  return {
    type: COMEDIEN_SHOW_LISTE_MP3,
    dataListeMP3
  };
}

export function sortListeMP3Action(sortString) {
  return {
    type: COMEDIEN_SORT_LISTE_MP3,
    payload: {
      sortString
    }
  };
}

export const updateVisibiliteMP3Action = (visibilite, id) => {
  return {
    type: COMEDIEN_UPDATE_VISIBILITE_MP3,
    payload: {
      visibilite,
      id
    },
  };
};

export const deleteMP3Action = (id) => {
  return {
    type: COMEDIEN_DELETE_MP3,
    payload: {
      id
    },
  };
};

//Modification d'un MP3

export function getExtraitMP3Action(idExtrait) {
  return {
    type: COMEDIEN_GET_EXTRAIT_MP3,
    payload: {
      idExtrait
    }
  };
}

export function showExtraitMP3Action(dataExtraitMp3) {
  return {
    type: COMEDIEN_SHOW_EXTRAIT_MP3,
    dataExtraitMp3
  };
}

export const updateMP3Action = (values, file) => {
  return {
    type: COMEDIEN_UPDATE_MP3,
    payload: {
      values,
      file
    },
  };
};

//Ajout d'un MP3

export const addMP3Action = (values, file) => {
  return {
    type: COMEDIEN_ADD_MP3,
    payload: {
      values,
      file,
    },
  };
};

///////////////////////////////////////////////////////////////////////
// Mes Videos
///////////////////////////////////////////////////////////////////////

//Stats Videos

export function getStatsVideosAction() {
  return {
    type: COMEDIEN_GET_STATS_VIDEOS,
  };
}

export function showStatsVideosAction(dataStatsVideos) {
  return {
    type: COMEDIEN_SHOW_STATS_VIDEOS,
    dataStatsVideos
  };
}

//Liste des vidéos

export function getListeVideosAction(idUser) {
  return {
    type: COMEDIEN_GET_LISTE_VIDEOS,
    payload: {
      idUser
    },

  };
}

export function showListeVideosAction(dataListeVideos) {
  return {
    type: COMEDIEN_SHOW_LISTE_VIDEOS,
    dataListeVideos
  };
}

export function sortListeVideosAction(sortString) {
  return {
    type: COMEDIEN_SORT_LISTE_VIDEOS,
    payload: {
      sortString
    }
  };
}

export const updateVisibiliteVideoAction = (visibilite, id) => {
  return {
    type: COMEDIEN_UPDATE_VISIBILITE_VIDEO,
    payload: {
      visibilite,
      id
    },
  };
};

export const deleteVideoAction = (id) => {
  return {
    type: COMEDIEN_DELETE_VIDEO,
    payload: {
      id
    },
  };
};

//Modification d'une vidéo

export function getExtraitVideoAction(idExtrait) {
  return {
    type: COMEDIEN_GET_EXTRAIT_VIDEO,
    payload: {
      idExtrait
    }
  };
}

export function showExtraitVideoAction(dataExtraitVideo) {
  return {
    type: COMEDIEN_SHOW_EXTRAIT_VIDEO,
    dataExtraitVideo
  };
}

export const updateVideoAction = (values, file) => {
  return {
    type: COMEDIEN_UPDATE_VIDEO,
    payload: {
      values,
      file
    },
  };
};

//Ajout d'une vidéo

export const addVideoAction = (values, file) => {
  return {
    type: COMEDIEN_ADD_VIDEO,
    payload: {
      values,
      file
    },
  };
};

///////////////////////////////////////////////////////////////////////
// Mes News
///////////////////////////////////////////////////////////////////////

// Ajout d'une News

export const addNewsAction = (values) => {
  return {
    type: COMEDIEN_ADD_NEWS,
    payload: {
      values
    },
  };
};

//Liste des News

export function getListNewsAction(idUser) {
  return {
    type: COMEDIEN_GET_LIST_NEWS,
    payload: {
      idUser
    }
  };
}

export function showListNewsAction(dataListeNews) {
  return {
    type: COMEDIEN_SHOW_LIST_NEWS,
    dataListeNews
  };
}

///////////////////////////////////////////////////////////////////////
// Mes Infos Employeur
///////////////////////////////////////////////////////////////////////

export function getInfosEmployeurAction(idUser) {
  return {
    type: COMEDIEN_GET_INFOS_EMPLOYEUR,
    payload: {
      idUser
    }
  };
}

export function showInfosEmployeurAction(dataInfosEmployeur) {
  return {
    type: COMEDIEN_SHOW_INFOS_EMPLOYEUR,
    dataInfosEmployeur
  };
}

export const updateInfosEmployeurAction = (idUser, values) => {
  return {
    type: COMEDIEN_UPDATE_INFOS_EMPLOYEUR,
    payload: {
      values,
      idUser
    }
  };
};

///////////////////////////////////////////////////////////////////////
// Mon Equipement
///////////////////////////////////////////////////////////////////////

export function getEquipementAction(idUser) {
  return {
    type: COMEDIEN_GET_EQUIPEMENT,
    payload: {
      idUser
    }
  };
}

export function showEquipementAction(dataEquipement) {
  return {
    type: COMEDIEN_SHOW_EQUIPEMENT,
    dataEquipement
  };
}

export const updateEquipementAction = (idUser, values, image) => {
  return {
    type: COMEDIEN_UPDATE_EQUIPEMENT,
    payload: {
      idUser,
      values,
      image
    },
  };
};

///////////////////////////////////////////////////////////////////////
// Mon Compte
///////////////////////////////////////////////////////////////////////

//Tableau de bord

export const getTDBMonCompteAction = () => {
  return {
    type: COMEDIEN_GET_TDB_MON_COMPTE
  };
};
export function showTDBMonCompteAction(dataTDBCompte) {
  return {
    type: COMEDIEN_SHOW_TDB_MON_COMPTE,
    dataTDBCompte
  };
};


//Récupération de l'adresse de facturation

export const getAdresseFacturationAction = (idUser) => {
  return {
    type: COMEDIEN_GET_ADRESSE_FACTURATION,
    payload: {
      idUser: idUser
    }
  };
};

export function showAdresseFacturationAction(dataAdresseFacturation) {
  return {
    type: COMEDIEN_SHOW_ADRESSE_FACTURATION,
    dataAdresseFacturation
  };
};

//Mise à jour de l'adresse de facturation

export const updateAdresseFacturationAction = (idUser, values) => {
  return {
    type: COMEDIEN_UPDATE_ADRESSE_FACTURATION,
    payload: {
      idUser,
      values
    }
  };
};

//Factures

export const getFacturesAction = () => {
  return {
    type: COMEDIEN_GET_FACTURES
  }
}

export function showFacturesAction(dataFacture) {
  return {
    type: COMEDIEN_SHOW_FACTURES,
    dataFacture
  };
};

///////////////////////////////////////////////////////////////////////
// Mes stats
///////////////////////////////////////////////////////////////////////

//Stats
export const getStatsAction = (filtre) => {
  return {
    type: COMEDIEN_GET_STATS,
    payload: {
      filtre
    }
  }
}

export const showStatsAction = (dataStats) => {
  return {
    type: COMEDIEN_SHOW_STATS,
    dataStats
  }
}

export const getTopExtraitsStatsAction = (filtre) => {
  return {
    type: COMEDIEN_GET_TOP_EXTRAITS_STATS,
    payload: {
      filtre
    }
  }
}

export const showTopExtraitsStatsAction = (dataTopExtraits) => {
  return {
    type: COMEDIEN_SHOW_TOP_EXTRAITS_STATS,
    dataTopExtraits
  }
}

//Historique

export const getHistoriqueStatsComedienAction = (pageSize = 10, page = 1, action = '', filtre) => {
  return {
    type: COMEDIEN_GET_HISTORIQUE_STATS,
    payload: {
      pageSize,
      page,
      action,
      filtre
    }
  }
}

export const showHistoriqueStatsComedienAction = (dataHistoriqueStats) => {
  return {
    type: COMEDIEN_SHOW_HISTORIQUE_STATS,
    dataHistoriqueStats
  }
}

///////////////////////////////////////////////////////////////////////
// Accés Confidentialité
///////////////////////////////////////////////////////////////////////

export function getListDemandesAccesAction() {
  return {
    type: COMEDIEN_GET_LIST_DEMANDES_ACCES
  };
}

export function showListDemandesAccesAction(dataAccesConfidentialites) {
  return {
    type: COMEDIEN_SHOW_LIST_DEMANDES_ACCES,
    dataAccesConfidentialites
  };
}

export function switchAccesTelephoneAction(idConfidentialite) {
  return {
    type: COMEDIEN_SWITCH_ACCES_TELEPHONE,
    payload: {
      idConfidentialite
    }
  };
}

export function switchAccesEmployeurAction(idConfidentialite) {
  return {
    type: COMEDIEN_SWITCH_ACCES_EMPLOYEUR,
    payload: {
      idConfidentialite
    }
  };
}

export function deleteAccesConfidentialiteAction(idConfidentialite) {
  return {
    type: COMEDIEN_DELETE_ACCES_CONFIDENTIALITE,
    payload: {
      idConfidentialite
    }
  };
}

///////////////////////////////////////////////////////////////////////
// Ré-inscription
///////////////////////////////////////////////////////////////////////

export const getReInscriptionFormAction = (formule) => {
  return {
    type: COMEDIEN_GET_RE_INSCRIPTION_FORM,
    payload: {
      formule
    }
  };
}

export function showReInscriptionFormAction(dataReInscriptionForm) {
  return {
    type: COMEDIEN_SHOW_RE_INSCRIPTION_FORM,
    dataReInscriptionForm
  };
};

export const verifPaiementReInscriptionAction = (dataComedien, dataPaybox) => {
  return {
    type: COMEDIEN_VERIF_PAIEMENT_RE_INSCRIPTION,
    payload: {
      dataComedien,
      dataPaybox
    },
  };
};

export const setStatusPaiementReInscription = (status) => {
  return {
    type: COMEDIEN_SET_STATUS_PAIEMENT_RE_INSCRIPTION,
    payload: status,
  };
};

export const paiementReInscriptionParChequeAction = (formule, values) => {
  return {
    type: COMEDIEN_PAIEMENT_RE_INSCRIPTION_CHEQUE,
    payload: {
      formule,
      values
    },
  };
};

export const setStatusPaiementReInscriptionCheque = (status) => {
  return {
    type: COMEDIEN_SET_STATUS_PAIEMENT_RE_INSCRIPTION_CHEQUE,
    payload: status,
  };
};



////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////



///////////////////////////////////////////////////////////////////////
// Contact comedien
///////////////////////////////////////////////////////////////////////

export function getDemandeContactDataAction(idComedien) {
  return {
    type: COMEDIEN_GET_DEMANDE_CONTACT_DATA,
    idComedien
  };
}

export function showDemandeContactDataAction(dataDemandeContact) {
  return {
    type: COMEDIEN_SHOW_DEMANDE_CONTACT_DATA,
    dataDemandeContact
  }
}

///////////////////////////////////////////////////////////////////////
// Demande de contact
///////////////////////////////////////////////////////////////////////

export const sendDemandeContactComedienAction = (type, idComedien, telephone, values) => {
  return {
    type: COMEDIEN_SEND_DEMANDE_CONTACT,
    payload: {
      type,
      idComedien,
      telephone,
      values
    }
  };
};

export const setSendDemandeContactComedienStatusAction = (status) => {
  return {
    type: COMEDIEN_SET_SEND_DEMANDE_CONTACT_STATUS,
    status
  };
};

export const sendDemandeContactComedienSuccessAction = () => {
  return {
    type: COMEDIEN_SEND_DEMANDE_CONTACT_SUCCESS,
  };
};

export const sendDemandeContactComedienErrorAction = (error) => {
  return {
    type: COMEDIEN_SEND_DEMANDE_CONTACT_ERROR,
    error
  };
};

///////////////////////////////////////////////////////////////////////
// Fiche Comedien avec URL
///////////////////////////////////////////////////////////////////////

export const getComedienByUrlAction = (url) => {
  return {
    type: COMEDIEN_GET_COMEDIEN_BY_URL,
    payload: {
      url: url
    }
  };
};

export function showComedienByUrlAction(dataComedienByUrl) {
  return {
    type: COMEDIEN_SHOW_COMEDIEN_BY_URL,
    dataComedienByUrl
  };
};


///////////////////////////////////////////////////////////////////////
// Get Comedien MP3 pour fiche
///////////////////////////////////////////////////////////////////////

export const getFicheComedienMP3Action = (idComedien, pageSize, page) => {
  return {
    type: COMEDIEN_GET_FICHE_COMEDIEN_MP3,
    payload: {
      idComedien,
      page,
      pageSize
    }
  };
};

export function showFicheComedienMP3Action(dataFicheComedienMP3) {
  return {
    type: COMEDIEN_SHOW_FICHE_COMEDIEN_MP3,
    dataFicheComedienMP3
  };
};


///////////////////////////////////////////////////////////////////////
// Get Comedien MP3 pour fiche
///////////////////////////////////////////////////////////////////////

export const getFicheComedienVideosAction = (idComedien, pageSize, page) => {
  
  return {
    type: COMEDIEN_GET_FICHE_COMEDIEN_VIDEOS,
    payload: {
      idComedien,
      pageSize,
      page
    }
  };
};

export function showFicheComedienVideosAction(dataFicheComedienVideos) {
  return {
    type: COMEDIEN_SHOW_FICHE_COMEDIEN_VIDEOS,
    dataFicheComedienVideos
  };
};






