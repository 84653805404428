import React, { Component } from "react";
import { Layout, Spin } from "antd";
import Topbar from "../Topbar/index";
import FooterContent from "../Footer/FooterContent";
import { connect } from "react-redux";
import SearchFormMobile from "components/SearchFormMobile"
import AlertMessage from "components/AlertMessage";

import {
  getSlidesPhotosXLAction
} from "appRedux/actions/Common";

export class FullWidthApp extends Component {

  constructor() {
    super();
    this.state = {
    }
  }

  componentDidMount() {
    //this.props.getSlidesPhotosXLAction();
  }

  getNavStyles = () => {
    switch (window.location.pathname) {
      default:
        return <Topbar page={this.props.page} />;
    }

  };

  render() {
    const { componentToRender } = this.props;

    return (
      <div>
        {this.getNavStyles()}

        <SearchFormMobile />

        {componentToRender}

        <AlertMessage />
      </div>
    )
  }
}

const mapStateToProps = ({ }) => {

};
export default connect(
  mapStateToProps,
  {
    getSlidesPhotosXLAction
  }
)(FullWidthApp);

