import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  MESSAGE_SUCCESS,
  MESSAGE_FAIL,
  NOTIFICATION_SUCCESS,
  NOTIFICATION_FAIL,
  COMEDIEN_GET_IDENTITE,
  COMEDIEN_UPDATE_IDENTITE,
  COMEDIEN_GET_ACTIVITE_FACTURATION,
  COMEDIEN_UPDATE_ACTIVITE_FACTURATION,
  COMEDIEN_UPDATE_CV,
  COMEDIEN_GET_CV,
  COMEDIEN_ADD_PHOTO,
  COMEDIEN_DELETE_PHOTO,
  COMEDIEN_SORT_PHOTO,
  COMEDIEN_GET_LISTE_PHOTOS,
  COMEDIEN_ADD_DEMANDE_PHOTO_XL,
  COMEDIEN_GET_DEMANDE_PHOTO_XL,
  COMEDIEN_GET_STATS_MP3,
  COMEDIEN_GET_LISTE_MP3,
  COMEDIEN_SORT_LISTE_MP3,
  COMEDIEN_UPDATE_VISIBILITE_MP3,
  COMEDIEN_DELETE_MP3,
  COMEDIEN_GET_EXTRAIT_MP3,
  COMEDIEN_UPDATE_MP3,
  COMEDIEN_ADD_MP3,
  COMEDIEN_GET_LISTE_VIDEOS,
  COMEDIEN_DELETE_VIDEO,
  COMEDIEN_UPDATE_VISIBILITE_VIDEO,
  COMEDIEN_SORT_LISTE_VIDEOS,
  COMEDIEN_GET_EXTRAIT_VIDEO,
  COMEDIEN_ADD_VIDEO,
  COMEDIEN_UPDATE_VIDEO,
  COMEDIEN_ADD_NEWS,
  COMEDIEN_GET_LIST_NEWS,
  COMEDIEN_GET_INFOS_EMPLOYEUR,
  COMEDIEN_UPDATE_INFOS_EMPLOYEUR,
  COMEDIEN_GET_EQUIPEMENT,

  //////////////////////////

  COMEDIEN_GET_CONTACT,
  COMEDIEN_UPDATE_CONTACT,













  COMEDIEN_GET_STATS_VIDEOS,






  COMEDIEN_UPDATE_EQUIPEMENT,
  COMEDIEN_GET_TDB_MON_COMPTE,

  COMEDIEN_GET_ADRESSE_FACTURATION,
  COMEDIEN_UPDATE_ADRESSE_FACTURATION,
  COMEDIEN_GET_FACTURES,
  COMEDIEN_GET_STATS,
  COMEDIEN_GET_TOP_EXTRAITS_STATS,
  COMEDIEN_GET_HISTORIQUE_STATS,

  COMEDIEN_GET_LIST_DEMANDES_ACCES,
  COMEDIEN_SWITCH_ACCES_TELEPHONE,
  COMEDIEN_SWITCH_ACCES_EMPLOYEUR,
  COMEDIEN_DELETE_ACCES_CONFIDENTIALITE,
  COMEDIEN_GET_DEMANDE_CONTACT_DATA,
  COMEDIEN_SEND_DEMANDE_CONTACT,
  COMEDIEN_GET_COMEDIEN_BY_URL,
  COMEDIEN_GET_FICHE_COMEDIEN_MP3,
  COMEDIEN_GET_FICHE_COMEDIEN_VIDEOS,

  COMEDIEN_GET_RE_INSCRIPTION_FORM,
  COMEDIEN_VERIF_PAIEMENT_RE_INSCRIPTION,
  COMEDIEN_PAIEMENT_RE_INSCRIPTION_CHEQUE
} from "constants/ActionTypes";

import {
  MAX_NEWS_PER_YEAR_REACHED,
  MAX_MP3_VSIBLE_RECHERCHE_REACHED,
  STATUS_RE_INSCRIPTION_COMEDIEN_SUCCESS,
  STATUS_RE_INSCRIPTION_COMEDIEN_ERROR,
  STATUS_RE_INSCRIPTION_COMEDIEN_CHEQUE_SUCCESS,
  STATUS_RE_INSCRIPTION_COMEDIEN_CHEQUE_ERROR,
  STATUS_DEMANDE_CONTACT_COMEDIEN_ERROR,
  STATUS_DEMANDE_CONTACT_COMEDIEN_ERROR_MAX_EMAILS_REACHED,
  MAX_EMAILS_DEMANDE_CONTACT_REACHED
} from "constants/General";

import {
  showContactAction,
  showCVAction,
  showEquipementAction,
  showIdentiteAction,
  showActiviteActionFacturation,
  showListNewsAction,
  showInfosEmployeurAction,
  showAdresseFacturationAction,
  showListeMP3Action,
  showExtraitMP3Action,
  showListePhotosAction,
  showDemandePhotoXLAction,
  showStatsMP3Action,
  showStatsVideosAction,
  showListeVideosAction,
  showExtraitVideoAction,
  showTDBMonCompteAction,
  showFacturesAction,
  showStatsAction,
  showTopExtraitsStatsAction,
  showHistoriqueStatsComedienAction,
  showListDemandesAccesAction,

  showDemandeContactDataAction,
  sendDemandeContactComedienSuccessAction,
  sendDemandeContactComedienErrorAction,
  showComedienByUrlAction,
  showFicheComedienMP3Action,
  showFicheComedienVideosAction,
  showReInscriptionFormAction,
  setStatusPaiementReInscription,
  setStatusPaiementReInscriptionCheque,
} from "appRedux/actions/Comedien";

import {
  showAlertAction,
  hideRequestLoaderAction,
  showNotificationAction,
  setRefreshListAction,
  setFormResetAction
} from "appRedux/actions/Common";

import {
  getIdentiteRequest,
  updateIdentiteRequest,
  getContactRequest,
  UpdateProfilContactRequest,
  getActiviteFacturationRequest,
  updateActiviteFacturationRequest,
  getAdresseFacturationRequest,
  updateAdresseFacturationRequest,
  getTDBMonCompteRequest,
  getDemandeContactDataRequest,
  sendDemandeContactComedienRequest,
  getStatsRequest,
  getComedienByUrlRequest,
  getReInscriptionFormRequest,
  verifPaiementReInscriptionRequest,
  paiementReInscriptionParChequeRequest,
  getHistoriqueStatsComedienRequest
} from 'appRedux/services/Comedien';

import {
  getCVRequest,
  updateCVRequest
} from 'appRedux/services/CV';

import {
  getInfosEmployeurRequest,
  updateInfosEmployeurRequest
} from 'appRedux/services/InfosEmployeur';

import {
  getEquipementRequest,
  updateEquipementRequest
} from 'appRedux/services/Equipement';

import {
  addNewsRequest,
  getListNewsRequest,
} from 'appRedux/services/News';

import {
  addPhotoRequest,
  getListePhotosRequest,
  deletePhotoRequest,
  sortPhotosRequest
} from 'appRedux/services/Photo';

import {
  addDemandePhotoXLRequest,
  getDemandePhotoXLRequest,
} from 'appRedux/services/DemandePhotoXL';

import {
  getListDemandesAccesRequest,
  switchAccesTelephoneRequest,
  switchAccesEmployeurRequest,
  deleteAccesConfidentialiteRequest,
} from 'appRedux/services/AccesConfidentialite';

import {
  updateVisibiliteMP3Request,
  getStatsMP3Request,
  getListeMP3Request,
  sortExtraitsMP3Request,
  deleteExtraitMP3Request,
  getExtraitMP3Request,
  updateMP3Request,
  addMP3Request,
  getTopExtraitsStatsRequest,
  getFicheComedienMP3Request
} from 'appRedux/services/Mp3';

import {
  updateVisibiliteVideoRequest,
  getStatsVideosRequest,
  getListeVideosRequest,
  deleteExtraitVideoRequest,
  sortExtraitsVideoRequest,
  addVideoRequest,
  getExtraitVideoRequest,
  updateVideoRequest,
  getFicheComedienVideosRequest
} from 'appRedux/services/Video';


import {
  getFacturesRequest,
} from 'appRedux/services/Abonnement';


///////////////////////////////////////////////////////////////////////
// Mon Profil
///////////////////////////////////////////////////////////////////////

//Récupération de Identité

function* getIdentite({ payload }) {
  try {
    const response = yield call(getIdentiteRequest, payload);
    if (response.status && response.status === 200 && response.data) {
      yield put(showIdentiteAction(response.data));
    }
  } catch (error) {
  }
}

export function* getIdentiteFork() {
  yield takeEvery(COMEDIEN_GET_IDENTITE, getIdentite);
}

//Modification de Identité

function* updateIdentite({ payload }) {
  try {
    const response = yield call(updateIdentiteRequest, payload);
    if (response.status && response.status === 200) {
      yield put(showAlertAction("comedien.monProfil.identite.update.succes", MESSAGE_SUCCESS));
    } else {
      yield put(showAlertAction("comedien.monProfil.identite.update.error", MESSAGE_FAIL));
    }
  } catch (error) {
    yield put(showAlertAction("comedien.monProfil.identite.update.error", MESSAGE_FAIL));
  }
}

export function* updateIdentiteFork() {
  yield takeEvery(COMEDIEN_UPDATE_IDENTITE, updateIdentite);
}

//Activité et facturation

function* getActiviteFacturation({ payload }) {
  try {
    const response = yield call(getActiviteFacturationRequest, payload);
    if (response.status && response.status === 200 && response.data) {
      yield put(showActiviteActionFacturation(response.data));
    }
  } catch (error) {
  }
}

export function* getActiviteFacturationFork() {
  yield takeEvery(COMEDIEN_GET_ACTIVITE_FACTURATION, getActiviteFacturation);
}

function* updateActiviteFacturation({ payload }) {
  try {
    const response = yield call(updateActiviteFacturationRequest, payload);
    if (response.status && response.status === 200) {
      yield put(showAlertAction("comedien.monProfil.activite.majSucces", MESSAGE_SUCCESS));
    } else {
      yield put(showAlertAction("comedien.monProfil.activite.majErreur", MESSAGE_FAIL));
    }
  } catch (error) {
    yield put(showAlertAction("comedien.monProfil.activite.majErreur", MESSAGE_FAIL));
  }
}

export function* updateActiviteFacturationFork() {
  yield takeEvery(COMEDIEN_UPDATE_ACTIVITE_FACTURATION, updateActiviteFacturation);
}

//Contact

function* getContact() {
  try {
    const profile = yield call(getContactRequest);
    if (profile.status && profile.status === 200 && profile.data) {
      yield put(showContactAction(profile.data));
    }
  } catch (error) {
  }
}

export function* getContactFork() {
  yield takeEvery(COMEDIEN_GET_CONTACT, getContact);
}

function* UpdateProfilContact({ payload }) {
  try {
    const contact = yield call(UpdateProfilContactRequest, payload);
    if (contact.status && contact.status === 200) {
      yield put(showAlertAction("comedien.monProfil.contact.majSucces", MESSAGE_SUCCESS));
    }
  } catch (error) {
  }
}

export function* UpdateProfilContactFork() {
  yield takeEvery(COMEDIEN_UPDATE_CONTACT, UpdateProfilContact);
}

///////////////////////////////////////////////////////////////////////
// Mon CV
////////////////////////////////////////////////////////////////////////

//Récupération du CV

function* getCV({ payload }) {
  try {
    const response = yield call(getCVRequest, payload);
    if (response.status && response.status === 200 && response.data) {
      yield put(showCVAction(response.data));
    }
  } catch (error) {
    yield put(hideRequestLoaderAction());
  }
}

export function* getCVFork() {
  yield takeEvery(COMEDIEN_GET_CV, getCV);
}

//Modification du CV

function* updateCV({ payload }) {
  try {
    const response = yield call(updateCVRequest, payload);
    if (response.status && response.status === 200) {
      yield put(showAlertAction("comedien.monCV.majSucces", MESSAGE_SUCCESS));
    } else {
      yield put(showAlertAction("comedien.monCV.majErreur", MESSAGE_FAIL));
    }
  } catch (error) {
    yield put(showAlertAction("comedien.monCV.majErreur", MESSAGE_FAIL));
  }
}

export function* updateCVFork() {
  yield takeEvery(COMEDIEN_UPDATE_CV, updateCV);
}

///////////////////////////////////////////////////////////////////////
// Mes photos
///////////////////////////////////////////////////////////////////////

//Ajout de photo
function* addPhoto({ payload }) {
  try {
    const response = yield call(addPhotoRequest, payload);
    if (response.status && response.status === 200) {
      yield put(showAlertAction("comedien.mesPhotos.ajoutSucces", MESSAGE_SUCCESS));
      yield put(setRefreshListAction());
    } else {
      yield put(showAlertAction("comedien.mesPhotos.ajoutErreur", MESSAGE_FAIL));
    }
  } catch (error) {
    yield put(showAlertAction("comedien.mesPhotos.ajoutErreur", MESSAGE_FAIL));
  }
}

export function* addPhotoFork() {
  yield takeEvery(COMEDIEN_ADD_PHOTO, addPhoto);
}

//Liste des photos
function* getListePhotos() {
  try {
    const response = yield call(getListePhotosRequest);
    if (response.status && response.status === 200 && response.data) {
      yield put(showListePhotosAction(response.data));
    }
  } catch (error) {
  }
}

export function* getListePhotosFork() {
  yield takeEvery(COMEDIEN_GET_LISTE_PHOTOS, getListePhotos);
}

//Suppression d'une photo
function* deletePhoto({ payload }) {
  try {
    const response = yield call(deletePhotoRequest, payload);
    if (response.status && response.status === 200) {
      yield put(showAlertAction("comedien.mesPhotos.suppression.success", MESSAGE_SUCCESS));
      yield put(setRefreshListAction());
    } else {
      yield put(showAlertAction("comedien.mesPhotos.suppression.erreur", MESSAGE_FAIL));
    }
  } catch (error) {
    yield put(showAlertAction("comedien.mesPhotos.suppression.erreur", MESSAGE_FAIL));
  }
}

export function* deletePhotoFork() {
  yield takeEvery(COMEDIEN_DELETE_PHOTO, deletePhoto);
}

//Tri de photos
function* sortPhotos({ payload }) {
  try {
    const response = yield call(sortPhotosRequest, payload);
    if (response.status && response.status === 200) {
      yield put(showAlertAction("comedien.mesPhotos.tri.success", MESSAGE_SUCCESS));
      yield put(setRefreshListAction());
    } else {
      yield put(showAlertAction("comedien.mesPhotos.tri.erreur", MESSAGE_FAIL));
    }
  } catch (error) {
    yield put(showAlertAction("comedien.mesPhotos.tri.erreur", MESSAGE_FAIL));
  }
}

export function* sortPhotosFork() {
  yield takeEvery(COMEDIEN_SORT_PHOTO, sortPhotos);
}

//Ajout d'une demande de photo XL

function* addDemandePhotoXL({ payload }) {
  try {
    const response = yield call(addDemandePhotoXLRequest, payload);
    if (response.status && response.status === 200) {
      yield put(showAlertAction("comedien.mesPhotos.photoXL.ajoutSucces", MESSAGE_SUCCESS));
    } else {
      yield put(showAlertAction("comedien.mesPhotos.photoXL.ajoutErreur", MESSAGE_FAIL));
    }
  } catch (error) {
    yield put(showAlertAction("comedien.mesPhotos.photoXL.ajoutErreur", MESSAGE_FAIL));
  }
}

export function* addDemandePhotoXLFork() {
  yield takeEvery(COMEDIEN_ADD_DEMANDE_PHOTO_XL, addDemandePhotoXL);
}

//Recupértion de la demande de photos XL
function* getDemandePhotoXL() {
  try {
    const response = yield call(getDemandePhotoXLRequest);
    if (response.status && response.status === 200 && response.data) {
      yield put(showDemandePhotoXLAction(response.data));
    }
  } catch (error) {
  }
}

export function* getDemandePhotoXLFork() {
  yield takeEvery(COMEDIEN_GET_DEMANDE_PHOTO_XL, getDemandePhotoXL);
}

///////////////////////////////////////////////////////////////////////
// Mes extraits MP3
///////////////////////////////////////////////////////////////////////

//Statistiques

function* getStatsMP3() {
  try {
    const response = yield call(getStatsMP3Request);
    if (response.status && response.status === 200 && response.data) {
      yield put(showStatsMP3Action(response.data));
    }
  } catch (error) {

  }
}

export function* getStatsMP3Fork() {
  yield takeEvery(COMEDIEN_GET_STATS_MP3, getStatsMP3);
}

//Liste des extraits

function* getListeMP3({ payload }) {
  try {
    const response = yield call(getListeMP3Request, payload);
    if (response.status && response.status === 200 && response.data) {
      yield put(showListeMP3Action(response.data));
    }
  } catch (error) {

  }
}

export function* getListeMP3Fork() {
  yield takeEvery(COMEDIEN_GET_LISTE_MP3, getListeMP3);
}

//Tri des extraits

function* sortListeMP3({ payload }) {
  try {
    const response = yield call(sortExtraitsMP3Request, payload);
    if (response.status && response.status === 200 && response.data) {
      yield put(showAlertAction("comedien.mesExtraitsMP3.tri.success", MESSAGE_SUCCESS));
    } else {
      yield put(showAlertAction("comedien.mesExtraitsMP3.tri.error", MESSAGE_FAIL));
    }
  } catch (error) {
    yield put(showAlertAction("comedien.mesExtraitsMP3.tri.error", MESSAGE_FAIL));
  }
}

export function* sortListeMP3Fork() {
  yield takeEvery(COMEDIEN_SORT_LISTE_MP3, sortListeMP3);
}

//Suppression d'un extrait

function* deleteMP3({ payload }) {
  try {
    const response = yield call(deleteExtraitMP3Request, payload);
    if (response.status && response.status === 200 && response.data) {
      yield put(setRefreshListAction());
      yield put(showAlertAction("comedien.mesExtraitsMP3.suppression.success", MESSAGE_SUCCESS));
    } else {
      yield put(showAlertAction("comedien.mesExtraitsMP3.suppression.error", MESSAGE_FAIL));
    }
  } catch (error) {
    yield put(showAlertAction("comedien.mesExtraitsMP3.suppression.error", MESSAGE_FAIL));
  }
}

export function* deleteMP3Fork() {
  yield takeEvery(COMEDIEN_DELETE_MP3, deleteMP3);
}

//Extrait

function* getExtraitMP3({ payload }) {
  try {
    const response = yield call(getExtraitMP3Request, payload);
    if (response.status && response.status === 200 && response.data) {
      yield put(showExtraitMP3Action(response.data));
    }
  } catch (error) {

  }
}

export function* getExtraitMP3Fork() {
  yield takeEvery(COMEDIEN_GET_EXTRAIT_MP3, getExtraitMP3);
}

//Visibilité d'un extrait

function* updateVisibiliteMP3({ payload }) {
  try {
    var response = yield call(updateVisibiliteMP3Request, payload);
    if (response.status === 200 && response.data) {

      if (response.data === MAX_MP3_VSIBLE_RECHERCHE_REACHED) {
        yield put(showAlertAction("comedien.mesExtraitsMP3.modifVisibilite.maxAutorise", MESSAGE_FAIL));
        yield put(setRefreshListAction());
      } else {
        yield put(showAlertAction("comedien.mesExtraitsMP3.modifVisibilite.success", MESSAGE_SUCCESS));
      }

      var response = yield call(getStatsMP3Request);
      if (response.status && response.status === 200 && response.data) {
        yield put(showStatsMP3Action(response.data));
      }

    } else {
      yield put(showAlertAction("comedien.mesExtraitsMP3.modifVisibilite.error", MESSAGE_FAIL));
    }
  } catch (error) {
    yield put(showAlertAction("comedien.mesExtraitsMP3.modifVisibilite.error", MESSAGE_FAIL));
  }
}

export function* updateVisibiliteMP3Fork() {
  yield takeEvery(COMEDIEN_UPDATE_VISIBILITE_MP3, updateVisibiliteMP3);
}

//Ajout d'un extrait

function* addMP3({ payload }) {
  try {
    var response = yield call(addMP3Request, payload);
    if (response.status && response.status === 200) {
      yield put(showAlertAction("comedien.mesExtraitsMP3.ajoutSucces", MESSAGE_SUCCESS));
      yield put(setFormResetAction());
      yield put(setRefreshListAction());
    } else {
      yield put(showAlertAction("comedien.mesExtraitsMP3.ajoutErreur", MESSAGE_FAIL));
    }
  } catch (error) {
    yield put(showAlertAction("comedien.mesExtraitsMP3.ajoutErreur", MESSAGE_FAIL));
  }
}

export function* addMP3Fork() {
  yield takeEvery(COMEDIEN_ADD_MP3, addMP3);
}

//Modification d'un extrait

function* updateMP3({ payload }) {
  try {
    const response = yield call(updateMP3Request, payload);
    if (response.status && response.status === 200) {
      yield put(showAlertAction("comedien.mesExtraitsMP3.modifSucces", MESSAGE_SUCCESS));
      yield put(setRefreshListAction());
    } else {
      yield put(showAlertAction("comedien.mesExtraitsMP3.modifErreur", MESSAGE_FAIL));
    }
  } catch (error) {
    yield put(showAlertAction("comedien.mesExtraitsMP3.modifErreur", MESSAGE_FAIL));
  }
}

export function* updateMP3Fork() {
  yield takeEvery(COMEDIEN_UPDATE_MP3, updateMP3);
}

///////////////////////////////////////////////////////////////////////
// Mes Videos
///////////////////////////////////////////////////////////////////////

//Statistiques

function* getStatsVideos() {
  try {
    const video2 = yield call(getStatsVideosRequest);
    if (video2.status && video2.status === 200 && video2.data) {
      yield put(showStatsVideosAction(video2.data));
    }
  } catch (error) {

  }
}

export function* getStatsVideosFork() {
  yield takeEvery(COMEDIEN_GET_STATS_VIDEOS, getStatsVideos);
}

//Liste des extraits

function* getListeVideos({ payload }) {
  try {
    const videos = yield call(getListeVideosRequest, payload);
    if (videos.status && videos.status === 200 && videos.data) {
      yield put(showListeVideosAction(videos.data));
    }
  } catch (error) {

  }
}

export function* getListeVideosFork() {
  yield takeEvery(COMEDIEN_GET_LISTE_VIDEOS, getListeVideos);
}

//Visibilité d'un extrait

function* updateVisibiliteVideo({ payload }) {
  try {
    var response = yield call(updateVisibiliteVideoRequest, payload);
    if (response.status && response.status === 200 && response.data) {
      yield put(showAlertAction("comedien.mesVideos.modifVisibilite.success", MESSAGE_SUCCESS));

      var response = yield call(getStatsVideosRequest);
      if (response.status && response.status === 200 && response.data) {
        yield put(showStatsVideosAction(response.data));
      }

    } else {
      yield put(showAlertAction("comedien.mesVideos.modifVisibilite.error", MESSAGE_FAIL));
    }

  } catch (error) {
    yield put(showAlertAction("comedien.mesVideos.modifVisibilite.error", MESSAGE_FAIL));
  }
}

export function* updateVisibiliteVideoFork() {
  yield takeEvery(COMEDIEN_UPDATE_VISIBILITE_VIDEO, updateVisibiliteVideo);
}

//Ajout d'un extrait

function* addVideo({ payload }) {
  try {
    const response = yield call(addVideoRequest, payload);
    if (response.status && response.status === 200) {
      yield put(showAlertAction("comedien.mesVideos.ajoutSucces", MESSAGE_SUCCESS));
      yield put(setFormResetAction());
      yield put(setRefreshListAction());
    } else {
      yield put(showAlertAction("comedien.mesVideos.ajoutErreur", MESSAGE_FAIL));
    }
  } catch (error) {
    yield put(showAlertAction("comedien.mesVideos.ajoutErreur", MESSAGE_FAIL));
  }
}

export function* addVideoFork() {
  yield takeEvery(COMEDIEN_ADD_VIDEO, addVideo);
}

//Modification d'un extrait

function* updateVideo({ payload }) {
  try {
    const response = yield call(updateVideoRequest, payload);
    if (response.status && response.status === 200) {
      yield put(showAlertAction("comedien.mesVideos.modifSucces", MESSAGE_SUCCESS));
      yield put(setRefreshListAction());
    } else {
      yield put(showAlertAction("comedien.mesVideos.modifErreur", MESSAGE_FAIL));
    }
  } catch (error) {
    yield put(showAlertAction("comedien.mesVideos.modifErreur", MESSAGE_FAIL));
  }
}

export function* updateVideoFork() {
  yield takeEvery(COMEDIEN_UPDATE_VIDEO, updateVideo);
}

function* deleteVideo({ payload }) {
  try {
    const response = yield call(deleteExtraitVideoRequest, payload);
    if (response.status && response.status === 200 && response.data) {
      yield put(setRefreshListAction());
      yield put(showAlertAction("comedien.mesVideos.suppression.success", MESSAGE_SUCCESS));
    } else {
      yield put(showAlertAction("comedien.mesVideos.suppression.error", MESSAGE_FAIL));
    }
  } catch (error) {
    yield put(showAlertAction("comedien.mesVideos.suppression.error", MESSAGE_FAIL));
  }
}

export function* deleteVideoFork() {
  yield takeEvery(COMEDIEN_DELETE_VIDEO, deleteVideo);
}

//Tri des extraits

function* sortListeVideos({ payload }) {
  try {
    const response = yield call(sortExtraitsVideoRequest, payload);
    if (response.status && response.status === 200 && response.data) {
      yield put(showAlertAction("comedien.mesVideos.tri.success", MESSAGE_SUCCESS));
    } else {
      yield put(showAlertAction("comedien.mesVideos.tri.error", MESSAGE_FAIL));
    }
  } catch (error) {
    yield put(showAlertAction("comedien.mesVideos.tri.error", MESSAGE_FAIL));
  }
}

export function* sortListeVideosFork() {
  yield takeEvery(COMEDIEN_SORT_LISTE_VIDEOS, sortListeVideos);
}

//Extrait

function* getExtraitVideo({ payload }) {
  try {
    const response = yield call(getExtraitVideoRequest, payload);
    if (response.status && response.status === 200 && response.data) {
      yield put(showExtraitVideoAction(response.data));
    }
  } catch (error) {

  }
}

export function* getExtraitVideoFork() {
  yield takeEvery(COMEDIEN_GET_EXTRAIT_VIDEO, getExtraitVideo);
}

///////////////////////////////////////////////////////////////////////
// Mes News
///////////////////////////////////////////////////////////////////////

//Ajout d'une News

function* addNews({ payload }) {
  try {
    const response = yield call(addNewsRequest, payload);
    if (response.status && response.status === 200) {

      if (response.data === MAX_NEWS_PER_YEAR_REACHED) {
        yield put(showAlertAction("comedien.mesNews.maxAutorise", MESSAGE_FAIL));
      } else {
        yield put(showAlertAction("comedien.mesNews.majSucces", MESSAGE_SUCCESS));
        yield put(setRefreshListAction());
      }

    } else {
      yield put(showAlertAction("comedien.mesNews.majErreur", MESSAGE_FAIL));
    }
  } catch (error) {
    yield put(showAlertAction("comedien.mesNews.majErreur", MESSAGE_FAIL));
  }
}

export function* addNewsFork() {
  yield takeEvery(COMEDIEN_ADD_NEWS, addNews);
}

//Liste des News

function* getListNews({ payload }) {
  try {
    const response = yield call(getListNewsRequest, payload);
    if (response.status && response.status === 200 && response.data) {
      yield put(showListNewsAction(response.data));
    }
  } catch (error) {
  }
}

export function* getListNewsFork() {
  yield takeEvery(COMEDIEN_GET_LIST_NEWS, getListNews);
}

///////////////////////////////////////////////////////////////////////
// Mes Infos Employeur
///////////////////////////////////////////////////////////////////////

function* getInfosEmployeur({ payload }) {
  try {
    const response = yield call(getInfosEmployeurRequest, payload);
    if (response.status && response.status === 200 && response.data) {
      yield put(showInfosEmployeurAction(response.data));
    }
  } catch (error) {
  }
}

export function* getInfosEmployeurFork() {
  yield takeEvery(COMEDIEN_GET_INFOS_EMPLOYEUR, getInfosEmployeur);
}

function* updateInfosEmployeur({ payload }) {
  try {
    const response = yield call(updateInfosEmployeurRequest, payload);
    if (response.status && response.status === 200) {
      yield put(showAlertAction("comedien.infosEmployeur.majSucces", MESSAGE_SUCCESS));
    } else {
      yield put(showAlertAction("comedien.infosEmployeur.majErreur", MESSAGE_FAIL));
    }
  } catch (error) {
    yield put(showAlertAction("comedien.infosEmployeur.majErreur", MESSAGE_FAIL));
  }
}

export function* updateInfosEmployeurFork() {
  yield takeEvery(COMEDIEN_UPDATE_INFOS_EMPLOYEUR, updateInfosEmployeur);
}

///////////////////////////////////////////////////////////////////////
// Mon Equipement
///////////////////////////////////////////////////////////////////////

function* getEquipement({ payload }) {
  try {
    const response = yield call(getEquipementRequest, payload);
    if (response.status && response.status === 200 && response.data) {
      yield put(showEquipementAction(response.data));
    }
  } catch (error) {
  }
}

export function* getEquipementFork() {
  yield takeEvery(COMEDIEN_GET_EQUIPEMENT, getEquipement);
}

function* updateEquipement({ payload }) {
  try {
    const response = yield call(updateEquipementRequest, payload);
    if (response.status && response.status === 200) {
      yield put(showAlertAction("comedien.equipement.majSucces", MESSAGE_SUCCESS));
    } else {
      yield put(showAlertAction("comedien.equipement.majErreur", MESSAGE_FAIL));
    }
  } catch (error) {
    yield put(showAlertAction("comedien.equipement.majErreur", MESSAGE_FAIL));
  }
}

export function* updateEquipementFork() {
  yield takeEvery(COMEDIEN_UPDATE_EQUIPEMENT, updateEquipement);
}

///////////////////////////////////////////////////////////////////////
// Mon Compte
///////////////////////////////////////////////////////////////////////

//Tableau de bord

function* getTDBMonCompte() {
  try {
    const response = yield call(getTDBMonCompteRequest);
    if (response.status && response.status === 200 && response.data) {
      yield put(showTDBMonCompteAction(response.data));
    }
  } catch (error) {
  }
}

export function* getTDBMonCompteFork() {
  yield takeEvery(COMEDIEN_GET_TDB_MON_COMPTE, getTDBMonCompte);
}

//Adresse de facturation

function* getAdresseFacturation({ payload }) {
  try {
    const response = yield call(getAdresseFacturationRequest, payload);
    if (response.status && response.status === 200 && response.data) {
      yield put(showAdresseFacturationAction(response.data));
    }
  } catch (error) {
  }
}

export function* getAdresseFacturationFork() {
  yield takeEvery(COMEDIEN_GET_ADRESSE_FACTURATION, getAdresseFacturation);
}

function* updateAdresseFacturation({ payload }) {
  var { idUser, values } = payload;

  try {
    const response = yield call(updateAdresseFacturationRequest, idUser, values);
    if (response.status && response.status === 200) {
      yield put(showAlertAction("comedien.monCompte.adresseFacturation.majSucces", MESSAGE_SUCCESS));
    } else {
      yield put(showAlertAction("comedien.monCompte.adresseFacturation.majErreur", MESSAGE_FAIL));
    }
  } catch (error) {
    yield put(showAlertAction("comedien.monCompte.adresseFacturation.majErreur", MESSAGE_FAIL));
  }
}

export function* updateAdresseFacturationFork() {
  yield takeEvery(COMEDIEN_UPDATE_ADRESSE_FACTURATION, updateAdresseFacturation);
}

//Factures

function* getFactures() {
  try {
    const response = yield call(getFacturesRequest);
    if (response.status && response.status === 200 && response.data) {
      yield put(showFacturesAction(response.data));
    }
  } catch (error) {
  }
}

export function* getFacturesFork() {
  yield takeEvery(COMEDIEN_GET_FACTURES, getFactures);
}


///////////////////////////////////////////////////////////////////////
// Mes stats
///////////////////////////////////////////////////////////////////////

//Stats

function* getStats({ payload }) {
  try {
    const response = yield call(getStatsRequest, payload);
    if (response.status && response.status === 200 && response.data) {
      yield put(showStatsAction(response.data));
    }
  } catch (error) {
  }
}

export function* getStatsFork() {
  yield takeEvery(COMEDIEN_GET_STATS, getStats);
}

//Historique

function* getHistoriqueStatsComedien({ payload }) {
  try {
    const response = yield call(getHistoriqueStatsComedienRequest, payload);
    if (response.status && response.status === 200 && response.data) {
      yield put(showHistoriqueStatsComedienAction(response.data));
    }
  } catch (error) {
  }
}

export function* getHistoriqueStatsComedienFork() {
  yield takeEvery(COMEDIEN_GET_HISTORIQUE_STATS, getHistoriqueStatsComedien);
}

//Top messages

function* getTopExtraitsStats({ payload }) {
  try {
    const response = yield call(getTopExtraitsStatsRequest, payload);
    if (response.status && response.status === 200 && response.data) {
      yield put(showTopExtraitsStatsAction(response.data));
    }
  } catch (error) {
  }
}

export function* getTopExtraitsStatsFork() {
  yield takeEvery(COMEDIEN_GET_TOP_EXTRAITS_STATS, getTopExtraitsStats);
}

///////////////////////////////////////////////////////////////////////
// Accés Confidentialité
/////////////////////////////////////////////////////////////////////

function* getListDemandesAcces() {
  try {
    const response = yield call(getListDemandesAccesRequest);
    if (response.status && response.status === 200 && response.data) {
      yield put(showListDemandesAccesAction(response.data));
    }
  } catch (error) {

  }
}

export function* getListDemandesAccesFork() {
  yield takeEvery(COMEDIEN_GET_LIST_DEMANDES_ACCES, getListDemandesAcces);
}

function* switchAccesTelephone({ payload }) {
  try {
    const response = yield call(switchAccesTelephoneRequest, payload);
    if (response.status && response.status === 200) {
      yield put(showAlertAction("comedien.AccesConfidentialite.switchAccesTelephone.success", MESSAGE_SUCCESS));
      yield put(setRefreshListAction());
    } else {
      yield put(showAlertAction("comedien.AccesConfidentialite.switchAccesTelephone.error", MESSAGE_FAIL));
    }
  } catch (error) {

  }
}

export function* switchAccesTelephoneFork() {
  yield takeEvery(COMEDIEN_SWITCH_ACCES_TELEPHONE, switchAccesTelephone);
}

function* switchAccesEmployeur({ payload }) {
  try {
    const response = yield call(switchAccesEmployeurRequest, payload);
    if (response.status && response.status === 200) {
      yield put(showAlertAction("comedien.AccesConfidentialite.switchAccesEmployeur.success", MESSAGE_SUCCESS));
      yield put(setRefreshListAction());
    } else {
      yield put(showAlertAction("comedien.AccesConfidentialite.switchAccesEmployeur.error", MESSAGE_FAIL));
    }
  } catch (error) {

  }
}

export function* switchAccesEmployeurFork() {
  yield takeEvery(COMEDIEN_SWITCH_ACCES_EMPLOYEUR, switchAccesEmployeur);
}

function* deleteAccesConfidentialite({ payload }) {
  try {
    const response = yield call(deleteAccesConfidentialiteRequest, payload);
    if (response.status && response.status === 200) {
      yield put(showAlertAction("comedien.AccesConfidentialite.deleteAccesConfidentialite.success", MESSAGE_SUCCESS));
      yield put(setRefreshListAction());
    } else {
      yield put(showAlertAction("comedien.AccesConfidentialite.deleteAccesConfidentialite.error", MESSAGE_FAIL));
    }
  } catch (error) {
  }
}

export function* deleteAccesConfidentialiteFork() {
  yield takeEvery(COMEDIEN_DELETE_ACCES_CONFIDENTIALITE, deleteAccesConfidentialite);
}

///////////////////////////////////////////////////////////////////////
// Historique de partage
///////////////////////////////////////////////////////////////////////

function* getDemandeContactData(payload) {
  try {
    const { idComedien } = payload;

    const demandeContactData = yield call(getDemandeContactDataRequest, idComedien)
    if (demandeContactData.status && demandeContactData.status === 200 && demandeContactData.data) {
      yield put(showDemandeContactDataAction(demandeContactData.data))
    }
  }
  catch (error) {

  }
}
export function* getDemandeContactDataFork() {
  yield takeEvery(COMEDIEN_GET_DEMANDE_CONTACT_DATA, getDemandeContactData);

}
//Envoi de la demande de contact

function* sendDemandeContactComedien({ payload }) {
  try {
    const response = yield call(sendDemandeContactComedienRequest, payload);
    if (response.status && response.status === 200) {
      if (response.data === MAX_EMAILS_DEMANDE_CONTACT_REACHED) {
        yield put(sendDemandeContactComedienErrorAction(STATUS_DEMANDE_CONTACT_COMEDIEN_ERROR_MAX_EMAILS_REACHED));
      } else {
        yield put(sendDemandeContactComedienSuccessAction());
      }
    } else {
      yield put(sendDemandeContactComedienErrorAction(STATUS_DEMANDE_CONTACT_COMEDIEN_ERROR));
    }
  } catch (error) {
    yield put(sendDemandeContactComedienErrorAction(STATUS_DEMANDE_CONTACT_COMEDIEN_ERROR));
  }
}

export function* sendDemandeContactComedienFork() {
  yield takeEvery(COMEDIEN_SEND_DEMANDE_CONTACT, sendDemandeContactComedien);
}

///////////////////////////////////////////////////////////////////////
// Fiche Comedien avec URL
///////////////////////////////////////////////////////////////////////

function* getComedienByUrl({ payload }) {
  try {
    const response = yield call(getComedienByUrlRequest, payload);
    if (response.status && response.status === 200 && response.data) {
      yield put(showComedienByUrlAction(response.data));
    }
  } catch (error) {
  }
}

export function* getComedienByUrlFork() {
  yield takeEvery(COMEDIEN_GET_COMEDIEN_BY_URL, getComedienByUrl);
}



function* getFicheComedienMP3({ payload }) {
  try {
    const response = yield call(getFicheComedienMP3Request, payload);
    if (response.status && response.status === 200 && response.data) {
      yield put(showFicheComedienMP3Action(response.data));
    }
  } catch (error) {
  }
}

export function* getFicheComedienMP3Fork() {
  yield takeEvery(COMEDIEN_GET_FICHE_COMEDIEN_MP3, getFicheComedienMP3);
}


function* getFicheComedienVideos({ payload }) {
  try {
    const response = yield call(getFicheComedienVideosRequest, payload);
    if (response.status && response.status === 200 && response.data) {
      yield put(showFicheComedienVideosAction(response.data));
    }
  } catch (error) {
  }
}

export function* getFicheComedienVideosFork() {
  yield takeEvery(COMEDIEN_GET_FICHE_COMEDIEN_VIDEOS, getFicheComedienVideos);
}


///////////////////////////////////////////////////////////////////////
// Ré-inscription
///////////////////////////////////////////////////////////////////////

//Form

function* getReInscriptionForm({ payload }) {
  try {
    const response = yield call(getReInscriptionFormRequest, payload);
    if (response.status && response.status === 200 && response.data) {
      yield put(showReInscriptionFormAction(response.data));
    }
  } catch (error) {

  }
}

export function* getReInscriptionFormFork() {
  yield takeEvery(COMEDIEN_GET_RE_INSCRIPTION_FORM, getReInscriptionForm);
}

//Paiement

function* verifPaiementReInscription({ payload }) {
  try {
    const response = yield call(verifPaiementReInscriptionRequest, payload);

    if (response.status && response.status === 200) {
      yield put(setStatusPaiementReInscription(STATUS_RE_INSCRIPTION_COMEDIEN_SUCCESS));
    } else {
      yield put(setStatusPaiementReInscription(STATUS_RE_INSCRIPTION_COMEDIEN_ERROR));
    }
  } catch (error) {
    yield put(setStatusPaiementReInscription(STATUS_RE_INSCRIPTION_COMEDIEN_ERROR));
  }
}

export function* verifPaiementReInscriptionFork() {
  yield takeEvery(COMEDIEN_VERIF_PAIEMENT_RE_INSCRIPTION, verifPaiementReInscription);
}

//Paiement par chèque

function* paiementReInscriptionParCheque({ payload }) {
  try {
    const response = yield call(paiementReInscriptionParChequeRequest, payload);
    if (response.status && response.status === 200) {
      yield put(setStatusPaiementReInscriptionCheque(STATUS_RE_INSCRIPTION_COMEDIEN_CHEQUE_SUCCESS));
    } else {
      yield put(setStatusPaiementReInscriptionCheque(STATUS_RE_INSCRIPTION_COMEDIEN_CHEQUE_ERROR));
    }
  } catch (error) {
    yield put(setStatusPaiementReInscriptionCheque(STATUS_RE_INSCRIPTION_COMEDIEN_CHEQUE_ERROR));
  }
}

export function* paiementReInscriptionParChequeFork() {
  yield takeEvery(COMEDIEN_PAIEMENT_RE_INSCRIPTION_CHEQUE, paiementReInscriptionParCheque);
}

////////////////////////////////////////////////////////////////////////////
// Exports
////////////////////////////////////////////////////////////////////////////

export default function* rootSaga() {
  yield all([
    fork(updateIdentiteFork),
    fork(getIdentiteFork),
    fork(getActiviteFacturationFork),
    fork(updateActiviteFacturationFork),
    fork(getContactFork),
    fork(UpdateProfilContactFork),
    fork(getCVFork),
    fork(updateCVFork),
    fork(addPhotoFork),
    fork(getListePhotosFork),
    fork(deletePhotoFork),
    fork(sortPhotosFork),
    fork(addDemandePhotoXLFork),
    fork(getDemandePhotoXLFork),
    fork(getStatsMP3Fork),
    fork(getListeMP3Fork),
    fork(sortListeMP3Fork),
    fork(deleteMP3Fork),
    fork(updateVisibiliteMP3Fork),
    fork(deleteVideoFork),
    fork(getListeVideosFork),
    fork(sortListeVideosFork),
    fork(getExtraitVideoFork),
    fork(addNewsFork),
    fork(getListNewsFork),
    fork(getInfosEmployeurFork),
    fork(updateInfosEmployeurFork),
    fork(getEquipementFork),
    fork(updateEquipementFork),




    fork(verifPaiementReInscriptionFork),





    fork(getExtraitMP3Fork),

    fork(addMP3Fork),
    fork(updateMP3Fork),
    fork(getStatsVideosFork),

    fork(updateVisibiliteVideoFork),
    fork(addVideoFork),
    fork(updateVideoFork),




    fork(getTDBMonCompteFork),
    fork(getAdresseFacturationFork),
    fork(updateAdresseFacturationFork),
    fork(getFacturesFork),
    fork(getStatsFork),
    fork(getHistoriqueStatsComedienFork),
    fork(getTopExtraitsStatsFork),
    fork(getListDemandesAccesFork),
    fork(switchAccesTelephoneFork),
    fork(switchAccesEmployeurFork),
    fork(deleteAccesConfidentialiteFork),
    fork(getDemandeContactDataFork),
    fork(sendDemandeContactComedienFork),
    fork(getComedienByUrlFork),
    fork(getFicheComedienMP3Fork),
    fork(getFicheComedienVideosFork),
    fork(getReInscriptionFormFork),
    fork(paiementReInscriptionParChequeFork),
  ]);
}
