import React from "react";
import IntlMessages from "util/IntlMessages";
import { connect } from "react-redux";
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Button, Alert } from "antd";
import { withRouter } from "util/withRouter";
import { injectIntl } from 'react-intl';
import Parser from 'html-react-parser';
import {
  requestResetPasswordAction,
  setStatusRequestResetPasswordAction

} from "appRedux/actions/Auth";

import {
  STATUS_REQUEST_RESET_PASSWORD_INIT,
  STATUS_REQUEST_RESET_PASSWORD_ERROR,
  STATUS_REQUEST_RESET_PASSWORD_SUCCESS
} from "constants/General";

const FormItem = Form.Item;

class ForgetPassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          statusRequestResetPassword: STATUS_REQUEST_RESET_PASSWORD_INIT,
        };
    }

    componentDidUpdate() {
    }

    componentDidUpdate(prevProps) {
      if (prevProps.statusRequestResetPassword !== this.props.statusRequestResetPassword) {
        if(this.props.statusRequestResetPassword === STATUS_REQUEST_RESET_PASSWORD_SUCCESS){
          this.props.onForgotPasswordSuccess();
        }
        this.setState({ statusRequestResetPassword: this.props.statusRequestResetPassword });
      }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.props.requestResetPasswordAction(values);
            }
        });
    };

    render(){
        const { getFieldDecorator } = this.props.form;
        var intl = this.props.intl;

        const formItemLayout = {
            labelCol: {
              xs: { span: 24 },
              sm: { span: 24 },
              md: { span: 24 },
            },
            wrapperCol: {
              xs: { span: 24 },
              sm: { span: 24 },
              md: { span: 24 },
            },
        };

        return(
            <div className="cm-auth-forgot-password">

                <div className="cm-auth-forgot-password-title">
                  <IntlMessages id="forgotPassword.title"/>
                </div>

                <div style={{textAlign: "center",paddingBottom: "20px",paddingTop: "20px"}}>
                  {Parser(intl.formatMessage({id: "forgotPassword.text"}))}
                </div>

                {this.state.statusRequestResetPassword === STATUS_REQUEST_RESET_PASSWORD_ERROR?
                    <div style={{marginBottom:"10px"}}>
                        <Alert message={<IntlMessages id="forgotPassword.error" />} type="error"/>
                    </div>
                :null}

                <div className="cm-auth-form">
                    <Form onSubmit={this.handleSubmit} id="forgotPassword" name="forgotPassword">
                        <FormItem {...formItemLayout}>
                            {getFieldDecorator('email', {
                                initialValue: "",
                                rules: [{required: true, type: 'email', message: <IntlMessages id="input.validation.email" />,}],
                                })(
                                    <Input placeholder={intl.formatMessage({id: "forgotPassword.email.placeholder"})} />
                            )}
                        </FormItem>

                        <FormItem {...formItemLayout}>
                            <Button 
                                type="primary" 
                                key="submit" 
                                htmlType="submit"
                                form='forgotPassword'
                                style={{ width: "100%", margin: "0",height:"48px"}} 
                            >
                                <IntlMessages id="forgotPassword.button" />
                            </Button>
                        </FormItem>
                                
                    </Form>
                </div>
            </div>
        )
    }
}

const ForgetPasswordForm = Form.create()(ForgetPassword);

const mapStateToProps = ({ auth }) => {
  const { 
    statusRequestResetPassword 
  } = auth;

  return { 
    statusRequestResetPassword 
  }
};

export default withRouter(
  injectIntl(
    connect(
      mapStateToProps, 
      {
        requestResetPasswordAction,
        setStatusRequestResetPasswordAction
      }
    )(ForgetPasswordForm)
  )
);
