import {
  SIGNIN_SET_STATUS,
  SIGNIN_STATUS_INIT,
  SIGNUP_SET_STATUS,
  SIGNUP_SUCCESS,
  SIGNUP_STATUS_INIT,
  AUTH_SET_STATUS_REQUEST_RESET_PASSWORD,
  AUTH_SET_STATUS_RESET_PASSWORD,
  COMEDIEN_GET_AUTH,
  COMEDIEN_SHOW_AUTH,
  COMEDIEN_UPDATE_AUTH_SUCCESS,
  COMEDIEN_UPDATE_AUTH_ERROR,
  COMEDIEN_UPDATE_PASSWORD_SUCCESS,
  COMEDIEN_UPDATE_PASSWORD_ERROR,
  //////////////
  GET_INFOS_USER,
  SHOW_INFOS_USER,
} from "constants/ActionTypes";

import {
  STATUS_REQUEST_RESET_PASSWORD_INIT,
  STATUS_RESET_PASSWORD_INIT,
  STATUS_UPDATE_AUTH_INIT,
  STATUS_UPDATE_AUTH_SUCCESS,
  STATUS_UPDATE_PASSWORD_INIT,
  STATUS_UPDATE_PASSWORD_SUCCESS,
} from "constants/General";

import {
  SIGNUP_STATUS_SUCCESS,
} from "constants/ActionTypes"

const INIT_STATE = {
  statusSignIn: SIGNIN_STATUS_INIT,
  statusSignUp: SIGNUP_STATUS_INIT,
  dataSignUp: null,
  statusRequestResetPassword: STATUS_REQUEST_RESET_PASSWORD_INIT,
  statusResetPassword: STATUS_RESET_PASSWORD_INIT,
  dataAuth: null,
  statutUpdateAuth: STATUS_UPDATE_AUTH_INIT,
  statutUpdatePassword: STATUS_UPDATE_PASSWORD_INIT,
  ///////////

  
  dataInfosUser: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {

    ///////////////////////////////////////////////////////////////////////
    // Sign In
    ///////////////////////////////////////////////////////////////////////

    case SIGNIN_SET_STATUS: {
      return {
          ...state,
          statusSignIn: action.payload,
      }
    }

    ///////////////////////////////////////////////////////////////////////
    // Sign Up
    ///////////////////////////////////////////////////////////////////////

    case SIGNUP_SET_STATUS: {
      return {
          ...state,
          statusSignUp: action.payload,
      }
    }
    case SIGNUP_SUCCESS: {
      return {
          ...state,
          dataSignUp: action.dataSignUp,
          statusSignUp: SIGNUP_STATUS_SUCCESS,
      }
    }

    ///////////////////////////////////////////////////////////////////////
    // Forgot Password
    ///////////////////////////////////////////////////////////////////////

    //Request Password Reset
    case AUTH_SET_STATUS_REQUEST_RESET_PASSWORD: {
      return {
          ...state,
          statusRequestResetPassword: action.payload,
      }
    }

    //Reset Password
    case AUTH_SET_STATUS_RESET_PASSWORD: {
      return {
          ...state,
          statusResetPassword: action.payload,
      }
    }

    ///////////////////////////////////////////////////////////////////////
    // Identifiants
    ///////////////////////////////////////////////////////////////////////

    case COMEDIEN_GET_AUTH: {
      return {
          ...state,
      }
    }
    case COMEDIEN_SHOW_AUTH: {
      return {
          ...state,
          dataAuth: action.dataAuth
      }
    }
    case COMEDIEN_UPDATE_AUTH_SUCCESS: {
      return {
          ...state,
          statutUpdateAuth: STATUS_UPDATE_AUTH_SUCCESS
      }
    }
    case COMEDIEN_UPDATE_AUTH_ERROR: {
      return {
          ...state,
          statutUpdateAuth: action.error
      }
    }

    ///////////////////////////////////////////////////////////////////////
    // Mot de passe
    ///////////////////////////////////////////////////////////////////////

    case COMEDIEN_UPDATE_PASSWORD_SUCCESS: {
      return {
          ...state,
          statutUpdatePassword: STATUS_UPDATE_PASSWORD_SUCCESS
      }
    }
    case COMEDIEN_UPDATE_PASSWORD_ERROR: {
      return {
          ...state,
          statutUpdatePassword: action.error
      }
    }

    ///////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////


   

    ///////////////////////////////////////////////////////////////////////
    // Infos user
    ///////////////////////////////////////////////////////////////////////

    case GET_INFOS_USER: {
      return {
          ...state
      }
    }
    case SHOW_INFOS_USER: {
      return {
          ...state,
          dataInfosUser: action.dataInfosUser
      }
    }

    default:
      return state;
  }
}
