import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  MESSAGE_SUCCESS,
  MESSAGE_FAIL,
  NOTIFICATION_SUCCESS,
  NOTIFICATION_FAIL,
  GET_FICHE_COMEDIEN,
  COMEDIEN_DEMANDE_ACCES_TELEPHONE,
  COMEDIEN_DEMANDE_ACCES_EMPLOYEUR,
  GET_DERNIERS_NEWS_COMEDIEN,
  GET_SLIDES_PHOTOS_XL,
  GET_SLIDES_BLOGS,
  GET_SLIDES_TEMOIGNAGES_COMEDIENS,
  COMMON_REQUEST_SONNEZ_ALFRED,
  COMMON_REQUEST_VOIX_ETRANGERES,
  COMMON_REQUEST_PROJET,
  CMS_GET_CGU,
  CMS_UPDATE_CGU,
  CMS_GET_RGPD,
  CMS_UPDATE_RGPD,
  COMMON_GET_LIST_MESSAGES,
} from "constants/ActionTypes";

import { 
  STATUT_SONNEZ_ALFRED_SUCCES,
  STATUT_SONNEZ_ALFRED_ERREUR,
  STATUT_VOIX_ETRANGERES_SUCCES,
  STATUT_VOIX_ETRANGERES_ERREUR,
  STATUT_PROJET_SUCCES,
  STATUT_PROJET_ERREUR
} from "constants/General";

import { 
  showAlertAction,
  showNotificationAction,
  showRequestLoaderAction,
  hideRequestLoaderAction,
  showFicheComedienAction, 
  showDerniersNewsComediensAction,
  showSlidesPhotosXLAction,
  showSlidesBlogsAction,
  showSlidesTemoignagesComediensAction,
  setStatusSonnezAlfredAction,
  setStatusVoixEtrangeresAction,
  setStatusProjetAction,
  showCGUAction,
  showRGPDAction,
  showListMessagesAction,
 } from "appRedux/actions/Common";

 import{
  getListMessagesRequest
} from 'appRedux/services/Message';

 import {
  demandeAccesTelephoneRequest,
  demandeAccesEmployeurRequest,
} from 'appRedux/services/AccesConfidentialite';

import {
  getFicheComedienRequest,
} from 'appRedux/services/Comedien';

import {
  getDerniersNewsComediensRequest
} from 'appRedux/services/News';

import { 
  getSlidesPhotosXLRequest
} from 'appRedux/services/PhotoXL';

import { 
  getSlidesBlogsRequest,
} from 'appRedux/services/Blog';

import { 
  getSlidesTemoignagesComediensRequest,
} from 'appRedux/services/TemoignageComedien';

import {
  sonnezAlfredRequest,
  voixEtrangeresRequest,
  projetRequest,
} from 'appRedux/services/Email';

import {
  getCGURequest,
  updateCGURequest,
  getRGPDRequest,
  updateRGPDRequest,
} from 'appRedux/services/CMS';


////////////////////////////////////////////////////////////////////////////
// Fiche comedien
////////////////////////////////////////////////////////////////////////////

function* getFicheComedien({ payload }) {
  try {
    const algoliaData = yield call(getFicheComedienRequest, payload);
    if (algoliaData.status === 200) {
      yield put(showFicheComedienAction(algoliaData.data));
    }
  } catch (error) {

  }
}

export function* getFicheComedienFork() {
  yield takeEvery(GET_FICHE_COMEDIEN, getFicheComedien);
}


///////////////////////////////////////////////////////////////////////
// Acc�s Confidentialit�
/////////////////////////////////////////////////////////////////////
function* demandeAccesTelephone(payload) {
  try {
    const { idComedien } = payload;
    const requestReturn = yield call(demandeAccesTelephoneRequest, idComedien);
    if (requestReturn.status && requestReturn.status === 200) {
      yield put(showAlertAction("contactComedien.demandePartageTelephone.success", MESSAGE_SUCCESS));
    } else {
      yield put(showAlertAction("contactComedien.demandePartageTelephone.error", MESSAGE_FAIL));
    }
  } catch (error) {

  }
}

export function* demandeAccesTelephoneFork() {
  yield takeEvery(COMEDIEN_DEMANDE_ACCES_TELEPHONE, demandeAccesTelephone);
}

function* demandeAccesEmployeur(payload) {
  try {
    const { idComedien } = payload;
    const requestReturn = yield call(demandeAccesEmployeurRequest, idComedien);
    if (requestReturn.status && requestReturn.status === 200) {
      yield put(showAlertAction("ficheComedien.panelEmployeur.demandePartageEmployeur.success", MESSAGE_SUCCESS));
    } else {
      yield put(showAlertAction("ficheComedien.panelEmployeur.demandePartageEmployeur.error", MESSAGE_FAIL));
    }
  } catch (error) {

  }
}

export function* demandeAccesEmployeurFork() {
  yield takeEvery(COMEDIEN_DEMANDE_ACCES_EMPLOYEUR, demandeAccesEmployeur);
}

////////////////////////////////////////////////////////////////////////////
// Derniers News com�diens
////////////////////////////////////////////////////////////////////////////

function* getDerniersNewsComediens({ payload }) {
  try {
    const response = yield call(getDerniersNewsComediensRequest, payload);
    if (response.status === 200) {
      yield put(showDerniersNewsComediensAction(response.data));
    }
  } catch (error) {

  }
}

export function* getDerniersNewsComediensFork() {
  yield takeEvery(GET_DERNIERS_NEWS_COMEDIEN, getDerniersNewsComediens);
}

////////////////////////////////////////////////////////////////////////////
// Slides Photos XL
////////////////////////////////////////////////////////////////////////////

function* getSlidesPhotosXL() {
  try {
      const response = yield call(getSlidesPhotosXLRequest);
      if (response.status && response.status === 200 && response.data) {
          yield put(showSlidesPhotosXLAction(response.data));
      }
  } catch (error) {

  }
}

export function* getSlidesPhotosXLFork() {
  yield takeEvery(GET_SLIDES_PHOTOS_XL, getSlidesPhotosXL);
}

////////////////////////////////////////////////////////////////////////////
// Slides Blogs
////////////////////////////////////////////////////////////////////////////

function* getSlidesBlogs() {
  try {
      const response = yield call(getSlidesBlogsRequest);
      if (response.status && response.status === 200 && response.data) {
          yield put(showSlidesBlogsAction(response.data));
      }
  } catch (error) {

  }
}

export function* getSlidesBlogsFork() {
  yield takeEvery(GET_SLIDES_BLOGS, getSlidesBlogs);
}

////////////////////////////////////////////////////////////////////////////
// Slides Temoignages Com�diens
////////////////////////////////////////////////////////////////////////////

function* getSlidesTemoignagesComediens() {
  try {
      const response = yield call(getSlidesTemoignagesComediensRequest);
      if (response.status && response.status === 200 && response.data) {
          yield put(showSlidesTemoignagesComediensAction(response.data));
      }
  } catch (error) {

  }
}

export function* getSlidesTemoignagesComediensFork() {
  yield takeEvery(GET_SLIDES_TEMOIGNAGES_COMEDIENS, getSlidesTemoignagesComediens);
}

///////////////////////////////////////////////////////////////////////
// Sonnez Alfred
///////////////////////////////////////////////////////////////////////

function* sonnezAlfred ({ payload }) {
  try {
    yield put(showRequestLoaderAction());

    const response = yield call(sonnezAlfredRequest, payload);
    if (response.status && response.status === 200) {
      yield put(setStatusSonnezAlfredAction(STATUT_SONNEZ_ALFRED_SUCCES));
    } else {
      yield put(setStatusSonnezAlfredAction(STATUT_SONNEZ_ALFRED_ERREUR));
    }

    yield put(hideRequestLoaderAction());

  } catch (error) {
    yield put(hideRequestLoaderAction());
    yield put(setStatusSonnezAlfredAction(STATUT_SONNEZ_ALFRED_ERREUR));
  }
}

export function* sonnezAlfredFork() {
  yield takeEvery(COMMON_REQUEST_SONNEZ_ALFRED, sonnezAlfred);
}

///////////////////////////////////////////////////////////////////////
// Voix �trang�res
///////////////////////////////////////////////////////////////////////

function* voixEtrangeres ({ payload }) {
  try {
    yield put(showRequestLoaderAction());

    const response = yield call(voixEtrangeresRequest, payload);
    if (response.status && response.status === 200) {
      yield put(setStatusVoixEtrangeresAction(STATUT_VOIX_ETRANGERES_SUCCES));
    } else {
      yield put(setStatusVoixEtrangeresAction(STATUT_VOIX_ETRANGERES_ERREUR));
    }

    yield put(hideRequestLoaderAction());

  } catch (error) {
    yield put(hideRequestLoaderAction());
    yield put(setStatusVoixEtrangeresAction(STATUT_VOIX_ETRANGERES_ERREUR));
  }
}

export function* voixEtrangeresFork() {
  yield takeEvery(COMMON_REQUEST_VOIX_ETRANGERES, voixEtrangeres);
}

///////////////////////////////////////////////////////////////////////
// Projet
///////////////////////////////////////////////////////////////////////

function* projet ({ payload }) {
  try {
    yield put(showRequestLoaderAction());

    const response = yield call(projetRequest, payload);
    if (response.status && response.status === 200) {
      yield put(setStatusProjetAction(STATUT_PROJET_SUCCES));
    } else {
      yield put(setStatusProjetAction(STATUT_PROJET_ERREUR));
    }

    yield put(hideRequestLoaderAction());

  } catch (error) {
    yield put(hideRequestLoaderAction());
    yield put(setStatusProjetAction(STATUT_PROJET_ERREUR));
  }
}

export function* projetFork() {
  yield takeEvery(COMMON_REQUEST_PROJET, projet);
}

////////////////////////////////////////////////////////////////////////////
// CMS
////////////////////////////////////////////////////////////////////////////

//CGU

function* getCGU() {
  try {
      const response = yield call(getCGURequest);
      if (response.status && response.status === 200 && response.data) {
          yield put(showCGUAction(response.data));
      }
  } catch (error) {
  }
}

export function* getCGUFork() {
  yield takeEvery(CMS_GET_CGU, getCGU);
}

function* updateCGU({ payload }) {
  try {
    const response = yield call(updateCGURequest, payload);
    if (response.status && response.status === 200) {
      yield put(showAlertAction("admin.cgu.update.success",MESSAGE_SUCCESS));
    } else {
        yield put(showAlertAction("admin.cgu.update.error",MESSAGE_FAIL));
    }
  } catch (error) {
    yield put(showAlertAction("admin.cgu.update.error",MESSAGE_FAIL));
  }
}

export function* updateCGUFork() {
  yield takeEvery(CMS_UPDATE_CGU, updateCGU);
}

//RGPD

function* getRGPD() {
  try {
      const response = yield call(getRGPDRequest);
      if (response.status && response.status === 200 && response.data) {
          yield put(showRGPDAction(response.data));
      }
  } catch (error) {
  }
}

export function* getRGPDFork() {
  yield takeEvery(CMS_GET_RGPD, getRGPD);
}

function* updateRGPD({ payload }) {
  try {
    const response = yield call(updateRGPDRequest, payload);
    if (response.status && response.status === 200) {
      yield put(showAlertAction("admin.rgpd.update.success",MESSAGE_SUCCESS));
    } else {
        yield put(showAlertAction("admin.rgpd.update.error",MESSAGE_FAIL));
    }
  } catch (error) {
    yield put(showAlertAction("admin.rgpd.update.error",MESSAGE_FAIL));
  }
}

export function* updateRGPDFork() {
  yield takeEvery(CMS_UPDATE_RGPD, updateRGPD);
}

///////////////////////////////////////////////////////////////////////
// Messages
///////////////////////////////////////////////////////////////////////

//Liste des Messages
function* getListMessages({ payload }) {
  try {
    const response = yield call(getListMessagesRequest, payload);
    if (response.status && response.status === 200 && response.data) {
      yield put(showListMessagesAction(response.data));
    }
  } catch (error) {
  }
}

export function* getListMessagesFork() {
  yield takeEvery(COMMON_GET_LIST_MESSAGES, getListMessages);
}

////////////////////////////////////////////////////////////////////////////
// Exports
////////////////////////////////////////////////////////////////////////////

export default function* rootSaga() {
  yield all([
    fork(getFicheComedienFork),
    fork(demandeAccesTelephoneFork),
    fork(demandeAccesEmployeurFork),
    fork(getDerniersNewsComediensFork),
    fork(getSlidesPhotosXLFork),
    fork(getSlidesBlogsFork),
    fork(getSlidesTemoignagesComediensFork),
    fork(sonnezAlfredFork),
    fork(voixEtrangeresFork),
    fork(projetFork),
    fork(getCGUFork),
    fork(updateCGUFork),
    fork(getRGPDFork),
    fork(updateRGPDFork),
    fork(getListMessagesFork),
  ]);
}