import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  PLAYLIST_GET_LIST,
  PLAYLIST_CREATE,
  PLAYLIST_CREATE_ADD_EXTRAIT,
  PLAYLIST_UPDATE,
  PLAYLIST_COPY,
  PLAYLIST_DELETE,
  PLAYLIST_SORT_EXTRAITS,
  PLAYLIST_ADD_EXTRAIT,
  PLAYLIST_RETRAIT_EXTRAIT,
  MESSAGE_SUCCESS,
  MESSAGE_FAIL,
  //////////////////
  NOTIFICATION_SUCCESS,
  NOTIFICATION_FAIL,


  PLAYLISTS_GET_LISTE_DECOUVERTE,
  PLAYLISTS_GET_PARTAGE,

} from "constants/ActionTypes";

import {
  showPlayListsAction,
  showPlayListsDecouverteAction,
  showPlayListPartageAction
} from "appRedux/actions/Playlist";

import {
  createPlaylistAddMediaRequest,
  getPlayListsRequest,
  createPlaylistRequest,
  createPlaylistAddExtraitRequest,
  deletePlaylistRequest,
  updatePlaylistRequest,
  getPlayListsDecouverteRequest,
  getPlayListPartageRequest,
  copyPlaylistRequest,
  sortExtraitsRequest,
  addExtraitToPlaylistRequest,
  retraitExtraitFromPlaylistRequest,

} from '../services/Playlist';

import {
  showNotificationAction,
  setRefreshListAction,
  showAlertAction
} from "appRedux/actions/Common";

//////////////////////////////////////////////////////////////
// List des playists
//////////////////////////////////////////////////////////////

function* getPlayLists() {
  try {
    const response = yield call(getPlayListsRequest);
    if (response.status === 200) {
      yield put(showPlayListsAction(response.data));
    }
  } catch (error) {
  }
}

export function* getPlayListsFork() {
  yield takeEvery(PLAYLIST_GET_LIST, getPlayLists);
}

//////////////////////////////////////////////////////////////
// Cr�ation d'une Playlist
//////////////////////////////////////////////////////////////

function* createPlaylist({ payload }) {
  try {
    var response = yield call(createPlaylistRequest, payload);
    if (response.status && response.status === 200) {
      yield put(showAlertAction("playlists.mesPlaylists.creationPlaylist.success", MESSAGE_SUCCESS));
      yield put(setRefreshListAction());
    } else {
      yield put(showAlertAction("playlists.mesPlaylists.creationPlaylist.error", MESSAGE_FAIL));
    }
  } catch (error) {
    yield put(showAlertAction("playlists.mesPlaylists.creationPlaylist.error", MESSAGE_FAIL));
  }
}

export function* createPlaylistFork() {
  yield takeEvery(PLAYLIST_CREATE, createPlaylist);
}

//////////////////////////////////////////////////////////////
// Cr�ation d'une Playlist
//////////////////////////////////////////////////////////////

function* createPlaylistAddExtrait({ payload }) {
  try {
    var response = yield call(createPlaylistAddExtraitRequest, payload);
    if (response.status && response.status === 200) {
      yield put(showAlertAction("playlists.modalAjoutPlaylist.creationPlaylist.success", MESSAGE_SUCCESS));

      response = yield call(getPlayListsRequest);
      if (response.status === 200) {
        yield put(showPlayListsAction(response.data));
      }
    } else {
      yield put(showAlertAction("playlists.modalAjoutPlaylist.creationPlaylist.error", MESSAGE_FAIL));
    }
  } catch (error) {
    yield put(showAlertAction("playlists.modalAjoutPlaylist.creationPlaylist.error", MESSAGE_FAIL));
  }
}

export function* createPlaylistAddExtraitFork() {
  yield takeEvery(PLAYLIST_CREATE_ADD_EXTRAIT, createPlaylistAddExtrait);
}

//////////////////////////////////////////////////////////////
// Modification d'une Playlist
//////////////////////////////////////////////////////////////

function* updatePlaylist({ payload }) {
  try {
    var response = yield call(updatePlaylistRequest, payload);
    if (response.status && response.status === 200) {
      yield put(showAlertAction("playlists.modalModifPlaylist.modifPlaylist.success", MESSAGE_SUCCESS));
      yield put(setRefreshListAction());
    } else {
      yield put(showAlertAction("playlists.modalModifPlaylist.modifPlaylist.error", MESSAGE_FAIL));
    }
  } catch (error) {
    yield put(showAlertAction("playlists.modalModifPlaylist.modifPlaylist.error", MESSAGE_FAIL));
  }
}

export function* updatePlaylistFork() {
  yield takeEvery(PLAYLIST_UPDATE, updatePlaylist);
}

//////////////////////////////////////////////////////////////
// Copie d'une Playlist
//////////////////////////////////////////////////////////////

function* copyPlaylist({ payload }) {
  try {
    var response = yield call(copyPlaylistRequest, payload);
    if (!response.status && response.status === 200) {
      yield put(showAlertAction("playlists.copiePlaylist.success", MESSAGE_SUCCESS));
      yield put(setRefreshListAction());
    } else {
      yield put(showAlertAction("playlists.copiePlaylist.error", MESSAGE_FAIL));
    }
  } catch (error) {
    yield put(showAlertAction("playlists.copiePlaylist.error", MESSAGE_FAIL));
  }
}

export function* copyPlaylistFork() {
  yield takeEvery(PLAYLIST_COPY, copyPlaylist);
}

//////////////////////////////////////////////////////////////
// Suppression d'une Playlist
//////////////////////////////////////////////////////////////

function* deletePlaylist({ payload }) {
  try {
    var response = yield call(deletePlaylistRequest, payload);
    if (response.status && response.status === 200) {
      yield put(showAlertAction("playlists.suppressionPlaylist.success", MESSAGE_SUCCESS));
      yield put(setRefreshListAction());
    } else {
      yield put(showAlertAction("playlists.suppressionPlaylist.error", MESSAGE_FAIL));
    }
  } catch (error) {
    yield put(showAlertAction("playlists.suppressionPlaylist.error", MESSAGE_FAIL));
  }
}

export function* deletePlaylistFork() {
  yield takeEvery(PLAYLIST_DELETE, deletePlaylist);
}

//////////////////////////////////////////////////////////////
// Ajout d'un extrait � une playlist
//////////////////////////////////////////////////////////////

function* addExtraitToPlaylist({ payload }) {
  try {
    var response = yield call(addExtraitToPlaylistRequest, payload);
    if (response.status && response.status === 200) {
      yield put(showAlertAction("playlists.modalAjoutPlaylist.ajoutMediaPlaylist.success", MESSAGE_SUCCESS));

      var response = yield call(getPlayListsRequest);
      if (response.status === 200) {
        yield put(showPlayListsAction(response.data));
      }
    } else {
      yield put(showAlertAction("playlists.modalAjoutPlaylist.ajoutMediaPlaylist.error", MESSAGE_FAIL));
    }
  } catch (error) {
    yield put(showAlertAction("playlists.modalAjoutPlaylist.ajoutMediaPlaylist.error", MESSAGE_FAIL));
  }
}

export function* addExtraitToPlaylistFork() {
  yield takeEvery(PLAYLIST_ADD_EXTRAIT, addExtraitToPlaylist);
}

//////////////////////////////////////////////////////////////
// Retrait d'un extrait d'une playlist
//////////////////////////////////////////////////////////////

function* retraitExtraitFromPlaylist({ payload }) {
  try {
    var response = yield call(retraitExtraitFromPlaylistRequest, payload);
    if (response.status && response.status === 200) {
      yield put(showAlertAction("playlists.retraitMediaFromPlaylist.success", MESSAGE_SUCCESS));

      var response = yield call(getPlayListsRequest);
      if (response.status === 200) {
        yield put(showPlayListsAction(response.data));
      }
    } else {
      yield put(showAlertAction("playlists.retraitMediaFromPlaylist.error", MESSAGE_FAIL));
    }
  } catch (error) {
    yield put(showAlertAction("playlists.retraitMediaFromPlaylist.error", MESSAGE_FAIL));
  }
}

export function* retraitExtraitFromPlaylistFork() {
  yield takeEvery(PLAYLIST_RETRAIT_EXTRAIT, retraitExtraitFromPlaylist);
}

//////////////////////////////////////////////////////////////
// Suppression d'une Playlist
//////////////////////////////////////////////////////////////

function* sortExtraits({ payload }) {
  try {
    var response = yield call(sortExtraitsRequest, payload);
    if (response.status && response.status === 200) {
      yield put(showAlertAction("playlists.triPlaylist.success", MESSAGE_SUCCESS));
      yield put(setRefreshListAction());
    } else {
      yield put(showAlertAction("playlists.suppressionPlaylist.error", MESSAGE_FAIL));
    }
  } catch (error) {
    yield put(showAlertAction("playlists.suppressionPlaylist.error", MESSAGE_FAIL));
  }
}

export function* sortExtraitsFork() {
  yield takeEvery(PLAYLIST_SORT_EXTRAITS, sortExtraits);
}

//////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////

//////////////////////////////////////////////////////////////
// Playists decouvertes
//////////////////////////////////////////////////////////////

function* getPlayListsDecouverte({ payload }) {
  try {
    const response = yield call(getPlayListsDecouverteRequest,payload);
    if (response.status === 200) {
      yield put(showPlayListsDecouverteAction(response.data));
    }
  } catch (error) {
  }
}

export function* getPlayListsDecouverteFork() {
  yield takeEvery(PLAYLISTS_GET_LISTE_DECOUVERTE, getPlayListsDecouverte);
}

////////////////////////////////////////////////////////////////////////////
// Partage
////////////////////////////////////////////////////////////////////////////

function* getPlayListPartage({ payload }) {
  try {
    const response = yield call(getPlayListPartageRequest, payload);
    if (response.status === 200) {
      yield put(showPlayListPartageAction(response.data));
    }
  } catch (error) {
  }
}

export function* getPlayListPartageFork() {
  yield takeEvery(PLAYLISTS_GET_PARTAGE, getPlayListPartage);
}

////////////////////////////////////////////////////////////////////////////
// Exports
////////////////////////////////////////////////////////////////////////////

export default function* rootSaga() {
  yield all([
    fork(getPlayListsFork),
    fork(createPlaylistFork),
    fork(createPlaylistAddExtraitFork),
    fork(updatePlaylistFork),
    fork(copyPlaylistFork),
    fork(deletePlaylistFork),
    fork(sortExtraitsFork),
    fork(addExtraitToPlaylistFork),
    fork(retraitExtraitFromPlaylistFork),





    fork(getPlayListsDecouverteFork),
    fork(getPlayListPartageFork),
  ]);
}