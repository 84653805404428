import {
  PARTAGE_SHOW_EXTRAIT_BY_LINK,
  //////////////////////////
  PARTAGE_SHOW_EXTRAIT,
  PARTAGE_SHOW_PLAYLIST
} from "constants/ActionTypes";
  
const INIT_STATE = {
  dataPartageExtraitByLink: null,
  ////////////////////
  dataPartageExtrait:{},
  dataPartagePlaylist:{},
};
  
  
export default (state = INIT_STATE, action) => {
  switch (action.type) {
  
    //////////////////////////////////////////////////////////////
    // Partage d'extrait
    /////////////////////////////////////////////////////////////
      
    case PARTAGE_SHOW_EXTRAIT: {
      return {
        ...state,
        dataPartageExtrait: action.dataPartageExtrait
      }
        
    }
    case PARTAGE_SHOW_EXTRAIT_BY_LINK: {
      return {
        ...state,
        dataPartageExtraitByLink: action.dataPartageExtraitByLink
      }
        
    }

    //////////////////////////////////////////////////////////////
    // Partage de playlist
    /////////////////////////////////////////////////////////////
      
    case PARTAGE_SHOW_PLAYLIST: {
      return {
        ...state,
        dataPartagePlaylist: action.dataPartagePlaylist
      }
        
    }
      
    default:
      return state;
  }
}
  