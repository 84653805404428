import {
  SWITCH_LANGUAGE, 
  TOGGLE_COLLAPSED_NAV, 
  TOGGLE_COLLAPSED_MOBILE_MENU,
  WINDOW_WIDTH
} from "constants/ActionTypes";


export function toggleCollapsedSideNav(navCollapsed) {
  return {type: TOGGLE_COLLAPSED_NAV, navCollapsed};
}

export function toggleCollapsedMobileMenu(mobileMenuCollapsed) {
  return {type: TOGGLE_COLLAPSED_MOBILE_MENU, mobileMenuCollapsed};
}

export function updateWindowWidth(width) {
  return {type: WINDOW_WIDTH, width};
}

export function switchLanguage(locale) {
  return {
    type: SWITCH_LANGUAGE,
    payload: locale
  };
}
