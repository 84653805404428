import React, { Component } from 'react';
import { connect } from "react-redux";
import AutoSuggest from 'react-autosuggest';
import Parser from 'html-react-parser';
import { injectIntl } from 'react-intl';
import { withRouter } from "util/withRouter";
import { Button, Input } from "antd";
import { algolia } from 'util/config';
import { APPLICATION_PATHS } from "constants/ApplicationPaths";
import IntlMessages from "util/IntlMessages";

import {
  connectAutoComplete,
  Hits,
  Highlight
} from 'react-instantsearch-dom';

import {
  searchAction,
} from "appRedux/actions/Common";

class AutoComplete extends Component {

  constructor(props) {
    super(props);
    this.state = {
      inputValue: this.props.currentRefinement,
    };
  }

  onSuggestionsFetchRequested = ({ value }) => {
    this.props.refine(value);
  };

  onSuggestionsClearRequested = () => {
    this.props.refine();
  };

  getSuggestedAttribute = (hit) => {
    for (var i = 0; i < algolia.searchableAttributes.length; i++) {
      let attribute = algolia.searchableAttributes[i];

      if (hit._highlightResult[attribute]) {
        if (Array.isArray(hit._highlightResult[attribute])) {
          let match = false;
          for (var j = 0; j < hit._highlightResult[attribute].length; j++) {
            if (hit._highlightResult[attribute][j].matchLevel !== "none") {
              match = true;
            }
          }
          if (match) {
            return attribute;
          }
        } else {
          if (hit._highlightResult[attribute].matchLevel !== "none") {
            return attribute;
          }
        }
      }
    }
    return null;
  }

  getSuggestionValue = (hit) => {
    var suggestedAttribute = this.getSuggestedAttribute(hit);
    if (!suggestedAttribute) {
      return "";
    }
    return hit[suggestedAttribute];
  }

  onSuggestionSelected = (event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => {
    if (typeof suggestionValue !== 'undefined') {
      this.search(suggestionValue);
    }
  };

  renderSuggestion = (hit) => {
    var suggestedAttribute = this.getSuggestedAttribute(hit);
    if (!suggestedAttribute) {
      return "";
    }

    return <Highlight attribute={suggestedAttribute} hit={hit} tagName="mark" />;
  }

  search = (searchValue) => {
    switch (window.location.pathname) {
      case APPLICATION_PATHS.RECHERCHE:
        this.props.searchAction(searchValue)
        break;
      default:
        window.location.replace("/recherche?keyword=" + searchValue);
    }
  }

  onChange = (event, { newValue }) => {
    this.setState({ inputValue: newValue });
  };

  onKeyPress = (e) => {
    if (e.key === 'Enter') {
      this.search(this.state.inputValue);
    }
  }

  clearInput = () => {
    this.setState({ inputValue: '' });
    this.search('');
  }

  render() {
    const { hits } = this.props;
    const { inputValue } = this.state;
    var intl = this.props.intl;

    var hitsFiltered = [];
    var suggestesValues = [];
    for (var i = 0; i < hits.length; i++) {
      let attribute = this.getSuggestedAttribute(hits[i]);
      if (attribute) {
        if (!suggestesValues.includes(hits[i][attribute])) {
          suggestesValues.push(hits[i][attribute])
          hitsFiltered.push(hits[i])
        }
      }
    }

    const inputProps = {
      placeholder: intl.formatMessage({ id: "searchForm.recherche.placeholder" }),
      onChange: this.onChange,
      onKeyPress: this.onKeyPress,
      value: inputValue,
      className: 'ant-input',
      style: { paddingRight: '28px' },
    };

    var classPage;
    var buttonText;
    if (this.props.displayOn == "SLIDER") {
      classPage = "gx-search-bar cm-search-box-slider";
      buttonText = intl.formatMessage({ id: "searchForm.recherche.text" });
    } else if (this.props.displayOn == "TOP-BAR") {
      classPage = "gx-search-bar";
      buttonText = '<i class="anticon anticon-search"><svg viewBox="64 64 896 896" focusable="false" class="" data-icon="search" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M909.6 854.5L649.9 594.8C690.2 542.7 712 479 712 412c0-80.2-31.3-155.4-87.9-212.1-56.6-56.7-132-87.9-212.1-87.9s-155.5 31.3-212.1 87.9C143.2 256.5 112 331.8 112 412c0 80.1 31.3 155.5 87.9 212.1C256.5 680.8 331.8 712 412 712c67 0 130.6-21.8 182.7-62l259.7 259.6a8.2 8.2 0 0 0 11.6 0l43.6-43.5a8.2 8.2 0 0 0 0-11.6zM570.4 570.4C528 612.7 471.8 636 412 636s-116-23.3-158.4-65.6C211.3 528 188 471.8 188 412s23.3-116.1 65.6-158.4C296 211.3 352.2 188 412 188s116.1 23.2 158.4 65.6S636 352.2 636 412s-23.3 116.1-65.6 158.4z"></path></svg></i>'
    } else if (this.props.displayOn == "MOBILE") {
      classPage = "gx-search-bar";
      buttonText = '<i class="anticon anticon-search"><svg viewBox="64 64 896 896" focusable="false" class="" data-icon="search" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M909.6 854.5L649.9 594.8C690.2 542.7 712 479 712 412c0-80.2-31.3-155.4-87.9-212.1-56.6-56.7-132-87.9-212.1-87.9s-155.5 31.3-212.1 87.9C143.2 256.5 112 331.8 112 412c0 80.1 31.3 155.5 87.9 212.1C256.5 680.8 331.8 712 412 712c67 0 130.6-21.8 182.7-62l259.7 259.6a8.2 8.2 0 0 0 11.6 0l43.6-43.5a8.2 8.2 0 0 0 0-11.6zM570.4 570.4C528 612.7 471.8 636 412 636s-116-23.3-158.4-65.6C211.3 528 188 471.8 188 412s23.3-116.1 65.6-158.4C296 211.3 352.2 188 412 188s116.1 23.2 158.4 65.6S636 352.2 636 412s-23.3 116.1-65.6 158.4z"></path></svg></i>'
    } else {
      classPage = "gx-search-bar cm-search-box-slider";
      buttonText = intl.formatMessage({ id: "searchForm.recherche.text" });
    }

    const renderInputComponent = inputProps => (
      <div>
        <div className={classPage} >
          <div className="gx-form-group">
            <span className="ant-input-search ant-input-search-enter-button ant-input-group-wrapper">
              <span className="ant-input-wrapper ant-input-group">
                <div style={{ position: "relative", display: "flex" }}>
                  <Input {...inputProps} />
                  <i
                    className="fa fa-times-circle cm-search-reset-input"
                    onClick={this.clearInput}
                  >
                  </i>
                </div>

                <span className="ant-input-group-addon" style={{ border: "0", padding: "0" }}>
                  <Button
                    type="button"
                    id="search-btn"
                    aria-label="Recherche"
                    className="ant-input-search-button ant-btn-primary"
                    style={{ borderTopLeftRadius: "0", borderBottomLeftRadius: "0" }}
                    onClick={e => this.search(this.state.inputValue)}>
                    {Parser(buttonText)}
                  </Button>
                </span>
              </span>
            </span>
          </div>
        </div>
      </div>
    );

    return (
      <AutoSuggest
        suggestions={hitsFiltered}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        onSuggestionSelected={this.onSuggestionSelected}
        getSuggestionValue={this.getSuggestionValue}
        renderSuggestion={this.renderSuggestion}
        renderInputComponent={renderInputComponent}
        inputProps={inputProps}
      />
    );
  }
}

const mapStateToProps = ({ }) => {
  return {}
};

export default injectIntl(
  connectAutoComplete(
    withRouter(
      connect(
        mapStateToProps,
        {
          searchAction,
        }
      )(AutoComplete)
    )
  )
);
