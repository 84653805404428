import React from "react";
import { connect } from "react-redux";
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal, Input, Row, Col, Checkbox, Button, Alert, Select } from "antd";
import IntlMessages from "util/IntlMessages";
import { getCurrentUser } from 'util/Auth';
import { injectIntl } from 'react-intl';
import Auth from "components/Auth";
import Swal from 'sweetalert2';
import Parser from 'html-react-parser';

import { 
  getTypeExtraitOptions,
  getLangueOptions,
  getDiffusionOptions
} from 'util/ValueList/Options';

import { 
  projetAction,
  setStatusProjetAction
} from "appRedux/actions/Common";

import { 
  STATUT_PROJET_EN_ATTENTE,
  STATUT_PROJET_SUCCES,
  STATUT_PROJET_ERREUR
} from "constants/General";

import logoCastingMachine from "assets/images/logo-casting-machine.webp";

const FormItem = Form.Item;
const { TextArea } = Input;
const Option = Select.Option;

class Projet extends React.Component {
  constructor() {
    super();
    this.state = {
      visible: false,
      destroyOnClose: true,
      selectedFile: null,
      selectedFileName: '',
      statusProjet: STATUT_PROJET_EN_ATTENTE,
      showModalAuth: false,
      vueAuth: '',
      dataSignUp: null,
    };

    this.idSenderUser = null;
    this.formValues   = null;
  }

  componentDidMount() {
    let currentUser = getCurrentUser();

    if(currentUser){
      this.idSenderUser = currentUser.userId;
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let newState = {}

    if (nextProps.dataSignUp !== prevState.dataSignUp && nextProps.dataSignUp) {
      newState.dataSignUp = nextProps.dataSignUp;
    }
    
    return newState;
  }

  componentDidUpdate (prevProps) {
    var intl = this.props.intl;

    if (prevProps.visible !== this.props.visible) {
      this.setState({ visible: this.props.visible });
    }

    if (prevProps.statusProjet !== this.props.statusProjet) {
      this.setState({ statusProjet: this.props.statusProjet });

      if(this.props.statusProjet === STATUT_PROJET_SUCCES){
        Swal.fire({
          imageUrl: logoCastingMachine,
          imageWidth: 180,
          buttonsStyling: false,
          customClass: { confirmButton: 'ant-btn ant-btn-primary', cancelButton: 'ant-btn' },
          title: intl.formatMessage({id: "projet.success.titre"}),
          html: intl.formatMessage({id: "projet.success.text"}),
        });
        
        this.onCancel();
      }else if(this.props.statusProjet === STATUT_PROJET_ERREUR){
      }
    }
  }

  ////////////////////////////////////////////////////////
  // Auth / Show-Hide
  ///////////////////////////////////////////////////////

  onHideModalAuth = () => {
    this.setState({
      showModalAuth:false,
      vueAuth:'',
      visible: true,
    });
  }

  onSignInSuccess = () => {
    let currentUser = getCurrentUser();

    this.setState({
      showModalAuth: false,
      vueAuth: '',
      visible: true,
    });

    this.idSenderUser = currentUser.userId;
    this.sendForm();
  }

  onSignUpSuccess = () => {
    this.setState({
      showModalAuth: false,
      vueAuth: '',
      visible: true,
    });

    this.idSenderUser = this.state.dataSignUp.id;
    this.sendForm();
  }

  ////////////////////////////////////////////////////////
  // Cancel Modal
  ///////////////////////////////////////////////////////

  onCancel = () => {
    this.setState({ 
      visible:false, 
      destroyOnClose: true 
    });
    this.props.onCancel();
    this.props.setStatusProjetAction(STATUT_PROJET_EN_ATTENTE);
  };

  ////////////////////////////////////////////////////////
  // Form
  ///////////////////////////////////////////////////////

  onFileChange = (e) => {
    this.setState({ selectedFile: e.target.files[0] });
    this.setState({ selectedFileName: e.target.files[0].name });
  }

  sendForm = () => {
    if(this.idSenderUser){
      this.props.projetAction(this.idSenderUser, this.formValues, this.state.selectedFile);
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {

        this.formValues = values;

        if(!this.idSenderUser){
          this.setState({
            showModalAuth: true,
            vueAuth: 'signUp',
            visible: false,
            destroyOnClose: false,
          });
        }else{
          this.sendForm();
        }
      }

    });
  }

  render(){
    const { statusProjet } = this.state;
    const { getFieldDecorator } = this.props.form;
    var intl = this.props.intl;

    let currentUser = getCurrentUser();

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 24 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 24 },
      },
    };

    if(currentUser){
      var prenom = currentUser.prenom
    }else{
      var prenom = null;
    }

    return(
      <div>
        <Modal
          title={<IntlMessages id="projet.titre" />}
          wrapClassName="cm-modal-projet"
          visible={this.state.visible}
          onCancel={this.onCancel}
          destroyOnClose={this.state.destroyOnClose}
          footer={[
        ]}
        >
          <Form onSubmit={this.handleSubmit} id="projetForm" name="projetForm">
        
            <Row>
              <Col xl={24} lg={24} md={24} sm={24} xs={24} className="cm-projet-intro">
                {prenom ? `${prenom}, ` : '' } <IntlMessages id="projet.intro" />
              </Col>
            </Row>

            {statusProjet === STATUT_PROJET_ERREUR ?
              <div style={{marginBottom:"10px",textAlign:"center"}}>
                <Alert message={Parser(intl.formatMessage({id: "projet.error"}))} type="error"/>
              </div>
            :null}

            <FormItem {...formItemLayout}>
              {getFieldDecorator('nomCasting', {
                rules: [{ required: true, message: <IntlMessages id="input.validation.obligatoire" /> }],
                initialValue: '',
              })(
                <Input 
                  type="text" 
                  placeholder={intl.formatMessage({id: "projet.nomCasting.placeholder"})}
                />
              )}
            </FormItem>

            <Row>
              <Col xl={12} lg={12} md={12} sm={12} xs={24} style={{paddingLeft:"0",paddingRight:"0"}}>
                <FormItem {...formItemLayout}>
                  {getFieldDecorator('typeProjet', {
                    rules: [{ required: true, message: <IntlMessages id="input.validation.obligatoire" /> }],
                  })(
                    getTypeExtraitOptions({placeholder:intl.formatMessage({id: "projet.typeProjet.placeholder"})})
                  )}
              </FormItem>
              </Col>
              <Col xl={12} lg={12} md={12} sm={12} xs={24} style={{paddingLeft:"0",paddingRight:"0"}}>
                <FormItem {...formItemLayout}>
                  {getFieldDecorator('diffusion', {
                    rules: [{ required: true, message: <IntlMessages id="input.validation.obligatoire" /> }],
                  })(
                    getDiffusionOptions({placeholder:intl.formatMessage({id: "projet.diffusion.placeholder"})})
                  )}
                </FormItem>
              </Col>
            </Row>

            <Row>
              <Col xl={12} lg={12} md={12} sm={12} xs={24} style={{paddingLeft:"0",paddingRight:"0"}}>
                <FormItem {...formItemLayout}>
                  {getFieldDecorator('genre', {
                    rules: [{ required: true, message: <IntlMessages id="input.validation.obligatoire" /> }],
                  })(
                    <Select
                      showSearch
                      optionFilterProp="children"
                      placeholder={intl.formatMessage({id: "projet.genre.placeholder"})}
                    >
                      <Option value="Femme"><IntlMessages id="projet.genre.homme" /></Option>
                      <Option value="Homme"><IntlMessages id="projet.genre.femme" /></Option>
                      <Option value="Les deux"><IntlMessages id="projet.genre.lesDeux" /></Option>
                    </Select>
                  )}
                </FormItem>
              </Col>
              <Col xl={12} lg={12} md={12} sm={12} xs={24} style={{paddingLeft:"0",paddingRight:"0"}}>
                <FormItem {...formItemLayout}>
                  {getFieldDecorator('langues', {
                    rules: [{ required: true, message: <IntlMessages id="input.validation.obligatoire" /> }],
                  })(
                    getLangueOptions({placeholder:intl.formatMessage({id: "projet.langues.placeholder"}), multiple:true})
                  )}
                </FormItem>
              </Col>
            </Row>

            <FormItem {...formItemLayout} >
              <IntlMessages id="projet.description.label" /> 
              {getFieldDecorator('description', {
                  rules: [{ required: true, message: <IntlMessages id="input.validation.obligatoire" />, whitespace: true }],
                  initialValue: '',
              })(
                <TextArea  
                  //maxLength={maxNbPresCharacters}
                  multiline="true"
                  rows={6}
                  placeholder={intl.formatMessage({id: "projet.description.placeholder"})}
                />
              )}
            </FormItem>

            <FormItem {...formItemLayout}>
              {getFieldDecorator('lieuEnregistrement', {
                rules: [{ required: false, message: <IntlMessages id="input.validation.obligatoire" /> }],
                initialValue: '',
              })(
                <Input 
                  type="text" 
                  placeholder={intl.formatMessage({id: "projet.lieuEnregistrement.placeholder"})}
                />
              )}
            </FormItem>

            <FormItem {...formItemLayout}>
              {getFieldDecorator('pasStudioEnregistrement', {
                valuePropName: 'checked',
                initialValue: false,
              })(
                <Checkbox>
                  <IntlMessages id="projet.pasStudioEnregistrement.label" />
                </Checkbox>
              )}
            </FormItem>

            <FormItem {...formItemLayout}>
              <div className="cm-modal-projet-file-container">
                <input  style={{ opacity: "0",display:"none" }} 
                  type="file" 
                  id="file" 
                  name="file"
                  title="Joindre un fichier" 
                  //accept=".m" 
                  onChange={this.onFileChange} 
                />
                <div className="cm-modal-projet-file-name">
                  <span style={{ paddingRight: "12px" }}>
                    {this.state.selectedFileName}
                  </span>
                </div>
                <div className="cm-modal-projet-file-button">
                  <label for="file">
                    <IntlMessages id="projet.fichier.button" />
                  </label>
                </div>
              </div>
            </FormItem>

            <FormItem {...formItemLayout}>
              <Button 
                type="primary"
                key="submit"
                htmlType="submit"
                form='projetForm'    
                style={{ width: "100%", margin: "0"}} 
              >
                <IntlMessages id="projet.submitButton" />
              </Button>
            </FormItem>
              
          </Form>

        </Modal>

        <Auth
          visible={this.state.showModalAuth}
          vue={this.state.vueAuth}
          onCancel={this.onHideModalAuth}
          onSignInSuccess={this.onSignInSuccess}
          onSignUpSuccess={this.onSignUpSuccess}
          signUpButtonText={<IntlMessages id="signUp.continuer.button" />}
        />
      </div>
    )
  }

}

const RegisterFormProjet = Form.create()(Projet);

const mapStateToProps = ({ common, auth }) => {
  const { 
    statusProjet 
  } = common;

  const{
    dataSignUp
  } = auth;

  return {
    statusProjet,
    dataSignUp
  }
};

export default injectIntl(
  connect(
    mapStateToProps,
    {
      projetAction,
      setStatusProjetAction
    }
  )(RegisterFormProjet));
