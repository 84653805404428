import React, { Component } from "react";
import { connect } from "react-redux";
import { Drawer, Layout } from "antd";

import SidebarContent from "./SidebarContent";
import { toggleCollapsedSideNav, updateWindowWidth } from "appRedux/actions/Setting";
import {
  TAB_SIZE,
  THEME_TYPE_LITE
} from "../../constants/ThemeSetting";

const { Sider } = Layout;

export class Sidebar extends Component {

  onToggleCollapsedNav = () => {
    this.props.toggleCollapsedSideNav(!this.props.navCollapsed);
  };

  componentDidMount() {
    window.addEventListener('resize', () => {
      this.props.updateWindowWidth(window.innerWidth)
    });
  }

  render() {
    const { themeType, navCollapsed, width } = this.props;

    let drawerStyle = "";
    if (width < TAB_SIZE) {
      drawerStyle = "gx-collapsed-sidebar"
    }

    return (
      <Sider
        className={`gx-app-sidebar ${drawerStyle} ${themeType !== THEME_TYPE_LITE ? 'gx-layout-sider-dark' : null}`}
        trigger={null}
        collapsed={(width < TAB_SIZE ? false : false)}
        theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
        collapsible>
        {
          width < TAB_SIZE ?
            <Drawer
              className={`gx-drawer-sidebar ${themeType !== THEME_TYPE_LITE ? 'gx-drawer-sidebar-dark' : null}`}
              placement="left"
              closable={false}
              onClose={this.onToggleCollapsedNav.bind(this)}
              visible={navCollapsed}>
              <SidebarContent page={this.props.page || ''} />
            </Drawer> :
            <SidebarContent page={this.props.page || ''} />
        }
      </Sider>)
  }
}

const mapStateToProps = ({ settings }) => {
  const {
    themeType,
    navCollapsed,
    width,
    locale
  } = settings;

  return {
    themeType,
    navCollapsed,
    width,
    locale
  }
};
export default connect(mapStateToProps, { toggleCollapsedSideNav, updateWindowWidth })(Sidebar);
