import React, { Component } from "react";
import { connect } from "react-redux";
import { Menu } from "antd";
import { NavLink } from "react-router-dom";
import IntlMessages from "util/IntlMessages";
import { injectIntl } from 'react-intl';
import { getInfosUserAction } from "appRedux/actions/Auth";
import { withRouter } from "util/withRouter";

class MenuUser extends Component {
  constructor() {
    super();
    this.state = {
      dataInfosUser: {},
    }
  }

  render() {

    const { pathname } = this.props;
    const selectedKeys = pathname.substr(1);
    const defaultOpenKeys = selectedKeys.split('/')[1];

    return (
      <div>

        <div className={'cm-side-bar-menu-separator'}></div>

        <Menu
          defaultOpenKeys={[defaultOpenKeys]}
          selectedKeys={[selectedKeys]}
          theme='dark'
          mode="inline"
        >

          <Menu.Item key="User/MonCompte">
            <NavLink to="/User/MonCompte" className={({ isActive }) => (isActive ? 'ant-menu-item-selected' : '')}>
              <IntlMessages id="menu.user.MonCompte" />
            </NavLink>
          </Menu.Item>

          <Menu.Item key="User/MesPlaylists">
            <NavLink to="/User/MesPlaylists" className={({ isActive }) => (isActive ? 'ant-menu-item-selected' : '')}>
              <IntlMessages id="menu.user.mesPlaylists" />
            </NavLink>
          </Menu.Item>

          <Menu.Item key="User/AcceConfidentialite">
            <NavLink to="/User/AcceConfidentialite" className={({ isActive }) => (isActive ? 'ant-menu-item-selected' : '')}>
              <IntlMessages id="menu.user.accesConfidentialite" />
            </NavLink>
          </Menu.Item>

          {/* <Menu.Item key="User/HistoriquePartage">
              <NavLink to="/User/HistoriquePartage" className={({ isActive }) => (isActive ? 'ant-menu-item-selected' : '')}>
                <IntlMessages id="menu.user.historiquePartage" />
              </NavLink>
            </Menu.Item> */}

          <Menu.Item key="User/messages">
            <NavLink to="/User/messages" className={({ isActive }) => (isActive ? 'ant-menu-item-selected' : '')}>
              <IntlMessages id="menu.comedien.messages" />
            </NavLink>
          </Menu.Item>

        </Menu>
      </div>
    );
  }

}

const mapStateToProps = ({ settings, auth }) => {
  const {
    pathname
  } = settings;

  const {
    dataInfosUser
  } = auth;

  return {
    pathname,
    dataInfosUser
  }
};


export default withRouter(injectIntl(connect(
  mapStateToProps,
  {
    getInfosUserAction,
  })(MenuUser)));