// Customizer const
export const USER_ROLE_ADMIN = 1;
export const USER_ROLE_UTILISATEUR = 2;
export const USER_ROLE_COMEDIEN = 3;
export const USER_ROLE_ANONYMOUS = 4;

export const EXTRAIT_MP3_MASQUE = 1;
export const EXTRAIT_MP3_VISIBLE_FICHE = 2;
export const EXTRAIT_MP3_VISIBLE_RECHERCHE_FICHE = 3;

export const EXTRAIT_VIDEO_MASQUE = 1;
export const EXTRAIT_VIDEO_VISIBLE_FICHE = 2;
export const EXTRAIT_VIDEO_VISIBILITE_DEFAULT = EXTRAIT_VIDEO_VISIBLE_FICHE;

export const ALGOLIA_INDEX_AGE_BEBE = 1;
export const ALGOLIA_INDEX_AGE_ENFANT = 2;
export const ALGOLIA_INDEX_AGE_ADO = 3;
export const ALGOLIA_INDEX_AGE_JEUNE = 4;
export const ALGOLIA_INDEX_AGE_ADULTE = 5;
export const ALGOLIA_INDEX_AGE_SENIOR = 6;

export const ALGOLIA_INDEX_TONE_GRAVE = 1;
export const ALGOLIA_INDEX_TONE_MEDIUM_GRAVE = 2;
export const ALGOLIA_INDEX_TONE_MEDIUM = 3;
export const ALGOLIA_INDEX_TONE_MEDIUM_AIGU = 4;
export const ALGOLIA_INDEX_TONE_AIGU = 5;

export const COMEDIEN_TELEPHONE_PRIVE = 1;
export const COMEDIEN_TELEPHONE_VISIBLE = 2;

export const STATUT_INSCRIPTION_COMEDIEN_EN_ATTENTE = 'en-attente';
export const STATUT_INSCRIPTION_COMEDIEN_VALIDE = 'valide';
export const STATUT_INSCRIPTION_COMEDIEN_REFUSE = 'refuse';
export const STATUT_INSCRIPTION_COMEDIEN_ATTENTE_CHEQUE = 'attente-cheque';
export const STATUT_INSCRIPTION_COMEDIEN_PAYE = 'paye';

export const DEMANDE_PHOTO_XL_EN_ATTENTE = 'en-attente';
export const DEMANDE_PHOTO_XL_VALIDEE = 'valide';
export const DEMANDE_PHOTO_XL_REFUSEE = 'refuse';

export const STATUS_PAIEMENT_DEMANDE_INSCRIPTION_COMEDIEN_WAIT = 'STATUS_PAIEMENT_DEMANDE_INSCRIPTION_COMEDIEN_WAIT';
export const STATUS_PAIEMENT_DEMANDE_INSCRIPTION_COMEDIEN_SUCCESS = 'STATUS_PAIEMENT_DEMANDE_INSCRIPTION_COMEDIEN_SUCCESS';
export const STATUS_PAIEMENT_DEMANDE_INSCRIPTION_COMEDIEN_ERROR = 'STATUS_PAIEMENT_DEMANDE_INSCRIPTION_COMEDIEN_ERROR';

export const STATUS_INSCRIPTION_COMEDIEN_INIT = 'STATUS_INSCRIPTION_COMEDIEN_INIT';
export const STATUS_INSCRIPTION_COMEDIEN_SUCCESS = 'STATUS_INSCRIPTION_COMEDIEN_SUCCESS';
export const STATUS_INSCRIPTION_COMEDIEN_ERROR = 'STATUS_INSCRIPTION_COMEDIEN_ERROR';

export const STATUS_INSCRIPTION_COMEDIEN_CHEQUE_INIT = 'STATUS_INSCRIPTION_COMEDIEN_CHEQUE_INIT';
export const STATUS_INSCRIPTION_COMEDIEN_CHEQUE_SUCCESS = 'STATUS_INSCRIPTION_COMEDIEN_CHEQUE_SUCCESS';
export const STATUS_INSCRIPTION_COMEDIEN_CHEQUE_ERROR = 'STATUS_INSCRIPTION_COMEDIEN_CHEQUE_ERROR';

export const STATUS_RE_INSCRIPTION_COMEDIEN_INIT = 'STATUS_RE_INSCRIPTION_COMEDIEN_INIT';
export const STATUS_RE_INSCRIPTION_COMEDIEN_SUCCESS = 'STATUS_RE_INSCRIPTION_COMEDIEN_SUCCESS';
export const STATUS_RE_INSCRIPTION_COMEDIEN_ERROR = 'STATUS_RE_INSCRIPTION_COMEDIEN_ERROR';

export const STATUS_RE_INSCRIPTION_COMEDIEN_CHEQUE_INIT = 'STATUS_RE_INSCRIPTION_COMEDIEN_CHEQUE_INIT';
export const STATUS_RE_INSCRIPTION_COMEDIEN_CHEQUE_SUCCESS = 'STATUS_RE_INSCRIPTION_COMEDIEN_CHEQUE_SUCCESS';
export const STATUS_RE_INSCRIPTION_COMEDIEN_CHEQUE_ERROR = 'STATUS_RE_INSCRIPTION_COMEDIEN_CHEQUE_ERROR';

export const STATUS_REQUEST_RESET_PASSWORD_INIT = 'STATUS_REQUEST_RESET_PASSWORD_INIT';
export const STATUS_REQUEST_RESET_PASSWORD_SUCCESS = 'STATUS_REQUEST_RESET_PASSWORD_SUCCESS';
export const STATUS_REQUEST_RESET_PASSWORD_ERROR = 'STATUS_REQUEST_RESET_PASSWORD_ERROR';

export const STATUS_RESET_PASSWORD_INIT = 'STATUS_RESET_PASSWORD_INIT';
export const STATUS_RESET_PASSWORD_SUCCESS = 'STATUS_RESET_PASSWORD_SUCCESS';
export const STATUS_RESET_PASSWORD_ERROR = 'STATUS_RESET_PASSWORD_ERROR';

export const STATUS_UPDATE_AUTH_INIT = 'STATUS_UPDATE_AUTH_INIT';
export const STATUS_UPDATE_AUTH_SUCCESS = 'STATUS_UPDATE_AUTH_SUCCESS';
export const STATUS_UPDATE_AUTH_ERROR = 'STATUS_UPDATE_AUTH_ERROR';
export const STATUS_UPDATE_AUTH_CURRENT_PASSWORD_INCORRECT = 'STATUS_UPDATE_AUTH_CURRENT_PASSWORD_INCORRECT';

export const STATUS_UPDATE_PASSWORD_INIT = 'STATUS_UPDATE_PASSWORD_INIT';
export const STATUS_UPDATE_PASSWORD_SUCCESS = 'STATUS_UPDATE_PASSWORD_SUCCESS';
export const STATUS_UPDATE_PASSWORD_ERROR = 'STATUS_UPDATE_PASSWORD_ERROR';
export const STATUS_UPDATE_PASSWORD_CURRENT_PASSWORD_INCORRECT = 'STATUS_UPDATE_PASSWORD_CURRENT_PASSWORD_INCORRECT';

export const CMS_ELEMENTS = {
  CGU: 'CGU',
  RGPD: 'RGPD'
}

export const STATUT_ABONNEMENT_EN_ATTENTE = 'en-attente';
export const STATUT_ABONNEMENT_CONFIRME = 'confirme';
export const STATUT_ABONNEMENT_REFUSE = 'refuse';
export const STATUT_ABONNEMENT_ANNULE = 'annule';
export const STATUT_ABONNEMENT_EN_ATTENTE_CHEQUE = 'en-attente-cheque';

export const STATUT_SONNEZ_ALFRED_EN_ATTENTE = 'en-attente';
export const STATUT_SONNEZ_ALFRED_SUCCES = 'succes';
export const STATUT_SONNEZ_ALFRED_ERREUR = 'erreur';

export const STATUT_VOIX_ETRANGERES_EN_ATTENTE = 'en-attente';
export const STATUT_VOIX_ETRANGERES_SUCCES = 'succes';
export const STATUT_VOIX_ETRANGERES_ERREUR = 'erreur';

export const STATUT_PROJET_EN_ATTENTE = 'en-attente';
export const STATUT_PROJET_SUCCES = 'succes';
export const STATUT_PROJET_ERREUR = 'erreur';

export const STATUS_DEMANDE_CONTACT_COMEDIEN_INIT = 'STATUS_DEMANDE_CONTACT_COMEDIEN_INIT'
export const STATUS_DEMANDE_CONTACT_COMEDIEN_SUCCESS = 'STATUS_DEMANDE_CONTACT_COMEDIEN_SUCCESS'
export const STATUS_DEMANDE_CONTACT_COMEDIEN_ERROR = 'STATUS_DEMANDE_CONTACT_COMEDIEN_ERROR'
export const STATUS_DEMANDE_CONTACT_COMEDIEN_ERROR_MAX_EMAILS_REACHED = 'STATUS_DEMANDE_CONTACT_COMEDIEN_ERROR_MAX_EMAILS_REACHED'

//API STATUS
export const MAX_NEWS_PER_YEAR_REACHED = 'MAX_NEWS_PER_YEAR_REACHED';
export const MAX_MP3_VSIBLE_RECHERCHE_REACHED = 'MAX_MP3_VSIBLE_RECHERCHE_REACHED';
export const SIGNUP_ERROR_EMAIL_EXIST = 'SIGNUP_ERROR_EMAIL_EXIST';
export const SIGNUP_ERROR_COMPTE_FACEBOOK_EXIST = 'SIGNUP_ERROR_COMPTE_FACEBOOK_EXIST';
export const SIGNIN_ERROR_ACCOUNT_EXPIRED = "SIGNIN_ERROR_ACCOUNT_EXPIRED";
export const AUTH_UPDATE_CURRENT_PASSWORD_INCORRECT = "AUTH_UPDATE_CURRENT_PASSWORD_INCORRECT";
export const PASSWORD_UPDATE_CURRENT_PASSWORD_INCORRECT = "PASSWORD_UPDATE_CURRENT_PASSWORD_INCORRECT";
export const MAX_EMAILS_DEMANDE_CONTACT_REACHED = "MAX_EMAILS_DEMANDE_CONTACT_REACHED";
//Messages
export const MESSAGE_TYPE_SMS = 'sms';
export const MESSAGE_TYPE_EMAIL = 'email';
export const MESSAGE_SUJET_DEMANDE_CONTACT = 'demande-contact';
export const MESSAGE_SUJET_DEMANDE_ACCES_TELEPHONE = 'demande-acces-telephone';
export const MESSAGE_SUJET_DEMANDE_ACCES_INFOS_EMPLOYEUR = 'demande-acces-infos-employeur';

export const ONE_DAY_MS = 1000 * 60 * 60 * 24;