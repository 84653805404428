import React, { Component } from "react";
import { connect } from "react-redux";
import SearchForm from "components/SearchForm";
import {APPLICATION_PATHS} from "constants/ApplicationPaths";

class SearchFormMobile extends Component {

  render () {
    if(this.props.showMobileSearch){
      if(window.location.pathname !== APPLICATION_PATHS.HOME){
        return (
          <div className="cm-search-container-mobile">
            <SearchForm 
              displayOn="MOBILE"
            />
          </div>
        )
      }else{
        return <div></div>;
      }
    }else{
      return <div></div>;
    }
  };
}

const mapStateToProps = ({ common }) => {
  const { 
    showMobileSearch, 
  } = common;

  return { 
    showMobileSearch, 
  }
};

export default
  connect(
    mapStateToProps, 
    { 
    }
  )(SearchFormMobile);
