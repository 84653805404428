
import React from "react";
import IntlMessages from "util/IntlMessages";
import { connect } from "react-redux";
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Checkbox, Input, Button, Divider, Alert } from "antd";
import { withRouter } from "util/withRouter";
import { injectIntl } from 'react-intl';
import { APPLICATION_PATHS } from "constants/ApplicationPaths";
import logoCastingMachine from "assets/images/logo-casting-machine.webp";
import Parser from 'html-react-parser';
import ReCAPTCHA from "react-google-recaptcha";
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { facebook, google } from 'util/config';
import {
  signUpAction,
} from "appRedux/actions/Auth";

import {
  SIGNUP_STATUS_INIT,
  SIGNUP_STATUS_SUCCESS,
  SIGNUP_STATUS_FAIL,
  SIGNUP_STATUS_FAIL_EMAIL_EXIST,
  SIGNUP_STATUS_FAIL_COMPTE_FACEBOOK_EXIST,
} from "constants/ActionTypes";

const FormItem = Form.Item;

class SignUp extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      statusSignUp: SIGNUP_STATUS_INIT,
      captcha: null,
      showCaptchaValidationError: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.statusSignUp !== this.props.statusSignUp) {
      if (this.props.statusSignUp === SIGNUP_STATUS_SUCCESS) {
        this.props.onSignUpSuccess();
      }
      this.setState({ statusSignUp: this.props.statusSignUp });
    }
  }

  renderSignUpSuccess = () => {
    return (
      <div style={{ textAlign: "center", paddingBottom: "20px", paddingTop: "20px" }}>
        <img src={logoCastingMachine} style={{ width: "180px" }} />
        <h1 style={{ fontSize: "32px", marginTop: "30px", marginBottom: "30px" }}>Inscription réussie !</h1>
        <p>
          Vous allez recevoir un email de validation pour finaliser votre inscription. Rendez vous sur votre email.
        </p>
      </div>
    );
  }

  handleSubmit = (e) => {
    e.preventDefault();

    if (!this.state.captcha) {
      this.setState({ showCaptchaValidationError: true })
    } else {
      this.props.form.validateFields((err, values) => {
        if (!err) {
          var data = {
            sourceInscription: 'form',
            prenom: values.prenom,
            nom: values.nom,
            infoSociete: values.infoSociete,
            email: values.email,
            password: values.password,
          };

          this.props.signUpAction(data);
        }
      });
    }
  };

  onChangeCaptcha = (value) => {
    this.setState({
      captcha: value,
      showCaptchaValidationError: false,
    });
  }

  responseFacebook = (response) => {

    if (response.accessToken && response.signedRequest && response.id) {
      var data = {
        sourceInscription: 'facebook',
        prenom: response.first_name,
        nom: response.last_name,
        infoSociete: '',
        email: response.email,
        password: '',
        idUserFacebook: response.id,
        avatarFacebook: response.picture ? (response.picture.data ? response.picture.data.url : '') : ''
      };
      this.props.signUpAction(data);
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    var intl = this.props.intl;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 24 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 24 },
      },
    };

    return (
      <div className="cm-auth-signup">
        <div className="cm-auth-signup-title">
          <IntlMessages id="signUp.title" />
        </div>
        <div className="cm-auth-signup-title-mobile">
          {Parser(intl.formatMessage({ id: "signUp.title.mobile" }))}
        </div>

        <div style={{ textAlign: "center", margin: "10px 0px 10px 0px" }}>
          <IntlMessages id="signUp.introduction" />
        </div>

        <div className="cm-auth-signup-links">
          <IntlMessages id="signUp.signInlink1" />&nbsp;
            <span className="cm-auth-link" onClick={this.props.onShowSignIn}><IntlMessages id="signUp.signInlink2" /></span>
        </div>

        <FacebookLogin
          appId={facebook.loginAppId}
          autoLoad={false}
          fields="name,email,picture,first_name,last_name"
          callback={this.responseFacebook}
          render={renderProps => (
            <button onClick={renderProps.onClick} className="cm-auth-button-facebook gx-pointer">
              <i className="fa-brands fa-facebook-f fa-fw" style={{ fontSize: "14px", display: "flex" }} />
              <span style={{ paddingLeft: "0px" }}>
                <IntlMessages id="signIn.button.facebook" />
              </span>
            </button>
          )}
        />

        <Divider><IntlMessages id="signUp.ou" /></Divider>

        {this.state.statusSignUp === SIGNUP_STATUS_FAIL ?
          <div style={{ marginBottom: "10px", textAlign: "center" }}>
            <Alert message={<IntlMessages id="signUp.error" />} type="error" />
          </div>
          : null}

        {this.state.statusSignUp === SIGNUP_STATUS_FAIL_EMAIL_EXIST ?
          <div style={{ marginBottom: "10px", textAlign: "center" }}>
            <Alert message={<IntlMessages id="signUp.error.emailExist" />} type="error" />
          </div>
          : null}

        {this.state.statusSignUp === SIGNUP_STATUS_FAIL_COMPTE_FACEBOOK_EXIST ?
          <div style={{ marginBottom: "10px", textAlign: "center" }}>
            <Alert message={<IntlMessages id="signUp.error.compteFacebookExist" />} type="error" />
          </div>
          : null}



        <div className="cm-auth-form">
          <Form onSubmit={this.handleSubmit} id="signUp" name="signUp">

            <FormItem {...formItemLayout}>
              {getFieldDecorator('prenom', {
                rules: [{
                  required: true,
                  message: <IntlMessages id="input.validation.obligatoire" />
                }],
              })(
                <Input placeholder={intl.formatMessage({ id: "signUp.prenom.placeholder" })} />
              )}
            </FormItem>

            <FormItem {...formItemLayout}>
              {getFieldDecorator('nom', {
                rules: [{
                  required: true,
                  message: <IntlMessages id="input.validation.obligatoire" />
                }],
              })(
                <Input placeholder={intl.formatMessage({ id: "signUp.nom.placeholder" })} />
              )}
            </FormItem>

            <FormItem {...formItemLayout}>
              {getFieldDecorator('infoSociete', {
                rules: [{
                  required: false,
                }],
              })(
                <Input placeholder={intl.formatMessage({ id: "signUp.societe.placeholder" })} />
              )}
            </FormItem>

            <FormItem {...formItemLayout}>
              {getFieldDecorator('email', {
                rules: [{
                  required: true,
                  type: 'email', message: <IntlMessages id="input.validation.obligatoire" />,
                }],
              })(
                <Input placeholder={intl.formatMessage({ id: "signUp.email.placeholder" })} />
              )}
            </FormItem>

            <FormItem {...formItemLayout}>
              {getFieldDecorator('password', {
                rules: [{
                  required: true,
                  message: <IntlMessages id="input.validation.obligatoire" />
                }],
              })(
                <Input type="password" placeholder={intl.formatMessage({ id: "signUp.password.placeholder" })} />
              )}
            </FormItem>

            <FormItem {...formItemLayout}>
              {getFieldDecorator('cgu', {
                rules: [{
                  required: true,
                  transform: value => (value || undefined),  // Those two lines
                  type: 'boolean',                           // Do the magic
                  message: <IntlMessages id="signUp.cgu.obligatoire" />,
                }],
                valuePropName: 'checked',
                initialValue: false,
              })(
                <div style={{ display: "flex", lineHeight: "24px" }}>
                  <div><Checkbox></Checkbox></div>
                  <div className="cm-auth-signup-cgu">
                    <IntlMessages id="signUp.conditions1" />&nbsp;
                      <a href={APPLICATION_PATHS.CGU} target="_blank">
                      <span className="cm-auth-link"><IntlMessages id="signUp.conditions2" /></span>
                    </a>
                  </div>
                </div>
              )}
            </FormItem>

            <FormItem {...formItemLayout}>
              <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
                <ReCAPTCHA
                  sitekey={google.captchaSiteKey}
                  onChange={this.onChangeCaptcha}
                />
                {this.state.showCaptchaValidationError ?
                  <div className="has-error" style={{ marginTop: "20px" }}>
                    <div className="ant-form-explain">
                      <span style={{ fontWeight: "normal" }}>
                        <IntlMessages id="signUp.captcha.validationError" />
                      </span>
                    </div>
                  </div>
                  : null}
              </div>
            </FormItem>

            <FormItem {...formItemLayout}>
              <Button
                type="primary"
                key="submit"
                htmlType="submit"
                form='signUp'
                style={{ width: "100%", margin: "0", height: "48px" }}
              >
                {this.props.signUpButtonText || <IntlMessages id="signUp.button" />}
              </Button>
            </FormItem>

          </Form>
        </div>
      </div>
    );
  }
}

const SignUpForm = Form.create()(SignUp);

const mapStateToProps = ({ auth }) => {
  const {
    statusSignUp,
  } = auth;

  return {
    statusSignUp
  }
};

export default withRouter(
  injectIntl(
    connect(
      mapStateToProps,
      {
        signUpAction,
      }
    )(SignUpForm)
  )
);