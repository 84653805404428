import axios from "axios";
import { endpointEquipement } from 'util/api'

/**
 * R�cup�ration de l'�quipement d'un com�dien
 * @param {*} payload 
 */
export const getEquipementRequest = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  let { idUser } = payload;

  // call WS Loopback
  const wsResponse = await axios.request({
    method: 'get',
    url: `${endpointEquipement.GET_EQUIPEMENT}?access_token=${accessToken}`,
      params:{
        idUser
      }
  });

  return wsResponse;
}

/**
 * Mise � jour d'un �quipement
 * @param {*} payload 
 */
export const updateEquipementRequest = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';
  const formData = new FormData();
  
  const { idUser, values, image } = payload;

  formData.append("idUser", idUser);
  formData.append("micro", values.micro);
  formData.append("preampliMicro", values.preampliMicro);
  formData.append("carteSon", values.carteSon);
  formData.append("stationLogiciel", values.stationLogiciel);
  formData.append("logiciel", values.logiciel);
  formData.append("description", values.description);
  formData.append("file", image);
  formData.append("nomPhoto", values.nomPhoto);

  const wsResponse = await axios.request({
    method: 'post',
    url: `${endpointEquipement.UPDATE_EQUIPEMENT}?access_token=${accessToken}`,
    data: formData,
    config: {
      headers: {
        'Content-Type': `multipart/form-data`,
        'Accept': 'application/json',
      }
    }
  });
  
  return wsResponse;
}