import axios from "axios";
import { endpointCV } from 'util/api';

/**
 * R�cup�ration de CV
 * @param {*} payload 
 */
export const getCVRequest = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  let { idUser } = payload;

  // call WS Loopback
  const wsResponse = await axios.request({
    method: 'get',
    url: `${endpointCV.GET_CV}?access_token=${accessToken}`,
    params: {
      idUser
    }
  });

  return wsResponse;
}

/**
 * Modificatio de CV
 * @param {*} payload 
 */
export const updateCVRequest = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  const formData = new FormData();
  const { idUser, values, competences, infosComplementaires, experiences, formation } = payload;

  formData.append('idUser', idUser);
  formData.append('langueNatale', (values.langueNatale ? values.langueNatale.join() : ''));
  formData.append('competences', competences);
  formData.append('infosComplementaires', infosComplementaires);
  formData.append('experiences', experiences);
  formData.append('formation', formation);

  const wsResponse = await axios.request({
    method: 'post',
    url: `${endpointCV.UPDATE_CV}?access_token=${accessToken}`,
    data: formData,
    config: {
      headers: {
        'Content-Type': `multipart/form-data`,
        'Accept': 'application/json',
      }
    }
  });

  return wsResponse;
}