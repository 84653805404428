module.exports = {
  footerText: 'Copyright Casting Machine © 2021',
  frontBaseUrl:'https://castingmachine.com',
  serverBaseUrl:'https://api.castingmachine.com',
  server: 'https://api.castingmachine.com/api',
  algolia:{ 
    applicationID:'OYKQJYHGCO',
    adminAPIKey:'782c61e64b0de3c05bab9f821a42136d',
    indexName:'castingmachine-prod',
    indexAutoCompleteName:'castingmachine-prod-autocomplete',
    searchableAttributes:['name','tag1','tag2','tag3','type','description','title','language','gender','tone'],
  },
  facebook:{
    loginAppId:'970633426690898'
  },
  google:{
    captchaSiteKey:'6Lf-n6oZAAAAAB8lbcGPd7bd6Fr-W579xmQDY-DS'
  },
  campagneComDisplayCycleDays: 7
}
