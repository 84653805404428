import React, { Component } from "react";
import { connect } from "react-redux";
import { Menu } from "antd";
import { Link } from "react-router-dom";
import CustomScrollbars from "util/CustomScrollbars";
import Auxiliary from "util/Auxiliary";
import Avatar from "components/Avatar";
import IntlMessages from "util/IntlMessages";
import { injectIntl } from 'react-intl';
import Projet from "components/Projet";
import { getCurrentUser } from 'util/Auth'
import MenuAdmin from '../Menus/MenuAdmin';
import MenuUser from '../Menus/MenuUser';
import MenuComedien from '../Menus/MenuComedien';

import {
  USER_ROLE_COMEDIEN,
  USER_ROLE_UTILISATEUR,
  USER_ROLE_ADMIN,
  USER_ROLE_ANONYMOUS,
} from "constants/General";

import noAvatar from "assets/images/no-avatar.webp";
import { getInfosUserAction } from "appRedux/actions/Auth";
import { APPLICATION_PATHS } from "constants/ApplicationPaths";

import { getCurrentUserRole } from 'util/Auth';
import { withRouter } from "util/withRouter";

import {
  signOutAction
} from "appRedux/actions/Auth";

const SubMenu = Menu.SubMenu;

class MobileMenuContent extends Component {
  constructor() {
    super();
    this.state = {
      dataInfosUser: {},
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.dataInfosUser !== prevState.dataInfosUser && nextProps.dataInfosUser) {
      return { dataInfosUser: nextProps.dataInfosUser };
    } else return null;
  }

  componentDidMount() {
    this.props.getInfosUserAction();
    this.userRole = getCurrentUserRole();
  }

  signOut = () => {
    this.props.signOutAction();
  };

  renderMainMenu = () => {
    const { pathname } = this.props;
    const selectedKeys = pathname.substr(1);
    const defaultOpenKeys = selectedKeys.split('/')[1];

    var currentUser = getCurrentUser();

    return (
      <div className="cm-mobile-menu-main">
        <Menu
          defaultOpenKeys={[defaultOpenKeys]}
          selectedKeys={[selectedKeys]}
          theme='dark'
          mode="inline"
        >
          <Menu.Item key={APPLICATION_PATHS.HOME}>
            <Link to={APPLICATION_PATHS.HOME}>
              <div className="cm-mobile-menu-main-item">
                <div className="cm-mobile-menu-main-item-title">
                  <IntlMessages id="menu.mobile.home" />
                </div>
                <div className="cm-mobile-menu-main-item-icon">
                  <i className="fas fa-home"></i>
                </div>
              </div>
            </Link>
          </Menu.Item>


          <Menu.Item key='projet'>
            <span onClick={this.props.onDisplayModalProjet}>
              <div className="cm-mobile-menu-main-item">
                <div className="cm-mobile-menu-main-item-title">
                  <IntlMessages id="menu.mobile.projet" />
                </div>
                <div className="cm-mobile-menu-main-item-icon">
                  <i className="fas fa-briefcase"></i>
                </div>
              </div>
            </span>
          </Menu.Item>

          <Menu.Item key={APPLICATION_PATHS.DECOUVERTE}>
            <Link to={APPLICATION_PATHS.DECOUVERTE}>
              <div className="cm-mobile-menu-main-item">
                <div className="cm-mobile-menu-main-item-title">
                  <IntlMessages id="menu.mobile.decouverte" />
                </div>
                <div className="cm-mobile-menu-main-item-icon">
                  <i className="fa-regular fa-compass" style={{ marginRight: "0px" }}></i>
                </div>
              </div>
            </Link>
          </Menu.Item>

          {/*currentUser ?
            <Menu.Item key='playlists'>
              <span onClick={this.props.onShowPlaylists}>
                <div className="cm-mobile-menu-main-item">
                  <div className="cm-mobile-menu-main-item-title"> 
                    <IntlMessages id="menu.mobile.castings" />
                  </div>
                  <div className="cm-mobile-menu-main-item-icon">
                    <i className="fa-regular fa-heart" style={{marginRight:"0px"}}></i>
                  </div>
                </div>
              </span>
            </Menu.Item>
          : null*/}

          <Menu.Item key={APPLICATION_PATHS.AIDE}>
            <Link to={APPLICATION_PATHS.AIDE}>
              <div className="cm-mobile-menu-main-item">
                <div className="cm-mobile-menu-main-item-title">
                  <IntlMessages id="menu.mobile.aide" />
                </div>
                <div className="cm-mobile-menu-main-item-icon">
                  <i className="far fa-question-circle" style={{ fontSize: "20px" }}></i>
                </div>
              </div>
            </Link>
          </Menu.Item>

        </Menu>

        <Projet
          visible={this.state.showModalProjet}
          onCancel={this.onCancelModalProjet}
        />

      </div>
    )
  }


  renderMenuAnonymousUser = () => {
    const { pathname } = this.props;
    const selectedKeys = pathname.substr(1);
    const defaultOpenKeys = selectedKeys.split('/')[1];

    return (
      <div className="cm-mobile-menu-anonymous">

        <CustomScrollbars className="cm-mobile-menu-scrollbar">
          <Menu
            defaultOpenKeys={[defaultOpenKeys]}
            selectedKeys={[selectedKeys]}
            theme='dark'
            mode="inline"
          >

            <Menu.Item key='signUp'>
              <span onClick={this.props.onSignUp}>
                <IntlMessages id="menu.mobile.signUp" />
              </span>
            </Menu.Item>

            <Menu.Item key='signIn'>
              <span onClick={this.props.onSignIn}>
                <IntlMessages id="menu.mobile.signIn" />
              </span>
            </Menu.Item>

          </Menu>
        </CustomScrollbars>
      </div>
    )
  }

  renderMenuConnectedUser = () => {

    let userAvatar = ""
    if (this.state.dataInfosUser.photo && (this.state.dataInfosUser.photo !== '')) {
      userAvatar = this.state.dataInfosUser.photo;
    } else {
      userAvatar = noAvatar;
    }

    return (
      <Auxiliary>
        <div className="cm-mobile-menu-avatar">
          <Avatar
            src={userAvatar}
            width="96px"
            height="96px"
          />
        </div>

        <div className="cm-mobile-menu-name">
          {this.state.dataInfosUser.prenom + ' ' + this.state.dataInfosUser.nom}
        </div>

        <CustomScrollbars className="cm-mobile-menu-scrollbar">

          {Number(this.userRole) === USER_ROLE_COMEDIEN ?
            <MenuComedien />
            :
            null
          }

          {Number(this.userRole) === USER_ROLE_UTILISATEUR ?
            <MenuUser />
            :
            null
          }

          {Number(this.userRole) === USER_ROLE_ADMIN ?
            <MenuAdmin />
            :
            null
          }

          {Number(this.userRole) !== USER_ROLE_ANONYMOUS ?
            <div className="cm-mobile-menu-auth-action">
              <span onClick={this.signOut}>
                <IntlMessages id="menu.mobile.signOut" />
              </span>
            </div>
            :
            null
          }

          <div style={{marginTop:"50px"}}></div>

        </CustomScrollbars>

      </Auxiliary>
    );
  }

  render() {
    var currentUser = getCurrentUser();

    return (
      <div className="cm-mobile-menu-content">

        {this.renderMainMenu()}

        {currentUser ?
          <div>
            {this.renderMenuConnectedUser()}
          </div>
          :
          <div>
            {this.renderMenuAnonymousUser()}
          </div>
        }

      </div>
    );
  }
}

MobileMenuContent.propTypes = {};

const mapStateToProps = ({ settings, auth }) => {
  const {
    navStyle,
    themeType,
    locale,
    pathname
  } = settings;

  const {
    dataInfosUser
  } = auth;

  return {
    navStyle,
    themeType,
    locale,
    pathname,
    dataInfosUser
  }
};


export default withRouter(injectIntl(connect(
  mapStateToProps,
  {
    getInfosUserAction,
    signOutAction
  })(MobileMenuContent)));