import React from "react";
import IntlMessages from "util/IntlMessages";
import { connect } from "react-redux";
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Button, Divider, Alert } from "antd";
import { withRouter } from "util/withRouter";
import { injectIntl } from 'react-intl';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { facebook } from 'util/config';
import Parser from 'html-react-parser';

import {
  signInAction,
} from "appRedux/actions/Auth";

import {
  SIGNIN_STATUS_INIT,
  SIGNIN_STATUS_SUCCESS,
  SIGNIN_STATUS_FAIL,
  SIGNIN_STATUS_ACCOUNT_EXPIRED,
} from "constants/ActionTypes";

import {
  showRequestLoaderAction,
  hideRequestLoaderAction
} from 'appRedux/actions/Common'

import ForgetPassword from "./ForgetPassword"

const FormItem = Form.Item;

class SignIn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showSignIn: true,
      statusSignIn: SIGNIN_STATUS_INIT,
      showForgetPassword: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.statusSignIn !== this.props.statusSignIn) {
      if (this.props.statusSignIn === SIGNIN_STATUS_SUCCESS) {
        this.props.onSignInSuccess();
      }
      this.setState({ statusSignIn: this.props.statusSignIn });
    }
  }

  //////////////////////////////////////////////////////////////
  // Sign In
  /////////////////////////////////////////////////////////////

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        var data = {
          sourceSignIn: 'form',
          email: values.email,
          password: values.password,
          idUserFacebook: '',
        };
        this.props.signInAction(data);
      }
    });
  };

  onShowForgetPassword = () => {
    this.setState({
      showSignIn: false,
      showForgetPassword: true,
    });
  };

  responseFacebook = (response) => {

    if (response.accessToken && response.signedRequest && response.id) {
      var data = {
        sourceSignIn: 'facebook',
        email: '',
        password: '',
        idUserFacebook: response.id,
      };
      this.props.signInAction(data);
    }
  }

  renderSignIn() {
    const { getFieldDecorator } = this.props.form;
    var intl = this.props.intl;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 24 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 24 },
      },
    };

    return (
      <div className="cm-auth-signin">

        <div className="cm-auth-signin-title">
          <IntlMessages id="signIn.title" />
        </div>

        <FacebookLogin
          appId={facebook.loginAppId}
          autoLoad={false}
          fields="name,email,picture,first_name,last_name"
          callback={this.responseFacebook}
          render={renderProps => (
            <button onClick={renderProps.onClick} className="cm-auth-button-facebook gx-pointer">
              <i className="fa-brands fa-facebook-f fa-fw" style={{ fontSize: "14px", display: "flex" }} />
              <span style={{ paddingLeft: "0px" }}>
                <IntlMessages id="signIn.button.facebook" />
              </span>
            </button>
          )}
        />

        <Divider><IntlMessages id="signIn.ou" /></Divider>

        {this.state.statusSignIn === SIGNIN_STATUS_FAIL ?
          <div style={{ marginBottom: "10px" }}>
            <Alert message={<IntlMessages id="signIn.error" />} type="error" />
          </div>
          : null}

        {this.state.statusSignIn === SIGNIN_STATUS_ACCOUNT_EXPIRED ?
          <div style={{ marginBottom: "10px" }}>
            <Alert message={Parser(intl.formatMessage({id: "signIn.accountExpired"}))} type="error" />
          </div>
          : null}

        <div className="cm-auth-form">
          <Form onSubmit={this.handleSubmit} id="signIn" name="signIn">
            <FormItem {...formItemLayout}>
              {getFieldDecorator('email', {
                initialValue: "",
                rules: [{ required: true, type: 'email', message: <IntlMessages id="input.validation.email" /> }],
              })(
                <Input placeholder={intl.formatMessage({ id: "signIn.email.placeholder" })} />
              )}
            </FormItem>

            <FormItem {...formItemLayout}>
              {getFieldDecorator('password', {
                initialValue: "",
                rules: [{ required: true, message: <IntlMessages id="input.validation.obligatoire" /> }],
              })(
                <Input type="password" placeholder={intl.formatMessage({ id: "signIn.password.placeholder" })} />
              )}
            </FormItem>

            <div style={{ textAlign: "right", marginBottom: "20px" }} >
              <span className="cm-auth-signin-forgot-link" onClick={this.onShowForgetPassword}>
                <IntlMessages id="signIn.forgetPassword" />
              </span>
            </div>

            <FormItem {...formItemLayout}>
              <Button
                type="primary"
                key="submit"
                htmlType="submit"
                form='signIn'
                style={{ width: "100%", margin: "0", height: "48px" }}
              >
                <IntlMessages id="signIn.button" />
              </Button>
            </FormItem>
          </Form>
        </div>

        <div className="cm-auth-signIn-links">
          <IntlMessages id="signIn.signUplink1" />&nbsp;
                  <span className="cm-auth-link" onClick={this.props.onShowSignUp}><IntlMessages id="signIn.signUplink2" /></span>
        </div>
      </div>
    )
  }

  //////////////////////////////////////////////////////////////
  // Forgot Password
  /////////////////////////////////////////////////////////////

  renderForgetPassword() {
    return (
      <ForgetPassword onForgotPasswordSuccess={this.props.onForgotPasswordSuccess} />
    )
  }

  render() {
    if (this.state.showSignIn) {
      return (this.renderSignIn());
    } else if (this.state.showForgetPassword) {
      return (this.renderForgetPassword());
    } else {
      return null;
    }
  }
}

const SignInForm = Form.create()(SignIn);

const mapStateToProps = ({ auth }) => {
  const {
    statusSignIn
  } = auth;

  return {
    statusSignIn
  }
};

export default withRouter(
  injectIntl(
    connect(
      mapStateToProps,
      {
        signInAction,
        showRequestLoaderAction,
        hideRequestLoaderAction
      }
    )(SignInForm)
  )
);
