import {
  COMMON_REQUEST_SONNEZ_ALFRED,
  COMMON_SET_STATUS_MODAL_SONNEZ_ALFRED,
  COMMON_REQUEST_VOIX_ETRANGERES,
  COMMON_SET_STATUS_MODAL_VOIX_ETRANGERES,
  COMMON_REQUEST_PROJET,
  COMMON_SET_STATUS_MODAL_PROJET,
  COMMON_SEARCH,
  COMMON_TOGGLE_MOBILE_SEARCH,
  COMMON_ALGOLIA_STATE_CHANGE,

  
  COMEDIEN_DEMANDE_ACCES_TELEPHONE,
  COMEDIEN_DEMANDE_ACCES_EMPLOYEUR,
  
    MESSAGE_SUCCESS,
    NOTIFICATION_SUCCESS,
    SHOW_ALERT,
    HIDE_ALERT,
    SHOW_NOTIFICATION,
    HIDE_NOTIFICATION,
    SHOW_REQUEST_LOADER,
    HIDE_REQUEST_LOADER,
    SET_REFRESH_LIST,
    UNSET_REFRESH_LIST,
    GET_FICHE_COMEDIEN, 
    SHOW_FICHE_COMEDIEN,
    GET_DERNIERS_NEWS_COMEDIEN, 
    SHOW_DERNIERS_NEWS_COMEDIEN,
    GET_SLIDES_PHOTOS_XL,
    SHOW_SLIDES_PHOTOS_XL,
    GET_SLIDES_BLOGS,
    SHOW_SLIDES_BLOGS,
    GET_SLIDES_TEMOIGNAGES_COMEDIENS,
    SHOW_SLIDES_TEMOIGNAGES_COMEDIENS,
    CMS_GET_CGU,
    CMS_SHOW_CGU,
    CMS_UPDATE_CGU,
    CMS_GET_RGPD,
    CMS_SHOW_RGPD,
    CMS_UPDATE_RGPD,

    COMMON_GET_LIST_MESSAGES,
    COMMON_SHOW_LIST_MESSAGES,
    COMMON_PLAY_MP3,
    COMMON_SET_FORM_RESET,
    COMMON_UNSET_FORM_RESET

} from "constants/ActionTypes";

import{
  CMS_ELEMENTS
} from "constants/General";

////////////////////////////////////////////////////////////////////////////
// Loader
////////////////////////////////////////////////////////////////////////////

export const showRequestLoaderAction = () => {
  return {
    type: SHOW_REQUEST_LOADER,
  };
};

export const hideRequestLoaderAction = () => { 
  return {
    type: HIDE_REQUEST_LOADER,
  };
};

////////////////////////////////////////////////////////////////////////////
// Alert
////////////////////////////////////////////////////////////////////////////

export const showAlertAction = (message, status = MESSAGE_SUCCESS) => {
  return {
    type: SHOW_ALERT,
    payload: message,
    status: status
  };
};
  
export const hideAlertAction = () => {
  return {
    type: HIDE_ALERT,
  };
};

////////////////////////////////////////////////////////////////////////////
// Notification
////////////////////////////////////////////////////////////////////////////

export const showNotificationAction = (message, status = NOTIFICATION_SUCCESS) => {
  return {
    type: SHOW_NOTIFICATION,
    payload: message,
    status: status
  };
};

export const hideNotificationAction = (message, status = NOTIFICATION_SUCCESS) => {
  return {
    type: HIDE_NOTIFICATION,
  };
};

////////////////////////////////////////////////////////////////////////////
// Refresh list
////////////////////////////////////////////////////////////////////////////
  
export const setRefreshListAction = () => {
  return {
    type: SET_REFRESH_LIST
  };
};

export const unsetRefreshListAction = () => {
  return {
    type: UNSET_REFRESH_LIST
  };
};

////////////////////////////////////////////////////////////////////////////
// Fiche comedien
////////////////////////////////////////////////////////////////////////////

export function getFicheComedienAction(datauseralgolia) {
  return {
    type: GET_FICHE_COMEDIEN,
    payload: datauseralgolia
  };
};

export function showFicheComedienAction(ficheComedien) {
  return { 
    type: SHOW_FICHE_COMEDIEN, 
    ficheComedien 
  };
}

////////////////////////////////////////////////////////////////////////////
// Derniers News com�diens
////////////////////////////////////////////////////////////////////////////

export function getDerniersNewsComediensAction() {
  return {
    type: GET_DERNIERS_NEWS_COMEDIEN,
    payload:{}
  };
};

export function showDerniersNewsComediensAction(dataDerniersNewsComediens) {
  return { 
    type: SHOW_DERNIERS_NEWS_COMEDIEN, 
    dataDerniersNewsComediens 
  };
}

////////////////////////////////////////////////////////////////////////////
// Slides Photos XL
////////////////////////////////////////////////////////////////////////////

export function getSlidesPhotosXLAction() {
  return { 
    type: GET_SLIDES_PHOTOS_XL
  };
}

export function showSlidesPhotosXLAction(dataSlidesPhotosXL) {
  return { 
    type: SHOW_SLIDES_PHOTOS_XL, 
    dataSlidesPhotosXL 
  };
}

////////////////////////////////////////////////////////////////////////////
// Slides Blogs
////////////////////////////////////////////////////////////////////////////

export function getSlidesBlogsAction() {
  return { 
    type: GET_SLIDES_BLOGS
  };
}

export function showSlidesBlogsAction(dataSlidesBlogs) {
  return { 
    type: SHOW_SLIDES_BLOGS, 
    dataSlidesBlogs 
  };
}

////////////////////////////////////////////////////////////////////////////
// Slides Temoignages Com�diens
////////////////////////////////////////////////////////////////////////////

export function getSlidesTemoignagesComediensAction() {
  return { 
    type: GET_SLIDES_TEMOIGNAGES_COMEDIENS
  };
}

export function showSlidesTemoignagesComediensAction(dataSlidesTemoignages) {
  return { 
    type: SHOW_SLIDES_TEMOIGNAGES_COMEDIENS, 
    dataSlidesTemoignages 
  };
}

///////////////////////////////////////////////////////////////////////
// Sonnez Alfred
///////////////////////////////////////////////////////////////////////

export const sonnezAlfredAction = (idUser, values, file) => {
  return {
    type: COMMON_REQUEST_SONNEZ_ALFRED,
    payload:{
      idUser,
      file,
      values
    } 
  };
};

export const setStatusSonnezAlfredAction = (status) => {
  return {
    type: COMMON_SET_STATUS_MODAL_SONNEZ_ALFRED,
    payload: status,
  };
};

///////////////////////////////////////////////////////////////////////
// Voix �trang�res
///////////////////////////////////////////////////////////////////////

export const voixEtrangeresAction = (idUser, values, file) => {
  return {
    type: COMMON_REQUEST_VOIX_ETRANGERES,
    payload:{
      idUser,
      file,
      values
    } 
  };
};

export const setStatusVoixEtrangeresAction = (status) => {
  return {
    type: COMMON_SET_STATUS_MODAL_VOIX_ETRANGERES,
    payload: status,
  };
};

///////////////////////////////////////////////////////////////////////
// Projet
///////////////////////////////////////////////////////////////////////

export const projetAction = (idUser, values, file) => {
  return {
    type: COMMON_REQUEST_PROJET,
    payload:{
      idUser,
      file,
      values
    } 
  };
};

export const setStatusProjetAction = (status) => {
  return {
    type: COMMON_SET_STATUS_MODAL_PROJET,
    payload: status,
  };
};

///////////////////////////////////////////////////////////////////////
// CMS
///////////////////////////////////////////////////////////////////////

//CGU

export function getCGUAction() {
  return {
    type: CMS_GET_CGU
  };
}

export function showCGUAction(dataCGU) {
  return { 
    type: CMS_SHOW_CGU, 
    dataCGU 
  };
}

export const updateCGUAction = (contenu) => {
  return {
    type: CMS_UPDATE_CGU,
    payload: {
      contenu
    }
  };
};

//RGPD

export function getRGPDAction() {
  return {
    type: CMS_GET_RGPD
  };
}

export function showRGPDAction(dataRGPD) {
  return { 
    type: CMS_SHOW_RGPD, 
    dataRGPD 
  };
}

export const updateRGPDAction = (contenu) => {
  return {
    type: CMS_UPDATE_RGPD,
    payload: {
      contenu
    }
  };
};
  
///////////////////////////////////////////////////////////////////////
// Recherche
///////////////////////////////////////////////////////////////////////

export const searchAction = (dataSearchKeyword) => {
  return {
    type: COMMON_SEARCH,
    dataSearchKeyword
  };
};

///////////////////////////////////////////////////////////////////////
// Mobile search
///////////////////////////////////////////////////////////////////////

export const toggleMobileSearchAction = (showMobileSearch) => {
  return {
    type: COMMON_TOGGLE_MOBILE_SEARCH,
    showMobileSearch
  };
};

///////////////////////////////////////////////////////////////////////
// Algolia State Change
///////////////////////////////////////////////////////////////////////

export const algoliaStateChangeAction = (algoliaStateChange) => {
  return {
    type: COMMON_ALGOLIA_STATE_CHANGE,
    algoliaStateChange
  };
};

///////////////////////////////////////////////////////////////////////
// Acc�s Confidentialit�
///////////////////////////////////////////////////////////////////////

export function demandeAccesTelephoneAction(idComedien) {
  return {
    type: COMEDIEN_DEMANDE_ACCES_TELEPHONE,
    idComedien
  };
}

export function demandeAccesEmployeurAction(idComedien) {
  return {
    type: COMEDIEN_DEMANDE_ACCES_EMPLOYEUR,
    idComedien
  };
}

///////////////////////////////////////////////////////////////////////
// SMS
///////////////////////////////////////////////////////////////////////

//Liste des SMS

export function getListMessagesAction(pageSize, page, keyword) {
  return {
    type: COMMON_GET_LIST_MESSAGES,
    payload: {
      pageSize,
      page,
      keyword
    }
  };
}

export function showListMessagesAction(dataListMessages) {
  return {
    type: COMMON_SHOW_LIST_MESSAGES,
    dataListMessages
  };
}

///////////////////////////////////////////////////////////////////////
// Lecture d'un MP3
///////////////////////////////////////////////////////////////////////

export const playMP3Action = (MP3ToPlay) => {
  return {
    type: COMMON_PLAY_MP3,
    MP3ToPlay
  };
};

////////////////////////////////////////////////////////////////////////////
// Form reset
////////////////////////////////////////////////////////////////////////////
  
export const setFormResetAction = () => {
  return {
    type: COMMON_SET_FORM_RESET
  };
};

export const unsetFormResetAction = () => {
  return {
    type: COMMON_UNSET_FORM_RESET
  };
};