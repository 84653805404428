import {
  SET_STATUT_DEMANDE_INSCRIPTION_COMEDIEN,
  SET_STATUS_PAIEMENT_DEMANDE_INSCRIPTION_COMEDIEN,
  UTILISATEUR_GET_FORM_INSCRIPTION,
  UTILISATEUR_SHOW_FORM_INSCRIPTION,
  SET_STATUS_PAIEMENT_INSCRIPTION_COMEDIEN_CHEQUE,

    UTILISATEUR_GET_PROFIL,
    UTILISATEUR_SHOW_PROFIL,
    UTILISATEUR_GET_COORDONNEES,
    UTILISATEUR_SHOW_COORDONNEES,

    GET_ACCE_CONFIDENTIALITE_USER,
    SHOW_ACCE_CONFIDENTIALITE_USER,

    USER_SHOW_HISTORIQUE_PARTAGES,

    GET_ROLE_UTILISATEUR,
    SHOW_ROLE_UTILISATEUR,
} from "constants/ActionTypes";

import {
  STATUS_INSCRIPTION_COMEDIEN_INIT,
  STATUS_PAIEMENT_DEMANDE_INSCRIPTION_COMEDIEN_WAIT,
  STATUS_INSCRIPTION_COMEDIEN_CHEQUE_INIT,
} from "constants/General";

const INIT_STATE={
    statusInscriptionComedien: STATUS_INSCRIPTION_COMEDIEN_INIT,
    statusPaiementInscriptionComedien: STATUS_PAIEMENT_DEMANDE_INSCRIPTION_COMEDIEN_WAIT,
    dataPayboxFormInscriptionComedien:{},
    statusPaiementInscriptionComedienCheque:STATUS_INSCRIPTION_COMEDIEN_CHEQUE_INIT,
    dataProfil:{},
    dataCoordonnees:null,
    dataHistoriquePartage:null,
    roleUtilisateur:0,
    historique:{},
};

export default (state= INIT_STATE, action)=>{
    switch(action.type){

      ///////////////////////////////////////////////////////////////////////
      // Demande d'inscription comedien
      ///////////////////////////////////////////////////////////////////////

      case SET_STATUT_DEMANDE_INSCRIPTION_COMEDIEN: {
        return {
            ...state,
            statusInscriptionComedien: action.payload,
        }
      }

      case SET_STATUS_PAIEMENT_DEMANDE_INSCRIPTION_COMEDIEN: {
          return {
              ...state,
              statusPaiementInscriptionComedien: action.payload,
          }
      }


      ///////////////////////////////////////////////////////////////////////
      // Paybox Form
      ///////////////////////////////////////////////////////////////////////

      case UTILISATEUR_GET_FORM_INSCRIPTION: {
        return {
            ...state,
        }
    }

    case UTILISATEUR_SHOW_FORM_INSCRIPTION: {
      return {
          ...state,
          dataPayboxFormInscriptionComedien: action.dataPayboxFormInscriptionComedien
      }
  }

///////////////////////////////////////////////////////////////////////
// Inscription comédien : Payement par chèque bancaire
///////////////////////////////////////////////////////////////////////

  case SET_STATUS_PAIEMENT_INSCRIPTION_COMEDIEN_CHEQUE: {
    return {
        ...state,
        statusPaiementInscriptionComedienCheque: action.payload,
    }
}




        ///////////////////////////////////////////////////////////////////////
        // Mon Compte
        ///////////////////////////////////////////////////////////////////////

        //Profil

        case UTILISATEUR_GET_PROFIL:
            return{
                ...state,
            }
        
        case UTILISATEUR_SHOW_PROFIL:
            return{
                ...state,
                dataProfil:action.dataProfil
            }
        
        //Coordonnées

        case UTILISATEUR_GET_COORDONNEES:
            return{
                ...state,
            }

        case UTILISATEUR_SHOW_COORDONNEES:
            return{
                ...state,
                dataCoordonnees:action.dataCoordonnees
            }
        


            
        //************Action Of Get Type Utilisateur ************//    
        case GET_ROLE_UTILISATEUR:
            return{
                ...state,
            }
        
        case SHOW_ROLE_UTILISATEUR:

            return{
                ...state,
                roleUtilisateur:action.roleUtilisateur
                
            }

        //************Action Of Get Coordonnées Utilisateur ************//    
        

        case GET_ACCE_CONFIDENTIALITE_USER:
            return{
                ...state,
            }
        case SHOW_ACCE_CONFIDENTIALITE_USER:
            return{
                ...state,
                confidentialite:action.confidentialite
            }

        //Historique de partage

        case USER_SHOW_HISTORIQUE_PARTAGES:
            return{
                ...state,
                dataHistoriquePartage:action.dataHistoriquePartage
            }



   
        

        default:
            return state;
    }
}