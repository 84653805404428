import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "util/withRouter";
import { Layout } from "antd";
import IntlMessages from "util/IntlMessages";
import SearchForm from "components/SearchForm";
import Auxiliary from "util/Auxiliary";
import UserProfile from "./UserProfile";
import { getCurrentUser } from 'util/Auth';
import Auth from "components/Auth";
import { decodeUrl } from 'util/Url';
import { injectIntl } from 'react-intl';
import Swal from 'sweetalert2'
import Projet from "components/Projet";
import MobileMenu from "../MobileMenu/index";
import CampagneCom from "../CampagneCom/index"

import { APPLICATION_PATHS } from "constants/ApplicationPaths";
import { DEVICE_SM_SIZE } from 'constants/ThemeSetting.js'

import logoCastingMachine from "assets/images/logo-casting-machine.webp";
import logoCastingMachineWhite from "assets/images/logo-casting-machine-white.webp";
import iconFiltre from "assets/images/icon-filtre.webp";
import iconSearch from "assets/images/icon-search.webp";
import iconMenu from "assets/images/icon-menu.webp";
import iconMenuWhite from "assets/images/icon-menu-white.webp";

import {
  toggleCollapsedSideNav,
  toggleCollapsedMobileMenu
} from "appRedux/actions/Setting";

import {
  toggleMobileSearchAction,
  getSlidesPhotosXLAction
} from "appRedux/actions/Common";

const { Header } = Layout;

const overlayTopBarPages = [
  APPLICATION_PATHS.HOME,
  APPLICATION_PATHS.INSCRIPTION_COMEDIEN,
  APPLICATION_PATHS.PAGE_COMEDIEN,
  APPLICATION_PATHS.AIDE,
  APPLICATION_PATHS.EQUIPE,
  APPLICATION_PATHS.DECOUVERTE,
];

class Topbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModalAuth: false,
      vueAuth: "signIn",
      showModalProjet: false
    };
  }

  componentDidMount() {
    //this.props.getSlidesPhotosXLAction();
    const params = new URLSearchParams(window.location.search);
    if (params.has("sign-in")) {
      this.setState({
        showModalAuth: true,
        vueAuth: "signIn",
      });
    }
  }

  ////////////////////////////////////////////////////////
  // Composants Mobile
  ////////////////////////////////////////////////////////

  getFilterToggleButton = () => {
    const { navCollapsed, mobileMenuCollapsed } = this.props;

    return (
      <div className="cm-top-bar-filtre-toggle">
        {(window.location.pathname == APPLICATION_PATHS.RECHERCHE ||
          (this.props.page == "ficheComedienUrl" && window.innerWidth > DEVICE_SM_SIZE))
          ?
          <img src={iconFiltre}
            onClick={() => {
              this.props.toggleCollapsedSideNav(!navCollapsed);
              if (mobileMenuCollapsed) {
                this.props.toggleCollapsedMobileMenu(!mobileMenuCollapsed);
              }
            }}
          />
          : null
        }
      </div>
    );
  }

  getMenuMobile = (iconMenuSrc) => {
    const { navCollapsed, mobileMenuCollapsed } = this.props;

    return (
      <div className="cm-top-bar-menu-mobile">
        <img src={iconMenuSrc} loading="lazy"
          onClick={() => {
            this.props.toggleCollapsedMobileMenu(!mobileMenuCollapsed);
            if (navCollapsed) {
              this.props.toggleCollapsedSideNav(!navCollapsed);
            }
          }}
        />

        <MobileMenu
          page={this.props.page}
          callbackShowPlaylistsFromTopBar={this.props.callbackShowPlaylistsFromTopBar}
          onSignUp={this.onSignUp}
          onSignIn={this.onSignIn}
          onShowPlaylists={this.onShowPlaylists}
          onDisplayModalProjet={this.onDisplayModalProjet}
        />
      </div>
    )
  };

  getSearchMobile = () => {
    return (
      <div className="cm-top-bar-mobile-col-search gx-pointer">
        {window.location.pathname !== APPLICATION_PATHS.HOME ?
          <img
            loading="lazy"
            src={iconSearch}
            onClick={this.handleClickMobileSearch}
          />
          : null
        }
      </div>
    );
  }

  ////////////////////////////////////////////////////////
  // Composants Desktop
  ////////////////////////////////////////////////////////

  getSearchDesktop = () => {
    if (window.location.pathname !== APPLICATION_PATHS.HOME) {
      return (
        <SearchForm
          displayOn="TOP-BAR"
        />
      );
    } else {
      return null;
    }
  }

  getMenuDesktop = () => {
    var currentUser = getCurrentUser();

    return (
      <div className="cm-top-bar-menu-desktop">
        <ul className="gx-app-nav">

          <li className="cm-top-bar-col-action-btn" onClick={this.onDisplayModalProjet}>
            <IntlMessages id="topBar.menu.projet" />
          </li>

          <li>
            <a href={APPLICATION_PATHS.DECOUVERTE}>
              <IntlMessages id="topBar.menu.decouverte" />
            </a>
          </li>

          {currentUser ?
            <Auxiliary>
              <li style={{ padding: "0px" }}>|</li>

              <li onClick={this.onShowPlaylists}>
                <IntlMessages id="topBar.menu.playlists" />
              </li>

            </Auxiliary>
            :
            <Auxiliary>
              <li style={{ padding: "2px" }}>|</li>

              <li onClick={e => this.onSignIn()}>
                <IntlMessages id="topBar.menu.connexion" />
              </li>

              <li style={{ padding: "2px" }}>|</li>

              <li onClick={e => this.onSignUp()}>
                <IntlMessages id="topBar.menu.inscription" />
              </li>
            </Auxiliary>
          }

        </ul>

        {currentUser ?
          <div className="cm-top-bar-col-avatar">
            <UserProfile />
          </div>
          : null}
      </div>
    );
  }

  getLogo = (logoSrc) => {
    var intl = this.props.intl;
    return (
      <div className="cm-top-bar-logo">
        <a href={APPLICATION_PATHS.HOME} className="gx-pointer">
          <img src={logoSrc} fetchpriority="low" loading="lazy" width="220" height="44" alt={intl.formatMessage({ id: "topbar.img.alt.logo" })} />
        </a>
      </div>
    )
  }

  ////////////////////////////////////////////////////////
  // Auth / Modal
  ///////////////////////////////////////////////////////

  onSignIn = () => {
    this.setState({
      showModalAuth: true,
      vueAuth: "signIn",
    });
  }

  onSignUp = () => {
    this.setState({
      showModalAuth: true,
      vueAuth: "signUp",
    });
  }

  onHideModalAuth = () => {
    this.setState({
      showModalAuth: false,
    });
  }

  ////////////////////////////////////////////////////////
  // Auth / SignUp Success
  ///////////////////////////////////////////////////////

  onSignUpSuccess = () => {
    var intl = this.props.intl;

    this.setState({
      showModalAuth: false,
    })

    Swal.fire({
      imageUrl: logoCastingMachine,
      imageWidth: 180,
      buttonsStyling: false,
      customClass: { confirmButton: 'ant-btn ant-btn-primary', cancelButton: 'ant-btn' },
      title: intl.formatMessage({ id: "signUp.success.titre" }),
      text: intl.formatMessage({ id: 'signUp.success.message' }),
    })
  }

  ////////////////////////////////////////////////////////
  // Auth / SignIn Success
  ///////////////////////////////////////////////////////

  onSignInSuccess = () => {
    const params = new URLSearchParams(window.location.search);
    if (params.has("redirect")) {
      window.location = decodeUrl(params.get("redirect"))
    } else {
      window.location.reload();
    }
  }

  ////////////////////////////////////////////////////////
  // Go to Playlists
  ///////////////////////////////////////////////////////

  onShowPlaylists = () => {
    switch (window.location.pathname) {
      case APPLICATION_PATHS.RECHERCHE:
        if (this.props.callbackShowPlaylistsFromTopBar) {
          this.props.callbackShowPlaylistsFromTopBar();
        }
        break;
      default:
        window.location.replace(APPLICATION_PATHS.RECHERCHE + '?playlists=1');
    }
  };

  handleClickMobileSearch = () => {
    this.props.toggleMobileSearchAction(!this.props.showMobileSearch)
  }

  //////////////////////////////////////////////////////////////////
  // Projet
  //////////////////////////////////////////////////////////////////

  onDisplayModalProjet = () => {
    this.setState({
      showModalProjet: true
    });
  };

  onCancelModalProjet = () => {
    this.setState({
      showModalProjet: false
    });
  };

  render() {
    const { navCollapsed, mobileMenuCollapsed } = this.props;

    var currentPath = window.location.pathname.split('?')[0];

    if (overlayTopBarPages.indexOf(currentPath) !== -1) {
      var headerClass = "cm-top-bar cm-top-bar-overlay";
      var logoSrc = logoCastingMachineWhite;
      var iconMenuSrc = iconMenuWhite;
    } else {
      var headerClass = "cm-top-bar";
      var logoSrc = logoCastingMachine;
      var iconMenuSrc = iconMenu;
    }

    return (
      <Auxiliary>
        <CampagneCom />
        <Header className={headerClass}>

          { /* Large/Medium screen */}
          <div className="cm-top-bar-desktop-container">

            <div className="cm-top-bar-desktop-col-logo">
              {this.getFilterToggleButton()}
              {this.getLogo(logoSrc)}
            </div>

            <div className="cm-top-bar-desktop-col-search">
              {this.getSearchDesktop()}
            </div>

            <div className="cm-top-bar-desktop-col-menu">
              {this.getMenuDesktop()}
              {this.getMenuMobile(iconMenuSrc)}
            </div>

          </div>

          { /* Small screen Top Bar */}
          <div className="cm-top-bar-mobile-container">

            {this.getFilterToggleButton()}

            <div className="cm-top-bar-mobile-col-logo">
              {this.getLogo(logoCastingMachine)}
            </div>

            {this.getSearchMobile()}
            {this.getMenuMobile(iconMenu)}

          </div>
        </Header>

        <Auth
          visible={this.state.showModalAuth}
          vue={this.state.vueAuth}
          onCancel={this.onHideModalAuth}
          onSignInSuccess={this.onSignInSuccess}
          onSignUpSuccess={this.onSignUpSuccess}
          signUpButtonText={<IntlMessages id="signUp.button" />}
        />

        <Projet
          visible={this.state.showModalProjet}
          onCancel={this.onCancelModalProjet}
        />

      </Auxiliary>
    );
  }

}

const mapStateToProps = ({ settings, common }) => {
  const {
    navCollapsed,
    mobileMenuCollapsed,
  } = settings;

  const {
    showMobileSearch,
  } = common;

  return {
    navCollapsed,
    mobileMenuCollapsed,
    showMobileSearch
  }
};

export default withRouter(injectIntl(
  connect(
    mapStateToProps,
    {
      toggleCollapsedSideNav,
      toggleCollapsedMobileMenu,
      toggleMobileSearchAction,
      getSlidesPhotosXLAction
    }
  )(Topbar)));
