import axios from "axios";
import { endpointPlaylist } from 'util/api';

/**
 * r�cup�ration de la liste des playlists
 */
export const getPlayListsRequest = async () => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  const wsResponse = await axios.request({
    method: 'get',
    url: `${endpointPlaylist.GET_PLAYLISTS}?access_token=${accessToken}`,
  });

  return wsResponse;
}

/**
 * Cr�ation d'une playlist
 * @param {*} payload 
 */
export const createPlaylistRequest = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  const { values, photo } = payload;

  const formData = new FormData();

  formData.append("decouverte", values.decouverte ? 1 : 0);
  formData.append("nomPlaylist", values.nomPlaylist);
  formData.append("description", values.description);
  formData.append("photo", photo);

  const wsResponse = await axios.request({
    method: 'post',
    url: `${endpointPlaylist.CREATE_PLAYLIST}?access_token=${accessToken}`,
    data: formData,
    config: {
      headers: {
        'Content-Type': `multipart/form-data`,
        'Accept': 'application/json',
      }
    }
  });
  return wsResponse;
}

/**
 * 
 * @param {*} idPlaylist 
 * @param {*} nomPlaylist 
 * @param {*} description 
 * @param {*} photo 
 */
export const updatePlaylistRequest = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  const { idPlaylist, values, photo } = payload;

  const formData = new FormData();

  formData.append("idPlaylist", idPlaylist);
  formData.append("nomPlaylist", values.nomPlaylistEdit);
  formData.append("description", values.descriptionEdit);
  formData.append("photo", photo);

  const wsResponse = await axios.request({
    method: 'post',
    url: `${endpointPlaylist.UPDATE_PLAYLIST}?access_token=${accessToken}`,
    data: formData
  });

  return wsResponse;
}

/**
 * Copie d'une playlist
 * @param {*} idPlaylist 
 */
export const copyPlaylistRequest = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  const { idPlaylist } = payload;

  const wsResponse = await axios.request({
    method: 'POST',
    url: `${endpointPlaylist.COPY_PLAYLIST}?access_token=${accessToken}`,
    data: {
      idPlaylist
    }
  });

  return wsResponse;
}

export const deletePlaylistRequest = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  const { idPlaylist } = payload;

  const wsResponse = await axios.request({
    method: 'DELETE',
    url: `${endpointPlaylist.DELETE_PLAYLIST}?access_token=${accessToken}`,
    data: {
      idPlaylist
    }
  });

  return wsResponse;
}

/**
 * Tri des extraits d'une playlist
 * @param {*} payload 
 */
export const sortExtraitsRequest = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  const { idPlaylist, sortString } = payload;

  const wsResponse = await axios.request({
    method: 'post',
    url: `${endpointPlaylist.SORT_EXTRAITS}?access_token=${accessToken}`,
    data: {
      idPlaylist,
      sortString
    },
    config: {
      headers: {
        'Content-Type': `multipart/form-data`,
        'Accept': 'application/json',
      }
    }
  });

  return wsResponse;
}

/**
 * Cr�ation d'une playlist et ajout de l'extrait
 * @param {*} idSon 
 * @param {*} nomPlaylist 
 */
export const createPlaylistAddExtraitRequest = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  const { idExtrait, nomPlaylist } = payload;

  const wsResponse = await axios.request({
    method: 'post',
    url: `${endpointPlaylist.CREATE_PLAYLIST_ADD_EXTRAIT}?access_token=${accessToken}`,
    data: {
      idExtrait,
      nomPlaylist
    },
    config: {
      headers: {
        'Content-Type': `multipart/form-data`,
        'Accept': 'application/json',
      }
    }
  });

  return wsResponse;
}

/**
 * Ajout d'un extrait � une playlist
 * @param {*} idSon 
 * @param {*} idPlaylist 
 */
export const addExtraitToPlaylistRequest = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  const { idSon, idPlaylist } = payload;

  const wsResponse = await axios.request({
    method: 'post',
    url: `${endpointPlaylist.ADD_EXTRAIT_TO_PLAYLIST}?access_token=${accessToken}`,
    data: {
      idSon,
      idPlaylist
    }
  });

  return wsResponse;
}

/**
 * Retrait d'un extrait d'une playlist
 * @param {*} idSon 
 * @param {*} idPlaylist 
 */
export const retraitExtraitFromPlaylistRequest = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  const { idSon, idPlaylist } = payload;

  const wsResponse = await axios.request({
    method: 'post',
    url: `${endpointPlaylist.RETRAT_EXTRAIT_FROM_PLAYLIST}?access_token=${accessToken}`,
    data: {
      idSon,
      idPlaylist
    }

  });
  return wsResponse;
}
///////////////////////////////////////////////////////
///////////////////////////////////////////////////////
///////////////////////////////////////////////////////














export const getPlayListsDecouverteRequest = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  const { withMP3 } = payload;

  const wsResponse = await axios.request({
    method: 'get',
    url: `${endpointPlaylist.GET_PLAYLISTS_DECOUVERTE}?access_token=${accessToken}`,
    params: {
      withMP3
    }
  });
  return wsResponse;
}





export const getPlayListPartageRequest = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  const { lien } = payload;

  const wsResponse = await axios.request({
    method: 'get',
    url: `${endpointPlaylist.GET_PLAYLISTS_PARTAGE}?access_token=${accessToken}`,
    params: {
      lien: lien
    }
  });
  return wsResponse;
}


