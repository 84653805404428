import axios from "axios";
import { endpointMessage } from 'util/api';

/**
 * Liste des SMS recus par un comédien 
 * @param {*} payload 
 */
export const getListMessagesRequest = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  var { pageSize, page, keyword } = payload;

  const wsResponse = await axios.request({
    method: 'get',
    url: `${endpointMessage.GET_MESSAGES_COMEDIEN}?access_token=${accessToken}`,
    params: {
      pageSize,
      page,
      keyword
    }
  });

  return wsResponse;
}