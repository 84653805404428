// Customizer const
export const TOGGLE_COLLAPSED_NAV = 'TOGGLE_COLLAPSE_MENU';
export const TOGGLE_COLLAPSED_MOBILE_MENU = 'TOGGLE_COLLAPSED_MOBILE_MENU';
export const WINDOW_WIDTH = 'WINDOW-WIDTH';
export const SWITCH_LANGUAGE = 'SWITCH-LANGUAGE';

///////////////////////////////////////////////////////////////////////
// Common
///////////////////////////////////////////////////////////////////////

//Accés et Confidentialité
export const COMEDIEN_DEMANDE_ACCES_TELEPHONE = 'COMEDIEN_DEMANDE_ACCES_TELEPHONE';
export const COMEDIEN_DEMANDE_ACCES_EMPLOYEUR = 'COMEDIEN_DEMANDE_ACCES_EMPLOYEUR';

//Request Loader
export const SHOW_REQUEST_LOADER = 'SHOW_REQUEST_LOADER';
export const HIDE_REQUEST_LOADER = 'HIDE_REQUEST_LOADER';

//Alert
export const SHOW_ALERT = 'SHOW_ALERT';
export const HIDE_ALERT = 'HIDE_ALERT';
export const MESSAGE_SUCCESS = 'MESSAGE_SUCCESS';
export const MESSAGE_FAIL = 'MESSAGE_FAIL';

//Notification
export const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION';
export const HIDE_NOTIFICATION = 'HIDE_NOTIFICATION';
export const NOTIFICATION_SUCCESS = 'NOTIFICATION_SUCCESS';
export const NOTIFICATION_FAIL = 'NOTIFICATION_FAIL';

//Refresh List
export const SET_REFRESH_LIST = "SET_REFRESH_LIST";
export const UNSET_REFRESH_LIST = "UNSET_REFRESH_LIST";

//Récupération de la fiche comedie
export const GET_FICHE_COMEDIEN = "GET_FICHE_COMEDIEN";
export const SHOW_FICHE_COMEDIEN = "SHOW_FICHE_COMEDIEN";

//Derniers News comédiens
export const GET_DERNIERS_NEWS_COMEDIEN = "GET_DERNIERS_NEWS_COMEDIEN";
export const SHOW_DERNIERS_NEWS_COMEDIEN = "SHOW_DERNIERS_NEWS_COMEDIEN";

//Slides Photos XL
export const GET_SLIDES_PHOTOS_XL = "GET_SLIDES_PHOTOS_XL"
export const SHOW_SLIDES_PHOTOS_XL = "SHOW_SLIDES_PHOTOS_XL"

//Slides Blogs
export const GET_SLIDES_BLOGS = "GET_SLIDES_BLOGS";
export const SHOW_SLIDES_BLOGS = "SHOW_SLIDES_BLOGS";

//Slides Temoignages Comédiens
export const GET_SLIDES_TEMOIGNAGES_COMEDIENS = "GET_SLIDES_TEMOIGNAGES_COMEDIENS";
export const SHOW_SLIDES_TEMOIGNAGES_COMEDIENS = "SHOW_SLIDES_TEMOIGNAGES_COMEDIENS";

//Sonnez Alfred
export const COMMON_REQUEST_SONNEZ_ALFRED = 'COMMON_REQUEST_SONNEZ_ALFRED';
export const COMMON_SET_STATUS_MODAL_SONNEZ_ALFRED = 'COMMON_SET_STATUS_MODAL_SONNEZ_ALFRED';

//Voix étrangères
export const COMMON_REQUEST_VOIX_ETRANGERES = 'COMMON_REQUEST_VOIX_ETRANGERES';
export const COMMON_SET_STATUS_MODAL_VOIX_ETRANGERES = 'SET_STATUS_MODAL_VOIX_ETRANGERES';

//Projet
export const COMMON_REQUEST_PROJET = 'COMMON_REQUEST_PROJET';
export const COMMON_SET_STATUS_MODAL_PROJET = 'COMMON_SET_STATUS_MODAL_PROJET';

//CMS/CGU
export const CMS_GET_CGU = 'CMS_GET_CGU';
export const CMS_SHOW_CGU = 'CMS_SHOW_CGU';
export const CMS_UPDATE_CGU = 'CMS_UPDATE_CGU';

//CMS/RGPD
export const CMS_GET_RGPD = 'CMS_GET_RGPD';
export const CMS_SHOW_RGPD = 'CMS_SHOW_RGPD';
export const CMS_UPDATE_RGPD = 'CMS_UPDATE_RGPD';

//Search
export const COMMON_SEARCH = 'COMMON_SEARCH';

//Mobile search
export const COMMON_TOGGLE_MOBILE_SEARCH = 'COMMON_TOGGLE_MOBILE_SEARCH'

//Algolia state change
export const COMMON_ALGOLIA_STATE_CHANGE = 'COMMON_ALGOLIA_STATE_CHANGE';

//Lecture d'un MP3
export const COMMON_PLAY_MP3 = 'COMMON_PLAY_MP3';

//Form reset
export const COMMON_SET_FORM_RESET = 'COMMON_SET_FORM_RESET';
export const COMMON_UNSET_FORM_RESET = 'COMMON_UNSET_FORM_RESET';

///////////////////////////////////////////////////////////////////////
// Utilisateur
///////////////////////////////////////////////////////////////////////

export const DEMANDE_INSCRIPTION_COMEDIEN = 'DEMANDE_INSCRIPTION_COMEDIEN';
export const SET_STATUT_DEMANDE_INSCRIPTION_COMEDIEN = 'SET_STATUT_DEMANDE_INSCRIPTION_COMEDIEN';
export const VERIF_PAIEMENT_DEMANDE_INSCRIPTION_COMEDIEN = 'VERIF_PAIEMENT_DEMANDE_INSCRIPTION_COMEDIEN';
export const SET_STATUS_PAIEMENT_DEMANDE_INSCRIPTION_COMEDIEN = 'SET_STATUS_PAIEMENT_DEMANDE_INSCRIPTION_COMEDIEN';
export const UTILISATEUR_PAIEMENT_INSCRIPTION_CHEQUE = 'UTILISATEUR_PAIEMENT_INSCRIPTION_CHEQUE';
export const SET_STATUS_PAIEMENT_INSCRIPTION_COMEDIEN_CHEQUE = 'SET_STATUS_PAIEMENT_INSCRIPTION_COMEDIEN_CHEQUE';
//Mon Compte
export const UTILISATEUR_GET_PROFIL = 'UTILISATEUR_GET_PROFIL';
export const UTILISATEUR_SHOW_PROFIL = 'UTILISATEUR_SHOW_PROFIL';
export const UTILISATEUR_UPDATE_PROFIL = 'UTILISATEUR_UPDATE_PROFIL';
export const UTILISATEUR_GET_COORDONNEES = 'UTILISATEUR_GET_COORDONNEES';
export const UTILISATEUR_SHOW_COORDONNEES = 'UTILISATEUR_SHOW_COORDONNEES';

export const UTILISATEUR_UPDATE_COORDONNEES = 'UTILISATEUR_UPDATE_COORDONNEES';
export const GET_ACCE_CONFIDENTIALITE_USER = 'GET_ACCE_CONFIDENTIALITE_USER';
export const SHOW_ACCE_CONFIDENTIALITE_USER = 'SHOW_ACCE_CONFIDENTIALITE_USER';

export const USER_GET_HISTORIQUE_PARTAGES = "USER_GET_HISTORIQUE_PARTAGES";
export const USER_SHOW_HISTORIQUE_PARTAGES = "USER_SHOW_HISTORIQUE_PARTAGES";

export const GET_ROLE_UTILISATEUR = 'GET_ROLE_UTILISATEUR';
export const SHOW_ROLE_UTILISATEUR = 'SHOW_ROLE_UTILISATEUR';

export const UTILISATEUR_GET_FORM_INSCRIPTION = 'UTILISATEUR_GET_FORM_INSCRIPTION';
export const UTILISATEUR_SHOW_FORM_INSCRIPTION = 'UTILISATEUR_SHOW_FORM_INSCRIPTION';

///////////////////////////////////////////////////////////////////////
// Comedien
///////////////////////////////////////////////////////////////////////

// Mon Profil
export const COMEDIEN_GET_IDENTITE = 'COMEDIEN_GET_IDENTITE';
export const COMEDIEN_SHOW_IDENTITE = 'COMEDIEN_SHOW_IDENTITE';
export const COMEDIEN_UPDATE_IDENTITE = 'COMEDIEN_UPDATE_IDENTITE';

export const COMEDIEN_GET_ACTIVITE_FACTURATION = 'COMEDIEN_GET_ACTIVITE_FACTURATION';
export const COMEDIEN_SHOW_ACTIVITE_FACTURATION = 'COMEDIEN_SHOW_ACTIVITE_FACTURATION';
export const COMEDIEN_UPDATE_ACTIVITE_FACTURATION = 'COMEDIEN_UPDATE_ACTIVITE_FACTURATION';

export const COMEDIEN_GET_CONTACT = 'COMEDIEN_GET_CONTACT';
export const COMEDIEN_SHOW_CONTACT = 'COMEDIEN_SHOW_CONTACT';
export const COMEDIEN_UPDATE_CONTACT = 'COMEDIEN_UPDATE_CONTACT';

// Mon CV
export const COMEDIEN_GET_CV = 'COMEDIEN_GET_CV';
export const COMEDIEN_SHOW_CV = 'COMEDIEN_SHOW_CV';
export const COMEDIEN_UPDATE_CV = 'COMEDIEN_UPDATE_CV';

//Mes Photos
export const COMEDIEN_ADD_PHOTO = 'COMEDIEN_ADD_PHOTO';
export const COMEDIEN_GET_LISTE_PHOTOS = 'COMEDIEN_GET_LISTE_PHOTOS';
export const COMEDIEN_SHOW_LISTE_PHOTOS = 'COMEDIEN_SHOW_LISTE_PHOTOS';
export const COMEDIEN_DELETE_PHOTO = 'COMEDIEN_DELETE_PHOTO';
export const COMEDIEN_SORT_PHOTO = 'COMEDIEN_SORT_PHOTO';
export const COMEDIEN_ADD_DEMANDE_PHOTO_XL = 'COMEDIEN_ADD_DEMANDE_PHOTO_XL';
export const COMEDIEN_GET_DEMANDE_PHOTO_XL = 'COMEDIEN_GET_DEMANDE_PHOTO_XL';
export const COMEDIEN_SHOW_DEMANDE_PHOTO_XL = 'COMEDIEN_SHOW_DEMANDE_PHOTO_XL';

//Mes Extraits MP3
export const COMEDIEN_ADD_MP3 = 'COMEDIEN_ADD_MP3';
export const COMEDIEN_UPDATE_MP3 = 'COMEDIEN_UPDATE_MP3';
export const COMEDIEN_GET_LISTE_MP3 = 'COMEDIEN_GET_LISTE_MP3';
export const COMEDIEN_SHOW_LISTE_MP3 = 'COMEDIEN_SHOW_LISTE_MP3';
export const COMEDIEN_GET_EXTRAIT_MP3 = 'COMEDIEN_GET_EXTRAIT_MP3';
export const COMEDIEN_SHOW_EXTRAIT_MP3 = 'COMEDIEN_SHOW_EXTRAIT_MP3';
export const COMEDIEN_GET_STATS_MP3 = 'COMEDIEN_GET_STATS_MP3';
export const COMEDIEN_SHOW_STATS_MP3 = 'COMEDIEN_SHOW_STATS_MP3';
export const COMEDIEN_UPDATE_VISIBILITE_MP3 = 'COMEDIEN_UPDATE_VISIBILITE_MP3';
export const COMEDIEN_SORT_LISTE_MP3 = 'COMEDIEN_SORT_LISTE_MP3';
export const COMEDIEN_DELETE_MP3 = 'COMEDIEN_DELETE_MP3';
//Mes Videos
export const COMEDIEN_ADD_VIDEO = 'COMEDIEN_ADD_VIDEO';
export const COMEDIEN_UPDATE_VIDEO = 'COMEDIEN_UPDATE_VIDEO';
export const COMEDIEN_GET_LISTE_VIDEOS = 'COMEDIEN_GET_LISTE_VIDEOS';
export const COMEDIEN_SHOW_LISTE_VIDEOS = 'COMEDIEN_SHOW_LISTE_VIDEOS';
export const COMEDIEN_GET_STATS_VIDEOS = 'COMEDIEN_GET_STATS_VIDEOS';
export const COMEDIEN_SHOW_STATS_VIDEOS = 'COMEDIEN_SHOW_STATS_VIDEOS';
export const COMEDIEN_UPDATE_VISIBILITE_VIDEO = 'COMEDIEN_UPDATE_VISIBILITE_VIDEO';
export const COMEDIEN_DELETE_VIDEO = 'COMEDIEN_DELETE_VIDEO';
export const COMEDIEN_SORT_LISTE_VIDEOS = 'COMEDIEN_SORT_LISTE_VIDEOS';
export const COMEDIEN_GET_EXTRAIT_VIDEO = 'COMEDIEN_GET_EXTRAIT_VIDEO';
export const COMEDIEN_SHOW_EXTRAIT_VIDEO = 'COMEDIEN_SHOW_EXTRAIT_VIDEO';

//Mes News
export const COMEDIEN_ADD_NEWS = 'COMEDIEN_ADD_NEWS';
export const COMEDIEN_GET_LIST_NEWS = 'COMEDIEN_GET_LIST_NEWS';
export const COMEDIEN_SHOW_LIST_NEWS = 'COMEDIEN_SHOW_LIST_NEWS';

//Infos Employeur
export const COMEDIEN_GET_INFOS_EMPLOYEUR = 'COMEDIEN_GET_INFOS_EMPLOYEUR';
export const COMEDIEN_SHOW_INFOS_EMPLOYEUR = 'COMEDIEN_SHOW_INFOS_EMPLOYEUR';
export const COMEDIEN_UPDATE_INFOS_EMPLOYEUR = 'COMEDIEN_UPDATE_INFOS_EMPLOYEUR';

//Mon Equipement
export const COMEDIEN_GET_EQUIPEMENT = 'COMEDIEN_GET_EQUIPEMENT';
export const COMEDIEN_SHOW_EQUIPEMENT = 'COMEDIEN_SHOW_EQUIPEMENT';
export const COMEDIEN_UPDATE_EQUIPEMENT = 'COMEDIEN_UPDATE_EQUIPEMENT';

//Mon Compte
export const COMEDIEN_GET_ADRESSE_FACTURATION = 'COMEDIEN_GET_ADRESSE_FACTURATION';
export const COMEDIEN_SHOW_ADRESSE_FACTURATION = 'COMEDIEN_SHOW_ADRESSE_FACTURATION';
export const COMEDIEN_UPDATE_ADRESSE_FACTURATION = 'COMEDIEN_UPDATE_ADRESSE_FACTURATION';
export const COMEDIEN_GET_TDB_MON_COMPTE = 'COMEDIEN_GET_TDB_MON_COMPTE';
export const COMEDIEN_SHOW_TDB_MON_COMPTE = 'COMEDIEN_SHOW_TDB_MON_COMPTE';
export const COMEDIEN_GET_FACTURES = 'COMEDIEN_GET_FACTURES';
export const COMEDIEN_SHOW_FACTURES = 'COMEDIEN_SHOW_FACTURES';

//Mes stats
export const COMEDIEN_GET_STATS = 'COMEDIEN_GET_STATS';
export const COMEDIEN_SHOW_STATS = 'COMEDIEN_SHOW_STATS';
export const COMEDIEN_GET_TOP_EXTRAITS_STATS = 'COMEDIEN_GET_TOP_EXTRAITS_STATS';
export const COMEDIEN_SHOW_TOP_EXTRAITS_STATS = 'COMEDIEN_SHOW_TOP_EXTRAITS_STATS';
export const COMEDIEN_GET_HISTORIQUE_STATS = 'COMEDIEN_GET_HISTORIQUE_STATS';
export const COMEDIEN_SHOW_HISTORIQUE_STATS = 'COMEDIEN_SHOW_HISTORIQUE_STATS';

//Accés et Confidentialité
export const COMEDIEN_GET_LIST_DEMANDES_ACCES = 'COMEDIEN_GET_LIST_DEMANDES_ACCES';
export const COMEDIEN_SHOW_LIST_DEMANDES_ACCES = 'COMEDIEN_SHOW_LIST_DEMANDES_ACCES';
export const COMEDIEN_SWITCH_ACCES_TELEPHONE = 'COMEDIEN_SWITCH_ACCES_TELEPHONE';
export const COMEDIEN_SWITCH_ACCES_EMPLOYEUR = 'COMEDIEN_SWITCH_ACCES_EMPLOYEUR';
export const COMEDIEN_DELETE_ACCES_CONFIDENTIALITE = 'COMEDIEN_DELETE_ACCES_CONFIDENTIALITE';

//Contact comedien
export const COMEDIEN_GET_DEMANDE_CONTACT_DATA = 'COMEDIEN_GET_DEMANDE_CONTACT_DATA';
export const COMEDIEN_SHOW_DEMANDE_CONTACT_DATA = 'COMEDIEN_SHOW_DEMANDE_CONTACT_DATA';
export const COMEDIEN_SEND_DEMANDE_CONTACT = 'COMEDIEN_SEND_DEMANDE_CONTACT';
export const COMEDIEN_SET_SEND_DEMANDE_CONTACT_STATUS = 'COMEDIEN_SET_SEND_DEMANDE_CONTACT_STATUS'
export const COMEDIEN_SEND_DEMANDE_CONTACT_SUCCESS = 'COMEDIEN_SEND_DEMANDE_CONTACT_SUCCESS';
export const COMEDIEN_SEND_DEMANDE_CONTACT_ERROR = 'COMEDIEN_SEND_DEMANDE_CONTACT_ERROR';

// Fiche Comedien avec URL
export const COMEDIEN_GET_COMEDIEN_BY_URL = 'COMEDIEN_GET_COMEDIEN_BY_URL'
export const COMEDIEN_SHOW_COMEDIEN_BY_URL = 'COMEDIEN_SHOW_COMEDIEN_BY_URL'

export const COMEDIEN_GET_FICHE_COMEDIEN_MP3='COMEDIEN_GET_FICHE_COMEDIEN_MP3'
export const COMEDIEN_SHOW_FICHE_COMEDIEN_MP3='COMEDIEN_SHOW_FICHE_COMEDIEN_MP3'

export const COMEDIEN_GET_FICHE_COMEDIEN_VIDEOS='COMEDIEN_GET_FICHE_COMEDIEN_VIDEOS'
export const COMEDIEN_SHOW_FICHE_COMEDIEN_VIDEOS='COMEDIEN_SHOW_FICHE_COMEDIEN_VIDEOS'

//Ré-inscription
export const COMEDIEN_GET_RE_INSCRIPTION_FORM = 'COMEDIEN_GET_RE_INSCRIPTION_FORM';
export const COMEDIEN_SHOW_RE_INSCRIPTION_FORM = 'COMEDIEN_SHOW_RE_INSCRIPTION_FORM';
export const COMEDIEN_VERIF_PAIEMENT_RE_INSCRIPTION = 'COMEDIEN_VERIF_PAIEMENT_RE_INSCRIPTION';
export const COMEDIEN_SET_STATUS_PAIEMENT_RE_INSCRIPTION = 'COMEDIEN_SET_STATUS_PAIEMENT_RE_INSCRIPTION';
export const COMEDIEN_PAIEMENT_RE_INSCRIPTION_CHEQUE = 'COMEDIEN_PAIEMENT_RE_INSCRIPTION_CHEQUE';
export const COMEDIEN_SET_STATUS_PAIEMENT_RE_INSCRIPTION_CHEQUE = 'COMEDIEN_SET_STATUS_PAIEMENT_RE_INSCRIPTION_CHEQUE';

//Message
export const COMMON_GET_LIST_MESSAGES = 'COMMON_GET_LIST_MESSAGES';
export const COMMON_SHOW_LIST_MESSAGES = 'COMMON_SHOW_LIST_MESSAGES';

///////////////////////////////////////////////////////////////////////
// Administrateur
///////////////////////////////////////////////////////////////////////

//Liste de tous les comediens
export const ADMIN_GET_ALL_COMEDIENS = "ADMIN_GET_ALL_COMEDIENS";
export const ADMIN_SHOW_ALL_COMEDIENS = "ADMIN_SHOW_ALL_COMEDIENS";

//Gestion des utilisateurs
export const ADMIN_GET_LIST_UTILISATEURS = "ADMIN_GET_LIST_UTILISATEURS";
export const ADMIN_SHOW_LIST_UTILISATEURS = "ADMIN_SHOW_LIST_UTILISATEURS";
export const ADMIN_DELETE_UTILISATEUR = "ADMIN_DELETE_UTILISATEUR";
export const ADMIN_ACTIVATION_UTILISATEUR = "ADMIN_ACTIVATION_UTILISATEUR";
export const ADMIN_DESACTIVATION_UTILISATEUR = "ADMIN_DESACTIVATION_UTILISATEUR";
export const ADMIN_SWITCH_UTILISATEUR = "ADMIN_SWITCH_UTILISATEUR";
export const ADMIN_SWITCH_UTILISATEUR_SUCCESS = "ADMIN_SWITCH_UTILISATEUR_SUCCESS";
export const ADMIN_VERIF_EMAIL_UTILISATEUR = "ADMIN_VERIF_EMAIL_UTILISATEUR";

//Gestion des comediens
export const ADMIN_GET_LIST_COMEDIENS = "ADMIN_GET_LIST_COMEDIENS";
export const ADMIN_SHOW_LIST_COMEDIENS = "ADMIN_SHOW_LIST_COMEDIENS";
export const ADMIN_DELETE_COMEDIEN = "ADMIN_DELETE_COMEDIEN";
export const ADMIN_ACTIVATION_COMEDIEN = "ADMIN_ACTIVATION_COMEDIEN";
export const ADMIN_DESACTIVATION_COMEDIEN = "ADMIN_DESACTIVATION_COMEDIEN";
export const ADMIN_SEND_STATS_COMEDIEN = "ADMIN_SEND_STATS_COMEDIEN"
export const ADMIN_GET_ABONNEMENT_COMEDIEN = "ADMIN_GET_ABONNEMENT_COMEDIEN";
export const ADMIN_SHOW_ABONNEMENT_COMEDIEN = "ADMIN_SHOW_ABONNEMENT_COMEDIEN";
export const ADMIN_UPDATE_ABONNEMENT_COMEDIEN = "ADMIN_UPDATE_ABONNEMENT_COMEDIEN";
export const ADMIN_VALIDATION_PAIEMENT_CHEQUE_ABONNEMENT_COMEDIEN = "ADMIN_VALIDATION_PAIEMENT_CHEQUE_ABONNEMENT_COMEDIEN";
export const ADMIN_SET_REFRESH_ABONNEMENT_COMEDIEN = "ADMIN_SET_REFRESH_ABONNEMENT_COMEDIEN";
export const ADMIN_UNSET_REFRESH_ABONNEMENT_COMEDIEN = "ADMIN_UNSET_REFRESH_ABONNEMENT_COMEDIEN";
export const ADMIN_UPDATE_NOTES_COMEDIEN = "ADMIN_UPDATE_NOTES_COMEDIEN";
export const ADMIN_GET_NOTES_COMEDIEN = "ADMIN_GET_NOTES_COMEDIEN";
export const ADMIN_SHOW_NOTES_COMEDIEN = "ADMIN_SHOW_NOTES_COMEDIEN";

//Demandes d'inscriptions comediens
export const ADMIN_GET_DEMANDES_INSCRIPTIONS_COMEDIENS = 'ADMIN_GET_DEMANDES_INSCRIPTIONS_COMEDIENS';
export const ADMIN_SHOW_DEMANDES_INSCRIPTIONS_COMEDIENS = 'ADMIN_SHOW_DEMANDES_INSCRIPTIONS_COMEDIENS';
export const ADMIN_GET_DEMANDE_INSCRIPTION_COMEDIEN = 'ADMIN_GET_DEMANDE_INSCRIPTION_COMEDIEN';
export const ADMIN_SHOW_DEMANDE_INSCRIPTION_COMEDIEN = 'ADMIN_SHOW_DEMANDE_INSCRIPTION_COMEDIEN';
export const ADMIN_VALIDATION_DEMANDE_INSCRIPTION_COMEDIEN = 'ADMIN_VALIDATION_DEMANDE_INSCRIPTION_COMEDIEN';
export const ADMIN_REFUS_DEMANDE_INSCRIPTION_COMEDIEN = 'ADMIN_REFUS_DEMANDE_INSCRIPTION_COMEDIEN';
export const ADMIN_DELETE_DEMANDE_INSCRIPTION_COMEDIEN = 'ADMIN_DELETE_DEMANDE_INSCRIPTION_COMEDIEN';
export const ADMIN_VALIDATION_PAIEMENT_CHEQUE = 'ADMIN_VALIDATION_PAIEMENT_CHEQUE';
//News Comediens
export const ADMIN_GET_LIST_NEWS_COMEDIENS = "ADMIN_GET_LIST_NEWS_COMEDIENS";
export const ADMIN_SHOW_LIST_NEWS_COMEDIENS = "ADMIN_SHOW_LIST_NEWS_COMEDIENS";
export const ADMIN_DELETE_NEWS_COMEDIEN = 'ADMIN_DELETE_NEWS_COMEDIEN';
export const ADMIN_GET_NEWS = 'ADMIN_GET_NEWS';
export const ADMIN_SHOW_NEWS = 'ADMIN_SHOW_NEWS';
export const ADMIN_UPDATE_NEWS = 'ADMIN_UPDATE_NEWS';

//Extraits MP3
export const ADMIN_GET_MP3_COMEDIENS = "ADMIN_GET_MP3_COMEDIENS";
export const ADMIN_SHOW_MP3_COMEDIENS = "ADMIN_SHOW_MP3_COMEDIENS";

//Demandes de photo XL
export const ADMIN_GET_LIST_DEMANDES_PHOTOS_XL = 'ADMIN_GET_LIST_DEMANDES_PHOTOS_XL';
export const ADMIN_SHOW_LIST_DEMANDES_PHOTOS_XL = 'ADMIN_SHOW_LIST_DEMANDES_PHOTOS_XL';
export const ADMIN_VALIDATION_DEMANDE_PHOTO_XL = 'ADMIN_VALIDATION_DEMANDE_PHOTO_XL';
export const ADMIN_REFUS_DEMANDE_PHOTO_XL = 'ADMIN_REFUS_DEMANDE_PHOTO_XL';

//Photos XL
export const ADMIN_ADD_PHOTO_XL = 'ADMIN_ADD_PHOTO_XL';
export const ADMIN_GET_LISTE_PHOTOS_XL = 'ADMIN_GET_LISTE_PHOTOS_XL';
export const ADMIN_SHOW_LISTE_PHOTOS_XL = 'ADMIN_SHOW_LISTE_PHOTOS_XL';
export const ADMIN_DELETE_PHOTO_XL = 'ADMIN_DELETE_PHOTO_XL';
export const ADMIN_UPDATE_PHOTO_XL = 'ADMIN_UPDATE_PHOTO_XL';
export const ADMIN_GET_PHOTO_XL = 'ADMIN_GET_PHOTO_XL';
export const ADMIN_SHOW_PHOTO_XL = 'ADMIN_SHOW_PHOTO_XL';

//Blogs
export const ADMIN_ADD_BLOG = 'ADMIN_ADD_BLOG';
export const ADMIN_GET_LISTE_BLOGS = 'ADMIN_GET_LISTE_BLOG';
export const ADMIN_SHOW_LISTE_BLOGS = 'ADMIN_SHOW_LISTE_BLOG';
export const ADMIN_UPDATE_BLOG = 'ADMIN_UPDATE_BLOG';
export const ADMIN_DELETE_BLOG = 'ADMIN_DELETE_BLOG';
export const ADMIN_GET_BLOG = 'ADMIN_GET_BLOG';
export const ADMIN_SHOW_BLOG = 'ADMIN_SHOW_BLOG';

//Temoignages comédiens
export const ADMIN_ADD_TEMOIGNAGE_COMEDIEN = 'ADMIN_ADD_TEMOIGNAGE_COMEDIEN';
export const ADMIN_GET_LISTE_TEMOIGNAGES_COMEDIENS = 'ADMIN_GET_LISTE_TEMOIGNAGES_COMEDIENS';
export const ADMIN_SHOW_LISTE_TEMOIGNAGES_COMEDIENS = 'ADMIN_SHOW_LISTE_TEMOIGNAGES_COMEDIENS';
export const ADMIN_UPDATE_TEMOIGNAGE_COMEDIEN = 'ADMIN_UPDATE_TEMOIGNAGE_COMEDIEN';
export const ADMIN_DELETE_TEMOIGNAGE_COMEDIEN = 'ADMIN_DELETE_TEMOIGNAGE_COMEDIEN';
export const ADMIN_GET_TEMOIGNAGE_COMEDIEN = 'ADMIN_GET_TEMOIGNAGE_COMEDIEN';
export const ADMIN_SHOW_TEMOIGNAGE_COMEDIEN = 'ADMIN_SHOW_TEMOIGNAGE_COMEDIEN';
//Aide
export const ADMIN_ADD_AIDE = "ADMIN_ADD_AIDE";
export const ADMIN_GET_LISTE_AIDES = "ADMIN_GET_LISTE_AIDES";
export const ADMIN_SHOW_LISTE_AIDES = "ADMIN_SHOW_LISTE_AIDES";
export const ADMIN_DELETE_AIDE = "ADMIN_DELETE_AIDE";
export const ADMIN_GET_AIDE = "ADMIN_GET_AIDE";
export const ADMIN_SHOW_AIDE = "ADMIN_SHOW_AIDE";
export const ADMIN_UPDATE_AIDE = "ADMIN_UPDATE_AIDE";
export const ADMIN_SORT_AIDES = "ADMIN_SORT_AIDES";

//Articles
export const ADMIN_ADD_ARTICLE = "ADMIN_ADD_ARTICLE";
export const ADMIN_GET_LISTE_ARTICLES = "ADMIN_GET_LISTE_ARTICLES";
export const ADMIN_SHOW_LISTE_ARTICLES = "ADMIN_SHOW_LISTE_ARTICLES";
export const ADMIN_DELETE_ARTICLE = "ADMIN_DELETE_ARTICLE";
export const ADMIN_GET_ARTICLE = "ADMIN_GET_ARTICLE";
export const ADMIN_SHOW_ARTICLE = "ADMIN_SHOW_ARTICLE";
export const ADMIN_UPDATE_ARTICLE = "ADMIN_UPDATE_ARTICLE";

//Stats
export const ADMIN_GET_STATS = "ADMIN_GET_STATS";
export const ADMIN_SHOW_STATS = "ADMIN_SHOW_STATS";
export const ADMIN_GET_HISTORIQUE_STATS = "ADMIN_GET_HISTORIQUE_STATS";
export const ADMIN_SHOW_HISTORIQUE_STATS = "ADMIN_SHOW_HISTORIQUE_STATS";

//Config
export const ADMIN_UPDATE_CONFIG_RANKING_ALGOLIA = "ADMIN_UPDATE_CONFIG_RANKING_ALGOLIA";
export const ADMIN_GET_CONFIG_RANKING_ALGOLIA = "ADMIN_GET_CONFIG_RANKING_ALGOLIA";
export const ADMIN_SHOW_CONFIG_RANKING_ALGOLIA = "ADMIN_SHOW_CONFIG_RANKING_ALGOLIA";

//Profil
export const ADMIN_GET_PROFIL = "ADMIN_GET_PROFIL";
export const ADMIN_SHOW_PROFIL = "ADMIN_SHOW_PROFIL";
export const ADMIN_UPDATE_PROFIL = "ADMIN_UPDATE_PROFIL";

//Campagnes de Com
export const ADMIN_GET_LISTE_CAMPAGNES_COM = 'ADMIN_GET_LISTE_CAMPAGNES_COM';
export const ADMIN_SHOW_LISTE_CAMPAGNES_COM = 'ADMIN_SHOW_LISTE_CAMPAGNES_COM';
export const ADMIN_DELETE_CAMPAGNE_COM = 'ADMIN_DELETE_CAMPAGNE_COM';
export const ADMIN_ACTIVATION_CAMPAGNE_COM = 'ADMIN_ACTIVATION_CAMPAGNE_COM';
export const ADMIN_DESACTIVATION_CAMPAGNE_COM = 'ADMIN_DESACTIVATION_CAMPAGNE_COM';
export const ADMIN_ADD_CAMPAGNE_COM = 'ADMIN_ADD_CAMPAGNE_COM';
export const ADMIN_GET_CAMPAGNE_COM = 'ADMIN_GET_CAMPAGNE_COM';
export const ADMIN_SHOW_CAMPAGNE_COM = 'ADMIN_SHOW_CAMPAGNE_COM';
export const ADMIN_UPDATE_CAMPAGNE_COM = 'ADMIN_UPDATE_CAMPAGNE_COM';
export const ADMIN_GET_CAMPAGNE_COM_BY_USER = 'ADMIN_GET_CAMPAGNE_COM_BY_USER';
export const ADMIN_SHOW_CAMPAGNE_COM_BY_USER = 'ADMIN_SHOW_CAMPAGNE_COM_BY_USER';

//Medias
export const ADMIN_ADD_MEDIA = 'ADMIN_ADD_MEDIA';
export const ADMIN_GET_LISTE_MEDIAS = 'ADMIN_GET_LISTE_MEDIAS';
export const ADMIN_SHOW_LISTE_MEDIAS = 'ADMIN_SHOW_LISTE_MEDIAS';
export const ADMIN_DELETE_MEDIA = 'ADMIN_DELETE_MEDIA';

///////////////////////////////////////////////////////////////////////
// Partage
///////////////////////////////////////////////////////////////////////

// Partage d'extrait
export const PARTAGE_ADD_EXTRAIT = "PARTAGE_ADD_EXTRAIT";
export const PARTAGE_SHOW_EXTRAIT = "PARTAGE_SHOW_EXTRAIT";

export const PARTAGE_GET_EXTRAIT_BY_LINK = "PARTAGE_GET_EXTRAIT_BY_LINK";
export const PARTAGE_SHOW_EXTRAIT_BY_LINK = "PARTAGE_SHOW_EXTRAIT_BY_LINK";

// Partage de playlist
export const PARTAGE_ADD_PLAYLIST = "PARTAGE_ADD_PLAYLIST";
export const PARTAGE_SHOW_PLAYLIST = "PARTAGE_SHOW_PLAYLIST";

///////////////////////////////////////////////////////////////////////
// Log
///////////////////////////////////////////////////////////////////////

export const LOG_LECTURE_EXTRAIT = "LOG_LECTURE_EXTRAIT";
export const LOG_PARTAGE_EXTRAIT = "LOG_PARTAGE_EXTRAIT";
export const LOG_AJOUT_EXTRAIT_PLAYLIST = "LOG_AJOUT_EXTRAIT_PLAYLIST";
export const LOG_AFFICHAGE_TELEPHONE = "LOG_AFFICHAGE_TELEPHONE";
export const LOG_VISITE_FICHE = "LOG_VISITE_FICHE";


///////////////////////////////////////////////////////////////////////
// Auth
///////////////////////////////////////////////////////////////////////

//Sign In
export const SIGNIN = 'SIGNIN';
export const SIGNIN_SUCCESS = 'SIGNIN_SUCCESS';
export const SIGNIN_STATUS_INIT = 'SIGNIN_STATUS_INIT';
export const SIGNIN_STATUS_SUCCESS = 'SIGNIN_STATUS_SUCCESS';
export const SIGNIN_STATUS_FAIL = 'SIGNIN_STATUS_FAIL';
export const SIGNIN_STATUS_ACCOUNT_EXPIRED = 'SIGNIN_STATUS_ACCOUNT_EXPIRED';
export const SIGNIN_SET_STATUS = 'SIGNIN_SET_STATUS';

//Sign Out
export const SIGNOUT = 'SIGNOUT';
export const SIGNOUT_SUCCESS = 'SIGNOUT_SUCCESS';

//Sign Up
export const SIGNUP = 'SIGNUP';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_STATUS_INIT = 'SIGNUP_STATUS_INIT';
export const SIGNUP_STATUS_SUCCESS = 'SIGNUP_STATUS_SUCCESS';
export const SIGNUP_STATUS_FAIL_EMAIL_EXIST = 'SIGNUP_STATUS_FAIL_EMAIL_EXIST';
export const SIGNUP_STATUS_FAIL_COMPTE_FACEBOOK_EXIST = 'SIGNUP_STATUS_FAIL_COMPTE_FACEBOOK_EXIST';
export const SIGNUP_STATUS_FAIL = 'SIGNUP_STATUS_FAIL';
export const SIGNUP_SET_STATUS = 'SIGNUP_SET_STATUS';

//Sign Up Confirmation
export const SIGNUP_CONFIRM = 'SIGNUP_CONFIRM';
export const SIGNUP_CONFIRM_SUCCESS = 'SIGNUP_CONFIRM_SUCCESS';

//Forgot Password
export const AUTH_REQUEST_RESET_PASSWORD = 'AUTH_REQUEST_RESET_PASSWORD';
export const AUTH_SET_STATUS_REQUEST_RESET_PASSWORD = 'AUTH_SET_STATUS_REQUEST_RESET_PASSWORD';
export const AUTH_RESET_PASSWORD = 'AUTH_RESET_PASSWORD';
export const AUTH_SET_STATUS_RESET_PASSWORD = 'AUTH_SET_STATUS_RESET_PASSWORD';

//Identifiants
export const COMEDIEN_GET_AUTH = 'COMEDIEN_GET_AUTH';
export const COMEDIEN_SHOW_AUTH = 'COMEDIEN_SHOW_AUTH';
export const COMEDIEN_UPDATE_AUTH = 'COMEDIEN_UPDATE_AUTH';
export const COMEDIEN_UPDATE_AUTH_SUCCESS = 'COMEDIEN_UPDATE_AUTH_SUCCESS';
export const COMEDIEN_UPDATE_AUTH_ERROR = 'COMEDIEN_UPDATE_AUTH_ERROR';

//Mot de passe
export const COMEDIEN_UPDATE_PASSWORD = 'COMEDIEN_UPDATE_PASSWORD';
export const COMEDIEN_UPDATE_PASSWORD_SUCCESS = 'COMEDIEN_UPDATE_PASSWORD_SUCCESS';
export const COMEDIEN_UPDATE_PASSWORD_ERROR = 'COMEDIEN_UPDATE_PASSWORD_ERROR';

//Infos user
export const GET_INFOS_USER = "GET_INFOS_USER";
export const SHOW_INFOS_USER = "SHOW_INFOS_USER";

////////////////////////////



///////////////////////////////////////////////////////////////////////
// Playlist
///////////////////////////////////////////////////////////////////////

//List des playists
export const PLAYLIST_GET_LIST = "PLAYLIST_GET_LIST";
export const PLAYLIST_SHOW_LIST = "PLAYLIST_SHOW_LIST";
export const PLAYLISTS_GET_LISTE_DECOUVERTE = "PLAYLISTS_GET_LISTE_DECOUVERTE";
export const PLAYLISTS_SHOW_LISTE_DECOUVERTE = "PLAYLISTS_SHOW_LISTE_DECOUVERTE";
export const PLAYLIST_CREATE = "PLAYLIST_CREATE";
export const PLAYLIST_CREATE_ADD_EXTRAIT = "PLAYLIST_CREATE_ADD_EXTRAIT";
export const PLAYLIST_UPDATE = "PLAYLIST_UPDATE";
export const PLAYLIST_COPY = "PLAYLIST_COPY";
export const PLAYLIST_DELETE = "PLAYLIST_DELETE";
export const PLAYLISTS_GET_PARTAGE = "PLAYLISTS_GET_PARTAGE";
export const PLAYLISTS_SHOW_PARTAGE = "PLAYLISTS_SHOW_PARTAGE";
export const PLAYLIST_SELECT = "PLAYLIST_SELECT";
export const PLAYLIST_SORT_EXTRAITS = "PLAYLIST_SORT_EXTRAITS";
export const PLAYLIST_ADD_EXTRAIT = "PLAYLIST_ADD_EXTRAIT";
export const PLAYLIST_RETRAIT_EXTRAIT = "PLAYLIST_RETRAIT_EXTRAIT";

//Support Comedien
export const ADMIN_ADD_SUPPORT = "ADMIN_ADD_SUPPORT";
export const ADMIN_GET_LISTE_SUPPORTS_BY_COMEDIEN = 'ADMIN_GET_LISTE_SUPPORTS_BY_COMEDIEN';
export const ADMIN_SHOW_LISTE_SUPPORTS_BY_COMEDIEN = 'ADMIN_SHOW_LISTE_SUPPORTS_BY_COMEDIEN';
export const ADMIN_GET_SUPPORT_COMEDIEN = 'ADMIN_GET_SUPPORT_COMEDIEN';
export const ADMIN_SHOW_SUPPORT_COMEDIEN = 'ADMIN_SHOW_SUPPORT_COMEDIEN';
export const ADMIN_DELETE_SUPPORT_COMEDIEN = 'ADMIN_DELETE_SUPPORT_COMEDIEN';
export const ADMIN_UPDATE_SUPPORT_COMEDIEN = 'ADMIN_UPDATE_SUPPORT_COMEDIEN';









