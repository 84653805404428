import axios from "axios";
import { endpointPhotoXL } from 'util/api'

/**
 * Liste des photos XL
 * @param {*} payload 
 */
export const getListePhotosXLRequest = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  const { pageSize, page, keyword } = payload;

  // call WS Loopback
  const wsResponse = await axios.request({
    method: 'get',
    url: `${endpointPhotoXL.GET_PHOTOS}?access_token=${accessToken}`,
    params: {
      pageSize,
      page,
      keyword
    }
  });

  return wsResponse;
}

/**
 * Suppression d'une photo XL
 * @param {*} id 
 */
export const deletePhotoXLRequest = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  const { idPhoto } = payload;

  const wsResponse = await axios.request({
    method: 'delete',
    url: `${endpointPhotoXL.DELETE_PHOTO}?access_token=${accessToken}`,
    data: {
      idPhoto
    }
  });

  return wsResponse;
}

/**
 * Ajout d'une photo XL
 * @param {*} payload 
 */
export const addPhotoXLRequest = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';
  const formData = new FormData();
  const { values, photo } = payload;

  formData.append("file", photo);
  formData.append("phrase", values.phrase);
  formData.append("idComedien", values.idComedien);

  const wsResponse = await axios.request({
    method: 'post',
    url: `${endpointPhotoXL.ADD_PHOTO}?access_token=${accessToken}`,
    data: formData,
    config: {
      headers: {
        'Content-Type': `multipart/form-data`,
        'Accept': 'application/json',
      }
    }
  });

  return wsResponse;
}

/**
 * Modification d'une photo XL
 * @param {*} values 
 * @param {*} photo 
 */
export const updatePhotoXLRequest = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  const {idPhoto, values, photo} = payload;

  const formData = new FormData();

  formData.append("file", photo);
  formData.append("idPhoto", idPhoto);
  formData.append("phrase", values.phrase);
  formData.append("idComedien", values.idComedien);

  const wsResponse = await axios.request({
    method: 'post',
    url: `${endpointPhotoXL.UPDATE_PHOTO}?access_token=${accessToken}`,
    data: formData,
    config: {
      headers: {
        'Content-Type': `multipart/form-data`,
        'Accept': 'application/json',
      }
    }
  });

  return wsResponse;
}

/**
 * Photo XL
 * @param {*} payload 
 */
export const getPhotoXLRequest = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  const { idPhoto } = payload;

  // call WS Loopback
  const wsResponse = await axios.request({
    method: 'get',
    url: `${endpointPhotoXL.GET_PHOTO}?access_token=${accessToken}`,
    params: {
      idPhoto,
    }
  });

  return wsResponse;
}

/////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////






export const getSlidesPhotosXLRequest = async () => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  // call WS Loopback
  const wsResponse = await axios.request({
    method: 'get',
    url: `${endpointPhotoXL.GET_SLIDES}?access_token=${accessToken}`,
  });

  return wsResponse;
}





