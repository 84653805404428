import React, { Component } from "react";
import { connect } from "react-redux";
import { Menu } from "antd";
import { NavLink  } from "react-router-dom";
import IntlMessages from "util/IntlMessages";
import { injectIntl } from 'react-intl';
import { getInfosUserAction } from "appRedux/actions/Auth";
import { APPLICATION_PATHS } from "constants/ApplicationPaths";
import { withRouter } from "util/withRouter";

const SubMenu = Menu.SubMenu;

class MenuAdmin extends Component {
  constructor() {
    super();
    this.state = {
      dataInfosUser: {},
    }
  }

  render() {

    const { pathname } = this.props;
    const selectedKeys = pathname.substr(1);
    const defaultOpenKeys = selectedKeys.split('/')[1];

    return (
      <div>

        <div className={'cm-side-bar-menu-separator'}></div>

          <Menu
            defaultOpenKeys={[defaultOpenKeys]}
            selectedKeys={[selectedKeys]}
            theme='dark'
            mode="inline"
          >

            <Menu.Item key="Administrateur/GestionUtilisateurs">
              <NavLink to="/Administrateur/GestionUtilisateurs" className={({ isActive }) => (isActive ? 'ant-menu-item-selected' : '')}>
                <IntlMessages id="menu.admin.gestionUtilisateurs" />
              </NavLink>
            </Menu.Item>

            <Menu.Item key="Administrateur/GestionComediens">
              <NavLink to="/Administrateur/GestionComediens" className={({ isActive }) => (isActive ? 'ant-menu-item-selected' : '')}>
                <IntlMessages id="menu.admin.gestionComediens" />
              </NavLink>
            </Menu.Item>

            <Menu.Item key="Administrateur/DemandesInscriptionsComediens">
              <NavLink to="/Administrateur/DemandesInscriptionsComediens" className={({ isActive }) => (isActive ? 'ant-menu-item-selected' : '')}>
                <IntlMessages id="menu.admin.demandesInscriptions" />
              </NavLink>
            </Menu.Item>

            <Menu.Item key="Administrateur/NewsComediens">
              <NavLink to="/Administrateur/NewsComediens" className={({ isActive }) => (isActive ? 'ant-menu-item-selected' : '')}>
                <IntlMessages id="menu.admin.newsComediens" />
              </NavLink>
            </Menu.Item>

            <Menu.Item key="Administrateur/ExtraitsMP3">
              <NavLink to="/Administrateur/ExtraitsMP3" className={({ isActive }) => (isActive ? 'ant-menu-item-selected' : '')}>
                <IntlMessages id="menu.admin.extraitsMP3" />
              </NavLink>
            </Menu.Item>

            <Menu.Item key="Administrateur/Stats">
              <NavLink to="/Administrateur/Stats" className={({ isActive }) => (isActive ? 'ant-menu-item-selected' : '')}>
                <IntlMessages id="menu.admin.stats" />
              </NavLink>
            </Menu.Item>

            <Menu.Item key="Administrateur/MesPlaylists">
              <NavLink to="/Administrateur/MesPlaylists" className={({ isActive }) => (isActive ? 'ant-menu-item-selected' : '')}>
                <IntlMessages id="menu.admin.playlists" />
              </NavLink>
            </Menu.Item>

            <SubMenu
              key="contenu"
              title={<IntlMessages id="menu.admin.photosXL.gestion" />}
            >
              <Menu.Item key="Administrateur/DemandesPhotosXL">
                <NavLink to="/Administrateur/DemandesPhotosXL" className={({ isActive }) => (isActive ? 'ant-menu-item-selected' : '')}>
                  <IntlMessages id="menu.admin.photosXL.demandes" />
                </NavLink>
              </Menu.Item>

              <Menu.Item key="Administrateur/PhotosXL">
                <NavLink to="/Administrateur/PhotosXL" className={({ isActive }) => (isActive ? 'ant-menu-item-selected' : '')}>
                  <IntlMessages id="menu.admin.photosXL.photos" />
                </NavLink>
              </Menu.Item>

            </SubMenu>

            <Menu.Item key="Administrateur/Blogs">
              <NavLink to="/Administrateur/Blogs" className={({ isActive }) => (isActive ? 'ant-menu-item-selected' : '')}>
                <IntlMessages id="menu.admin.blogs" />
              </NavLink>
            </Menu.Item>

            <Menu.Item key="Administrateur/TemoignagesComediens">
              <NavLink to="/Administrateur/TemoignagesComediens" className={({ isActive }) => (isActive ? 'ant-menu-item-selected' : '')}>
                <IntlMessages id="menu.admin.temoignagesComediens" />
              </NavLink>
            </Menu.Item>

            <Menu.Item key="Administrateur/CampagnesCom">
              <NavLink to="/Administrateur/CampagnesCom" className={({ isActive }) => (isActive ? 'ant-menu-item-selected' : '')}>
                <IntlMessages id="menu.admin.campagnesCom" />
              </NavLink>
            </Menu.Item>

            <SubMenu
              key="cms"
              title={<IntlMessages id="menu.admin.cms" />}
            >
              <Menu.Item key="Administrateur/AdminCGU">
                <NavLink to="/Administrateur/AdminCGU" className={({ isActive }) => (isActive ? 'ant-menu-item-selected' : '')}>
                  <IntlMessages id="menu.admin.cms.cgu" />
                </NavLink>
              </Menu.Item>
              <Menu.Item key="Administrateur/AdminRGPD">
                <NavLink to="/Administrateur/AdminRGPD" className={({ isActive }) => (isActive ? 'ant-menu-item-selected' : '')}>
                  <IntlMessages id="menu.admin.cms.rgpd" />
                </NavLink>
              </Menu.Item>
              <Menu.Item key="Administrateur/AdminAide">
                <NavLink to="/Administrateur/AdminAide" className={({ isActive }) => (isActive ? 'ant-menu-item-selected' : '')}>
                  <IntlMessages id="menu.admin.cms.aide" />
                </NavLink>
              </Menu.Item>
              <Menu.Item key="Administrateur/AdminArticles">
                <NavLink to="/Administrateur/AdminArticles" className={({ isActive }) => (isActive ? 'ant-menu-item-selected' : '')}>
                  <IntlMessages id="menu.admin.cms.articles" />
                </NavLink>
              </Menu.Item>
              <Menu.Item key="Administrateur/AdminMedias">
                <NavLink to="/Administrateur/AdminMedias" className={({ isActive }) => (isActive ? 'ant-menu-item-selected' : '')}>
                  <IntlMessages id="menu.admin.cms.medias" />
                </NavLink>
              </Menu.Item>
            </SubMenu>

            <SubMenu
              key="config"
              title={<IntlMessages id="menu.admin.config" />}
            >
              <Menu.Item key="Administrateur/AlgoliaRanking">
                <NavLink to="/Administrateur/AlgoliaRanking" className={({ isActive }) => (isActive ? 'ant-menu-item-selected' : '')}>
                  <IntlMessages id="menu.admin.config.algoliaRanking" />
                </NavLink>
              </Menu.Item>
            </SubMenu>


            <Menu.Item key="Administrateur/MonCompteAdmin">
              <NavLink to="/Administrateur/MonCompteAdmin" className={({ isActive }) => (isActive ? 'ant-menu-item-selected' : '')}>
                <IntlMessages id="menu.admin.monCompte" />
              </NavLink>
            </Menu.Item>
            <div className={'cm-side-bar-menu-separator'}></div>

            <Menu.Item key={APPLICATION_PATHS.HOME}>
              <NavLink to={APPLICATION_PATHS.HOME} className={({ isActive }) => (isActive ? 'ant-menu-item-selected' : '')}>
                <IntlMessages id="menu.admin.home" />
              </NavLink>
            </Menu.Item>

            <Menu.Item key={APPLICATION_PATHS.INSCRIPTION_COMEDIEN}>
              <NavLink to={APPLICATION_PATHS.INSCRIPTION_COMEDIEN} className={({ isActive }) => (isActive ? 'ant-menu-item-selected' : '')}>
                <IntlMessages id="menu.admin.homeComediens" />
              </NavLink>
            </Menu.Item>

          </Menu>
      </div>
    );
  }

}

const mapStateToProps = ({ settings, auth }) => {
  const {
    pathname
  } = settings;

  const {
    dataInfosUser
  } = auth;

  return {
    pathname,
    dataInfosUser
  }
};

export default withRouter(
  injectIntl(
    connect(
      mapStateToProps,
      {
        getInfosUserAction,
      }
    )(MenuAdmin)
  )
);