import React, { Component } from "react";
import { Modal, Checkbox } from "antd";
import { connect } from "react-redux";
import { withRouter } from "util/withRouter";
import IntlMessages from "util/IntlMessages";
import { injectIntl } from 'react-intl';

import {
  getCampagneComByUserAction,
} from "appRedux/actions/Administrateur";

import Parser from 'html-react-parser';

class CampagneCom extends Component {

  constructor(props) {
    super(props);
    this.state = {
      dataCampagneComByUser: null,
      showModalCampagne: false,
    };
  }

  componentDidMount() {
    var dontShowCampagneCom = localStorage.getItem("dontShowCampagneCom");

    if (!dontShowCampagneCom) {

      var today = new Date();
      var dd = String(today.getDate()).padStart(2, '0');
      var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      var yyyy = today.getFullYear();
      var todayFormatted = dd + '/' + mm + '/' + yyyy;

      var dateLastShowModalCampagneCom = localStorage.getItem("dateLastShowModalCampagneCom");

      if (!dateLastShowModalCampagneCom || dateLastShowModalCampagneCom !== todayFormatted) {
        this.props.getCampagneComByUserAction();
      }

    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.dataCampagneComByUser !== prevState.dataCampagneComByUser && nextProps.dataCampagneComByUser) {

      var today = new Date();
      var dd = String(today.getDate()).padStart(2, '0');
      var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      var yyyy = today.getFullYear();
      var todayFormatted = dd + '/' + mm + '/' + yyyy;

      localStorage.setItem('dateLastShowModalCampagneCom', todayFormatted);

      return {
        dataCampagneComByUser: nextProps.dataCampagneComByUser,
        showModalCampagne: true
      };
    } else {
      return null;
    }
  }

  //////////////////////////////////////////////////////////////
  // Modal apercu
  /////////////////////////////////////////////////////////////

  /*onShowModalCampagne = (lienTypeform) => {
    this.setState({
      showModalCampagne: true,
      lienTypeform: lienTypeform,
    });
  }*/

  onCancelModalOverview = () => {
    this.setState({
      showModalCampagne: false
    })
  }

  onChangeViewMsgCheck = (e) => {
    if (e.target.checked) {
      localStorage.setItem('dontShowCampagneCom', 1);
    } else {
      localStorage.removeItem('dontShowCampagneCom');
    }
  };

  renderModalCampagne() {
    return (
      <Modal
        title=""
        visible={this.state.showModalCampagne}
        onCancel={this.onCancelModalOverview}
        wrapClassName="cm-admin-campagnes-com-overview-modal"
        destroyOnClose={true}
        footer={[/*
      <Button key="cancel" onClick={this.onCancelModalOverview}>
        OK
      </Button>,
*/]}
      >
        {/*<iframe src={this.state.dataCampagneComByUser ? this.state.dataCampagneComByUser.lienTypeform : ""} className="cm-admin-campagnes-com-overview-iframe"></iframe>*/}

        <div>
          {Parser(this.state.dataCampagneComByUser ? this.state.dataCampagneComByUser.contenuHtml : "")}

          {this.state.dataCampagneComByUser && this.state.dataCampagneComByUser.ajoutLienPage ?
            <div className="cm-campagnes-com-btn-lien-page">
              <a href={this.state.dataCampagneComByUser.lienPageUrl} target="_blank" className="ant-btn ant-btn-primary ant-btn-lg">
                {this.state.dataCampagneComByUser.lienPageText}
              </a>
            </div>
            : null}

        </div>

        <div className="cm-admin-campagnes-com-overview-checkbox-container">
          <Checkbox onChange={this.onChangeViewMsgCheck}>
            <IntlMessages id="admin.campagnesCom.overview.viewMsgCheckboxText" />
          </Checkbox>
        </div>
      </Modal>
    )
  }



  render() {
    const { dataCampagneComByUser } = this.state;


    return (
      <div>
        {this.renderModalCampagne()}
      </div>
    );
  }

}

const mapStateToProps = ({ administrateur }) => {

  const {
    dataCampagneComByUser
  } = administrateur;

  return {
    dataCampagneComByUser
  }
};

export default withRouter(injectIntl(connect(
  mapStateToProps,
  {
    getCampagneComByUserAction
  }
)(CampagneCom)));
