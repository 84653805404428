import React, { Component } from "react";
import { connect } from "react-redux";
import Auxiliary from "util/Auxiliary";
import Avatar from "components/Avatar";
import { injectIntl } from 'react-intl';
import MenuAdmin from '../Menus/MenuAdmin';
import MenuUser from '../Menus/MenuUser';
import MenuComedien from '../Menus/MenuComedien';
import AlgoliaFilter from '../Menus/AlgoliaFilter';
import CustomScrollbars from "util/CustomScrollbars";

import {
  USER_ROLE_COMEDIEN,
  USER_ROLE_UTILISATEUR,
  USER_ROLE_ADMIN,
} from "constants/General";

import noAvatar from "assets/images/no-avatar.webp";
import { getInfosUserAction } from "appRedux/actions/Auth";
import { APPLICATION_PATHS } from "constants/ApplicationPaths";
import { getCurrentUserRole } from 'util/Auth';
import { withRouter } from "util/withRouter";

class SidebarContent extends Component {
  constructor() {
    super();
    this.state = {
      dataInfosUser: {},
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.dataInfosUser !== prevState.dataInfosUser && nextProps.dataInfosUser) {
      return { dataInfosUser: nextProps.dataInfosUser };
    } else return null;
  }

  componentDidMount() {
    this.props.getInfosUserAction();
    this.userRole = getCurrentUserRole();
  }

  renderMenus() {
    let userAvatar = ""

    if (this.state.dataInfosUser.photo && (this.state.dataInfosUser.photo !== '')) {
      userAvatar = this.state.dataInfosUser.photo;
    } else {
      userAvatar = noAvatar;
    }

    return (
      <Auxiliary>
        <div className="cm-sidebar-menu-content">

          <div className="cm-sider-avatar">
            <Avatar
              src={userAvatar}
              width="96px"
              height="96px"
            />
          </div>

          <div className="cm-sider-user-name">
            {this.state.dataInfosUser.prenom + ' ' + this.state.dataInfosUser.nom}
          </div>

          <CustomScrollbars className="gx-layout-sider-scrollbar">
            {Number(this.userRole) === USER_ROLE_COMEDIEN ?
              <MenuComedien />
              :
              null
            }

            {Number(this.userRole) === USER_ROLE_UTILISATEUR ?
              <MenuUser />
              :
              null
            }

            {Number(this.userRole) === USER_ROLE_ADMIN ?
              <MenuAdmin />
              :
              null
            }
          </CustomScrollbars>

        </div>
      </Auxiliary>
    );
  }

  render() {
    if (window.location.pathname === APPLICATION_PATHS.RECHERCHE) {
      return (<AlgoliaFilter />);
    } else if (this.props.page === 'ficheComedienUrl') {
      return (<AlgoliaFilter />);
    } else {
      return (this.renderMenus());
    }
  }
}

SidebarContent.propTypes = {};

const mapStateToProps = ({ auth }) => {
  const {
    dataInfosUser
  } = auth;

  return {
    dataInfosUser
  }
};

export default withRouter(
  injectIntl(
    connect(
      mapStateToProps,
      {
        getInfosUserAction,
      }
    )(SidebarContent)
  )
);