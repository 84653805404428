import React from "react";
import PropTypes from "prop-types";

const Avatar = ({ src, width, height, style, alt }) => {

  //var avatarStyle={backgroundImage:'url("' + src + '")',width:width,height:height};
  var avatarStyle = { width: width, height: height };

  let key;
  for (key in style) {
    if (style.hasOwnProperty(key)) {
      avatarStyle[key] = style[key];
    }
  }

  return (
    <div className="cm-avatar" style={avatarStyle}>
      <img src={src} alt={alt || ''} />
    </div>
  )
};

export default Avatar;
Avatar.defaultProps = {
  src: '',
  width: '96px',
  height: '96px',
  style: {}
};

Avatar.propTypes = {
  src: PropTypes.node,
  width: PropTypes.node,
  height: PropTypes.node,
  style: PropTypes.object,
};
