import axios from "axios";
import { endpointCampagnesCom } from 'util/api'

/**
 * Liste des témoignages
 */
export const getListeCampagnesComRequest = async () => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  // call WS Loopback
  const wsResponse = await axios.request({
    method: 'get',
    url: `${endpointCampagnesCom.GET_CAMPAGNES}?access_token=${accessToken}`,
  });

  return wsResponse;
}


/**
 * Suppression d'une campagne
 * @param {*} payload 
 */
export const deleteCampagneComRequest = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  const { idCampagne } = payload;

  const wsResponse = await axios.request({
    method: 'delete',
    url: `${endpointCampagnesCom.DELETE_CAMPAGNE}?access_token=${accessToken}`,
    data: {
      idCampagne
    }
  });

  return wsResponse;
}


/**
 * Activation d'une campagne
 * @param {*} payload 
 */
export const activationCompagneComRequest = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  let { idCampagne } = payload;

  const wsResponse = await axios.request({
    method: 'POST',
    url: `${endpointCampagnesCom.ACTIVATION_CAMPAGNE}?access_token=${accessToken}`,
    data: {
      idCampagne
    }
  });

  return wsResponse;
}

/**
 * Desactivation d'une campagne
 * @param {*} payload 
 */
export const desactivationCompagneComRequest = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  let { idCampagne } = payload;

  const wsResponse = await axios.request({
    method: 'POST',
    url: `${endpointCampagnesCom.DESACTIVATION_CAMPAGNE}?access_token=${accessToken}`,
    data: {
      idCampagne
    }
  });

  return wsResponse;
}


/**
 * Ajout d'un témoignage
 * @param {*} payload 
 */
export const addCampagneComRequest = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  const { values } = payload;

  const formData = new FormData();

  formData.append("campagne", values.campagne);
  formData.append("lienTypeform", values.lienTypeform);
  formData.append("contenuHtml", values.contenuHtml);
  formData.append("utilisateur", values.utilisateur ? 1 : 0);
  formData.append("comedien", values.comedien ? 1 : 0);
  formData.append("test", values.test ? 1 : 0);
  formData.append("ajoutLienPage", values.ajoutLienPage ? 1 : 0);
  formData.append("lienPageText", values.ajoutLienPage ? values.lienPageText : "");
  formData.append("lienPageUrl", values.ajoutLienPage ? values.lienPageUrl : "");


  const wsResponse = await axios.request({
    method: 'post',
    url: `${endpointCampagnesCom.ADD_CAMPAGNE}?access_token=${accessToken}`,
    data: formData,
    config: {
      headers: {
        'Content-Type': `multipart/form-data`,
        'Accept': 'application/json',
      }
    }
  });

  return wsResponse;
}


/**
 * Campagne
 */
export const getCampagneComRequest = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  const { idCampagne } = payload;
  // call WS Loopback
  const wsResponse = await axios.request({
    method: 'get',
    url: `${endpointCampagnesCom.GET_CAMPAGNE}?access_token=${accessToken}`,
    params: {
      idCampagne
    }
  });

  return wsResponse;
}


/**
 * Modification d'une campagne
 * @param {*} payload 
 */
export const updateCampagneComRequest = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  const { idCampagne, values } = payload;

  const formData = new FormData();


  formData.append("idCampagne", idCampagne);
  formData.append("campagne", values.campagne);
  formData.append("lienTypeform", values.lienTypeform);
  formData.append("contenuHtml", values.contenuHtml);
  formData.append("utilisateur", values.utilisateur ? 1 : 0);
  formData.append("comedien", values.comedien ? 1 : 0);
  formData.append("test", values.test ? 1 : 0);
  formData.append("ajoutLienPage", values.ajoutLienPage ? 1 : 0);
  formData.append("lienPageText", values.ajoutLienPage ? values.lienPageText : "");
  formData.append("lienPageUrl", values.ajoutLienPage ? values.lienPageUrl : "");

  const wsResponse = await axios.request({
    method: 'post',
    url: `${endpointCampagnesCom.UPDATE_CAMPAGNE}?access_token=${accessToken}`,
    data: formData,
    config: {
      headers: {
        'Content-Type': `multipart/form-data`,
        'Accept': 'application/json',
      }
    }
  });

  return wsResponse;
}



/**
 * Campagne
 */
export const getCampagneComByUserRequest = async () => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  // call WS Loopback
  const wsResponse = await axios.request({
    method: 'get',
    url: `${endpointCampagnesCom.GET_CAMPAGNE_BY_USER}?access_token=${accessToken}`,
    params: {
    }
  });

  return wsResponse;
}


