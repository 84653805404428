import axios from "axios";
import { endpointVideo } from 'util/api';

/**
 * R�cup�ration de la liste des videos d'un comedien
 * @param {*} payload 
 */
export const getListeVideosRequest = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  const { idUser } = payload;

  // call WS Loopback
  const wsResponse = await axios.request({
    method: 'get',
    url: `${endpointVideo.GET_LIST_VIDEOS}?access_token=${accessToken}`,
    params: {
      idUser
    }
  });

  return wsResponse;
}

/**
 * Modification de la visibilit� d'une vid�o
 * @param {*} payload 
 */
export const updateVisibiliteVideoRequest = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  const { visibilite, id } = payload;

  const wsResponse = await axios.request({
    method: 'post',
    url: `${endpointVideo.UPDATE_VISIBILITE}?access_token=${accessToken}`,
    config: {
      headers: {
        'Content-Type': `application/x-www-form-urlencoded`,
        'Accept': 'application/json',
      }
    },
    data: {
      id,
      visibilite
    }
  });

  return wsResponse;
}

/**
 * Suppression d'une video
 * @param {*} id 
 */
export const deleteExtraitVideoRequest = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  const { id } = payload;

  const wsResponse = await axios.request({
    method: 'delete',
    url: `${endpointVideo.DELETE_VIDEO}?access_token=${accessToken}`,
    data: {
      id
    }
  });

  return wsResponse;
}

export const sortExtraitsVideoRequest = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  const { sortString } = payload;

  const wsResponse = await axios.request({
    method: 'post',
    url: `${endpointVideo.SORT_VIDEOS}?access_token=${accessToken}`,
    config: {
      headers: {
        'Content-Type': `multipart/form-data`,
        'Accept': 'application/json',
      }
    },
    data: {
      sortString
    },
  });

  return wsResponse;
}

/**
 * Ajout d'une video
 * @param {*} payload 
 */
export const addVideoRequest = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  const { values, file } = payload;

  const formData = new FormData();

  formData.append("typeMedia", values.typeMedia);
  formData.append("file", file);
  formData.append("lienYoutube", values.lienYoutube);
  formData.append("lienVimeo", values.lienVimeo);
  formData.append("titre", values.titre);
  //formData.append("description", values.description);
  formData.append("qfType", values.qfType);
  formData.append("qfLangue", values.qfLangue);

  const wsResponse = await axios.request({
    method: 'post',
    url: `${endpointVideo.ADD_VIDEO}?access_token=${accessToken}`,
    config: {
      headers: {
        'Content-Type': `multipart/form-data`,
        'Accept': 'application/json',
      }
    },
    data: formData,
  });
  return wsResponse;
}

/**
 * R�cup�ration d'une video
 * @param {*} payload 
 */
export const getExtraitVideoRequest = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  let { idExtrait } = payload;

  // call WS Loopback
  const wsResponse = await axios.request({
    method: 'get',
    url: `${endpointVideo.GET_VIDEO}?access_token=${accessToken}`,
    params: {
      idExtrait
    }
  });

  return wsResponse;
}

/**
 * Modification d'une video
 * @param {*} payload 
 */
export const updateVideoRequest = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  const { values, file } = payload;

  const formData = new FormData();

  formData.append("typeMedia", values.typeMedia);
  formData.append("file", file);
  formData.append("id", values.id);
  formData.append("titre", values.titre);
  //formData.append("description", values.description);
  formData.append("qfType", values.qfType);
  formData.append("qfLangue", values.qfLangue);
  formData.append("lienYoutube", values.lienYoutube);
  formData.append("lienVimeo", values.lienVimeo);

  const wsResponse = await axios.request({
    method: 'post',
    url: `${endpointVideo.UPDATE_VIDEO}?access_token=${accessToken}`,
    config: {
      headers: {
        'Content-Type': `multipart/form-data`,
        'Accept': 'application/json',
      }
    },
    data: formData,
  });
  return wsResponse;
}

/**
 * Stats Videos
 */
export const getStatsVideosRequest = async () => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  const wsResponse = await axios.request({
    method: 'get',
    url: `${endpointVideo.GET_STATS}?access_token=${accessToken}`,
  });

  return wsResponse;
}

export const getFicheComedienVideosRequest = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  let { idComedien,pageSize, page } = payload;

  console.log("CCCCCCCCCCCCCCCCCCCCCC id : ", idComedien)


  const wsResponse = await axios.request({
    method: 'get',
    url: `${endpointVideo.GET_FICHE_COMEDIEN_VIDEOS}?access_token=${accessToken}`,
    params: {
      idComedien,
      pageSize,
      page
    }
  });
  return wsResponse;
}

