// ⚠️ This example only works with the version 2.x of Rheostat

import React, { Component } from "react";
import Rheostat from 'rheostat';
import IntlMessages from "util/IntlMessages";

import{
  ALGOLIA_INDEX_TONE_GRAVE,
  ALGOLIA_INDEX_TONE_MEDIUM_GRAVE,
  ALGOLIA_INDEX_TONE_MEDIUM,
  ALGOLIA_INDEX_TONE_MEDIUM_AIGU,
  ALGOLIA_INDEX_TONE_AIGU
}from "constants/General";

class RangeSliderTimbre extends Component {
  state = {
    minValue: 1,
    maxValue: 5,
    min: this.props.min,
    max: this.props.max,
  }

  componentDidUpdate(prevProps) {
    const { currentRefinement } = prevProps;
    if (
      this.props.canRefine &&
      ( 
        currentRefinement.min !== this.props.currentRefinement.min ||
        currentRefinement.max !== this.props.currentRefinement.max
      )
    ) {
      this.setState({
        ...currentRefinement,
      });
    }
  }

  getAlgoliaIndexValue = (value) => {
    return value;
  }

  onChange = ({ values: [min, max] }) => {
    const { currentRefinement, refine } = this.props;

    let minAlgolia = this.getAlgoliaIndexValue(min);
    let maxAlgolia = this.getAlgoliaIndexValue(max);

    //if (currentRefinement.min !== minAlgolia || currentRefinement.max !== maxAlgolia) {
    refine({
      min:minAlgolia,
      max:maxAlgolia,
    });
    //}
  }

  onValuesUpdated = ({ values: [min, max] }) => {
    
    this.setState({
      min:min,
      max:max,
      minValue:min,
      maxValue:max
    });
  }

  render() {
    const { min, max, currentRefinement } = this.props;

    if(this.props.resetTimbreRange) {
      this.setState({
        minValue : 1,
        maxValue : 5
      });
      this.props.setResetTimbreRange();
    } 

    if (min === max) {
      return null;
    }

    let minAlgolia = this.getAlgoliaIndexValue(this.state.minValue);
    let maxAlgolia = this.getAlgoliaIndexValue(this.state.maxValue);

    var classGrave        = 'rheostat-value-selected';
    var classMediumGrave  = 'rheostat-value-selected';
    var classMedium       = 'rheostat-value-selected';
    var classMediumAigu   = 'rheostat-value-selected';
    var classAigu         = 'rheostat-value-selected';

    if(minAlgolia === ALGOLIA_INDEX_TONE_GRAVE){
      //Nothing to do
    }else if(minAlgolia === ALGOLIA_INDEX_TONE_MEDIUM_GRAVE){
      classGrave = '';
    }else if(minAlgolia === ALGOLIA_INDEX_TONE_MEDIUM){
      classGrave        = '';
      classMediumGrave  = '';
    }else if(minAlgolia === ALGOLIA_INDEX_TONE_MEDIUM_AIGU){
      classGrave        = '';
      classMediumGrave  = '';
      classMedium       = '';
    }else if(minAlgolia === ALGOLIA_INDEX_TONE_AIGU){
      classGrave        = '';
      classMediumGrave  = '';
      classMedium       = '';
      classMediumAigu   = '';
    }

    if(maxAlgolia == ALGOLIA_INDEX_TONE_AIGU){
      //Nothing to do
    }else if(maxAlgolia == ALGOLIA_INDEX_TONE_MEDIUM_AIGU){
      classAigu = '';
    }else if(maxAlgolia == ALGOLIA_INDEX_TONE_MEDIUM){
      classAigu       = '';
      classMediumAigu = '';
    }else if(maxAlgolia == ALGOLIA_INDEX_TONE_MEDIUM_GRAVE){
      classAigu       = '';
      classMediumAigu = '';
      classMedium     = '';
    }else if(maxAlgolia == ALGOLIA_INDEX_TONE_GRAVE){
      classAigu         = '';
      classMediumAigu   = '';
      classMedium       = '';
      classMediumGrave  = '';
    }

    return (
      <div className="cm-search-rangeslider-timbre">
          <div style={{marginBottom:"10px"}}>
            <div 
                className="rheostat-value"
                style={{ float:"left" }}
            >
                {/*this.state.minValue*/}
            </div>

            <div 
              className="rheostat-value"
              style={{ float:"right" }}
            >
              {/*this.state.maxValue*/}
            </div>
          </div>


        <Rheostat
          className="ais-RangeSlider"
          min={min}
          max={max}
          values={[this.state.minValue, this.state.maxValue]}
          onChange={this.onChange}
          onValuesUpdated={this.onValuesUpdated}
        >
          
          <div
            className="rheostat-marker rheostat-marker--large"
            style={{ left: 0 }}
          >
          </div>

          <div
            className="rheostat-marker rheostat-marker--large"
            style={{ right: 0 }}
          >
          </div>
        </Rheostat>

        <div style={{width:"100%",display:"flex",marginTop:"15px"}}>
          <div className={"cm-rheostat-interval " + classGrave} style={{width:"20%",textAlign:"center"}}>
            <IntlMessages id="rangeSliderTimbre.grave" />
          </div>
          <div className={"cm-rheostat-interval " + classMediumGrave} style={{width:"20%",textAlign:"center"}}>
          </div>
          <div className={"cm-rheostat-interval " + classMedium} style={{width:"20%",textAlign:"center"}}>
            <IntlMessages id="rangeSliderTimbre.medium" />
          </div>
          <div className={"cm-rheostat-interval " + classMediumAigu} style={{width:"20%",textAlign:"center"}}>
          </div>
          <div className={"cm-rheostat-interval " + classAigu} style={{width:"20%",textAlign:"right"}}>
            <IntlMessages id="rangeSliderTimbre.aigu" />
          </div>
        </div>
      </div>
    );
  }
}

 export default RangeSliderTimbre;