import axios from "axios";
import { endpointAide } from 'util/api'

/**
 * R�cup�ration de la liste des aides
 * @param {*} payload 
 */
export const getListeAidesRequest = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  var { keyword } = payload;

  const wsResponse = await axios.request({
    method: 'get',
    url: `${endpointAide.GET_LIST_AIDES}?access_token=${accessToken}`,
    params: {
      keyword
    }
  })

  return wsResponse;
}

/**
 * Tri des aides
 * @param {*} payload 
 */
export const sortAidesRequest = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  const { sortString } = payload;

  const wsResponse = await axios.request({
    method: 'post',
    url: `${endpointAide.SORT_AIDES}?access_token=${accessToken}`,
    data: {
      sortString
    },
    config: {
      headers: {
        'Content-Type': `multipart/form-data`,
        'Accept': 'application/json',
      }
    }
  });

  return wsResponse;
}

/**
 * Suppression d'un aide
 * @param {*} payload 
 */
export const deleteAideRequest = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  const { idAide } = payload;

  const wsResponse = await axios.request({
    method: 'delete',
    url: `${endpointAide.DELETE_AIDE}?access_token=${accessToken}`,
    data: {
      idAide
    }
  });

  return wsResponse;
}

/**
 * Ajout d'un aide
 * @param {*} payload 
 */
export const addAideRequest = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  const { values, reponse } = payload;

  const formData = new FormData();

  formData.append("user", values.user ? 1 : 0);
  formData.append("comedien", values.comedien ? 1 : 0);
  formData.append("question", values.question);
  formData.append("reponse", reponse);

  const wsResponse = await axios.request({
    method: 'post',
    url: `${endpointAide.ADD_AIDE}?access_token=${accessToken}`,
    data: formData,
    config: {
      headers: {
        'Content-Type': `multipart/form-data`,
        'Accept': 'application/json',
      }
    }
  });

  return wsResponse;
}

/**
 * R�cup�ration d'une instance aide
 * @param {*} payload 
 */
export const getAideRequest = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  var { idAide } = payload;

  const wsResponse = await axios.request({
    method: 'get',
    url: `${endpointAide.GET_AIDE}?access_token=${accessToken}`,
    params: {
      idAide
    }
  })

  return wsResponse;
}

/**
 * Ajout d'un aide
 * @param {*} payload 
 */
export const updateAideRequest = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  const { idAide, values, reponse } = payload;

  const formData = new FormData();
  
  formData.append("idAide", idAide);
  formData.append("user", values.user ? 1 : 0);
  formData.append("comedien", values.comedien ? 1 : 0);
  formData.append("question", values.question);
  formData.append("reponse", reponse);

  const wsResponse = await axios.request({
    method: 'post',
    url: `${endpointAide.UPDATE_AIDE}?access_token=${accessToken}`,
    data: formData,
    config: {
      headers: {
        'Content-Type': `multipart/form-data`,
        'Accept': 'application/json',
      }
    }
  });

  return wsResponse;
}
