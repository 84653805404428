import axios from "axios";
import { 
    endpointPartageMedia, 
    endpointPartagePlaylist
} from 'util/api';

/**
 * R�cup�ration du partage d'un extrait
 * @param {*} payload 
 */

export const getPartageExtraitByLinkRequest = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  let { link } = payload;

  // call WS Loopback
  const wsResponse = await axios.request({
    method: 'get',
    url: `${endpointPartageMedia.GET_PARTAGE_EXTRAIT_BY_LINK}?access_token=${accessToken}`,
    params:{
      link
    }
  });

  return wsResponse;
}

///////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////



export const addPartageExtraitRequest = async (payload) => {
    const user = localStorage.user;
    const accessToken = user ? JSON.parse(user).id : '';
    
    var {idSon} = payload;

    const wsResponse = await axios.request({
        method: 'post',
        url: `${endpointPartageMedia.ADD_PARTAGE}?access_token=${accessToken}`,
        data: {
          idSon
        }

    });
    return wsResponse;
}

export const addPartagePlaylistRequest = async (payload) => {
    const user = localStorage.user;
    const accessToken = user ? JSON.parse(user).id : '';
    
    var {idPlaylist} = payload;

    const wsResponse = await axios.request({
        method: 'post',
        url: `${endpointPartagePlaylist.ADD_PARTAGE}?access_token=${accessToken}`,
        data: {
            idPlaylist
        }

    });
    return wsResponse;
}