import axios from "axios";
import {
  endpointEmail,
} from 'util/api';

/**
 * Envoi d'une demande "Sonnez Alfred"
 * @param {*} payload 
 */
export const sonnezAlfredRequest = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  const {idUser, file, values} = payload;

  const formData = new FormData();

  formData.append("idUser", idUser);
  formData.append("file", file);
  formData.append("nomCasting", values.nomCasting);
  formData.append("typeProjet", values.typeProjet);
  formData.append("diffusion", values.diffusion);
  formData.append("cachetPrevu", values.cachetPrevu);
  formData.append("langues", (values.langues ? values.langues.join() : ''));
  formData.append("description", values.description);
  formData.append("lieuEnregistrement", values.lieuEnregistrement);
  formData.append("pasStudioEnregistrement", values.pasStudioEnregistrement ? 1 : 0);

  const wsResponse = await axios.request({
    method: 'post',
    url:`${endpointEmail.SONNEZ_ALFRED}?access_token=${accessToken}`,
    data: formData,
    config: {
      headers: {
        'Content-Type': `application/x-www-form-urlencoded`,
        'Accept': 'application/json',
      }
    }
  });
  
  return wsResponse;
}

/**
 * Envoi d'une demande "Voix �trang�res"
 * @param {*} payload 
 */
export const voixEtrangeresRequest = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  const {idUser, file, values} = payload;

  const formData = new FormData();

  formData.append("idUser", idUser);
  formData.append("file", file);
  formData.append("nomCasting", values.nomCasting);
  formData.append("typeProjet", values.typeProjet);
  formData.append("diffusion", values.diffusion);
  formData.append("cachetPrevu", values.cachetPrevu);
  formData.append("langues", (values.langues ? values.langues.join() : ''));
  formData.append("description", values.description);
  formData.append("lieuEnregistrement", values.lieuEnregistrement);
  formData.append("pasStudioEnregistrement", values.pasStudioEnregistrement ? 1 : 0);

  const wsResponse = await axios.request({
    method: 'post',
    url:`${endpointEmail.VOIX_ETRANGERES}?access_token=${accessToken}`,
    data: formData,
    config: {
      headers: {
        'Content-Type': `application/x-www-form-urlencoded`,
        'Accept': 'application/json',
      }
    }
  });
  
  return wsResponse;
}

/**
 * Envoi d'une demande "Projet"
 * @param {*} payload 
 */
export const projetRequest = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  const {idUser, file, values} = payload;

  const formData = new FormData();

  formData.append("idUser", idUser);
  formData.append("file", file);
  formData.append("nomCasting", values.nomCasting);
  formData.append("typeProjet", values.typeProjet);
  formData.append("diffusion", values.diffusion);
  formData.append("genre", values.genre);
  formData.append("langues", (values.langues ? values.langues.join() : ''));
  formData.append("description", values.description);
  formData.append("lieuEnregistrement", values.lieuEnregistrement);
  formData.append("pasStudioEnregistrement", values.pasStudioEnregistrement ? 1 : 0);

  const wsResponse = await axios.request({
    method: 'post',
    url:`${endpointEmail.PROJET}?access_token=${accessToken}`,
    data: formData,
    config: {
      headers: {
        'Content-Type': `application/x-www-form-urlencoded`,
        'Accept': 'application/json',
      }
    }
  });
  
  return wsResponse;
}