import {
  PLAYLIST_GET_LIST,
  PLAYLIST_SHOW_LIST,
  PLAYLISTS_GET_LISTE_DECOUVERTE,
  PLAYLISTS_SHOW_LISTE_DECOUVERTE,
  PLAYLISTS_SHOW_PARTAGE,
  PLAYLIST_SELECT
} from "constants/ActionTypes";

const INIT_STATE = {
  playlists: [],
  playlistsDecouverte: null,
  playlistPartage: null,
  idSelectedPlaylist: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {

    //////////////////////////////////////////////////////////////
    // List des playists
    //////////////////////////////////////////////////////////////

    case PLAYLIST_GET_LIST: {
      return {
        ...state,
      }
    }

    case PLAYLIST_SHOW_LIST: {
      return {
        ...state,
        playlists: action.playlists
      }
    }

    //////////////////////////////////////////////////////////////
    // Playists decouvertes
    //////////////////////////////////////////////////////////////

    case PLAYLISTS_GET_LISTE_DECOUVERTE: {
      return {
        ...state,
      }
    }

    case PLAYLISTS_SHOW_LISTE_DECOUVERTE: {
      return {
        ...state,
        playlistsDecouverte: action.playlistsDecouverte
      }
    }

    case PLAYLISTS_SHOW_PARTAGE: {
      return {
        ...state,
        playlistPartage: action.playlistPartage
      }
    }

    case PLAYLIST_SELECT: {
      return {
        ...state,
        idSelectedPlaylist: action.idSelectedPlaylist
      }
    }

    default:
      return state;
  }
}
