import axios from "axios";
import { endpointPhoto } from 'util/api'

/**
 * Ajout d'une photo d'un com�dien
 * @param {*} payload 
 */
export const addPhotoRequest = async (payload) => {
  const user              = localStorage.user;
  const accessToken       = user ? JSON.parse(user).id : '';
  
  const { values, photo } = payload;

  const formData          = new FormData();

  formData.append("file", photo);
  formData.append("nom", values.nom);

  const wsResponse = await axios.request({
    method: 'post',
    url: `${endpointPhoto.ADD_PHOTO}?access_token=${accessToken}`,
    data: formData,
    config: {
      headers: {
        'Content-Type': `multipart/form-data`,
        'Accept': 'application/json',
      }
    }
  });

  return wsResponse;
}

/**
 * R�cup�ration de la liste de photos d'un com�dien
 */
export const getListePhotosRequest = async () => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  // call WS Loopback
  const wsResponse = await axios.request({
      method: 'get',
      url: `${endpointPhoto.GET_PHOTOS}?access_token=${accessToken}`,
  });

  return wsResponse;
}

/**
 * Supression d'une photo com�dien
 * @param {*} payload 
 */
export const deletePhotoRequest = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  const { idPhoto } = payload;

  const wsResponse = await axios.request({
      method: 'delete',
      url: `${endpointPhoto.DELETE_PHOTO}?access_token=${accessToken}`,
      data: {
        idPhoto
      }
  });
  return wsResponse;
}

export const sortPhotosRequest = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';
  
  const { sortString } = payload;
  const wsResponse = await axios.request({
      method: 'post',
      url: `${endpointPhoto.SORT_PHOTOS}?access_token=${accessToken}`,
      data: {sortString},
      config: {
          headers: {
              'Content-Type': `multipart/form-data`,
              'Accept': 'application/json',
          }
      }
  });

  return wsResponse;
}

