import { server } from "util/config";


export const endpointCV = {
  GET_CV:     `${server}/cm_comediens_cvs/getCV`,
  UPDATE_CV:  `${server}/cm_comediens_cvs/updateCV`,
}

export const endpointInfosEmployeur = {
  GET_INFOS:    `${server}/cm_comediens_infos_employeurs/getInfos`,
  UPDATE_INFOS: `${server}/cm_comediens_infos_employeurs/updateInfos`,
}

export const endpointEquipement = {
  GET_EQUIPEMENT:     `${server}/cm_comediens_equipements/getEquipement`,
  UPDATE_EQUIPEMENT:  `${server}/cm_comediens_equipements/updateEquipement`,
}

export const endpointPhoto = {
  ADD_PHOTO:      `${server}/cm_comediens_photos/addPhoto`,
  GET_PHOTOS:     `${server}/cm_comediens_photos/getPhotos`,
  DELETE_PHOTO:   `${server}/cm_comediens_photos/deletePhoto`,
  SORT_PHOTOS:    `${server}/cm_comediens_photos/sortPhotos`,
}

export const endpointEmail = {
  SONNEZ_ALFRED:      `${server}/cm_emails/sonnezAlfred`,
  VOIX_ETRANGERES:    `${server}/cm_emails/voixEtrangeres`,
  PROJET:             `${server}/cm_emails/projet`,
}

export const endpointDemandesPhotoXL = {
  ADD_DEMANDE:              `${server}/cm_photos_xl_demandes/addDemande`,
  GET_DEMANDE_COMEDIEN:  `${server}/cm_photos_xl_demandes/getDemandeComedien`,
  GET_LIST_DEMANDES:        `${server}/cm_photos_xl_demandes/getListDemandes`,
  VALIDATION_DEMANDE:       `${server}/cm_photos_xl_demandes/validationDemande`,
  REFUS_DEMANDE:            `${server}/cm_photos_xl_demandes/refusDemande`,
}

export const endpointDemandeInscriptionComedien={

  GET_LIST_DEMANDES:                    `${server}/cm_comediens_demandes_inscriptions/getListDemandes`,
  DELETE_DEMANDE:                       `${server}/cm_comediens_demandes_inscriptions/deleteDemande`,
  GET_DEMANDE:                          `${server}/cm_comediens_demandes_inscriptions/getDemande`,
  VALIDATION_DEMANDE:                   `${server}/cm_comediens_demandes_inscriptions/validationDemande`,
  REFUS_DEMANDE:                        `${server}/cm_comediens_demandes_inscriptions/refusDemande`,
  VALIDATION_PAIEMENT_CHEQUE:           `${server}/cm_comediens_demandes_inscriptions/validationPaiementCheque`,

  /////////////////////////////////////////////////

  ADD_DEMANDE:                          `${server}/cm_comediens_demandes_inscriptions/addDemande`,
  
  GET_FORM_INSCRIPTION:                 `${server}/cm_comediens_demandes_inscriptions/getFormInscription`,
  VERIF_PAIEMENT:                       `${server}/cm_comediens_demandes_inscriptions/verifPaiement`,
  PAIEMENT_CHEQUE:                      `${server}/cm_comediens_demandes_inscriptions/paiementCheque`,
}

export const endpointBlog = {
  GET_BLOGS:    `${server}/cm_blogs/getListBlogs`,
  GET_BLOG:    `${server}/cm_blogs/getBlog`,
  DELETE_BLOG:  `${server}/cm_blogs/deleteBlog`,
  ADD_BLOG:     `${server}/cm_blogs/addBlog`,
  UPDATE_BLOG:    `${server}/cm_blogs/updateBlog`,

  //////////////////////////////////////////
  
  GET_SLIDES:   `${server}/cm_blogs/getSlides`,

 
}

export const endpointTemoignageComedien = {
  GET_TEMOIGNAGES :   `${server}/cm_temoignages_comediens/getListTemoignages`,
  GET_TEMOIGNAGE: `${server}/cm_temoignages_comediens/getTemoignage`,
  ADD_TEMOIGNAGE:     `${server}/cm_temoignages_comediens/addTemoignage`,
  DELETE_TEMOIGNAGE:  `${server}/cm_temoignages_comediens/deleteTemoignage`,
  UPDATE_TEMOIGNAGE:    `${server}/cm_temoignages_comediens/updateTemoignage`,

  /////////////////////////////////////////////////////


  GET_SLIDES :        `${server}/cm_temoignages_comediens/getSlides`,

}

/////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////

export const endpointNews = {
  ADD_NEWS:                     `${server}/cm_comediens_news/addNews`,
  GET_LIST_NEWS:                `${server}/cm_comediens_news/getListNews`,
  GET_ALL_NEWS:                 `${server}/cm_comediens_news/getAllNews`,
  DELETE_NEWS:                  `${server}/cm_comediens_news/deleteNews`,
  GET_NEWS:                     `${server}/cm_comediens_news/getNews`,
  UPDATE_NEWS:                  `${server}/cm_comediens_news/updateNews`,
  
  //////////////////////////////////////////////////////////////////////

  




  GET_DERNIERS_NEWS_COMEDIENS:  `${server}/cm_comediens_news/getDerniersNews`,
}






export const endpointPhotoXL = {

  GET_PHOTOS :  `${server}/cm_photos_xls/getListPhotos`,
  DELETE_PHOTO: `${server}/cm_photos_xls/deletePhoto`,
  ADD_PHOTO:    `${server}/cm_photos_xls/addPhoto`,
  UPDATE_PHOTO:    `${server}/cm_photos_xls/updatePhoto`,
  GET_PHOTO: `${server}/cm_photos_xls/getPhoto`,
  ///////////////////////////////////////////////////

  
  GET_SLIDES :  `${server}/cm_photos_xls/getSlides`,


}










export const endpointPayBox={
}

export const endpointCMS = {
  GET_ELEMENT:    `${server}/cm_cms/getElement`,
  UPDATE_ELEMENT: `${server}/cm_cms/updateElement`,
}

export const endpointAide = {
  GET_LIST_AIDES:   `${server}/cm_aides/getListeAides`,
  SORT_AIDES:       `${server}/cm_aides/sortAides`,
  DELETE_AIDE:      `${server}/cm_aides/deleteAide`,
  ADD_AIDE:         `${server}/cm_aides/addAide`,
  GET_AIDE:         `${server}/cm_aides/getAide`,
  UPDATE_AIDE:        `${server}/cm_aides/updateAide`,
}

export const endpointArticle = {
  GET_LIST_ARTICLES:   `${server}/cm_articles/getListeArticles`,
  DELETE_ARTICLE:      `${server}/cm_articles/deleteArticle`,
  ADD_ARTICLE:         `${server}/cm_articles/addArticle`,
  GET_ARTICLE:         `${server}/cm_articles/getArticle`,
  UPDATE_ARTICLE:        `${server}/cm_articles/updateArticle`,
}




export const endpointUser = {
  SIGNIN:                     `${server}/cm_users/signIn`,
  SIGNOUT:                    `${server}/cm_users/logout`,
  SIGNUP:                     `${server}/cm_users/signUp`, 
  SIGNUP_CONFIRM:             `${server}/cm_users/signUpConfirm`, 
  FORGOT_PASSWORD:            `${server}/cm_users/reset`,
  RESET_PASSWORD:             `${server}/cm_users/reset-password`,
  GET_IDENTIFIANTS:           `${server}/cm_users/getIdentifiants`,
  UPDATE_IDENTIFIANTS:        `${server}/cm_users/updateIdentifiants`,
  UPDATE_PASSWORD:        `${server}/cm_users/updatePassword`,
  GET_LIST_UTILISATEURS:      `${server}/cm_users/getListUtilisateurs`,
  SWITCH_UTILISATEUR:         `${server}/cm_users/switchUtilisateur`,
  DELETE_UTILISATEUR:         `${server}/cm_users/deleteUtilisateur`,
  DESACTIVATION_UTILISATEUR:  `${server}/cm_users/desactivationUtilisateur`,
  ACTIVATION_UTILISATEUR:     `${server}/cm_users/activationUtilisateur`,
  VERIF_EMAIL_UTILISATEUR:     `${server}/cm_users/verifEmailUtilisateur`,
  GET_PROFIL:                 `${server}/cm_users/getProfilUtilisateur`,
  UPDATE_PROFIL:              `${server}/cm_users/updateProfilUtilisateur`,
  GET_COORDONNEES:            `${server}/cm_users/getCoordonnees`,
  UPDATE_COORDONNEES:         `${server}/cm_users/updateCoordonnees`,
  GET_HISTORIQUE_PARTAGES:    `${server}/cm_users/getHistoriquePartagesUser`,
  GET_STATS:                  `${server}/cm_users/getAdminStats`,
  GET_HISTORIQUE_STATS:       `${server}/cm_users/getAdminHistoriqueStats`,
  GET_PROFIL_ADMIN:           `${server}/cm_users/getProfilAdmin`,
  UPDATE_PROFIL_ADMIN:         `${server}/cm_users/updateProfilAdmin`,
  UPDATE_INFOS_BEFORE_PAYEMENT : `${server}/cm_users/updateUserInfosBeforePayement`,
  
    ////////////////////////////////////////////////////////////////////////////

    
    
    

    
    GET_ROLE:                   `${server}/cm_users/getRole`,
  



    ADD_USER_TO_NEWSLETTER:     `${server}/cm_users/addUserToNewsletter`,
    GET_INFOS_USER:             `${server}/cm_users/getInfosUser`,




    
}

export const endpointComedien = {
    
  GET_IDENTITE:                           `${server}/cm_comediens/getIdentite`,
  UPDATE_IDENTITE:                        `${server}/cm_comediens/updateIdentite`,
  GET_ACTIVITE_FACTURATION:               `${server}/cm_comediens/getActiviteFacturation`,
  UPDATE_ACTIVITE_FACTURATION:            `${server}/cm_comediens/updateActiviteFacturation`,
  GET_ADRESSE_FACTURATION:                `${server}/cm_comediens/getAdresseFacturation`,
  UPDATE_ADRESSE_FACTURATION:             `${server}/cm_comediens/updateAdresseFacturation`,
  GET_TABLEAU_DE_BORD:                    `${server}/cm_comediens/getTableauDeBord`,
  GET_STATS:                              `${server}/cm_comediens/getStats`,
  GET_HISTORIQUE_STATS:                   `${server}/cm_comediens/getHistoriqueStatsComedien`,
  GET_LIST_COMEDIENS:                     `${server}/cm_comediens/getListComediens`,
  GET_ALL_COMEDIENS:          `${server}/cm_comediens/getAllComediens`,
  UPDATE_NOTES_ADMIN:         `${server}/cm_comediens/updateNotesAdmin`,
  GET_NOTES_ADMIN:            `${server}/cm_comediens/getNotesAdmin`,


  GET_RE_INSCRIPTION_FORM:                `${server}/cm_comediens/getReInscriptionForm`, 
  VERIF_PAIEMENT_RE_INSCRIPTION:          `${server}/cm_comediens/verifPaiementReInscription`,
  PAIEMENT_RE_INSCRIPTION_CHEQUE:         `${server}/cm_comediens/paiementReInscriptionCheque`,
  GET_ABONNEMENT_COMEDIEN:                `${server}/cm_comediens/getAbonnementComedien`,
  UPDATE_ABONNEMENT_COMEDIEN:             `${server}/cm_comediens/updateAbonnementComedien`,
  VALIDATION_PAIEMENT_CHEQUE_ABONNEMENT_COMEDIEN: `${server}/cm_comediens/validationPaiementChequeAbonnement`,
    ////////////////////////////////////////////////////////////////////////////



    GET_CONTACT:                `${server}/cm_comediens/getContact`,
    UPDATE_CONTACT:             `${server}/cm_comediens/updateContact`,
    GET_INFOS_DEMANDE_CONTACT:  `${server}/cm_comediens/getInfosDemandeContact`,
    SEND_DEMANDE_CONTACT: `${server}/cm_comediens/sendDemandeContact`,

    GET_FICHE:                  `${server}/cm_comediens/getFiche`,
    GET_BY_URL:                 `${server}/cm_comediens/getByUrl`,

    SEND_STATS:                 `${server}/cm_comediens/sendStats`,
}



export const endpointMedia = {
  ADD_MEDIA:    `${server}/cm_cms_medias/addMedia`,
  GET_MEDIAS :  `${server}/cm_cms_medias/getListMedias`,
  DELETE_MEDIA: `${server}/cm_cms_medias/deleteMedia`,
}




export const endpointMp3 = {
  GET_LIST_MP3:       `${server}/cm_comediens_extraits_mp3s/getMP3List`,
  UPDATE_VISIBILITE:  `${server}/cm_comediens_extraits_mp3s/updateVisibilite`,
  GET_MP3:            `${server}/cm_comediens_extraits_mp3s/getMP3`,
  UPDATE_MP3:         `${server}/cm_comediens_extraits_mp3s/updateMP3`,
  DELETE_MP3:         `${server}/cm_comediens_extraits_mp3s/deleteMP3`,
  ADD_MP3:            `${server}/cm_comediens_extraits_mp3s/addMP3`,
  SORT_MP3:           `${server}/cm_comediens_extraits_mp3s/sortMP3`,
  GET_STATS:          `${server}/cm_comediens_extraits_mp3s/getStats`,
  GET_TOP_EXTRAITS:   `${server}/cm_comediens_extraits_mp3s/getTopExtraits`,
  GET_ALL_EXTRAITS_MP3:   `${server}/cm_comediens_extraits_mp3s/getAllExtraitsMp3`,
  GET_FICHE_COMEDIEN_MP3:  `${server}/cm_comediens_extraits_mp3s/getFicheMP3`,


}

export const endpointVideo = {
  GET_LIST_VIDEOS:    `${server}/cm_comediens_extraits_videos/getVideosList`,
  UPDATE_VISIBILITE:  `${server}/cm_comediens_extraits_videos/updateVisibilite`,
  DELETE_VIDEO:       `${server}/cm_comediens_extraits_videos/deleteVideo`,
  SORT_VIDEOS:        `${server}/cm_comediens_extraits_videos/sortVideos`,
  ADD_VIDEO:          `${server}/cm_comediens_extraits_videos/addVideo`,
  GET_VIDEO:          `${server}/cm_comediens_extraits_videos/getVideo`,
  UPDATE_VIDEO:       `${server}/cm_comediens_extraits_videos/updateVideo`,
  GET_STATS:          `${server}/cm_comediens_extraits_videos/getStats`,  
  GET_FICHE_COMEDIEN_VIDEOS:`${server}/cm_comediens_extraits_videos/getFicheVideos`,
}









export const endpointStats = {
    ////////////////////////////////////////////////////////////////////////////
}

export const endpointAccesConfidentialite = {
  GET_LIST_DEMANDES:                      `${server}/cm_comediens_confidentialites/getListDemandesByComedien`,
  SWITCH_ACCES_TELEPHONE:         `${server}/cm_comediens_confidentialites/switchAccesTelephone`,
  SWITCH_ACCES_EMPLOYEUR:         `${server}/cm_comediens_confidentialites/switchAccesEmployeur`,
  DELETE_ACCES:                   `${server}/cm_comediens_confidentialites/deleteAcces`,
  UTILISATEUR_GET_ACCES_CONFIDENTIALITE: `${server}/cm_comediens_confidentialites/getListDemandesByUser`,

  ////////////////////////////////////////////////////////////////////////////
    DEMANDE_ACCES_TELEPHONE:        `${server}/cm_comediens_confidentialites/demandeAccesTelephone`,
    DEMANDE_ACCES_EMPLOYEUR:        `${server}/cm_comediens_confidentialites/demandeAccesEmployeur`,
}



export const endpointPlaylist = {
  GET_PLAYLISTS:                 `${server}/cm_playlists/getPlaylists`,
  CREATE_PLAYLIST:               `${server}/cm_playlists/createPlaylist`,
  UPDATE_PLAYLIST:               `${server}/cm_playlists/updatePlaylist`,
  COPY_PLAYLIST:                 `${server}/cm_playlists/copyPlaylist`,
  DELETE_PLAYLIST:               `${server}/cm_playlists/deletePlaylist`,
  SORT_EXTRAITS:  `${server}/cm_playlists_medias/sortExtraits`,
  CREATE_PLAYLIST_ADD_EXTRAIT:     `${server}/cm_playlists/createPlaylistAddExtrait`,
  ADD_EXTRAIT_TO_PLAYLIST:         `${server}/cm_playlists_medias/addExtraitToPlaylist`,
  RETRAT_EXTRAIT_FROM_PLAYLIST:    `${server}/cm_playlists_medias/retraitExtraitFromPlaylist`,
  
  ////////////////////////////////////////////////////////////////////////////

  



    GET_PLAYLISTS_DECOUVERTE:       `${server}/cm_playlists/getPlaylistsDecouverte`,

    GET_PLAYLISTS_PARTAGE: `${server}/cm_playlists/getPlaylistPartage`,
}



export const endpointAbonnement = {
  GET_LIST_FACTURES:         `${server}/cm_abonnements/getListFactures`,
}

export const endpointPartageMedia = {

  GET_PARTAGE_EXTRAIT_BY_LINK: `${server}/cm_partages_medias/getPartageByLink`,

  ////////////////////////////////////////////////////////////////////////////
    ADD_PARTAGE: `${server}/cm_partages_medias/addPartage`,
}

export const endpointPartagePlaylist = {

  ////////////////////////////////////////////////////////////////////////////
    ADD_PARTAGE: `${server}/cm_partages_playlists/addPartage`,
}

export const endpointLog = {

  ////////////////////////////////////////////////////////////////////////////
  ADD_AFFICHAGE_TELEPHONE:        `${server}/cm_logs/logAffichageTelephone`,
  ADD_VISITE_FICHE:               `${server}/cm_logs/logVisiteFiche`,
  ADD_PLAY:                       `${server}/cm_logs/logPlay`,
  ADD_PARTAGE_EXTRAIT:            `${server}/cm_logs/logPartageExtrait`,
  ADD_AJOUT_EXTRAIT_PLAYLIST:     `${server}/cm_logs/logAddExtraitToPlaylist`,

}

export const endpointMessage = {
  GET_MESSAGES_COMEDIEN:         `${server}/cm_messages/getListMessages`,
}


export const endpointConfig = {
  UPDATE_COEF_RANKING_ALGOLIA:  `${server}/cm_configs/updateCoefRankingAlgolia`,
  GET_COEF_RANKING_ALGOLIA:         `${server}/cm_configs/getCoefRankingAlgolia`,

};

export const endpointCampagnesCom = {
  GET_CAMPAGNES:  `${server}/cm_campagnes_coms/getListCampagnes`,
  DELETE_CAMPAGNE:  `${server}/cm_campagnes_coms/deleteCampagne`,
  ACTIVATION_CAMPAGNE:`${server}/cm_campagnes_coms/activationCampagne`,
  DESACTIVATION_CAMPAGNE:`${server}/cm_campagnes_coms/desactivationCampagne`,
  ADD_CAMPAGNE:`${server}/cm_campagnes_coms/addCampagne`,
  GET_CAMPAGNE:`${server}/cm_campagnes_coms/getCampagne`,
  UPDATE_CAMPAGNE:`${server}/cm_campagnes_coms/updateCampagne`,
  GET_CAMPAGNE_BY_USER:`${server}/cm_campagnes_coms/getCampagneByUser`,
};

//////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////


export const endpointAccesConfidentialiteUtilisateurGet = {
}


export const endpointSupportComedien = {
  ADD_SUPPORT: `${server}/cm_comediens_supports/addSupport`,
  GET_SUPPORTS_BY_COMEDIEN:`${server}/cm_comediens_supports/getListeSupportsByComedien`,
  DELETE_SUPPORT:`${server}/cm_comediens_supports/deleteSupport`,
  GET_SUPPORT:`${server}/cm_comediens_supports/getSupport`,
  UPDATE_SUPPORT:`${server}/cm_comediens_supports/updateSupport`,
}