import {
  COMEDIEN_SHOW_IDENTITE,
  COMEDIEN_SHOW_ACTIVITE_FACTURATION,
  COMEDIEN_SHOW_CONTACT,
  COMEDIEN_SHOW_CV,
  COMEDIEN_SHOW_LISTE_PHOTOS,
  COMEDIEN_SHOW_DEMANDE_PHOTO_XL,
  COMEDIEN_SHOW_STATS_MP3,
  COMEDIEN_SHOW_LISTE_MP3,
  COMEDIEN_SHOW_EXTRAIT_MP3,
  COMEDIEN_SHOW_STATS_VIDEOS,
  COMEDIEN_SHOW_LISTE_VIDEOS,
  COMEDIEN_SHOW_EXTRAIT_VIDEO,
  COMEDIEN_SHOW_LIST_NEWS,
  COMEDIEN_SHOW_INFOS_EMPLOYEUR,
  COMEDIEN_SHOW_EQUIPEMENT,
  COMEDIEN_SHOW_TDB_MON_COMPTE,
  COMEDIEN_SHOW_ADRESSE_FACTURATION,
  COMEDIEN_SHOW_FACTURES,
  COMEDIEN_SHOW_STATS,
  COMEDIEN_SHOW_TOP_EXTRAITS_STATS,
  COMEDIEN_SHOW_HISTORIQUE_STATS,
  COMEDIEN_SHOW_RE_INSCRIPTION_FORM,
  COMEDIEN_SET_STATUS_PAIEMENT_RE_INSCRIPTION,
  COMEDIEN_SET_STATUS_PAIEMENT_RE_INSCRIPTION_CHEQUE,
  COMEDIEN_SHOW_LIST_DEMANDES_ACCES,

  //////////////////////////////////////////
  //////////////////////////////////////////
  //////////////////////////////////////////


  COMEDIEN_GET_DEMANDE_CONTACT_DATA,
  COMEDIEN_SHOW_DEMANDE_CONTACT_DATA,
  COMEDIEN_GET_COMEDIEN_BY_URL,
  COMEDIEN_SHOW_COMEDIEN_BY_URL,
  COMEDIEN_GET_FICHE_COMEDIEN_MP3,
  COMEDIEN_SHOW_FICHE_COMEDIEN_MP3,
  COMEDIEN_GET_FICHE_COMEDIEN_VIDEOS,
  COMEDIEN_SHOW_FICHE_COMEDIEN_VIDEOS,
  COMEDIEN_SET_SEND_DEMANDE_CONTACT_STATUS,
  COMEDIEN_SEND_DEMANDE_CONTACT_SUCCESS,
  COMEDIEN_SEND_DEMANDE_CONTACT_ERROR,

} from "constants/ActionTypes";

import {
  STATUS_RE_INSCRIPTION_COMEDIEN_INIT,
  STATUS_RE_INSCRIPTION_COMEDIEN_CHEQUE_INIT,
  STATUS_DEMANDE_CONTACT_COMEDIEN_INIT,
  STATUS_DEMANDE_CONTACT_COMEDIEN_SUCCESS,
} from "constants/General";

const INIT_STATE = {
  dataIdentite: null,
  dataActivite: null,
  dataContact: {},
  dataCV: null,
  dataPhotos: [],
  dataDemandePhotoXL: null,
  dataStatsMP3: null,
  dataListeMP3: [],
  dataExtraitMp3: null,
  dataStatsVideos: null,
  dataListeVideos: [],
  dataExtraitVideo: null,
  dataListeNews: null,
  dataInfosEmployeur: null,
  dataEquipement: null,
  dataTDBCompte: null,
  dataAdresseFacturation: null,
  dataFacture: [],
  dataStats: null,
  dataTopExtraits: [],
  dataHistoriqueStats: null,
  dataAccesConfidentialites: [],
  dataReInscriptionForm: null,
  statusPaiementReinscription: STATUS_RE_INSCRIPTION_COMEDIEN_INIT,
  statusPaiementReInscriptionCheque: STATUS_RE_INSCRIPTION_COMEDIEN_CHEQUE_INIT,
 
 
  ////////////////////////////
  statusDemandeContact: STATUS_DEMANDE_CONTACT_COMEDIEN_INIT,
  dataHistoriquePartage: {},
  dataDemandeContact: {},
  dataComedienByUrl: null,
  dataFicheComedienMP3: null,
  dataFicheComedienVideos: null
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {

    ///////////////////////////////////////////////////////////////////////
    // Mon Profil
    ///////////////////////////////////////////////////////////////////////

    //Identité
    case COMEDIEN_SHOW_IDENTITE: {
      return {
        ...state,
        dataIdentite: action.dataIdentite
      }
    }
    //Activité et facturation
    case COMEDIEN_SHOW_ACTIVITE_FACTURATION: {
      return {
        ...state,
        dataActivite: action.dataActivite
      }
    }
    //Contact 
    case COMEDIEN_SHOW_CONTACT: {
      return {
        ...state,
        dataContact: action.dataContact
      }
    }

    ///////////////////////////////////////////////////////////////////////
    // Mon CV
    ///////////////////////////////////////////////////////////////////////

    case COMEDIEN_SHOW_CV: {
      return {
        ...state,
        dataCV: action.dataCV
      }
    }

    ///////////////////////////////////////////////////////////////////////
    // Mes photos
    ///////////////////////////////////////////////////////////////////////

    case COMEDIEN_SHOW_LISTE_PHOTOS: {
      return {
        ...state,
        dataPhotos: action.dataPhotos
      }
    }
    //Demande de photo XL
    case COMEDIEN_SHOW_DEMANDE_PHOTO_XL: {
      return {
        ...state,
        dataDemandePhotoXL: action.dataDemandePhotoXL
      }
    }

    ///////////////////////////////////////////////////////////////////////
    // Mes extraits MP3
    ///////////////////////////////////////////////////////////////////////

    //Stats
    case COMEDIEN_SHOW_STATS_MP3: {
      return {
        ...state,
        dataStatsMP3: action.dataStatsMP3
      }
    }
    //Liste des Extraits MP3
    case COMEDIEN_SHOW_LISTE_MP3: {
      return {
        ...state,
        dataListeMP3: action.dataListeMP3
      }
    }
    //Extrait MP3
    case COMEDIEN_SHOW_EXTRAIT_MP3: {
      return {
        ...state,
        dataExtraitMp3: action.dataExtraitMp3
      }
    }

    ///////////////////////////////////////////////////////////////////////
    // Mes Videos
    ///////////////////////////////////////////////////////////////////////

    //Stats
    case COMEDIEN_SHOW_STATS_VIDEOS: {
      return {
        ...state,
        dataStatsVideos: action.dataStatsVideos
      }
    }
    //Liste des Extraits videos
    case COMEDIEN_SHOW_LISTE_VIDEOS: {
      return {
        ...state,
        dataListeVideos: action.dataListeVideos
      }
    }
    //Extrait Vidéo
    case COMEDIEN_SHOW_EXTRAIT_VIDEO: {
      return {
        ...state,
        dataExtraitVideo: action.dataExtraitVideo
      }
    }

    ///////////////////////////////////////////////////////////////////////
    // Mes News
    ///////////////////////////////////////////////////////////////////////

    case COMEDIEN_SHOW_LIST_NEWS: {
      return {
        ...state,
        dataListeNews: action.dataListeNews
      }
    }

    ///////////////////////////////////////////////////////////////////////
    // Mes Infos Employeur
    ///////////////////////////////////////////////////////////////////////

    case COMEDIEN_SHOW_INFOS_EMPLOYEUR: {
      return {
        ...state,
        dataInfosEmployeur: action.dataInfosEmployeur
      }
    }

    ///////////////////////////////////////////////////////////////////////
    // Mon Equipement
    ///////////////////////////////////////////////////////////////////////

    case COMEDIEN_SHOW_EQUIPEMENT: {
      return {
        ...state,
        dataEquipement: action.dataEquipement
      }
    }

    ///////////////////////////////////////////////////////////////////////
    // Mon Compte
    ///////////////////////////////////////////////////////////////////////

    //Tableau de bord
    case COMEDIEN_SHOW_TDB_MON_COMPTE: {
      return {
        ...state,
        dataTDBCompte: action.dataTDBCompte
      }
    }
    //Adresse de facturation
    case COMEDIEN_SHOW_ADRESSE_FACTURATION: {
      return {
        ...state,
        dataAdresseFacturation: action.dataAdresseFacturation
      }
    }
    //Factures
    case COMEDIEN_SHOW_FACTURES: {
      return {
        ...state,
        dataFacture: action.dataFacture
      }
    }

    ///////////////////////////////////////////////////////////////////////
    // Mes stats
    ///////////////////////////////////////////////////////////////////////

    //Sommaire des Stats
    case COMEDIEN_SHOW_STATS: {
      return {
        ...state,
        dataStats: action.dataStats
      }
    }
    //Historique
    case COMEDIEN_SHOW_HISTORIQUE_STATS: {
      return {
        ...state,
        dataHistoriqueStats: action.dataHistoriqueStats
      }
    }
    //Top Extraits
    case COMEDIEN_SHOW_TOP_EXTRAITS_STATS: {
      return {
        ...state,
        dataTopExtraits: action.dataTopExtraits
      }
    }

    ///////////////////////////////////////////////////////////////////////
    // Accés Confidentialité
    ///////////////////////////////////////////////////////////////////////

    case COMEDIEN_SHOW_LIST_DEMANDES_ACCES: {
      return {
        ...state,
        dataAccesConfidentialites: action.dataAccesConfidentialites
      }
    }

    ///////////////////////////////////////////////////////////////////////
    // Ré-inscription
    ///////////////////////////////////////////////////////////////////////

    case COMEDIEN_SHOW_RE_INSCRIPTION_FORM: {
      return {
        ...state,
        dataReInscriptionForm: action.dataReInscriptionForm
      }
    }
    case COMEDIEN_SET_STATUS_PAIEMENT_RE_INSCRIPTION: {
      return {
        ...state,
        statusPaiementReinscription: action.payload,
      }
    }
    case COMEDIEN_SET_STATUS_PAIEMENT_RE_INSCRIPTION_CHEQUE: {
      return {
        ...state,
        statusPaiementReInscriptionCheque: action.payload,
      }
    }


    ///////////////////////////////////////////////////
    ///////////////////////////////////////////////////
    ///////////////////////////////////////////////////

    ///////////////////////////////////////////////////////////////////////
    // Contact comedien
    ///////////////////////////////////////////////////////////////////////

    case COMEDIEN_GET_DEMANDE_CONTACT_DATA: {
      return {
        ...state,
      }
    }

    case COMEDIEN_SHOW_DEMANDE_CONTACT_DATA: {
      return {
        ...state,
        dataDemandeContact: action.dataDemandeContact
      }
    }


    case COMEDIEN_SET_SEND_DEMANDE_CONTACT_STATUS: {
      return {
        ...state,
        statusDemandeContact: action.status
      }
    }

    case COMEDIEN_SEND_DEMANDE_CONTACT_SUCCESS: {
      return {
        ...state,
        statusDemandeContact: STATUS_DEMANDE_CONTACT_COMEDIEN_SUCCESS
      }
    }

    case COMEDIEN_SEND_DEMANDE_CONTACT_ERROR: {
      return {
        ...state,
        statusDemandeContact: action.error
      }
    }

    ///////////////////////////////////////////////////////////////////////
    // Fiche Comedien avec URL
    ///////////////////////////////////////////////////////////////////////

    case COMEDIEN_GET_COMEDIEN_BY_URL: {
      return {
        ...state,
      }
    }

    case COMEDIEN_SHOW_COMEDIEN_BY_URL: {
      return {
        ...state,
        dataComedienByUrl: action.dataComedienByUrl
      }
    }


    case COMEDIEN_GET_FICHE_COMEDIEN_MP3: {
      return {
        ...state,
      }
    }

    case COMEDIEN_SHOW_FICHE_COMEDIEN_MP3: {
      return {
        ...state,
        dataFicheComedienMP3: action.dataFicheComedienMP3
      }
    }

    case COMEDIEN_GET_FICHE_COMEDIEN_VIDEOS: {
      return {
        ...state,
      }
    }

    case COMEDIEN_SHOW_FICHE_COMEDIEN_VIDEOS: {
      return {
        ...state,
        dataFicheComedienVideos: action.dataFicheComedienVideos
      }
    }

    


    

    default:
      return state;
  }

}
