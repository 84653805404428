import axios from "axios";
import { endpointBlog } from 'util/api'

/**
 * Liste des blogs
 */
export const getListeBlogsRequest = async () => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  // call WS Loopback
  const wsResponse = await axios.request({
    method: 'get',
    url: `${endpointBlog.GET_BLOGS}?access_token=${accessToken}`,
  });

  return wsResponse;
}

/**
 * R�cup�ration d'un blog
 */
export const getBlogRequest = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  const { idBlog } = payload;

  // call WS Loopback
  const wsResponse = await axios.request({
    method: 'get',
    url: `${endpointBlog.GET_BLOG}?access_token=${accessToken}`,
    params: {
      idBlog
    }
  });

  return wsResponse;
}

/**
 * Suppression d'un blog
 * @param {*} idBlog
 */
export const deleteBlogRequest = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  const { idBlog } = payload;

  const wsResponse = await axios.request({
    method: 'delete',
    url: `${endpointBlog.DELETE_BLOG}?access_token=${accessToken}`,
    data: {
      idBlog
    }
  });

  return wsResponse;
}

/**
 * Ajout d'un blog
 * @param {*} payload 
 */
export const addBlogRequest = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  const formData = new FormData();

  const { values, description , photo } = payload;

  formData.append("file", photo);
  formData.append("typeBlog", values.typeBlog);
  formData.append("lienVideo", values.lienVideo || '');
  formData.append("titre", values.titre);
  formData.append("description", description);
  formData.append("lienBlog", values.lienBlog);

  const wsResponse = await axios.request({
    method: 'post',
    url: `${endpointBlog.ADD_BLOG}?access_token=${accessToken}`,
    data: formData,
    config: {
      headers: {
        'Content-Type': `multipart/form-data`,
        'Accept': 'application/json',
      }
    }
  });

  return wsResponse;
}

/**
 * Modification d'un blog
 * @param {*} payload 
 */
export const updateBlogRequest = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  const formData = new FormData();
  const { idBlog, values, description, photo } = payload;

  formData.append("file", photo);
  formData.append("idBlog", idBlog);
  formData.append("typeBlog", values.typeBlog);
  formData.append("lienVideo", values.lienVideo || '');
  formData.append("titre", values.titre);
  formData.append("description", description);
  formData.append("lienBlog", values.lienBlog);

  const wsResponse = await axios.request({
    method: 'post',
    url: `${endpointBlog.UPDATE_BLOG}?access_token=${accessToken}`,
    data: formData,
    config: {
      headers: {
        'Content-Type': `multipart/form-data`,
        'Accept': 'application/json',
      }
    }
  });

  return wsResponse;
}

/////////////////////////////////////////////////
/////////////////////////////////////////////////
/////////////////////////////////////////////////






export const getSlidesBlogsRequest = async () => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  // call WS Loopback
  const wsResponse = await axios.request({
    method: 'get',
    url: `${endpointBlog.GET_SLIDES}?access_token=${accessToken}`,
  });

  return wsResponse;
}





