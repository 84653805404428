import {
  ADMIN_GET_LIST_UTILISATEURS,
  ADMIN_SHOW_LIST_UTILISATEURS,
  ADMIN_SWITCH_UTILISATEUR,
  ADMIN_SWITCH_UTILISATEUR_SUCCESS,
  ADMIN_DELETE_UTILISATEUR,
  ADMIN_DESACTIVATION_UTILISATEUR,
  ADMIN_ACTIVATION_UTILISATEUR,
  ADMIN_VERIF_EMAIL_UTILISATEUR,
  ADMIN_GET_LIST_COMEDIENS,
  ADMIN_SHOW_LIST_COMEDIENS,
  ADMIN_DELETE_COMEDIEN,
  ADMIN_DESACTIVATION_COMEDIEN,
  ADMIN_ACTIVATION_COMEDIEN,
  ADMIN_SEND_STATS_COMEDIEN,
  ADMIN_GET_ALL_COMEDIENS,
  ADMIN_SHOW_ALL_COMEDIENS,
  ADMIN_GET_DEMANDES_INSCRIPTIONS_COMEDIENS,
  ADMIN_SHOW_DEMANDES_INSCRIPTIONS_COMEDIENS,
  ADMIN_DELETE_DEMANDE_INSCRIPTION_COMEDIEN,
  ADMIN_GET_DEMANDE_INSCRIPTION_COMEDIEN,
  ADMIN_SHOW_DEMANDE_INSCRIPTION_COMEDIEN,
  ADMIN_VALIDATION_DEMANDE_INSCRIPTION_COMEDIEN,
  ADMIN_REFUS_DEMANDE_INSCRIPTION_COMEDIEN,
  ADMIN_VALIDATION_PAIEMENT_CHEQUE,
  ADMIN_GET_LIST_NEWS_COMEDIENS,
  ADMIN_SHOW_LIST_NEWS_COMEDIENS,
  ADMIN_DELETE_NEWS_COMEDIEN,
  ADMIN_GET_NEWS,
  ADMIN_SHOW_NEWS,
  ADMIN_UPDATE_NEWS,
  ADMIN_GET_MP3_COMEDIENS,
  ADMIN_SHOW_MP3_COMEDIENS,
  ADMIN_GET_LIST_DEMANDES_PHOTOS_XL,
  ADMIN_SHOW_LIST_DEMANDES_PHOTOS_XL,
  ADMIN_VALIDATION_DEMANDE_PHOTO_XL,
  ADMIN_REFUS_DEMANDE_PHOTO_XL,
  ADMIN_GET_LISTE_PHOTOS_XL,
  ADMIN_SHOW_LISTE_PHOTOS_XL,
  ADMIN_ADD_PHOTO_XL,
  ADMIN_DELETE_PHOTO_XL,
  ADMIN_UPDATE_PHOTO_XL,
  ADMIN_GET_PHOTO_XL,
  ADMIN_SHOW_PHOTO_XL,
  ADMIN_GET_LISTE_BLOGS,
  ADMIN_SHOW_LISTE_BLOGS,
  ADMIN_GET_BLOG,
  ADMIN_SHOW_BLOG,
  ADMIN_DELETE_BLOG,
  ADMIN_ADD_BLOG,
  ADMIN_UPDATE_BLOG,
  ADMIN_GET_LISTE_TEMOIGNAGES_COMEDIENS,
  ADMIN_SHOW_LISTE_TEMOIGNAGES_COMEDIENS,
  ADMIN_ADD_TEMOIGNAGE_COMEDIEN,
  ADMIN_DELETE_TEMOIGNAGE_COMEDIEN,
  ADMIN_GET_TEMOIGNAGE_COMEDIEN,
  ADMIN_SHOW_TEMOIGNAGE_COMEDIEN,
  ADMIN_UPDATE_TEMOIGNAGE_COMEDIEN,
  ADMIN_GET_LISTE_AIDES,
  ADMIN_SHOW_LISTE_AIDES,
  ADMIN_SORT_AIDES,
  ADMIN_DELETE_AIDE,
  ADMIN_ADD_AIDE,
  ADMIN_GET_AIDE,
  ADMIN_SHOW_AIDE,
  ADMIN_UPDATE_AIDE,
  
  ADMIN_GET_LISTE_ARTICLES,
  ADMIN_SHOW_LISTE_ARTICLES,
  ADMIN_DELETE_ARTICLE,
  ADMIN_ADD_ARTICLE,
  ADMIN_GET_ARTICLE,
  ADMIN_SHOW_ARTICLE,
  ADMIN_UPDATE_ARTICLE,

  
  ADMIN_ADD_MEDIA,
  ADMIN_GET_LISTE_MEDIAS,
  ADMIN_SHOW_LISTE_MEDIAS,
  ADMIN_DELETE_MEDIA,


  ADMIN_GET_STATS,
  ADMIN_SHOW_STATS,
  ADMIN_GET_HISTORIQUE_STATS,
  ADMIN_SHOW_HISTORIQUE_STATS,
  ADMIN_UPDATE_CONFIG_RANKING_ALGOLIA,
  ADMIN_GET_CONFIG_RANKING_ALGOLIA,
  ADMIN_SHOW_CONFIG_RANKING_ALGOLIA,
  ADMIN_GET_PROFIL,
  ADMIN_SHOW_PROFIL,
  ADMIN_UPDATE_PROFIL,
  ADMIN_UPDATE_NOTES_COMEDIEN,
  ADMIN_GET_NOTES_COMEDIEN,
  ADMIN_SHOW_NOTES_COMEDIEN,
  ADMIN_GET_LISTE_CAMPAGNES_COM,
  ADMIN_SHOW_LISTE_CAMPAGNES_COM,
  ADMIN_DELETE_CAMPAGNE_COM,
  ADMIN_ACTIVATION_CAMPAGNE_COM,
  ADMIN_DESACTIVATION_CAMPAGNE_COM,
  ADMIN_ADD_CAMPAGNE_COM,
  ADMIN_GET_CAMPAGNE_COM,
  ADMIN_SHOW_CAMPAGNE_COM,
  ADMIN_UPDATE_CAMPAGNE_COM,
  ADMIN_GET_CAMPAGNE_COM_BY_USER,
  ADMIN_SHOW_CAMPAGNE_COM_BY_USER,
  ADMIN_ADD_SUPPORT,
  ADMIN_GET_LISTE_SUPPORTS_BY_COMEDIEN,
  ADMIN_SHOW_LISTE_SUPPORTS_BY_COMEDIEN,
  ADMIN_DELETE_SUPPORT_COMEDIEN,
  ADMIN_GET_SUPPORT_COMEDIEN,
  ADMIN_SHOW_SUPPORT_COMEDIEN,
  ADMIN_UPDATE_SUPPORT_COMEDIEN,
  ADMIN_GET_ABONNEMENT_COMEDIEN,
  ADMIN_SHOW_ABONNEMENT_COMEDIEN,
  ADMIN_UPDATE_ABONNEMENT_COMEDIEN,
  ADMIN_VALIDATION_PAIEMENT_CHEQUE_ABONNEMENT_COMEDIEN,
  ADMIN_SET_REFRESH_ABONNEMENT_COMEDIEN,
  ADMIN_UNSET_REFRESH_ABONNEMENT_COMEDIEN
} from 'constants/ActionTypes';

import { encodeUrl } from 'util/Url';

////////////////////////////////////////////////////////////////////////////
// Gestion des utilisateurs
////////////////////////////////////////////////////////////////////////////

//Récupération de la liste des utilisateurs
export const getListUtilisateursAction = (pageSize = 10, page = 1, keyword = '') => {
  return {
    type: ADMIN_GET_LIST_UTILISATEURS,
    payload: {
      pageSize,
      page,
      keyword
    }
  }
}

export function showListUtilisateursAction(listUtilisateurs) {
  return {
    type: ADMIN_SHOW_LIST_UTILISATEURS,
    listUtilisateurs
  }
}

//Switch Utilisateur
export const switchUtilisateurAction = (idUser) => {
  return {
    type: ADMIN_SWITCH_UTILISATEUR,
    payload: {
      idUser
    },
  };
};

//Switch Utilisateur avec succès 
export const switchUtilisateurSuccessAction = (authUser) => {
  let user = localStorage.user;

  localStorage.setItem('admin', user);

  let switchUser = {
    urlAdmin: encodeUrl(window.location.href)
  }

  localStorage.setItem('switchUser', JSON.stringify(switchUser));
  localStorage.setItem('user', JSON.stringify(authUser));

  window.location = "/";

  return {
    type: ADMIN_SWITCH_UTILISATEUR_SUCCESS,
  };
};

//Suppression d'un utilisateur
export const deleteUtilisateurAction = (idUser) => {
  return {
    type: ADMIN_DELETE_UTILISATEUR,
    payload: {
      idUser
    },
  };
};

//Activation d'un utilisateur
export const activationUtilisateurAction = (idUser) => {
  return {
    type: ADMIN_ACTIVATION_UTILISATEUR,
    payload: {
      idUser
    },
  };
};

//Désactivation d'un utilisateur
export const desactivationUtilisateurAction = (idUser) => {
  return {
    type: ADMIN_DESACTIVATION_UTILISATEUR,
    payload: {
      idUser
    },
  };
};


//Vérification de l'Email d'un utilisateur
export const verificationEmailUtilisateurAction = (idUser) => {
  return {
    type: ADMIN_VERIF_EMAIL_UTILISATEUR,
    payload: {
      idUser
    },
  };
};

////////////////////////////////////////////////////////////////////////////
// Gestion des comediens
////////////////////////////////////////////////////////////////////////////

//Récupération de la liste des comédiens
export const getListComediensAction = (pageSize = 10, page = 1, keyword = '') => {
  return {
    type: ADMIN_GET_LIST_COMEDIENS,
    payload: {
      pageSize,
      page,
      keyword
    }
  }
}

export function showListComediensAction(listComediens) {
  return {
    type: ADMIN_SHOW_LIST_COMEDIENS,
    listComediens
  }
}

//Suppression d'un comedien
export const deleteComedienAction = (idUser) => {
  return {
    type: ADMIN_DELETE_COMEDIEN,
    payload: {
      idUser
    },
  };
};

//Activation d'un comedien
export const activationComedienAction = (idUser) => {
  return {
    type: ADMIN_ACTIVATION_COMEDIEN,
    payload: {
      idUser
    },
  };
};

//Désactivation d'un comedien
export const desactivationComedienAction = (idUser) => {
  return {
    type: ADMIN_DESACTIVATION_COMEDIEN,
    payload: {
      idUser
    },
  };
};


//Envoi des Stats Comedien
export const sendStatsComedienAction = (idUser) => {
  return {
    type: ADMIN_SEND_STATS_COMEDIEN,
    payload: {
      idUser
    },
  };
};

//Récupération de l'abonnement d'un comédien
export const getAbonnementComedienAction = (idUser) => {
  return {
    type: ADMIN_GET_ABONNEMENT_COMEDIEN,
    payload: {
      idUser: idUser
    }
  };
};

export function showAbonnementComedienAction(dataAbonnementComedien) {
  return {
    type: ADMIN_SHOW_ABONNEMENT_COMEDIEN,
    dataAbonnementComedien
  }
}

//Modification de l'abonnement d'un comédien
export const updateAbonnementComedienAction = (idUser, formuleCompte, values) => {
  return {
    type: ADMIN_UPDATE_ABONNEMENT_COMEDIEN,
    payload: {
      idUser,
      formuleCompte,
      values
    }
  };
};

//Récupération de la liste de tous les comédiens
export const getAllComediensAction = (formuleCompte = '') => {
  return {
    type: ADMIN_GET_ALL_COMEDIENS,
    payload: {
      formuleCompte
    }
  }
}

export function showAllComediensAction(dataAllComediens) {
  return {
    type: ADMIN_SHOW_ALL_COMEDIENS,
    dataAllComediens
  }
}

//Validation du paiement par chèque
export const validationPaiementCheckAbonnementComedienAction = (idUser) => {
  return {
    type: ADMIN_VALIDATION_PAIEMENT_CHEQUE_ABONNEMENT_COMEDIEN,
    payload: {
      idUser
    }
  };
};

//Refresh de l'abonnement d'un comédien
export const setRefreshAbonnementComedienAction = () => {
  return {
    type: ADMIN_SET_REFRESH_ABONNEMENT_COMEDIEN
  };
};

export const unsetRefreshAbonnementComedienAction = () => {
  return {
    type: ADMIN_UNSET_REFRESH_ABONNEMENT_COMEDIEN
  };
};

////////////////////////////////////////////////////////////////////////////
// Demandes d'inscriptions comediens
////////////////////////////////////////////////////////////////////////////

//Liste des demandes d'inscriptions
export function getDemandesInscriptionsComediensAction(pageSize = 10, page = 1, keyword = '') {
  return {
    type: ADMIN_GET_DEMANDES_INSCRIPTIONS_COMEDIENS,
    payload: {
      pageSize,
      page,
      keyword
    }
  }
}

export function showDemandesInscriptionsComediensAction(listDemandesInscriptionsComediens) {
  return {
    type: ADMIN_SHOW_DEMANDES_INSCRIPTIONS_COMEDIENS,
    listDemandesInscriptionsComediens
  }
}

//Récupération d'une demande d'inscription
export function getDemandeInscriptionComedienAction(idDemande) {
  return {
    type: ADMIN_GET_DEMANDE_INSCRIPTION_COMEDIEN,
    payload: {
      idDemande
    }
  }
}

export function showDemandeInscriptionComedienAction(demandeInscriptionComedien) {
  return {
    type: ADMIN_SHOW_DEMANDE_INSCRIPTION_COMEDIEN,
    demandeInscriptionComedien
  };
}

//Validation d'une demande d'inscription
export const validationDemandeInscriptionComedienAction = (idDemande, commentaires) => {
  return {
    type: ADMIN_VALIDATION_DEMANDE_INSCRIPTION_COMEDIEN,
    payload: {
      idDemande,
      commentaires
    },
  };
};

//Refus d'une demande d'inscription
export const refusDemandeInscriptionComedienAction = (idDemande, commentaires) => {
  return {
    type: ADMIN_REFUS_DEMANDE_INSCRIPTION_COMEDIEN,
    payload: {
      idDemande,
      commentaires
    },
  };
};

//Suppression d'une demande d'inscription
export const deleteDemandeInscriptionComedienAction = (idDemande) => {
  return {
    type: ADMIN_DELETE_DEMANDE_INSCRIPTION_COMEDIEN,
    payload: {
      idDemande
    },
  };
};

//Validation d'un paiement par chèque bancaire
export const validationPaiementChequeAction = (idDemande) => {
  return {
    type: ADMIN_VALIDATION_PAIEMENT_CHEQUE,
    payload: {
      idDemande
    },
  };
};

////////////////////////////////////////////////////////////////////////////
// News Comediens
////////////////////////////////////////////////////////////////////////////

//Liste des News comédiens
export function getListNewsComediensAction(pageSize = 10, page = 1, keyword = '') {
  return {
    type: ADMIN_GET_LIST_NEWS_COMEDIENS,
    payload: {
      pageSize,
      page,
      keyword
    }
  };
};

export function showListNewsComediensAction(listNewsComediens) {
  return {
    type: ADMIN_SHOW_LIST_NEWS_COMEDIENS,
    listNewsComediens
  };
}

//Suppression d'une News
export const deleteNewsComedienAction = (idNews) => {
  return {
    type: ADMIN_DELETE_NEWS_COMEDIEN,
    payload: {
      idNews
    }
  };
};

//Récupération d'une News
export function getNewsAction(idNews) {
  return {
    type: ADMIN_GET_NEWS,
    payload: {
      idNews
    }
  };
}

export function showNewsAction(dataNewsComedien) {
  return {
    type: ADMIN_SHOW_NEWS,
    dataNewsComedien
  };
}

//Modification d'une News
export const updateNewsAction = (idNews, values) => {
  return {
    type: ADMIN_UPDATE_NEWS,
    payload: {
      idNews,
      values
    }
  };
};

////////////////////////////////////////////////////////////////////////////
// Extraits MP3
////////////////////////////////////////////////////////////////////////////

//Liste des extraits MP3
export function getMP3ComediensAction(pageSize = 10, page = 1, keyword = '') {
  return {
    type: ADMIN_GET_MP3_COMEDIENS,
    payload: {
      pageSize,
      page,
      keyword
    }
  };
};

export function showMP3ComediensAction(listExtraitsMP3) {
  return {
    type: ADMIN_SHOW_MP3_COMEDIENS,
    listExtraitsMP3
  };
}

////////////////////////////////////////////////////////////////////////////
// Demandes de photo XL
////////////////////////////////////////////////////////////////////////////

//Liste des demandes photos XL
export function getListDemandesPhotosXLAction(pageSize = 10, page = 1, keyword = '') {
  return {
    type: ADMIN_GET_LIST_DEMANDES_PHOTOS_XL,
    payload: {
      pageSize,
      page,
      keyword
    }
  };
};

export function showListDemandesPhotosXLAction(listDemandesPhotosXL) {
  return {
    type: ADMIN_SHOW_LIST_DEMANDES_PHOTOS_XL,
    listDemandesPhotosXL
  };
}

//Validation d'une demande photo XL
export const validationDemandePhotoXLAction = (idDemande) => {
  return {
    type: ADMIN_VALIDATION_DEMANDE_PHOTO_XL,
    payload: {
      idDemande
    }
  };
};

//Refus d'une demande photo XL
export const RefusDemandePhotoXLAction = (idDemande) => {
  return {
    type: ADMIN_REFUS_DEMANDE_PHOTO_XL,
    payload: {
      idDemande
    }
  };
};

////////////////////////////////////////////////////////////////////////////
// Photos XL
////////////////////////////////////////////////////////////////////////////

//Ajout d'une photo XL
export const addPhotoXLAction = (values, photo) => {
  return {
    type: ADMIN_ADD_PHOTO_XL,
    payload: {
      values,
      photo: photo
    },
  };
};

//Liste des photos XL
export function getListePhotosXLAction(pageSize, page, keyword) {
  return {
    type: ADMIN_GET_LISTE_PHOTOS_XL,
    payload: {
      pageSize,
      page,
      keyword
    }
  };
}

export function showListePhotosXLAction(dataListPhotosXL) {
  return {
    type: ADMIN_SHOW_LISTE_PHOTOS_XL,
    dataListPhotosXL
  };
}

//Suppression d'une photo XL
export const deletePhotoXLAction = (idPhoto) => {
  return {
    type: ADMIN_DELETE_PHOTO_XL,
    payload: {
      idPhoto
    },
  };
};

//Modification d'une photo XL
export const updatePhotoXLAction = (idPhoto, values, photo) => {
  return {
    type: ADMIN_UPDATE_PHOTO_XL,
    payload: {
      idPhoto,
      values,
      photo
    },
  };
};

//Récupération d'une Photos XL
export function getPhotoXLAction(idPhoto) {
  return {
    type: ADMIN_GET_PHOTO_XL,
    payload: {
      idPhoto
    }
  };
}

export function showPhotoXLAction(dataPhotoXL) {
  return {
    type: ADMIN_SHOW_PHOTO_XL,
    dataPhotoXL
  };
}

////////////////////////////////////////////////////////////////////////////
// Blogs
////////////////////////////////////////////////////////////////////////////

//Ajout d'un blog
export const addBlogAction = (values, description, photo) => {
  return {
    type: ADMIN_ADD_BLOG,
    payload: {
      values,
      description,
      photo: photo
    },
  };
};

//Liste des blogs
export function getListeBlogsAction() {
  return {
    type: ADMIN_GET_LISTE_BLOGS
  };
}

export function showListeBlogsAction(dataListBlogs) {
  return {
    type: ADMIN_SHOW_LISTE_BLOGS,
    dataListBlogs
  };
}

//Récupération d'un Blog
export function getBlogAction(idBlog) {
  return {
    type: ADMIN_GET_BLOG,
    payload: {
      idBlog
    }
  };
}

export function showBlogAction(dataBlog) {
  return {
    type: ADMIN_SHOW_BLOG,
    dataBlog
  };
}

//Modification d'un blog
export const updateBlogAction = (idBlog, values, description, photo) => {
  return {
    type: ADMIN_UPDATE_BLOG,
    payload: {
      idBlog,
      values,
      description,
      photo: photo
    },
  };
};

//Suppression d'un blog
export const deleteBlogAction = (idBlog) => {
  return {
    type: ADMIN_DELETE_BLOG,
    payload: {
      idBlog
    },
  };
};

////////////////////////////////////////////////////////////////////////////
// Temoignages Comédiens
////////////////////////////////////////////////////////////////////////////

//Ajout d'un temoignage
export const addTemoignageComedienAction = (values) => {
  return {
    type: ADMIN_ADD_TEMOIGNAGE_COMEDIEN,
    payload: {
      values,
    },
  };
};

//Liste des temoignages
export function getListeTemoignagesComediensAction() {
  return {
    type: ADMIN_GET_LISTE_TEMOIGNAGES_COMEDIENS
  };
}

export function showListeTemoignagesComediensAction(dataListTemoignages) {
  return {
    type: ADMIN_SHOW_LISTE_TEMOIGNAGES_COMEDIENS,
    dataListTemoignages
  };
}

//Récupération d'un temoignage
export function getTemoignageComedienAction(idTemoignage) {
  return {
    type: ADMIN_GET_TEMOIGNAGE_COMEDIEN,
    payload: {
      idTemoignage
    }
  };
}

export function showTemoignageComedienAction(dataTemoignage) {
  return {
    type: ADMIN_SHOW_TEMOIGNAGE_COMEDIEN,
    dataTemoignage
  };
}

//Suppression d'un temoignage
export const deleteTemoignageComedienAction = (idTemoignage) => {
  return {
    type: ADMIN_DELETE_TEMOIGNAGE_COMEDIEN,
    payload: {
      idTemoignage,
    },
  };
};

//Modification d'un temoignage
export const updateTemoignageComedienAction = (idTemoignage, values) => {
  return {
    type: ADMIN_UPDATE_TEMOIGNAGE_COMEDIEN,
    payload: {
      idTemoignage,
      values
    },
  };
};

////////////////////////////////////////////////////////////////////////////
// Aide
////////////////////////////////////////////////////////////////////////////

//Ajout d'un aide
export const addAideAction = (values, reponse) => {
  return {
    type: ADMIN_ADD_AIDE,
    payload: {
      values,
      reponse
    },
  };
};

//Liste des aides
export function getListeAidesAction(keyword = '') {
  return {
    type: ADMIN_GET_LISTE_AIDES,
    payload: {
      keyword
    }
  }
}

export function showListeAidesAction(dataListeAides) {
  return {
    type: ADMIN_SHOW_LISTE_AIDES,
    dataListeAides
  }
}

//Suppression d'un aide
export const deleteAideAction = (idAide) => {
  return {
    type: ADMIN_DELETE_AIDE,
    payload: {
      idAide
    },
  };
};

//Récupération d'un aide
export function getAideAction(idAide) {
  return {
    type: ADMIN_GET_AIDE,
    payload: {
      idAide
    }
  };
}

export function showAideAction(dataAide) {
  return {
    type: ADMIN_SHOW_AIDE,
    dataAide
  }
}

//Modification d'un aide
export const updateAideAction = (idAide, values, reponse) => {
  return {
    type: ADMIN_UPDATE_AIDE,
    payload: {
      idAide,
      values,
      reponse
    },
  };
};

// Tri des aides
export const sortAidesAction = (sortString) => {
  return {
    type: ADMIN_SORT_AIDES,
    payload: {
      sortString
    },
  };
};


////////////////////////////////////////////////////////////////////////////
// Articles
////////////////////////////////////////////////////////////////////////////

//Ajout d'un article
export const addArticleAction = (values, contenu) => {
  return {
    type: ADMIN_ADD_ARTICLE,
    payload: {
      values,
      contenu
    },
  };
};

//Liste des articles
export function getListeArticlesAction(keyword = '') {
  return {
    type: ADMIN_GET_LISTE_ARTICLES,
    payload: {
      keyword
    }
  }
}

export function showListeArticlesAction(dataListeArticles) {
  return {
    type: ADMIN_SHOW_LISTE_ARTICLES,
    dataListeArticles
  }
}

//Suppression d'un article
export const deleteArticleAction = (idArticle) => {
  return {
    type: ADMIN_DELETE_ARTICLE,
    payload: {
      idArticle
    },
  };
};

//Récupération d'un article
export function getArticleAction(idArticle) {
  return {
    type: ADMIN_GET_ARTICLE,
    payload: {
      idArticle
    }
  };
}

export function showArticleAction(dataArticle) {
  return {
    type: ADMIN_SHOW_ARTICLE,
    dataArticle
  }
}

//Modification d'un article
export const updateArticleAction = (idArticle, values, contenu) => {
  return {
    type: ADMIN_UPDATE_ARTICLE,
    payload: {
      idArticle,
      values,
      contenu
    },
  };
};

///////////////////////////////////////////////////////////////////////
// Stats
///////////////////////////////////////////////////////////////////////

//Récupération des stats
export const getAdminStatsAction = (filtre) => {
  return {
    type: ADMIN_GET_STATS,
    payload: {
      filtre
    }
  }
}

export const showAdminStatsAction = (dataAdminStats) => {
  return {
    type: ADMIN_SHOW_STATS,
    dataAdminStats
  }
}

//Récupération de l'historique des stats
export const getAdminHistoriqueStatsAction = (pageSize = 10, page = 1, action = '', filtre) => {
  return {
    type: ADMIN_GET_HISTORIQUE_STATS,
    payload: {
      pageSize,
      page,
      action,
      filtre
    }
  }
}

export const showAdminHistoriqueStatsAction = (dataAdminHistoriqueStats) => {
  return {
    type: ADMIN_SHOW_HISTORIQUE_STATS,
    dataAdminHistoriqueStats
  }
}


//Modification du Config Ranking Algolia
export const updateConfigRankingAlgoliaAction = (values) => {
  return {
    type: ADMIN_UPDATE_CONFIG_RANKING_ALGOLIA,
    payload: {
      values
    }
  };
};

//récupération du Config Ranking Algolia
export function getConfigRankingAlgoliaAction() {
  return {
    type: ADMIN_GET_CONFIG_RANKING_ALGOLIA,
  };
}

export function showConfigRankingAlgoliaAction(dataConfigRankingAlgolia) {
  return {
    type: ADMIN_SHOW_CONFIG_RANKING_ALGOLIA,
    dataConfigRankingAlgolia
  };
}

///////////////////////////////////////////////////////////////////////
// Mon Compte
///////////////////////////////////////////////////////////////////////

//Récupération du profil de l'admin
export function getAdminProfilAction(idUser) {
  return {
    type: ADMIN_GET_PROFIL,
    payload: {
      idUser
    }
  };
}

export function showAdminProfilAction(dataAdminProfil) {
  return {
    type: ADMIN_SHOW_PROFIL,
    dataAdminProfil
  };
}

//Modification du profil de l'admin
export const updateAdminProfilAction = (idUser, values, photoProfil, photoProfilCropped) => {
  return {
    type: ADMIN_UPDATE_PROFIL,
    payload: {
      idUser,
      values,
      photoProfil,
      photoProfilCropped
    }
  };
};

///////////////////////////////////////////////////////////////////////
// Notes de comédien
///////////////////////////////////////////////////////////////////////

//Modification des notes comédien
export const updateNotesComedienAction = (idUser, notes) => {
  return {
    type: ADMIN_UPDATE_NOTES_COMEDIEN,
    payload: {
      idUser,
      notes
    }
  };
};

//Récupération des Notes comédien
export function getNotesComedienAction(idUser) {
  return {
    type: ADMIN_GET_NOTES_COMEDIEN,
    payload: {
      idUser
    }
  };
}

export function showNotesComedienAction(dataNotesComedien) {
  return {
    type: ADMIN_SHOW_NOTES_COMEDIEN,
    dataNotesComedien
  };
}

///////////////////////////////////////////////////////////////////////
// Campagnes de Com
///////////////////////////////////////////////////////////////////////

//Liste des campagnes de Com
export function getListeCampagnesComAction() {
  return {
    type: ADMIN_GET_LISTE_CAMPAGNES_COM
  };
}

export function showListeCampagnesComAction(dataListCampagnesCom) {
  return {
    type: ADMIN_SHOW_LISTE_CAMPAGNES_COM,
    dataListCampagnesCom
  };
}

//Suppression d'une campagne de com
export const deleteCampagneComAction = (idCampagne) => {
  return {
    type: ADMIN_DELETE_CAMPAGNE_COM,
    payload: {
      idCampagne,
    },
  };
};

//Activation d'une campagne de com
export const activationCampagneComAction = (idCampagne) => {
  return {
    type: ADMIN_ACTIVATION_CAMPAGNE_COM,
    payload: {
      idCampagne
    },
  };
};

//Désctivation d'une campagne de com
export const desactivationCampagneComAction = (idCampagne) => {
  return {
    type: ADMIN_DESACTIVATION_CAMPAGNE_COM,
    payload: {
      idCampagne
    },
  };
};

//Ajout d'une campagne de com
export const addCampagneComAction = (values) => {
  return {
    type: ADMIN_ADD_CAMPAGNE_COM,
    payload: {
      values,
    },
  };
};

//Récupération d'une Campagne de com
export function getCampagneComAction(idCampagne) {
  return {
    type: ADMIN_GET_CAMPAGNE_COM,
    payload: {
      idCampagne
    }
  };
}

export function showCampagneComAction(dataCampagneCom) {
  return {
    type: ADMIN_SHOW_CAMPAGNE_COM,
    dataCampagneCom
  };
}

//Modification d'une campagne de com
export const updateCampagneComAction = (idCampagne, values) => {
  return {
    type: ADMIN_UPDATE_CAMPAGNE_COM,
    payload: {
      idCampagne,
      values
    },
  };
};

// Récupération de la Campagne pour l'Utilisateur/Comedien connecté
export function getCampagneComByUserAction() {
  return {
    type: ADMIN_GET_CAMPAGNE_COM_BY_USER
  };
}

export function showCampagneComByUserAction(dataCampagneComByUser) {
  return {
    type: ADMIN_SHOW_CAMPAGNE_COM_BY_USER,
    dataCampagneComByUser
  };
}

///////////////////////////////////////////////////////////////////////
// Support Comedien
///////////////////////////////////////////////////////////////////////

//Ajout d'un support comédien
export const addSupportComedienAction = (idUser, values) => {
  return {
    type: ADMIN_ADD_SUPPORT,
    payload: {
      idUser,
      values
    },
  };
};

//Liste des supports d'un comédien
export function getListeSupportsByComedienAction(idUser) {
  return {
    type: ADMIN_GET_LISTE_SUPPORTS_BY_COMEDIEN,
    payload: {
      idUser
    }
  }
}

export function showListeSupportsByComedienAction(dataListeSuportsByComedien) {
  return {
    type: ADMIN_SHOW_LISTE_SUPPORTS_BY_COMEDIEN,
    dataListeSuportsByComedien
  }
}

//Suppression d'un support comédien
export const deleteSupportComedienAction = (idSupport) => {
  return {
    type: ADMIN_DELETE_SUPPORT_COMEDIEN,
    payload: {
      idSupport
    },
  };
};

//Récupération d'un support comédien
export function getSupportComedienAction(idSupport) {
  return {
    type: ADMIN_GET_SUPPORT_COMEDIEN,
    payload: {
      idSupport
    }
  };
}

export function showSupportComedienAction(dataSupportComedien) {
  return {
    type: ADMIN_SHOW_SUPPORT_COMEDIEN,
    dataSupportComedien
  }
}

//Modification d'un support comédien
export const updateSupportComedienAction = (values) => {
  return {
    type: ADMIN_UPDATE_SUPPORT_COMEDIEN,
    payload: {
      values
    },
  };
};

////////////////////////////////////////////////////////////////////////////
// Medias
////////////////////////////////////////////////////////////////////////////

//Ajout d'un media
export const addMediaAction = (values, media) => {
  return {
    type: ADMIN_ADD_MEDIA,
    payload: {
      values,
      media: media
    },
  };
};

//Liste des medias
export function getListeMediasAction(pageSize, page) {
  return {
    type: ADMIN_GET_LISTE_MEDIAS,
    payload: {
      pageSize,
      page
    }
  };
}

export function showListeMediasAction(dataListMedias) {
  return {
    type: ADMIN_SHOW_LISTE_MEDIAS,
    dataListMedias
  };
}

//Suppression d'un media
export const deleteMediaAction = (idCmsMedia) => {
  return {
    type: ADMIN_DELETE_MEDIA,
    payload: {
      idCmsMedia
    },
  };
};