import { Base64 } from 'js-base64';
import { serverBaseUrl } from "./config";

export function getBackendMediaUrl (mediaPath) {
  return serverBaseUrl + '/' + mediaPath;
}

export function encodeUrl (urlToEncode) {
  return  Base64.encode(urlToEncode);
}

export function decodeUrl (urlEncoded) {
  return  Base64.decode(urlEncoded)
}
