import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import {
    LOG_LECTURE_EXTRAIT,
    LOG_PARTAGE_EXTRAIT,
    LOG_AJOUT_EXTRAIT_PLAYLIST,
    LOG_AFFICHAGE_TELEPHONE,
    LOG_VISITE_FICHE
  } from "constants/ActionTypes";

import {
    lectureExtraitRequest,
    affichageTelephoneRequest,
    visiteFicheRequest,
    partageExtraitRequest,
    ajoutExtraitPlaylistRequest
} from 'appRedux/services/Log';

//////////////////////////////////////////////////////////////
// Lecture d'un extrait
/////////////////////////////////////////////////////////////

function* lectureExtrait({payload}) {
    try {
      const response = yield call(lectureExtraitRequest,payload);
      if (response.status === 200) {
      }
    } catch (error) {
    }
}
  
export function* lectureExtraitFork() {
  yield takeEvery(LOG_LECTURE_EXTRAIT, lectureExtrait);
}

/////////////////////////////////////////////////////////////
// Partage d'un extrait
/////////////////////////////////////////////////////////////

function* partageExtrait({payload}) {
  try {
    const response = yield call(partageExtraitRequest,payload);
    if (response.status === 200) {
    }
  } catch (error) {
  }
}

export function* partageExtraitFork() {
  yield takeEvery(LOG_PARTAGE_EXTRAIT, partageExtrait);
}

/////////////////////////////////////////////////////////////
// Ajout d'un extrait dans une playlist
/////////////////////////////////////////////////////////////

function* ajoutExtraitPlaylist({payload}) {
  try {
    const response = yield call(ajoutExtraitPlaylistRequest,payload);
    if (response.status === 200) {
    }
  } catch (error) {
  }
}

export function* ajoutExtraitPlaylistFork() {
  yield takeEvery(LOG_AJOUT_EXTRAIT_PLAYLIST, ajoutExtraitPlaylist);
}

//////////////////////////////////////////////////////////////
// Affichage du téléphone
/////////////////////////////////////////////////////////////

function* affichageTelephone({payload}) {
    try {
      const response = yield call(affichageTelephoneRequest,payload);
      if (response.status === 200) {
      }
    } catch (error) {
    }
}
  
export function* affichageTelephoneFork() {
  yield takeEvery(LOG_AFFICHAGE_TELEPHONE, affichageTelephone);
}

//////////////////////////////////////////////////////////////
// Visite de la fiche
/////////////////////////////////////////////////////////////

function* visiteFiche({payload}) {
    try {
      const response = yield call(visiteFicheRequest,payload);
      if (response.status === 200) {
      }
    } catch (error) {
    }
}
  
export function* visiteFicheFork() {
  yield takeEvery(LOG_VISITE_FICHE, visiteFiche);
}

////////////////////////////////////////////////////////////////////////////
// Exports
////////////////////////////////////////////////////////////////////////////

export default function* rootSaga() {
  yield all([
    fork(lectureExtraitFork),
    fork(partageExtraitFork),
    fork(ajoutExtraitPlaylistFork),
    fork(affichageTelephoneFork),
    fork(visiteFicheFork),
  ]);
}