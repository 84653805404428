import axios from "axios";
import { endpointMedia } from 'util/api'

/**
   * Ajout d'une photo XL
   * @param {*} payload 
   */
export const addMediaRequest = async (payload) => {
    const user = localStorage.user;
    const accessToken = user ? JSON.parse(user).id : '';
    const formData = new FormData();
    const { values, media } = payload;
  
    formData.append("file", media);

    const wsResponse = await axios.request({
      method: 'post',
      url: `${endpointMedia.ADD_MEDIA}?access_token=${accessToken}`,
      data: formData,
      config: {
        headers: {
          'Content-Type': `multipart/form-data`,
          'Accept': 'application/json',
        }
      }
    });
  
    return wsResponse;
  }
  

/**
 * Liste des photos XL
 * @param {*} payload 
 */
export const getListeMediasRequest = async (payload) => {
    const user = localStorage.user;
    const accessToken = user ? JSON.parse(user).id : '';
  
    const { pageSize, page } = payload;
  
    // call WS Loopback
    const wsResponse = await axios.request({
      method: 'get',
      url: `${endpointMedia.GET_MEDIAS}?access_token=${accessToken}`,
      params: {
        pageSize,
        page
      }
    });
  
    return wsResponse;
  }
  
  /**
   * Suppression d'une photo XL
   * @param {*} id 
   */
  export const deleteMediaRequest = async (payload) => {
    const user = localStorage.user;
    const accessToken = user ? JSON.parse(user).id : '';
  
    const { idCmsMedia } = payload;
  
    const wsResponse = await axios.request({
      method: 'delete',
      url: `${endpointMedia.DELETE_MEDIA}?access_token=${accessToken}`,
      data: {
        idCmsMedia
      }
    });
  
    return wsResponse;
  }
  
  
  





























