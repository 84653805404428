import axios from "axios";
import { endpointComedien } from 'util/api';

var moment = require('moment');

/**
 * R�cup�ration de l'identit� d'un com�dien
 * @param {*} payload 
 */
export const getIdentiteRequest = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  const { idUser } = payload;

  const wsResponse = await axios.request({
    method: 'get',
    url: `${endpointComedien.GET_IDENTITE}?access_token=${accessToken}`,
    params: {
      idUser
    }
  });

  return wsResponse;
}

/**
 * Modification de l'identit� d'un com�dien
 * @param {*} payload 
 */
export const updateIdentiteRequest = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  const formData = new FormData();
  const { idUser, values, photoProfil, photoProfilCropped, photoFond } = payload;

  formData.append('idUser', idUser);
  formData.append('prenom', values.prenom);
  formData.append('nom', values.nom);
  formData.append('telMobile', values.telMobile);
  formData.append('visibilitePhone', values.visibilitePhone);
  formData.append('photoProfil', photoProfil);
  formData.append('photoProfilCropped', photoProfilCropped);
  formData.append('photoFond', photoFond);
  formData.append('photoFondSeule', values.photoFondSeule ? 1 : 0);
  formData.append('presentation', values.presentation);

  const wsResponse = await axios.request({
    method: 'post',
    url: `${endpointComedien.UPDATE_IDENTITE}?access_token=${accessToken}`,
    data: formData,
    config: {
      headers: {
        'Content-Type': `multipart/form-data`,
        'Accept': 'application/json',
      }
    }
  });

  return wsResponse;
}

/**
 * R�cup�ration des infos Activit� et Facturation d'un com�dien
 * @param {*} payload 
 */
export const getActiviteFacturationRequest = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  let { idUser } = payload;

  const wsResponse = await axios.request({
    method: 'get',
    url: `${endpointComedien.GET_ACTIVITE_FACTURATION}?access_token=${accessToken}`,
    params: {
      idUser
    }
  });

  return wsResponse;
}

/**
 * Modification des infos Activit� et Facturation d'un com�dien 
 * @param {*} payload 
 */
export const updateActiviteFacturationRequest = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  const { idUser, regions, travailDistant, paiementCachet, peutFacturer } = payload;

  const formData = new FormData();

  formData.append('idUser', idUser);
  formData.append('regions', regions ? regions.join() : '');
  formData.append('travailDistant', travailDistant ? 1 : 0);
  formData.append('paiementCachet', paiementCachet ? 1 : 0);
  formData.append('peutFacturer', peutFacturer ? 1 : 0);

  const wsResponse = await axios.request({
    method: 'post',
    url: `${endpointComedien.UPDATE_ACTIVITE_FACTURATION}?access_token=${accessToken}`,
    data: formData,
    config: {
      headers: {
        'Content-Type': `multipart/form-data`,
        'Accept': 'application/json',
      }
    }
  });

  return wsResponse;
}

/**
 * R�cup�ration des donn�es du formulaire de r�-inscription d'un com�dien
 * @param {*} payload 
 */
export const getReInscriptionFormRequest = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  let { formule } = payload;

  const wsResponse = await axios.request({
    method: 'get',
    url: `${endpointComedien.GET_RE_INSCRIPTION_FORM}?access_token=${accessToken}`,
    params: {
      formule
    }
  });

  return wsResponse;
}

/**
 * V�rification du paiement d'une r�-inscription com�dien
 * @param {*} payload 
 */
export const verifPaiementReInscriptionRequest = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  let { dataComedien, dataPaybox } = payload;

  const { civilite, prenom, nom, telMobile, adresse, codePostal, ville, pays, formule } = dataComedien;
  const { refCommande, erreur, montant, modePaiement, typeCarte, idTransaction, numTransaction, dateTransaction, numAutorisation, signature } = dataPaybox;

  const formData = new FormData();

  formData.append('civilite', civilite);
  formData.append('prenom', prenom);
  formData.append('nom', nom);
  formData.append('telMobile', telMobile);
  formData.append('adresse', adresse);
  formData.append('codePostal', codePostal);
  formData.append('ville', ville);
  formData.append('pays', pays);
  formData.append('refCommande', refCommande);
  formData.append('erreur', erreur);
  formData.append('montant', montant);
  formData.append('modePaiement', modePaiement);
  formData.append('typeCarte', typeCarte);
  formData.append('idTransaction', idTransaction);
  formData.append('numTransaction', numTransaction);
  formData.append('dateTransaction', dateTransaction);
  formData.append('numAutorisation', numAutorisation);
  formData.append('signature', signature);

  const wsResponse = await axios.request({
    method: 'POST',
    url: `${endpointComedien.VERIF_PAIEMENT_RE_INSCRIPTION}?access_token=${accessToken}`,
    data: formData,
    config: {
      headers: {
        'Content-Type': `multipart/form-data`,
        'Accept': 'application/json',
      }
    }
  });

  return wsResponse;
}

/**
 * Paiement d'une r�-inscription par ch�que
 * @param {*} payload 
 */
export const paiementReInscriptionParChequeRequest = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  let { formule, values } = payload;

  const formData = new FormData();

  formData.append('formule', formule);
  formData.append('civilite', values.civilite);
  formData.append('prenom', values.prenom);
  formData.append('nom', values.nom);
  formData.append('telMobile', values.telMobile);
  formData.append('adresse', values.adresse);
  formData.append('codePostal', values.codePostal);
  formData.append('ville', values.ville);
  formData.append('pays', values.pays);

  const wsResponse = await axios.request({
    method: 'POST',
    url: `${endpointComedien.PAIEMENT_RE_INSCRIPTION_CHEQUE}?access_token=${accessToken}`,
    data: formData,
    config: {
      headers: {
        'Content-Type': `multipart/form-data`,
        'Accept': 'application/json',
      }
    }
  });

  return wsResponse;
}

/**
 * R�cup�ration des param�tres de configuration d'un com�dien
 * @param {*} payload 
 */
export const getAbonnementComedienRequest = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  const { idUser } = payload;

  const wsResponse = await axios.request({
    method: 'get',
    url: `${endpointComedien.GET_ABONNEMENT_COMEDIEN}?access_token=${accessToken}`,
    params: {
      idUser
    }
  });

  return wsResponse;
}

/**
 * Modification des param�tres de configuration d'un com�dien
 * @param {*} idUser 
 * @param {*} values 
 */
export const updateAbonnementComedienRequest = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  const { idUser, formuleCompte, values } = payload;

  const formData = new FormData();

  formData.append("idUser", idUser);
  formData.append("formuleCompte", formuleCompte);
  formData.append("droitsDateFin", moment(values.droitsDateFin).format("YYYY-MM-DD"));
  formData.append("boost", values.boost);
  formData.append("heuresOpenStudio", values.heuresOpenStudio);

  const wsResponse = await axios.request({
    method: 'post',
    url: `${endpointComedien.UPDATE_ABONNEMENT_COMEDIEN}?access_token=${accessToken}`,
    data: formData,
    config: {
      headers: {
        'Content-Type': `multipart/form-data`,
        'Accept': 'application/json',
      }
    }
  });

  return wsResponse;
}

/**
 * Validation du paiement par ch�que d'un abonnement com�dien
 * @param {*} idUser 
 */
export const validationPaiementCheckAbonnementComedienRequest = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  const { idUser } = payload;

  const wsResponse = await axios.request({
    method: 'post',
    url: `${endpointComedien.VALIDATION_PAIEMENT_CHEQUE_ABONNEMENT_COMEDIEN}?access_token=${accessToken}`,
    data: {
      idUser
    }
  });

  return wsResponse;
}

/**
 * R�cup�ration des donn�es du Tableau de bord du compte
 */
export const getTDBMonCompteRequest = async () => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  const wsResponse = await axios.request({
    method: 'get',
    url: `${endpointComedien.GET_TABLEAU_DE_BORD}?access_token=${accessToken}`
  });

  return wsResponse;
}

/**
 * R�cup�ration de l'adresse de facturation
 * @param {*} payload 
 */
export const getAdresseFacturationRequest = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  let { idUser } = payload;

  const wsResponse = await axios.request({
    method: 'get',
    url: `${endpointComedien.GET_ADRESSE_FACTURATION}?access_token=${accessToken}`,
    params: {
      idUser
    }
  });

  return wsResponse;
}

/**
 * Mise � jour de l'adresse de facturation
 * @param {*} idUser 
 * @param {*} values 
 */
export const updateAdresseFacturationRequest = async (idUser, values) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  const formData = new FormData();

  formData.append("idUser", idUser);
  formData.append("civilite", values.civilite);
  formData.append("prenom", values.prenom);
  formData.append("nom", values.nom);
  formData.append("infoSociete", values.infoSociete);
  formData.append("coordAdresse", values.coordAdresse);
  formData.append("coordCp", values.coordCp);
  formData.append("coordVille", values.coordVille);
  formData.append("coordPays", values.coordPays);

  const wsResponse = await axios.request({
    method: 'post',
    url: `${endpointComedien.UPDATE_ADRESSE_FACTURATION}?access_token=${accessToken}`,
    data: formData,
    config: {
      headers: {
        'Content-Type': `multipart/form-data`,
        'Accept': 'application/json',
      }
    }
  });

  return wsResponse;
}

/**
 * R�cup�ration des stats d'un com�dien
 */
export const getStatsRequest = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  const { filtre } = payload;

  const wsResponse = await axios.request({
    method: 'get',
    url: `${endpointComedien.GET_STATS}?access_token=${accessToken}`,
    params: {
      filtre
    }
  });

  return wsResponse;
}

/**
 * R�cup�ration de l'historique de stats d'un com�dien
 * @param {*} payload 
 */
export const getHistoriqueStatsComedienRequest = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  const { pageSize, page, action, filtre } = payload;

  const wsResponse = await axios.request({
    method: 'get',
    url: `${endpointComedien.GET_HISTORIQUE_STATS}?access_token=${accessToken}`,
    params: {
      pageSize,
      page,
      action,
      filtre
    }
  });

  return wsResponse;
}

/**
 * Admin/Liste des com�diens
 * @param {*} payload 
 */
export const getListComediensRequest = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';
  var { pageSize, page, keyword } = payload;

  const wsResponse = await axios.request({
    method: 'get',
    url: `${endpointComedien.GET_LIST_COMEDIENS}?access_token=${accessToken}`,
    params: {
      pageSize,
      page,
      keyword
    }
  });

  return wsResponse;
}

/**
 * Admin / Liste de tous les com�diens actifs
 * @param {*} payload 
 */
export const getAllComediensRequest = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  const { formuleCompte } = payload;

  const wsResponse = await axios.request({
    method: 'get',
    url: `${endpointComedien.GET_ALL_COMEDIENS}?access_token=${accessToken}`,
    params: {
      formuleCompte
    }
  });

  return wsResponse;
}

/**
 * Modification des notes d'admin
 * @param {*} payload 
 */
export const updateNotesComedienRequest = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  const formData = new FormData();
  const { idUser, notes } = payload;

  formData.append('idUser', idUser);
  formData.append('notes', notes);

  const wsResponse = await axios.request({
    method: 'post',
    url: `${endpointComedien.UPDATE_NOTES_ADMIN}?access_token=${accessToken}`,
    data: formData,
    config: {
      headers: {
        'Content-Type': `multipart/form-data`,
        'Accept': 'application/json',
      }
    }
  });

  return wsResponse;
}

/**
 * R�cup�ration des Notes Admin d'un com�dien
 * @param {*} payload 
 */
export const getNotesComedienRequest = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  const { idUser } = payload;

  const wsResponse = await axios.request({
    method: 'get',
    url: `${endpointComedien.GET_NOTES_ADMIN}?access_token=${accessToken}`,
    params: {
      idUser
    }
  });

  return wsResponse;
}

/////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////



export const getDemandeContactDataRequest = async (idComedien) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  // call WS Loopback
  const wsResponse = await axios.request({
    method: 'get',
    url: `${endpointComedien.GET_INFOS_DEMANDE_CONTACT}?id=${idComedien}&access_token=${accessToken}`,
  });

  return wsResponse;
}



export const sendDemandeContactComedienRequest = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  const { type, idComedien, telephone, values } = payload;

  const formData = new FormData();

  formData.append("type", type);
  formData.append("idComedien", idComedien);
  formData.append("telephone", telephone);
  formData.append("message", values.message);

  const wsResponse = await axios.request({
    method: 'post',
    url: `${endpointComedien.SEND_DEMANDE_CONTACT}?access_token=${accessToken}`,
    data: formData,
    config: {
      headers: {
        'Content-Type': `multipart/form-data`,
        'Accept': 'application/json',
      }
    }
  });

  return wsResponse;
}







export const getContactRequest = async () => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';
  // call WS Loopback
  const wsResponse = await axios.request({
    method: 'get',
    url: `${endpointComedien.GET_CONTACT}?access_token=${accessToken}`,
  });
  return wsResponse;
}

export const UpdateProfilContactRequest = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  const { values } = payload;

  const formData = new FormData();
  //formData.append("email", values.email);

  formData.append("facebook", values.facebook);
  formData.append("linkedin", values.linkedin);
  formData.append("youtube", values.youtube);
  formData.append("website", values.website);

  const wsResponse = await axios.request({
    method: 'put',
    url: `${endpointComedien.UPDATE_CONTACT}?access_token=${accessToken}`,
    data: formData,
    config: {
      headers: {
        'Content-Type': `multipart/form-data`,
        'Accept': 'application/json',
      }
    }
  });
  return wsResponse;
}




export const getFicheComedienRequest = async (id) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  const wsResponse = await axios.request({
    method: 'get',
    url: `${endpointComedien.GET_FICHE}?id=${id}&access_token=${accessToken}`,
  });
  return wsResponse;
}

export const getComedienByUrlRequest = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  let { url } = payload;

  const wsResponse = await axios.request({
    method: 'get',
    url: `${endpointComedien.GET_BY_URL}?access_token=${accessToken}`,
    params: {
      url
    }
  });
  return wsResponse;
}

/**
 * D�sactivation d'un utilisateur
 * @param {*} payload 
 */
export const sendSatsComedienRequest = async (payload) => {

  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  let { idUser } = payload;

  const wsResponse = await axios.request({
    method: 'POST',
    url: `${endpointComedien.SEND_STATS}?access_token=${accessToken}`,
    data: {
      id: idUser
    }
  });

  return wsResponse;
}


