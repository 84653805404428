import {
  SIGNIN,
  SIGNIN_SET_STATUS,
  SIGNIN_SUCCESS,
  SIGNOUT,
  SIGNOUT_SUCCESS,
  SIGNUP,
  SIGNUP_SET_STATUS,
  SIGNUP_SUCCESS,
  SIGNUP_CONFIRM,
  SIGNUP_CONFIRM_SUCCESS,
  AUTH_REQUEST_RESET_PASSWORD,
  AUTH_SET_STATUS_REQUEST_RESET_PASSWORD,
  AUTH_RESET_PASSWORD,
  AUTH_SET_STATUS_RESET_PASSWORD,
  COMEDIEN_GET_AUTH,
  COMEDIEN_SHOW_AUTH,
  COMEDIEN_UPDATE_AUTH,
  COMEDIEN_UPDATE_AUTH_SUCCESS,
  COMEDIEN_UPDATE_AUTH_ERROR,
  COMEDIEN_UPDATE_PASSWORD,
  COMEDIEN_UPDATE_PASSWORD_SUCCESS,
  COMEDIEN_UPDATE_PASSWORD_ERROR,
  GET_INFOS_USER,
  SHOW_INFOS_USER
} from "constants/ActionTypes";

///////////////////////////////////////////////////////////////////////
// Sign In
///////////////////////////////////////////////////////////////////////

//Authentification
export const signInAction = (user) => {
  return {
    type: SIGNIN,
    payload: user
  };
};

//Authentification avec succ�s
export const signInSuccessAction = (authUser) => {
  localStorage.setItem('user', JSON.stringify(authUser));
  return {
    type: SIGNIN_SUCCESS,
  };
};

//Modification du status de l'Authentification
export const setStatusSignInAction = (status) => {
  return {
    type: SIGNIN_SET_STATUS,
    payload: status,
  };
};

///////////////////////////////////////////////////////////////////////
// Sign Out
///////////////////////////////////////////////////////////////////////

//D�conexxion
export const signOutAction = () => {
  return {
    type: SIGNOUT,
  };
};

//D�connexion avec succ�s
export const signOutSuccessAction = () => {
  localStorage.removeItem('user');
  window.location = "/";
  return {
    type: SIGNOUT_SUCCESS,
  };
};

///////////////////////////////////////////////////////////////////////
// Sign Up
///////////////////////////////////////////////////////////////////////

//Inscription
export const signUpAction = (user) => {
  return {
    type: SIGNUP,
    payload: user
  };
};

//Inscription avec succ�s
export const signUpSuccessAction = (dataSignUp) => {
  return {
    type: SIGNUP_SUCCESS,
    dataSignUp
  };
};

//Modification du statut de la d�connexion
export const setStatusSignUpAction = (status) => {
  return {
    type: SIGNUP_SET_STATUS,
    payload: status,
  };
};

///////////////////////////////////////////////////////////////////////
// Sign Up Confirm
///////////////////////////////////////////////////////////////////////

//Confirmation de l'inscription
export const signUpConfirmAction = (idUser, verificationToken) => {
  return {
    type: SIGNUP_CONFIRM,
    payload: {
      idUser,
      verificationToken
    }
  };
};

//Confirmation de l'inscription avec succ�s
export const signUpConfirmSuccessAction = () => {
  window.location = '/?account-verified=1';
  return {
    type: SIGNUP_CONFIRM_SUCCESS,
  };
};

///////////////////////////////////////////////////////////////////////
// Forgot Password
///////////////////////////////////////////////////////////////////////

//Demande de r�initialisation de mot de passe
export const requestResetPasswordAction = (values) => {
  return {
    type: AUTH_REQUEST_RESET_PASSWORD,
    payload: {
      values
    }
  };
};

//Configuration du status de r�initialisation du mot de passe
export const setStatusRequestResetPasswordAction = (status) => {
  return {
    type: AUTH_SET_STATUS_REQUEST_RESET_PASSWORD,
    payload: status,
  };
};

//R�initialisation du mot de passe
export const resetPasswordAction = (values, accessToken) => {
  return {
    type: AUTH_RESET_PASSWORD,
    payload: {
      values,
      accessToken
    }
  };
};

//Configuration du statut de la r�initialisation du mot de passe
export const setStatusResetPasswordAction = (status) => {
  return {
    type: AUTH_SET_STATUS_RESET_PASSWORD,
    payload: status,
  };
};

///////////////////////////////////////////////////////////////////////
// Identifiants
///////////////////////////////////////////////////////////////////////

//R�cup�ration des identifiants
export const getIdentifiantsAction = (idUser) => {
  return {
    type: COMEDIEN_GET_AUTH,
    payload: {
      idUser: idUser
    }
  }
}
export function showIdentifiantsAction(dataAuth) {
  return {
    type: COMEDIEN_SHOW_AUTH,
    dataAuth
  };
};

//Modification des identifiants
export const updateIdentifiantsAction = (idUser, currentPassword, values) => {
  return {
    type: COMEDIEN_UPDATE_AUTH,
    payload: {
      idUser,
      currentPassword,
      values
    }
  };
};

//Modification des identifiants avec succ�s
export const updateIdentifiantsSuccessAction = () => {
  return {
    type: COMEDIEN_UPDATE_AUTH_SUCCESS,
  };
};

//Echec de la Modification des identifiants
export const updateIdentifiantsErrorAction = (error) => {
  return {
    type: COMEDIEN_UPDATE_AUTH_ERROR,
    error
  };
};

///////////////////////////////////////////////////////////////////////
// Mot de passe
///////////////////////////////////////////////////////////////////////

//Modification du mot de passe
export const updatePasswordAction = (idUser, currentPassword, values) => {
  return {
    type: COMEDIEN_UPDATE_PASSWORD,
    payload: {
      idUser,
      currentPassword,
      values
    }
  };
};

//Modification du mot de passe avec succ�s
export const updatePasswordSuccessAction = () => {
  return {
    type: COMEDIEN_UPDATE_PASSWORD_SUCCESS,
  };
};

//Echec de la modification du mot de passe
export const updatePasswordErrorAction = (error) => {
  return {
    type: COMEDIEN_UPDATE_PASSWORD_ERROR,
    error
  };
};

///////////////////////////////////////////////////////////////////////
// Infos user
///////////////////////////////////////////////////////////////////////

//R�cup�ration des infos utilisateur
export function getInfosUserAction(idUser = null) {
  return {
    type: GET_INFOS_USER,
    payload: {
      idUser
    }
  };
}

export function showInfosUserAction(dataInfosUser) {
  return {
    type: SHOW_INFOS_USER,
    dataInfosUser
  };
}

