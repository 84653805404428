import axios from "axios";
import { endpointSupportComedien } from 'util/api'

/**
 * Ajout d'un nouveau support
 * @param {*} payload 
 */
export const addSupportComedienRequest = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  const formData = new FormData();
  const { idUser, values } = payload;

  formData.append('idUser', idUser);
  formData.append('date', values.date);
  formData.append('type', values.type);
  formData.append('details', values.details);

  const wsResponse = await axios.request({
    method: 'post',
    url: `${endpointSupportComedien.ADD_SUPPORT}?access_token=${accessToken}`,
    data: formData,
    config: {
      headers: {
        'Content-Type': `multipart/form-data`,
        'Accept': 'application/json',
      }
    }
  });

  return wsResponse;
}


/**
 * Liste des Supports par comedien
 * @param {*} payload 
 */
export const getListeSupportsByComedienRequest = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  var {idUser} = payload;

  // call WS Loopback
  const wsResponse = await axios.request({
    method: 'get',
    url: `${endpointSupportComedien.GET_SUPPORTS_BY_COMEDIEN}?access_token=${accessToken}`,
    params: {
      idUser
    }
  });

  return wsResponse;
}

/**
 * Suppression d'un support comedien
 * @param {*} id 
 */
export const deleteSupportComedienRequest = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  const { idSupport } = payload;

  const wsResponse = await axios.request({
    method: 'delete',
    url: `${endpointSupportComedien.DELETE_SUPPORT}?access_token=${accessToken}`,
    data: {
      idSupport
    }
  });

  return wsResponse;
}


/**
 * Récupération d'un support comédien
 * @param {*} payload 
 */
export const getSupportComedienRequest = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  const { idSupport } = payload;

  // call WS Loopback
  const wsResponse = await axios.request({
    method: 'get',
    url: `${endpointSupportComedien.GET_SUPPORT}?access_token=${accessToken}`,
    params: {
      idSupport,
    }
  });

  return wsResponse;
}

/**
 * Modification d'un Support
 * @param {*} payload 
 */
export const updateSupportComedienRequest = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  const { values } = payload;
  
  const formData = new FormData();

  formData.append("idSupport", values.idSupport);
  formData.append("date", values.date);
  formData.append("type", values.type);
  formData.append("details", values.details);

  const wsResponse = await axios.request({
    method: 'post',
    url: `${endpointSupportComedien.UPDATE_SUPPORT}?access_token=${accessToken}`,
    data: formData,

  });
  return wsResponse;
}