import React, { Component } from "react";
import { Layout, Spin } from "antd";
import Sidebar from "../Sidebar/index";
import Topbar from "../Topbar/index";
import FooterContent from "../Footer/FooterContent";
import { connect } from "react-redux";
import { APPLICATION_PATHS } from "constants/ApplicationPaths";
import algoliasearch from 'algoliasearch/lite';
import { algolia } from 'util/config';
import SearchFormMobile from "components/SearchFormMobile"
import {
  algoliaStateChangeAction
} from 'appRedux/actions/Common'

import {
  InstantSearch
} from 'react-instantsearch-dom';

import AlertMessage from "components/AlertMessage";

/* Alogolia APi Key */
const searchClient = algoliasearch(
  algolia.applicationID,
  algolia.adminAPIKey
);

const { Content, Footer } = Layout;

export class MainApp extends Component {

  constructor() {
    super();
    this.state = {
    }
  }

  getLayoutClass = () => {
    

    if (!this.props.page) {
      return ""
    }

    if (this.props.page === 'recherche') {
      return "cm-search-layout"
    } else if (this.props.page === 'ficheComedienUrl') {
      return "cm-search-layout cm-page-comedien-layout"
    } else {
      return "";
    }

  };

  getNavStyles = () => {
    switch (window.location.pathname) {
      case APPLICATION_PATHS.RECHERCHE:
        return null;
      default:
        return <Topbar page={this.props.page} />;
    }

  };

  getSidebar = () => {
    switch (window.location.pathname) {
      default:
        return <Sidebar page={this.props.page} />;
    }
  };

  onAlgoliaSearchStateChange = () => {
    this.props.algoliaStateChangeAction(true);
  }

  render() {
    const { componentToRender } = this.props;

    return (

      <InstantSearch
        indexName={algolia.indexName}
        searchClient={searchClient}
        onSearchStateChange={this.onAlgoliaSearchStateChange}
      >
        <Layout>
          <Layout className={`gx-app-layout ${this.getLayoutClass()}`}>
            {this.getSidebar()}
            <Layout className="cm-gx-layout-content">
              {this.getNavStyles()}
              <Content className="gx-layout-content">
                <SearchFormMobile />
                <div className="gx-main-content-wrapper">
                  {componentToRender}
                </div>
              </Content>
            </Layout>
          </Layout>
          <Footer>
            <div className="gx-layout-footer-content">
              <FooterContent />
            </div>
          </Footer>
        </Layout>
        <AlertMessage />
      </InstantSearch>
    )
  }
}

const mapStateToProps = ({ settings }) => {
};

export default connect(mapStateToProps, {
  algoliaStateChangeAction
})(MainApp);

