//import React from "react";

export function displayEmoji (code) {
  try{
    if(code){
      return String.fromCodePoint(code);
    }else{
      return '';
    }
  }catch(e){
    return '';
  }
 }
 
 export function displayIcon2 (code) {
  try{
    return String.fromCodePoint(code);
  }catch(e){
    return '';
  }
 }