import axios from "axios";
import { endpointMp3 } from 'util/api';

/**
 * R�cu�ration de la liste des extraits MP3 d'un com�dien
 * @param {*} payload 
 */
export const getListeMP3Request = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  let { idUser } = payload;

  // call WS Loopback
  const wsResponse = await axios.request({
    method: 'get',
    url: `${endpointMp3.GET_LIST_MP3}?access_token=${accessToken}`,
    params: {
      idUser
    }
  });

  return wsResponse;
}

/**
 * Modification d ela visibilit� d'un extrait MP3
 * @param {*} visibilite 
 * @param {*} id 
 */
export const updateVisibiliteMP3Request = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  const { visibilite, id } = payload;

  const wsResponse = await axios.request({
    method: 'post',
    url: `${endpointMp3.UPDATE_VISIBILITE}?access_token=${accessToken}`,
    config: {
      headers: {
        'Content-Type': `application/x-www-form-urlencoded`,
        'Accept': 'application/json',
      }
    },
    data: {
      id,
      visibilite
    }
  });

  return wsResponse;
}

/**
 * R�cup�ration d'un extrait MP3
 * @param {*} payload 
 */
export const getExtraitMP3Request = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  let { idExtrait } = payload;

  // call WS Loopback
  const wsResponse = await axios.request({
    method: 'get',
    url: `${endpointMp3.GET_MP3}?access_token=${accessToken}`,
    params: {
      idExtrait
    }
  });

  return wsResponse;
}

/**
 * Modification d'un extrait MP3
 * @param {*} payload 
 */
export const updateMP3Request = async (payload) => {

  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';
  const formData = new FormData();
  const { values, file } = payload;

  formData.append("file", file);
  formData.append("id", values.id);
  formData.append("qfDiffusion", values.qfDiffusion ? 1 : 0);
  formData.append("titre", values.titre);
  formData.append("description", values.description);
  formData.append("qfType", values.qfType);
  formData.append("qfLangue", values.qfLangue);
  formData.append("qfAge", values.qfAge);
  formData.append("qfGenre", values.qfGenre);
  formData.append("qfTimbre", values.qfTimbre);
  formData.append("qfInterpretation1", values.qfInterpretation1);
  formData.append("qfInterpretation2", values.qfInterpretation2 || 0);
  formData.append("qfInterpretation3", values.qfInterpretation3 || 0);

  const wsResponse = await axios.request({
    method: 'post',
    url: `${endpointMp3.UPDATE_MP3}?access_token=${accessToken}`,
    data: formData,
    config: {
      headers: {
        'Content-Type': `multipart/form-data`,
        'Accept': 'application/json',
      }
    }
  });

  return wsResponse;
}

/**
 * Suppression d'un extrait MP3
 * @param {*} id 
 */
export const deleteExtraitMP3Request = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  const { id } = payload;

  const wsResponse = await axios.request({
    method: 'delete',
    url: `${endpointMp3.DELETE_MP3}?access_token=${accessToken}`,
    data: {
      id
    }
  });

  return wsResponse;
}

/**
 * Ajout d'un extrait MP3
 * @param {*} payload 
 */
export const addMP3Request = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  const { values, file } = payload;

  const formData = new FormData();

  formData.append("file", file);
  formData.append("qfDiffusion", values.qfDiffusion ? 1 : 0);
  formData.append("titre", values.titre);
  formData.append("description", values.description);
  formData.append("qfType", values.qfType);
  formData.append("qfLangue", values.qfLangue);
  formData.append("qfAge", values.qfAge);
  formData.append("qfGenre", values.qfGenre);
  formData.append("qfTimbre", values.qfTimbre);
  formData.append("qfInterpretation1", values.qfInterpretation1);
  formData.append("qfInterpretation2", values.qfInterpretation2 || 0);
  formData.append("qfInterpretation3", values.qfInterpretation3 || 0);

  const wsResponse = await axios.request({
    method: 'post',
    url: `${endpointMp3.ADD_MP3}?access_token=${accessToken}`,
    data: formData,
    config: {
      headers: {
        'Content-Type': `multipart/form-data`,
        'Accept': 'application/json',
      }
    }
  });
  return wsResponse;
}

export const sortExtraitsMP3Request = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  const { sortString } = payload;

  const wsResponse = await axios.request({
    method: 'post',
    url: `${endpointMp3.SORT_MP3}?access_token=${accessToken}`,
    data: { sortString: sortString },
    config: {
      headers: {
        'Content-Type': `multipart/form-data`,
        'Accept': 'application/json',
      }
    }
  });

  return wsResponse;
}

/**
 * Stats MP3
 */
export const getStatsMP3Request = async () => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  const wsResponse = await axios.request({
    method: 'get',
    url: `${endpointMp3.GET_STATS}?access_token=${accessToken}`,
  });

  return wsResponse;
}

/**
 * Top Extraits d'un com�dien
 */
export const getTopExtraitsStatsRequest = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  const { filtre } = payload;

  const wsResponse = await axios.request({
    method: 'get',
    url: `${endpointMp3.GET_TOP_EXTRAITS}?access_token=${accessToken}`,
    params: {
      filtre
    }
  });

  return wsResponse;
}

/**
 * Admin / Liste des extraits MP3
 * @param {*} payload 
 */
export const getComediensMP3Request = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  var { pageSize, page, keyword } = payload;

  const wsResponse = await axios.request({
    method: 'get',
    url: `${endpointMp3.GET_ALL_EXTRAITS_MP3}?access_token=${accessToken}`,
    params: {
      pageSize,
      page,
      keyword
    }
  });
  return wsResponse;
}

export const getFicheComedienMP3Request = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  let { idComedien, pageSize, page } = payload;

  console.log("TTTTTTTTTTTTTTTTTTTTTTTTTTTTT id : ", idComedien)


  const wsResponse = await axios.request({
    method: 'get',
    url: `${endpointMp3.GET_FICHE_COMEDIEN_MP3}?access_token=${accessToken}`,
    params: {
      idComedien,
      pageSize,
      page
    }
  });
  return wsResponse;
}