import axios from "axios";
import { endpointCMS } from 'util/api'
import { CMS_ELEMENTS } from "constants/General";

export const getCGURequest = async () => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  const wsResponse = await axios.request({
    method: 'get',
    url: `${endpointCMS.GET_ELEMENT}?access_token=${accessToken}`,
    params:{
      nom : CMS_ELEMENTS.CGU
    }
  });

  return wsResponse;
}

export const updateCGURequest = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  let { contenu } = payload;

  const wsResponse = await axios.request({
      method: 'post',
      url:`${endpointCMS.UPDATE_ELEMENT}?access_token=${accessToken}`,
      data: {
        nom : CMS_ELEMENTS.CGU,
        contenu
      }
  });
  return wsResponse;
}

export const getRGPDRequest = async () => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  const wsResponse = await axios.request({
    method: 'get',
    url: `${endpointCMS.GET_ELEMENT}?access_token=${accessToken}`,
    params:{
      nom : CMS_ELEMENTS.RGPD
    }
  });

  return wsResponse;
}

export const updateRGPDRequest = async (payload) => {
  const user = localStorage.user;
  const accessToken = user ? JSON.parse(user).id : '';

  let { contenu } = payload;

  const wsResponse = await axios.request({
      method: 'post',
      url:`${endpointCMS.UPDATE_ELEMENT}?access_token=${accessToken}`,
      data: {
        nom : CMS_ELEMENTS.RGPD,
        contenu
      }
  });
  return wsResponse;
}