import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import {

  ADMIN_GET_LIST_UTILISATEURS,
  ADMIN_DELETE_UTILISATEUR,
  ADMIN_ACTIVATION_UTILISATEUR,
  ADMIN_DESACTIVATION_UTILISATEUR,
  ADMIN_SWITCH_UTILISATEUR,
  ADMIN_VERIF_EMAIL_UTILISATEUR,
  ADMIN_GET_LIST_COMEDIENS,
  ADMIN_DELETE_COMEDIEN,
  ADMIN_SEND_STATS_COMEDIEN,
  ADMIN_GET_DEMANDES_INSCRIPTIONS_COMEDIENS,
  ADMIN_DELETE_DEMANDE_INSCRIPTION_COMEDIEN,
  ADMIN_GET_DEMANDE_INSCRIPTION_COMEDIEN,
  ADMIN_VALIDATION_DEMANDE_INSCRIPTION_COMEDIEN,
  ADMIN_REFUS_DEMANDE_INSCRIPTION_COMEDIEN,
  ADMIN_VALIDATION_PAIEMENT_CHEQUE,
  ADMIN_GET_LIST_NEWS_COMEDIENS,
  ADMIN_DELETE_NEWS_COMEDIEN,
  ADMIN_GET_NEWS,
  ADMIN_UPDATE_NEWS,
  ADMIN_GET_MP3_COMEDIENS,
  ADMIN_GET_LIST_DEMANDES_PHOTOS_XL,
  ADMIN_VALIDATION_DEMANDE_PHOTO_XL,
  ADMIN_REFUS_DEMANDE_PHOTO_XL,
  ADMIN_GET_LISTE_PHOTOS_XL,
  ADMIN_ADD_PHOTO_XL,
  ADMIN_DELETE_PHOTO_XL,
  ADMIN_UPDATE_PHOTO_XL,
  ADMIN_GET_PHOTO_XL,
  ADMIN_GET_LISTE_BLOGS,
  ADMIN_GET_BLOG,
  ADMIN_DELETE_BLOG,
  ADMIN_ADD_BLOG,
  ADMIN_UPDATE_BLOG,
  ADMIN_GET_LISTE_TEMOIGNAGES_COMEDIENS,
  ADMIN_GET_TEMOIGNAGE_COMEDIEN,
  ADMIN_ADD_TEMOIGNAGE_COMEDIEN,
  ADMIN_DELETE_TEMOIGNAGE_COMEDIEN,
  ADMIN_UPDATE_TEMOIGNAGE_COMEDIEN,
  ADMIN_GET_LISTE_AIDES,
  ADMIN_SORT_AIDES,
  ADMIN_DELETE_AIDE,
  ADMIN_ADD_AIDE,
  ADMIN_GET_AIDE,
  ADMIN_UPDATE_AIDE,
  ADMIN_GET_STATS,
  ADMIN_GET_HISTORIQUE_STATS,
  ADMIN_UPDATE_CONFIG_RANKING_ALGOLIA,
  ADMIN_GET_CONFIG_RANKING_ALGOLIA,
  ADMIN_GET_PROFIL,
  ADMIN_UPDATE_PROFIL,
  ADMIN_UPDATE_NOTES_COMEDIEN,
  ADMIN_GET_NOTES_COMEDIEN,
  ADMIN_GET_LISTE_CAMPAGNES_COM,
  ADMIN_DELETE_CAMPAGNE_COM,
  ADMIN_ACTIVATION_CAMPAGNE_COM,
  ADMIN_DESACTIVATION_CAMPAGNE_COM,
  ADMIN_ADD_CAMPAGNE_COM,
  ADMIN_GET_CAMPAGNE_COM,
  ADMIN_UPDATE_CAMPAGNE_COM,
  ADMIN_GET_CAMPAGNE_COM_BY_USER,
  ADMIN_ADD_SUPPORT,
  ADMIN_GET_LISTE_SUPPORTS_BY_COMEDIEN,
  ADMIN_DELETE_SUPPORT_COMEDIEN,
  ADMIN_GET_SUPPORT_COMEDIEN,
  ADMIN_UPDATE_SUPPORT_COMEDIEN,
  ADMIN_GET_LISTE_ARTICLES,
  ADMIN_ADD_ARTICLE,
  ADMIN_GET_ARTICLE,
  ADMIN_UPDATE_ARTICLE,
  ADMIN_DELETE_ARTICLE,

  ADMIN_ADD_MEDIA,
  ADMIN_GET_LISTE_MEDIAS,
  ADMIN_DELETE_MEDIA,

  ///////////////////////////////////////////


  ADMIN_GET_ALL_COMEDIENS,



  ADMIN_ACTIVATION_COMEDIEN,
  ADMIN_DESACTIVATION_COMEDIEN,
  ADMIN_GET_ABONNEMENT_COMEDIEN,
  ADMIN_UPDATE_ABONNEMENT_COMEDIEN,
  ADMIN_VALIDATION_PAIEMENT_CHEQUE_ABONNEMENT_COMEDIEN,












  MESSAGE_SUCCESS,
  MESSAGE_FAIL,
  NOTIFICATION_SUCCESS,
  NOTIFICATION_FAIL,









} from 'constants/ActionTypes';

import {
  showAllComediensAction,
  showListUtilisateursAction,
  showListComediensAction,
  showDemandesInscriptionsComediensAction,
  showListNewsComediensAction,
  showNewsAction,
  showMP3ComediensAction,
  showDemandeInscriptionComedienAction,
  showListDemandesPhotosXLAction,
  showListePhotosXLAction,
  showPhotoXLAction,
  showListeBlogsAction,
  showBlogAction,
  showListeTemoignagesComediensAction,
  showTemoignageComedienAction,
  switchUtilisateurSuccessAction,
  showAbonnementComedienAction,
  setRefreshAbonnementComedienAction,
  showListeAidesAction,
  showAideAction,
  showAdminStatsAction,
  showAdminHistoriqueStatsAction,
  showConfigRankingAlgoliaAction,
  showAdminProfilAction,
  showNotesComedienAction,
  showListeCampagnesComAction,
  showCampagneComAction,
  showCampagneComByUserAction,
  showListeSupportsByComedienAction,
  showSupportComedienAction,

  showListeArticlesAction,
  showArticleAction,

  showListeMediasAction,

} from 'appRedux/actions/Administrateur';


import {
  showAlertAction,
  showNotificationAction,
  setRefreshListAction,
  setFormResetAction
} from "appRedux/actions/Common";

import {
  getListUtilisateursRequest,
  deleteUtilisateurRequest,
  activationUtilisateurRequest,
  desactivationUtilisateurRequest,
  verificationEmailUtilisateurRequest,
  switchUtilisateurRequest,
  getAdminProfilRequest,
  updateAdminProfilRequest
} from "appRedux/services/User";

import {
  getAdminStatsRequest,
  getAdminHistoriqueStatsRequest,
} from "appRedux/services/Stats";

import {
  getListComediensRequest,
  getAllComediensRequest,
  getAbonnementComedienRequest,
  updateAbonnementComedienRequest,
  validationPaiementCheckAbonnementComedienRequest,
  updateNotesComedienRequest,
  getNotesComedienRequest,
  sendSatsComedienRequest,
} from "appRedux/services/Comedien";

import {
  getDemandesInscriptionsComediensRequest,
  getDemandeInscriptionComedienRequest,
  validationDemandeInscriptionComedienRequest,
  refusDemandeInscriptionComedienRequest,
  deleteDemandeInscriptionComedienRequest,
  validationPaiementChequeRequest
} from 'appRedux/services/DemandeInscriptionComedien'

import {
  getComediensMP3Request,
} from 'appRedux/services/Mp3';

import {
  getListNewsComediensRequest,
  deleteNewsRequest,
  getNewsRequest,
  updateNewsRequest
} from 'appRedux/services/News';

import {
  getListDemandesPhotosXLRequest,
  validationDemandePhotoXLRequest,
  RefusDemandePhotoXLRequest
} from 'appRedux/services/DemandePhotoXL';

import {
  addPhotoXLRequest,
  getListePhotosXLRequest,
  deletePhotoXLRequest,
  updatePhotoXLRequest,
  getPhotoXLRequest
} from 'appRedux/services/PhotoXL';

import {
  addBlogRequest,
  getListeBlogsRequest,
  getBlogRequest,
  updateBlogRequest,
  deleteBlogRequest
} from 'appRedux/services/Blog';

import {
  addTemoignageComedienRequest,
  getListeTemoignagesComediensRequest,
  getTemoignageComedienRequest,
  deleteTemoignageComedienRequest,
  updateTemoignageComedienRequest
} from 'appRedux/services/TemoignageComedien';

import {
  addAideRequest,
  getListeAidesRequest,
  deleteAideRequest,
  getAideRequest,
  updateAideRequest,
  sortAidesRequest
} from 'appRedux/services/Aide';

import {
  addArticleRequest,
  getListeArticlesRequest,
  deleteArticleRequest,
  getArticleRequest,
  updateArticleRequest
} from 'appRedux/services/Article';

import {
  addMediaRequest,
  getListeMediasRequest,
  deleteMediaRequest,
} from 'appRedux/services/Media';

import {
  updateConfigRankingAlgoliaRequest,
  getConfigRankingAlgoliaRequest
} from 'appRedux/services/Config';

import {
  getListeCampagnesComRequest,
  deleteCampagneComRequest,
  activationCompagneComRequest,
  desactivationCompagneComRequest,
  addCampagneComRequest,
  getCampagneComRequest,
  updateCampagneComRequest,
  getCampagneComByUserRequest
} from 'appRedux/services/CampagnesCom';

import {
  addSupportComedienRequest,
  getListeSupportsByComedienRequest,
  deleteSupportComedienRequest,
  getSupportComedienRequest,
  updateSupportComedienRequest

} from 'appRedux/services/SupportComedien';

////////////////////////////////////////////////////////////////////////////
// Gestion des utilisateurs
////////////////////////////////////////////////////////////////////////////

//List des utilisateurs

function* getListUtilisateurs({ payload }) {
  try {
    const response = yield call(getListUtilisateursRequest, payload);
    if (response.status === 200 && response.data) {
      yield put(showListUtilisateursAction(response.data));
    }
  } catch (error) {
  }
}

export function* getListUtilisateursFork() {
  yield takeEvery(ADMIN_GET_LIST_UTILISATEURS, getListUtilisateurs);
}


//Switch utilisateur

function* switchUtilisateur({ payload }) {
  try {
    var response = yield call(switchUtilisateurRequest, payload);
    if (response.status === 200) {
      yield put(switchUtilisateurSuccessAction(response.data));
    } else {
      yield put(showAlertAction("admin.utilisateurs.activation.error", MESSAGE_FAIL));
    }
  } catch (error) {
    yield put(showAlertAction("admin.utilisateurs.activation.error", MESSAGE_FAIL));
  }
}

export function* switchUtilisateurFork() {
  yield takeEvery(ADMIN_SWITCH_UTILISATEUR, switchUtilisateur);
}

//Suppression d'un utilisateur

function* deleteUtilisateur({ payload }) {
  try {
    var response = yield call(deleteUtilisateurRequest, payload);
    if (response.status === 200) {
      yield put(showAlertAction("admin.utilisateurs.delete.success", MESSAGE_SUCCESS));
      yield put(setRefreshListAction());
    } else {
      yield put(showAlertAction("admin.utilisateurs.delete.error", MESSAGE_FAIL));
    }
  } catch (error) {
    yield put(showAlertAction("admin.utilisateurs.delete.error", MESSAGE_FAIL));
  }
}

export function* deleteUtilisateurFork() {
  yield takeEvery(ADMIN_DELETE_UTILISATEUR, deleteUtilisateur);
}

//Activation d'un utilisateur

function* activationUtilisateur({ payload }) {
  try {
    var response = yield call(activationUtilisateurRequest, payload);
    if (response.status === 200) {
      yield put(showAlertAction("admin.utilisateurs.activation.success", MESSAGE_SUCCESS));
      yield put(setRefreshListAction());
    } else {
      yield put(showAlertAction("admin.utilisateurs.activation.error", MESSAGE_FAIL));
    }
  } catch (error) {
    yield put(showAlertAction("admin.utilisateurs.activation.error", MESSAGE_FAIL));
  }
}

export function* activationUtilisateurFork() {
  yield takeEvery(ADMIN_ACTIVATION_UTILISATEUR, activationUtilisateur);
}

//desctivation d'un utilisateur

function* desactivationUtilisateur({ payload }) {
  try {
    var response = yield call(desactivationUtilisateurRequest, payload);
    if (response.status === 200) {
      yield put(showAlertAction("admin.utilisateurs.desactivation.success", MESSAGE_SUCCESS));
      yield put(setRefreshListAction());
    } else {
      yield put(showAlertAction("admin.utilisateurs.desactivation.error", MESSAGE_FAIL));
    }
  } catch (error) {
    yield put(showAlertAction("admin.utilisateurs.desactivation.error", MESSAGE_FAIL));
  }
}

export function* desactivationUtilisateurFork() {
  yield takeEvery(ADMIN_DESACTIVATION_UTILISATEUR, desactivationUtilisateur);
}


//Vérification de l'email d'un utilisateur

function* verificationEmailUtilisateur({ payload }) {
  try {
    var response = yield call(verificationEmailUtilisateurRequest, payload);
    if (response.status === 200) {
      yield put(showAlertAction("admin.utilisateurs.verifEmail.success", MESSAGE_SUCCESS));
      yield put(setRefreshListAction());
    } else {
      yield put(showAlertAction("admin.utilisateurs.verifEmail.error", MESSAGE_FAIL));
    }
  } catch (error) {
    yield put(showAlertAction("admin.utilisateurs.verifEmail.error", MESSAGE_FAIL));
  }
}

export function* verificationEmailUtilisateurFork() {
  yield takeEvery(ADMIN_VERIF_EMAIL_UTILISATEUR, verificationEmailUtilisateur);
}


////////////////////////////////////////////////////////////////////////////
// Gestion des comediens
////////////////////////////////////////////////////////////////////////////

//List des comediens

function* getListComediens({ payload }) {
  try {
    const response = yield call(getListComediensRequest, payload);
    if (response.status === 200 && response.data) {
      yield put(showListComediensAction(response.data));
    }
  }
  catch (error) {
  }
}

export function* getListComediensFork() {
  yield takeEvery(ADMIN_GET_LIST_COMEDIENS, getListComediens);
}

//Suppression d'un comedien

function* deleteComedien({ payload }) {
  try {
    var response = yield call(deleteUtilisateurRequest, payload);
    if (response.status === 200) {
      yield put(showAlertAction("admin.comediens.delete.success", MESSAGE_SUCCESS));
      yield put(setRefreshListAction());
    } else {
      yield put(showAlertAction("admin.comediens.delete.error", MESSAGE_FAIL));
    }
  } catch (error) {
    yield put(showAlertAction("admin.comediens.delete.error", MESSAGE_FAIL));
  }
}

export function* deleteComedienFork() {
  yield takeEvery(ADMIN_DELETE_COMEDIEN, deleteComedien);
}

//Activation d'un comedien

function* activationComedien({ payload }) {
  try {
    var response = yield call(activationUtilisateurRequest, payload);
    if (response.status === 200) {
      yield put(showAlertAction("admin.comediens.activation.success", MESSAGE_SUCCESS));
      yield put(setRefreshListAction());
    } else {
      yield put(showAlertAction("admin.comediens.activation.error", MESSAGE_FAIL));
    }
  } catch (error) {
    yield put(showAlertAction("admin.comediens.activation.error", MESSAGE_FAIL));
  }
}

export function* activationComedienFork() {
  yield takeEvery(ADMIN_ACTIVATION_COMEDIEN, activationComedien);
}

//Activation d'un comedien

function* desactivationComedien({ payload }) {
  try {
    var response = yield call(desactivationUtilisateurRequest, payload);
    if (response.status === 200) {
      yield put(showAlertAction("admin.comediens.desactivation.success", MESSAGE_SUCCESS));
      yield put(setRefreshListAction());
    } else {
      yield put(showAlertAction("admin.comediens.desactivation.error", MESSAGE_FAIL));
    }
  } catch (error) {
    yield put(showAlertAction("admin.comediens.desactivation.error", MESSAGE_FAIL));
  }
}

export function* desactivationComedienFork() {
  yield takeEvery(ADMIN_DESACTIVATION_COMEDIEN, desactivationComedien);
}

//Envoi des Stats Comedien

function* sendSatsComedien({ payload }) {

  try {
    var response = yield call(sendSatsComedienRequest, payload);
    if (response.status === 200) {
      yield put(showAlertAction("admin.comediens.sendStats.success", MESSAGE_SUCCESS));
      yield put(setRefreshListAction());
    } else {
      yield put(showAlertAction("admin.comediens.sendStats.error", MESSAGE_FAIL));
    }
  } catch (error) {
    yield put(showAlertAction("admin.comediens.sendStats.error", MESSAGE_FAIL));
  }
}

export function* sendSatsComedienFork() {
  yield takeEvery(ADMIN_SEND_STATS_COMEDIEN, sendSatsComedien);
}

///////////////////////////////////////////////////////////////////////
// Notes de comédien
///////////////////////////////////////////////////////////////////////

function* updateNotesComedien({ payload }) {
  try {
    var response = yield call(updateNotesComedienRequest, payload);
    if (response.status === 200) {
      yield put(showAlertAction("admin.comediens.updateNotes.success", MESSAGE_SUCCESS));
    } else {
      yield put(showAlertAction("admin.comediens.updateNotes.error", MESSAGE_FAIL));
    }
  } catch (error) {
    yield put(showAlertAction("admin.comediens.updateNotes.error", MESSAGE_FAIL));
  }
}

export function* updateNotesComedienFork() {
  yield takeEvery(ADMIN_UPDATE_NOTES_COMEDIEN, updateNotesComedien);
}

function* getNotesComedien({ payload }) {
  try {
    const response = yield call(getNotesComedienRequest, payload);
    if (response.status === 200 && response.data) {
      yield put(showNotesComedienAction(response.data));
    }
  }
  catch (error) {
  }
}

export function* getNotesComedienFork() {
  yield takeEvery(ADMIN_GET_NOTES_COMEDIEN, getNotesComedien);
}

///////////////////////////////////////////////
//Abonnement d'un comédien
/////////////////////////////////////////////////

export function* getAbonnementComedien({ payload }) {
  try {
    const response = yield call(getAbonnementComedienRequest, payload);
    if (response.status === 200 && response.data) {
      yield put(showAbonnementComedienAction(response.data));
    }
  }
  catch (error) {

  }
}

export function* getAbonnementComedienFork() {
  yield takeEvery(ADMIN_GET_ABONNEMENT_COMEDIEN, getAbonnementComedien);
}

function* updateAbonnementComedien({ payload }) {
  try {
    const response = yield call(updateAbonnementComedienRequest, payload);
    if (response.status && response.status === 200) {
      yield put(showAlertAction("admin.comediens.config.success", MESSAGE_SUCCESS));
    } else {
      yield put(showAlertAction("admin.comediens.config.error", MESSAGE_FAIL));
    }
  } catch (error) {
    yield put(showAlertAction("admin.comediens.config.error", MESSAGE_FAIL));
  }
}

export function* updateAbonnementComedienFork() {
  yield takeEvery(ADMIN_UPDATE_ABONNEMENT_COMEDIEN, updateAbonnementComedien);
}

function* validationPaiementCheckAbonnementComedien({ payload }) {
  try {
    const response = yield call(validationPaiementCheckAbonnementComedienRequest, payload);
    if (response.status && response.status === 200) {
      yield put(showAlertAction("admin.comediens.abonnementComedien.paiementCheque.success", MESSAGE_SUCCESS));
      yield put(setRefreshAbonnementComedienAction());
    } else {
      yield put(showAlertAction("admin.comediens.abonnementComedien.paiementCheque.error", MESSAGE_FAIL));
    }
  } catch (error) {
    yield put(showAlertAction("admin.comediens.abonnementComedien.paiementCheque.error", MESSAGE_FAIL));
  }
}

export function* validationPaiementCheckAbonnementComedienFork() {
  yield takeEvery(ADMIN_VALIDATION_PAIEMENT_CHEQUE_ABONNEMENT_COMEDIEN, validationPaiementCheckAbonnementComedien);
}

////////////////////////////////////////////////////////////////////////////
// Liste de tous les comediens
////////////////////////////////////////////////////////////////////////////

function* getAllComediens({ payload }) {
  try {
    const response = yield call(getAllComediensRequest, payload);
    if (response.status === 200 && response.data) {
      yield put(showAllComediensAction(response.data));
    }
  }
  catch (error) {
  }
}

export function* getAllComediensFork() {
  yield takeEvery(ADMIN_GET_ALL_COMEDIENS, getAllComediens);
}


////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////


////////////////////////////////////////////////////////////////////////////
// Demandes d'inscriptions comediens
////////////////////////////////////////////////////////////////////////////

//List des demandes

function* getDemandesInscriptionsComediens({ payload }) {
  try {
    const commande = yield call(getDemandesInscriptionsComediensRequest, payload);
    if (commande.status === 200) {
      yield put(showDemandesInscriptionsComediensAction(commande.data));
    }
  }
  catch (e) {
  }
}

export function* getDemandesInscriptionsFork() {
  yield takeEvery(ADMIN_GET_DEMANDES_INSCRIPTIONS_COMEDIENS, getDemandesInscriptionsComediens)
}

//Récupération d'une demande

function* getDemandeInscriptionComedien({ payload }) {
  try {
    const response = yield call(getDemandeInscriptionComedienRequest, payload);
    if (response.status === 200) {
      yield put(showDemandeInscriptionComedienAction(response.data));
    }
  } catch (error) {

  }
}

export function* getDemandeInscriptionComedienFork() {
  yield takeEvery(ADMIN_GET_DEMANDE_INSCRIPTION_COMEDIEN, getDemandeInscriptionComedien);
}

//Validation d'une demande

function* validationDemandeInscriptionComedien({ payload }) {
  try {
    var response = yield call(validationDemandeInscriptionComedienRequest, payload);

    if (response.status === 200) {
      yield put(showAlertAction("admin.inscriptionComedien.validation.success", MESSAGE_SUCCESS));
      yield put(setRefreshListAction());
    } else {
      yield put(showAlertAction("admin.inscriptionComedien.validation.error", MESSAGE_FAIL));
    }
  } catch (error) {
    yield put(showAlertAction("admin.inscriptionComedien.validation.error", MESSAGE_FAIL));
  }
}

export function* validationDemandeInscriptionComedienFork() {
  yield takeEvery(ADMIN_VALIDATION_DEMANDE_INSCRIPTION_COMEDIEN, validationDemandeInscriptionComedien);
}

//Refus d'une demande

function* refusDemandeInscriptionComedien({ payload }) {
  try {
    var response = yield call(refusDemandeInscriptionComedienRequest, payload);

    if (response.status === 200) {
      yield put(showAlertAction("admin.inscriptionComedien.refus.success", MESSAGE_SUCCESS));
      yield put(setRefreshListAction());
    } else {
      yield put(showAlertAction("admin.inscriptionComedien.refus.error", MESSAGE_FAIL));
    }
  } catch (error) {
    yield put(showAlertAction("admin.inscriptionComedien.refus.error", MESSAGE_FAIL));
  }
}

export function* refusDemandeInscriptionComedienFork() {
  yield takeEvery(ADMIN_REFUS_DEMANDE_INSCRIPTION_COMEDIEN, refusDemandeInscriptionComedien);
}

//Suppression d'une demande

function* deleteDemandeInscriptionComedien({ payload }) {
  try {
    var response = yield call(deleteDemandeInscriptionComedienRequest, payload);

    if (response.status === 200) {
      yield put(showAlertAction("admin.inscriptionComedien.delete.success", MESSAGE_SUCCESS));
      yield put(setRefreshListAction());
    } else {
      yield put(showAlertAction("admin.inscriptionComedien.delete.error", MESSAGE_FAIL));
    }
  } catch (error) {
    yield put(showAlertAction("admin.inscriptionComedien.delete.error", MESSAGE_FAIL));
  }
}

export function* deleteDemandeInscriptionComedienFork() {
  yield takeEvery(ADMIN_DELETE_DEMANDE_INSCRIPTION_COMEDIEN, deleteDemandeInscriptionComedien);
}


//Validation d'un paiement par chèque bancaire

function* validationPaiementCheque({ payload }) {
  try {
    var response = yield call(validationPaiementChequeRequest, payload);

    if (response.status === 200) {
      yield put(showAlertAction("admin.inscriptionComedien.cheque.validation.success", MESSAGE_SUCCESS));
      yield put(setRefreshListAction());
    } else {
      yield put(showAlertAction("admin.inscriptionComedien.cheque.validation.error", MESSAGE_FAIL));
    }
  } catch (error) {
    yield put(showAlertAction("admin.inscriptionComedien.cheque.validation.error", MESSAGE_FAIL));
  }
}

export function* validationPaiementChequeFork() {
  yield takeEvery(ADMIN_VALIDATION_PAIEMENT_CHEQUE, validationPaiementCheque);
}

////////////////////////////////////////////////////////////////////////////
// News comediens
////////////////////////////////////////////////////////////////////////////

//List des News

function* getListNewsComediens({ payload }) {
  try {
    const response = yield call(getListNewsComediensRequest, payload);
    if (response.status === 200) {
      yield put(showListNewsComediensAction(response.data));
    }
  } catch (error) {
  }
}

export function* getListNewsComediensFork() {
  yield takeEvery(ADMIN_GET_LIST_NEWS_COMEDIENS, getListNewsComediens);
}

//Modification d'une News

function* deleteNewsComedien({ payload }) {
  try {
    var response = yield call(deleteNewsRequest, payload);
    if (response.status === 200) {
      yield put(showAlertAction("admin.newsComediens.delete.success", MESSAGE_SUCCESS));
      yield put(setRefreshListAction());
    } else {
      yield put(showAlertAction("admin.newsComediens.delete.error", MESSAGE_FAIL));
    }
  } catch (error) {
    yield put(showAlertAction("admin.newsComediens.delete.error", MESSAGE_FAIL));
  }
}

export function* deleteNewsComedienFork() {
  yield takeEvery(ADMIN_DELETE_NEWS_COMEDIEN, deleteNewsComedien);
}

//Récupération d'une News

function* getNews({ payload }) {
  try {
    const response = yield call(getNewsRequest, payload);
    if (response.status === 200) {
      yield put(showNewsAction(response.data));
    }
  } catch (error) {
  }
}

export function* getNewsFork() {
  yield takeEvery(ADMIN_GET_NEWS, getNews);
}

//Modification d'une News

function* updateNews({ payload }) {
  try {
    var response = yield call(updateNewsRequest, payload);
    if (response.status === 200) {
      yield put(showAlertAction("admin.newsComediens.modification.succes", MESSAGE_SUCCESS));
      yield put(setRefreshListAction());
    } else {
      yield put(showAlertAction("admin.newsComediens.modification.error", MESSAGE_FAIL));
    }
  } catch (error) {
    yield put(showAlertAction("admin.newsComediens.modification.error", MESSAGE_FAIL));
  }
}

export function* updateNewsFork() {
  yield takeEvery(ADMIN_UPDATE_NEWS, updateNews);
}

////////////////////////////////////////////////////////////////////////////
// Extraits MP3
////////////////////////////////////////////////////////////////////////////

//List des extraits MP3

function* getMP3Comediens({ payload }) {
  try {
    const response = yield call(getComediensMP3Request, payload);
    if (response.status === 200) {
      yield put(showMP3ComediensAction(response.data));
    }
  } catch (error) {

  }
}

export function* getMP3ComediensFork() {
  yield takeEvery(ADMIN_GET_MP3_COMEDIENS, getMP3Comediens);
}

////////////////////////////////////////////////////////////////////////////
// Demandes de photo XL
////////////////////////////////////////////////////////////////////////////

//List des demandes

function* getListDemandesPhotosXL({ payload }) {
  try {
    const response = yield call(getListDemandesPhotosXLRequest, payload);
    if (response.status === 200) {
      yield put(showListDemandesPhotosXLAction(response.data));
    }
  } catch (error) {
  }
}

export function* getListDemandesPhotosXLFork() {
  yield takeEvery(ADMIN_GET_LIST_DEMANDES_PHOTOS_XL, getListDemandesPhotosXL);
}

//Validation d'une demande

function* validationDemandePhotoXL({ payload }) {
  try {
    const response = yield call(validationDemandePhotoXLRequest, payload);
    if (response.status === 200) {
      yield put(showAlertAction("admin.demandesPhotosXL.validation.success", MESSAGE_SUCCESS));
      yield put(setRefreshListAction());
    } else {
      yield put(showAlertAction("admin.demandesPhotosXL.validation.error", MESSAGE_FAIL));
    }
  } catch (error) {
    yield put(showAlertAction("admin.demandesPhotosXL.validation.error", MESSAGE_FAIL));
  }
}

export function* validationDemandePhotoXLFork() {
  yield takeEvery(ADMIN_VALIDATION_DEMANDE_PHOTO_XL, validationDemandePhotoXL);
}

//Refus d'une demande

function* RefusDemandePhotoXL({ payload }) {
  try {
    const response = yield call(RefusDemandePhotoXLRequest, payload);
    if (response.status === 200) {
      yield put(showAlertAction("admin.demandesPhotosXL.refus.success", MESSAGE_SUCCESS));
      yield put(setRefreshListAction());
    } else {
      yield put(showAlertAction("admin.demandesPhotosXL.refus.error", MESSAGE_FAIL));
    }
  } catch (error) {
    yield put(showAlertAction("admin.demandesPhotosXL.refus.error", MESSAGE_FAIL));
  }
}

export function* RefusDemandePhotoXLFork() {
  yield takeEvery(ADMIN_REFUS_DEMANDE_PHOTO_XL, RefusDemandePhotoXL);
}

////////////////////////////////////////////////////////////////////////////
// Photos XL
////////////////////////////////////////////////////////////////////////////

function* addPhotoXL({ payload }) {
  try {
    var response = yield call(addPhotoXLRequest, payload);
    if (response.status && response.status === 200) {
      yield put(showAlertAction("admin.photoXL.ajoutSucces", MESSAGE_SUCCESS));
      yield put(setRefreshListAction());
    } else {
      yield put(showAlertAction("admin.photoXL.ajoutErreur", MESSAGE_FAIL));
    }
  } catch (error) {
    yield put(showAlertAction("admin.photoXL.ajoutErreur", MESSAGE_FAIL));
  }
}

export function* addPhotoXLFork() {
  yield takeEvery(ADMIN_ADD_PHOTO_XL, addPhotoXL);
}

//Liste des photos
function* getListePhotosXL({ payload }) {
  try {
    const response = yield call(getListePhotosXLRequest, payload);
    if (response.status && response.status === 200 && response.data) {
      yield put(showListePhotosXLAction(response.data));
    }
  } catch (error) {

  }
}

export function* getListePhotosXLFork() {
  yield takeEvery(ADMIN_GET_LISTE_PHOTOS_XL, getListePhotosXL);
}

//Photo
function* getPhotoXL({ payload }) {
  try {
    const response = yield call(getPhotoXLRequest, payload);
    if (response.status && response.status === 200 && response.data) {
      yield put(showPhotoXLAction(response.data));
    }
  } catch (error) {

  }
}

export function* getPhotoXLFork() {
  yield takeEvery(ADMIN_GET_PHOTO_XL, getPhotoXL);
}

//Suppression d'une photo XL

function* deletePhotoXL({ payload }) {
  try {
    var response = yield call(deletePhotoXLRequest, payload);
    if (response.status && response.status === 200) {
      yield put(showAlertAction("admin.demandesPhotosXL.delete.success", MESSAGE_SUCCESS));
      yield put(setRefreshListAction());

    } else {
      yield put(showAlertAction("admin.demandesPhotosXL.delete.error", MESSAGE_FAIL));
    }
  } catch (error) {
    yield put(showAlertAction("admin.demandesPhotosXL.delete.error", MESSAGE_FAIL));
  }
}

export function* deletePhotoXLFork() {
  yield takeEvery(ADMIN_DELETE_PHOTO_XL, deletePhotoXL);
}

//Suppression d'une photo XL

function* updatePhotoXL({ payload }) {
  try {
    var response = yield call(updatePhotoXLRequest, payload);
    if (response.status && response.status === 200) {
      yield put(showAlertAction("admin.photoXL.update.success", MESSAGE_SUCCESS));
      yield put(setRefreshListAction());

    } else {
      yield put(showAlertAction("admin.photoXL.update.error", MESSAGE_FAIL));
    }
  } catch (error) {
    yield put(showAlertAction("admin.photoXL.update.error", MESSAGE_FAIL));
  }
}

export function* updatePhotoXLFork() {
  yield takeEvery(ADMIN_UPDATE_PHOTO_XL, updatePhotoXL);
}

////////////////////////////////////////////////////////////////////////////
// Blogs
////////////////////////////////////////////////////////////////////////////

function* addBlog({ payload }) {
  try {
    const response = yield call(addBlogRequest, payload);
    if (response.status && response.status === 200) {
      yield put(showAlertAction("admin.blogs.ajout.succes", MESSAGE_SUCCESS));
      yield put(setRefreshListAction());
    } else {
      yield put(showAlertAction("admin.blogs.ajout.erreur", MESSAGE_FAIL));
    }
  } catch (error) {
    yield put(showAlertAction("admin.blogs.ajout.erreur", MESSAGE_FAIL));
  }
}

export function* addBlogFork() {
  yield takeEvery(ADMIN_ADD_BLOG, addBlog);
}


//Liste des blogs
function* getListeBlogs() {
  try {
    const response = yield call(getListeBlogsRequest);
    if (response.status && response.status === 200 && response.data) {
      yield put(showListeBlogsAction(response.data));
    }
  } catch (error) {

  }
}

export function* getListeBlogsFork() {
  yield takeEvery(ADMIN_GET_LISTE_BLOGS, getListeBlogs);
}

//Blog
function* getBlog({ payload }) {
  try {
    const response = yield call(getBlogRequest, payload);
    if (response.status && response.status === 200 && response.data) {
      yield put(showBlogAction(response.data));
    }
  } catch (error) {

  }
}

export function* getBlogFork() {
  yield takeEvery(ADMIN_GET_BLOG, getBlog);
}

//Modification d'un blog

function* updateBlog({ payload }) {
  try {
    const response = yield call(updateBlogRequest, payload);
    if (response.status && response.status === 200) {
      yield put(showAlertAction("admin.blogs.modification.success", MESSAGE_SUCCESS));
      yield put(setRefreshListAction());
    } else {
      yield put(showAlertAction("admin.blogs.modification.error", MESSAGE_FAIL));
    }
  } catch (error) {
    yield put(showAlertAction("admin.blogs.modification.error", MESSAGE_FAIL));
  }
}

export function* updateBlogFork() {
  yield takeEvery(ADMIN_UPDATE_BLOG, updateBlog);
}

//Suppression d'un blog

function* deleteBlog({ payload }) {
  try {
    var response = yield call(deleteBlogRequest, payload);
    if (response.status && response.status === 200) {
      yield put(showAlertAction("admin.blogs.delete.success", MESSAGE_SUCCESS));
      yield put(setRefreshListAction());

    } else {
      yield put(showAlertAction("admin.blogs.delete.error", MESSAGE_FAIL));
    }
  } catch (error) {
    yield put(showAlertAction("admin.blogs.delete.error", MESSAGE_FAIL));
  }
}

export function* deleteBlogFork() {
  yield takeEvery(ADMIN_DELETE_BLOG, deleteBlog);
}

////////////////////////////////////////////////////////////////////////////
// Temoignages
////////////////////////////////////////////////////////////////////////////

function* addTemoignageComedien({ payload }) {
  try {
    const response = yield call(addTemoignageComedienRequest, payload);
    if (response.status && response.status === 200) {
      yield put(showAlertAction("admin.temoignagesComediens.ajout.success", MESSAGE_SUCCESS));
      yield put(setRefreshListAction());
    } else {
      yield put(showAlertAction("admin.temoignagesComediens.ajout.error", MESSAGE_FAIL));
    }
  } catch (error) {
    yield put(showAlertAction("admin.temoignagesComediens.ajout.error", MESSAGE_FAIL));
  }
}

export function* addTemoignageComedienFork() {
  yield takeEvery(ADMIN_ADD_TEMOIGNAGE_COMEDIEN, addTemoignageComedien);
}

//Liste des témoignages

function* getListeTemoignagesComediens() {
  try {
    const response = yield call(getListeTemoignagesComediensRequest);
    if (response.status && response.status === 200 && response.data) {
      yield put(showListeTemoignagesComediensAction(response.data));
    }
  } catch (error) {

  }
}

export function* getListeTemoignagesComediensFork() {
  yield takeEvery(ADMIN_GET_LISTE_TEMOIGNAGES_COMEDIENS, getListeTemoignagesComediens);
}

//Témoignage

function* getTemoignageComedien({ payload }) {
  try {
    const response = yield call(getTemoignageComedienRequest, payload);
    if (response.status && response.status === 200 && response.data) {
      yield put(showTemoignageComedienAction(response.data));
    }
  } catch (error) {

  }
}

export function* getTemoignageComedienFork() {
  yield takeEvery(ADMIN_GET_TEMOIGNAGE_COMEDIEN, getTemoignageComedien);
}

function* deleteTemoignageComedien({ payload }) {
  try {
    const response = yield call(deleteTemoignageComedienRequest, payload);
    if (response.status && response.status === 200) {
      yield put(showAlertAction("admin.temoignagesComediens.delete.success", MESSAGE_SUCCESS));
      yield put(setRefreshListAction());
    } else {
      yield put(showAlertAction("admin.temoignagesComediens.delete.error", MESSAGE_FAIL));
    }
  } catch (error) {
    yield put(showAlertAction("admin.temoignagesComediens.delete.error", MESSAGE_FAIL));
  }
}

export function* deleteTemoignageComedienFork() {
  yield takeEvery(ADMIN_DELETE_TEMOIGNAGE_COMEDIEN, deleteTemoignageComedien);
}

// Modification d'un témoignage

function* updateTemoignageComedien({ payload }) {
  try {
    const response = yield call(updateTemoignageComedienRequest, payload);
    if (response.status && response.status === 200) {
      yield put(showAlertAction("admin.temoignagesComediens.modification.success", MESSAGE_SUCCESS));
      yield put(setRefreshListAction());
    } else {
      yield put(showAlertAction("admin.temoignagesComediens.modification.error", MESSAGE_FAIL));
    }
  } catch (error) {
    yield put(showAlertAction("admin.temoignagesComediens.modification.error", MESSAGE_FAIL));
  }
}

export function* updateTemoignageComedienFork() {
  yield takeEvery(ADMIN_UPDATE_TEMOIGNAGE_COMEDIEN, updateTemoignageComedien);
}

////////////////////////////////////////////////////////////////////////////
// Aide
////////////////////////////////////////////////////////////////////////////

//Ajout d'un aide

function* addAide({ payload }) {
  try {
    const response = yield call(addAideRequest, payload);
    if (response.status && response.status === 200) {
      yield put(showAlertAction("admin.aide.ajout.succes", MESSAGE_SUCCESS));
      yield put(setRefreshListAction());
    } else {
      yield put(showAlertAction("admin.aide.ajout.erreur", MESSAGE_FAIL));
    }
  } catch (error) {
    yield put(showAlertAction("admin.aide.ajout.erreur", MESSAGE_FAIL));
  }
}

export function* addAideFork() {
  yield takeEvery(ADMIN_ADD_AIDE, addAide);
}

//Liste d'aides

function* getListeAides({ payload }) {
  try {
    const commande = yield call(getListeAidesRequest, payload);
    if (commande.status === 200) {
      yield put(showListeAidesAction(commande.data));
    }
  }
  catch (e) {
  }
}

export function* getListeAidesFork() {
  yield takeEvery(ADMIN_GET_LISTE_AIDES, getListeAides)
}

//Suppression d'un aide 

function* deleteAide({ payload }) {
  try {
    var response = yield call(deleteAideRequest, payload);

    if (response.status === 200) {
      yield put(showAlertAction("admin.aide.delete.success", MESSAGE_SUCCESS));
      yield put(setRefreshListAction());
    } else {
      yield put(showAlertAction("admin.aide.delete.error", MESSAGE_FAIL));
    }
  } catch (error) {
    yield put(showAlertAction("admin.aide.delete.error", MESSAGE_FAIL));
  }
}

export function* deleteAideFork() {
  yield takeEvery(ADMIN_DELETE_AIDE, deleteAide);
}

// Insctance Aide

function* getAide({ payload }) {
  try {
    const response = yield call(getAideRequest, payload);
    if (response.status === 200) {
      yield put(showAideAction(response.data));
    }
  }
  catch (e) {
  }
}

export function* getAideFork() {
  yield takeEvery(ADMIN_GET_AIDE, getAide)
}

//Modification d'un aide

function* updateAide({ payload }) {
  try {
    const response = yield call(updateAideRequest, payload);
    if (response.status && response.status === 200) {
      yield put(showAlertAction("admin.aide.edit.succes", MESSAGE_SUCCESS));
      yield put(setRefreshListAction());
    } else {
      yield put(showAlertAction("admin.aide.edit.erreur", MESSAGE_FAIL));
    }
  } catch (error) {
    yield put(showAlertAction("admin.aide.edit.erreur", MESSAGE_FAIL));
  }
}

export function* updateAideFork() {
  yield takeEvery(ADMIN_UPDATE_AIDE, updateAide);
}

//Tri des aides

function* sortAides({ payload }) {
  try {
    const response = yield call(sortAidesRequest, payload);
    if (response.status && response.status === 200) {
      yield put(showAlertAction("admin.aide.tri.success", MESSAGE_SUCCESS));
    } else {
      yield put(showAlertAction("admin.aide.tri.error", MESSAGE_FAIL));
    }
  } catch (error) {
    yield put(showAlertAction("admin.aide.tri.error", MESSAGE_FAIL));
  }
}

export function* sortAidesFork() {
  yield takeEvery(ADMIN_SORT_AIDES, sortAides);
}


////////////////////////////////////////////////////////////////////////////
// Articles
////////////////////////////////////////////////////////////////////////////

//Ajout d'un article

function* addArticle({ payload }) {
  try {
    const response = yield call(addArticleRequest, payload);
    if (response.status && response.status === 200) {
      yield put(showAlertAction("admin.articles.ajout.succes", MESSAGE_SUCCESS));
      yield put(setRefreshListAction());
    } else {
      yield put(showAlertAction("admin.articles.ajout.erreur", MESSAGE_FAIL));
    }
  } catch (error) {
    yield put(showAlertAction("admin.articles.ajout.erreur", MESSAGE_FAIL));
  }
}

export function* addArticleFork() {
  yield takeEvery(ADMIN_ADD_ARTICLE, addArticle);
}

//Liste des articles

function* getListeArticles({ payload }) {
  try {
    const commande = yield call(getListeArticlesRequest, payload);
    if (commande.status === 200) {
      yield put(showListeArticlesAction(commande.data));
    }
  }
  catch (e) {
  }
}

export function* getListeArticlesFork() {
  yield takeEvery(ADMIN_GET_LISTE_ARTICLES, getListeArticles)
}

// Instance Article

function* getArticle({ payload }) {
  try {
    const response = yield call(getArticleRequest, payload);
    if (response.status === 200) {
      yield put(showArticleAction(response.data));
    }
  }
  catch (e) {
  }
}

export function* getArticleFork() {
  yield takeEvery(ADMIN_GET_ARTICLE, getArticle)
}

//Modification d'un aide

function* updateArticle({ payload }) {
  try {
    const response = yield call(updateArticleRequest, payload);
    if (response.status && response.status === 200) {
      yield put(showAlertAction("admin.articles.edit.succes", MESSAGE_SUCCESS));
      yield put(setRefreshListAction());
    } else {
      yield put(showAlertAction("admin.articles.edit.erreur", MESSAGE_FAIL));
    }
  } catch (error) {
    yield put(showAlertAction("admin.articles.edit.erreur", MESSAGE_FAIL));
  }
}

export function* updateArticleFork() {
  yield takeEvery(ADMIN_UPDATE_ARTICLE, updateArticle);
}

//Suppression d'un article

function* deleteArticle({ payload }) {
  try {
    var response = yield call(deleteArticleRequest, payload);

    if (response.status === 200) {
      yield put(showAlertAction("admin.articles.delete.success", MESSAGE_SUCCESS));
      yield put(setRefreshListAction());
    } else {
      yield put(showAlertAction("admin.articles.delete.error", MESSAGE_FAIL));
    }
  } catch (error) {
    yield put(showAlertAction("admin.articles.delete.error", MESSAGE_FAIL));
  }
}

export function* deleteArticleFork() {
  yield takeEvery(ADMIN_DELETE_ARTICLE, deleteArticle);
}


////////////////////////////////////////////////////////////////////////////
// Medias
////////////////////////////////////////////////////////////////////////////

function* addMedia({ payload }) {
  try {
    var response = yield call(addMediaRequest, payload);
    if (response.status && response.status === 200) {
      yield put(showAlertAction("admin.medias.ajout.succes", MESSAGE_SUCCESS));
      yield put(setRefreshListAction());
    } else {
      yield put(showAlertAction("admin.medias.ajout.erreur", MESSAGE_FAIL));
    }
  } catch (error) {
    yield put(showAlertAction("admin.medias.ajout.erreur", MESSAGE_FAIL));
  }
}

export function* addMediaFork() {
  yield takeEvery(ADMIN_ADD_MEDIA, addMedia);
}

//Liste des photos
function* getListeMedias({ payload }) {
  try {
    const response = yield call(getListeMediasRequest, payload);
    if (response.status && response.status === 200 && response.data) {
      yield put(showListeMediasAction(response.data));
    }
  } catch (error) {

  }
}

export function* getListeMediasFork() {
  yield takeEvery(ADMIN_GET_LISTE_MEDIAS, getListeMedias);
}


//Suppression d'un media

function* deleteMedia({ payload }) {
  try {
    var response = yield call(deleteMediaRequest, payload);
    if (response.status && response.status === 200) {
      yield put(showAlertAction("admin.medias.suppression.success", MESSAGE_SUCCESS));
      yield put(setRefreshListAction());

    } else {
      yield put(showAlertAction("admin.medias.suppression.error", MESSAGE_FAIL));
    }
  } catch (error) {
    yield put(showAlertAction("admin.medias.suppression.error", MESSAGE_FAIL));
  }
}

export function* deleteMediaFork() {
  yield takeEvery(ADMIN_DELETE_MEDIA, deleteMedia);
}


///////////////////////////////////////////////////////////////////////
// Stats
///////////////////////////////////////////////////////////////////////

//Stats

function* getAdminStats({ payload }) {
  try {
    const response = yield call(getAdminStatsRequest, payload);
    if (response.status && response.status === 200 && response.data) {
      yield put(showAdminStatsAction(response.data));
    }
  } catch (error) {
  }
}

export function* getAdminStatsFork() {
  yield takeEvery(ADMIN_GET_STATS, getAdminStats);
}

//Historique

function* getAdminHistoriqueStats({ payload }) {
  try {
    const response = yield call(getAdminHistoriqueStatsRequest, payload);
    if (response.status && response.status === 200 && response.data) {
      yield put(showAdminHistoriqueStatsAction(response.data));
    }
  } catch (error) {
  }
}

export function* getAdminHistoriqueStatsFork() {
  yield takeEvery(ADMIN_GET_HISTORIQUE_STATS, getAdminHistoriqueStats);
}

//Config / Ranking Algolia

function* updateConfigRankingAlgolia({ payload }) {
  try {
    const response = yield call(updateConfigRankingAlgoliaRequest, payload);
    if (response.status && response.status === 200) {
      yield put(showAlertAction("admin.config.algoliaRanking.update.success", MESSAGE_SUCCESS));
    } else {
      yield put(showAlertAction("admin.config.algoliaRanking.update.error", MESSAGE_FAIL));
    }
  } catch (error) {
    yield put(showAlertAction("admin.config.algoliaRanking.update.error", MESSAGE_FAIL));
  }
}

export function* updateConfigRankingAlgoliaFork() {
  yield takeEvery(ADMIN_UPDATE_CONFIG_RANKING_ALGOLIA, updateConfigRankingAlgolia);
}

function* getConfigRankingAlgolia({ payload }) {
  try {
    const response = yield call(getConfigRankingAlgoliaRequest, payload);
    if (response.status && response.status === 200 && response.data) {
      yield put(showConfigRankingAlgoliaAction(response.data));
    }
  } catch (error) {
  }
}

export function* getConfigRankingAlgoliaFork() {
  yield takeEvery(ADMIN_GET_CONFIG_RANKING_ALGOLIA, getConfigRankingAlgolia);
}

///////////////////////////////////////////////////////////////////////
// Profil
///////////////////////////////////////////////////////////////////////

function* getAdminProfil({ payload }) {
  try {
    const response = yield call(getAdminProfilRequest, payload);
    if (response.status === 200 && response.data) {
      yield put(showAdminProfilAction(response.data));
    }
  } catch (error) {
  }
}

export function* getAdminProfilFork() {
  yield takeEvery(ADMIN_GET_PROFIL, getAdminProfil);
}

function* updateAdminProfil({ payload }) {
  try {
    const response = yield call(updateAdminProfilRequest, payload);
    if (response.status === 200) {
      yield put(showAlertAction("admin.monCompte.profil.update.success", MESSAGE_SUCCESS));
    } else {
      yield put(showAlertAction("admin.monCompte.profil.update.error", MESSAGE_FAIL));
    }
  } catch (error) {
    yield put(showAlertAction("admin.monCompte.profil.update.error", MESSAGE_FAIL));
  }
}

export function* updateAdminProfilFork() {
  yield takeEvery(ADMIN_UPDATE_PROFIL, updateAdminProfil);
}


///////////////////////////////////////////////////////////////////////
// Campagnes de Com
///////////////////////////////////////////////////////////////////////

//Liste des campagnes

function* getListeCampagnesCom() {
  try {
    const response = yield call(getListeCampagnesComRequest);
    if (response.status && response.status === 200 && response.data) {
      yield put(showListeCampagnesComAction(response.data));
    }
  } catch (error) {

  }
}

export function* getListeCampagnesComFork() {
  yield takeEvery(ADMIN_GET_LISTE_CAMPAGNES_COM, getListeCampagnesCom);
}

// Suppression d'une campagne

function* deleteCampagneCom({ payload }) {
  try {
    const response = yield call(deleteCampagneComRequest, payload);
    if (response.status && response.status === 200) {
      yield put(showAlertAction("admin.campagnesCom.delete.success", MESSAGE_SUCCESS));
      yield put(setRefreshListAction());
    } else {
      yield put(showAlertAction("admin.campagnesCom.delete.error", MESSAGE_FAIL));
    }
  } catch (error) {
    yield put(showAlertAction("admin.campagnesCom.delete.error", MESSAGE_FAIL));
  }
}

export function* deleteCampagneComFork() {
  yield takeEvery(ADMIN_DELETE_CAMPAGNE_COM, deleteCampagneCom);
}


//Activation d'un utilisateur

function* activationCompagneCom({ payload }) {
  try {
    var response = yield call(activationCompagneComRequest, payload);
    if (response.status === 200) {
      yield put(showAlertAction("admin.campagnesCom.activation.success", MESSAGE_SUCCESS));
      yield put(setRefreshListAction());
    } else {
      yield put(showAlertAction("admin.campagnesCom.activation.error", MESSAGE_FAIL));
    }
  } catch (error) {
    yield put(showAlertAction("admin.campagnesCom.activation.error", MESSAGE_FAIL));
  }
}

export function* activationCompagneComFork() {
  yield takeEvery(ADMIN_ACTIVATION_CAMPAGNE_COM, activationCompagneCom);
}

//Desactivation d'un utilisateur

function* desactivationCompagneCom({ payload }) {
  try {
    var response = yield call(desactivationCompagneComRequest, payload);
    if (response.status === 200) {
      yield put(showAlertAction("admin.campagnesCom.desactivation.success", MESSAGE_SUCCESS));
      yield put(setRefreshListAction());
    } else {
      yield put(showAlertAction("admin.campagnesCom.desactivation.error", MESSAGE_FAIL));
    }
  } catch (error) {
    yield put(showAlertAction("admin.campagnesCom.desactivation.error", MESSAGE_FAIL));
  }
}

export function* desactivationCompagneComFork() {
  yield takeEvery(ADMIN_DESACTIVATION_CAMPAGNE_COM, desactivationCompagneCom);
}

function* addCampagneCom({ payload }) {
  try {
    const response = yield call(addCampagneComRequest, payload);
    if (response.status && response.status === 200) {
      yield put(showAlertAction("admin.campagnesCom.ajout.success", MESSAGE_SUCCESS));
      yield put(setRefreshListAction());
    } else {
      yield put(showAlertAction("admin.campagnesCom.ajout.error", MESSAGE_FAIL));
    }
  } catch (error) {
    yield put(showAlertAction("admin.campagnesCom.ajout.error", MESSAGE_FAIL));
  }
}

export function* addCampagneComFork() {
  yield takeEvery(ADMIN_ADD_CAMPAGNE_COM, addCampagneCom);
}

//Campagne

function* getCampagneCom({ payload }) {
  try {
    const response = yield call(getCampagneComRequest, payload);
    if (response.status && response.status === 200 && response.data) {
      yield put(showCampagneComAction(response.data));
    }
  } catch (error) {

  }
}

export function* getCampagneComFork() {
  yield takeEvery(ADMIN_GET_CAMPAGNE_COM, getCampagneCom);
}

// Modification d'une campagne

function* updateCampagneCom({ payload }) {
  try {
    const response = yield call(updateCampagneComRequest, payload);
    if (response.status && response.status === 200) {
      yield put(showAlertAction("admin.campagnesCom.modification.success", MESSAGE_SUCCESS));
      yield put(setRefreshListAction());
    } else {
      yield put(showAlertAction("admin.campagnesCom.modification.error", MESSAGE_FAIL));
    }
  } catch (error) {
    yield put(showAlertAction("admin.campagnesCom.modification.error", MESSAGE_FAIL));
  }
}

export function* updateCampagneComFork() {
  yield takeEvery(ADMIN_UPDATE_CAMPAGNE_COM, updateCampagneCom);
}

// Campagne Utilisateur/Comedien

function* getCampagneComByUser({ payload }) {
  try {
    const response = yield call(getCampagneComByUserRequest, payload);
    if (response.status && response.status === 200 && response.data) {
      yield put(showCampagneComByUserAction(response.data));
    }
  } catch (error) {

  }
}

export function* getCampagneComByUserFork() {
  yield takeEvery(ADMIN_GET_CAMPAGNE_COM_BY_USER, getCampagneComByUser);
}

////////////////////////////////////////////////////////////////////////////
// SUpport Comedien
////////////////////////////////////////////////////////////////////////////

function* addSupportComedien({ idUser, payload }) {
  try {
    var response = yield call(addSupportComedienRequest, payload);
    if (response.status && response.status === 200) {
      yield put(showAlertAction("admin.supportComedien.ajoutSucces", MESSAGE_SUCCESS));
      yield put(setRefreshListAction());
      yield put(setFormResetAction());
    } else {
      yield put(showAlertAction("admin.supportComedien.ajoutErreur", MESSAGE_FAIL));
    }
  } catch (error) {
    yield put(showAlertAction("admin.supportComedien.ajoutErreur", MESSAGE_FAIL));
  }
}

export function* addSupportComedienFork() {
  yield takeEvery(ADMIN_ADD_SUPPORT, addSupportComedien);
}


//Liste des photos
function* getListeSupportsByComedien({ payload }) {

  try {
    const response = yield call(getListeSupportsByComedienRequest, payload);
    if (response.status && response.status === 200 && response.data) {
      yield put(showListeSupportsByComedienAction(response.data));
    }
  } catch (error) {

  }
}

export function* getListeSupportsByComedienFork() {
  yield takeEvery(ADMIN_GET_LISTE_SUPPORTS_BY_COMEDIEN, getListeSupportsByComedien);
}

//Suppression d'un support comedien

function* deleteSupportComedien({ payload }) {
  try {
    var response = yield call(deleteSupportComedienRequest, payload);
    if (response.status && response.status === 200) {
      yield put(showAlertAction("admin.supportComedien.delete.success", MESSAGE_SUCCESS));
      yield put(setRefreshListAction());

    } else {
      yield put(showAlertAction("admin.supportComedien.delete.error", MESSAGE_FAIL));
    }
  } catch (error) {
    yield put(showAlertAction("admin.supportComedien.delete.error", MESSAGE_FAIL));
  }
}

export function* deleteSupportComedienFork() {
  yield takeEvery(ADMIN_DELETE_SUPPORT_COMEDIEN, deleteSupportComedien);
}

//Récupération d'un support comédien
function* getSupportComedien({ payload }) {
  try {
    const response = yield call(getSupportComedienRequest, payload);
    if (response.status && response.status === 200 && response.data) {
      yield put(showSupportComedienAction(response.data));
    }
  } catch (error) {

  }
}

export function* getSupportComedienFork() {
  yield takeEvery(ADMIN_GET_SUPPORT_COMEDIEN, getSupportComedien);
}

//Modification d'un Support

function* updateSupportComedien({ payload }) {
  try {
    var response = yield call(updateSupportComedienRequest, payload);
    if (response.status && response.status === 200) {
      yield put(showAlertAction("admin.supportComedien.update.success", MESSAGE_SUCCESS));
      yield put(setRefreshListAction());

    } else {
      yield put(showAlertAction("admin.supportComedien.update.error", MESSAGE_FAIL));
    }
  } catch (error) {
    yield put(showAlertAction("admin.supportComedien.update.error", MESSAGE_FAIL));
  }
}

export function* updateSupportComedienFork() {
  yield takeEvery(ADMIN_UPDATE_SUPPORT_COMEDIEN, updateSupportComedien);
}


////////////////////////////////////////////////////////////////////////////
// Exports
////////////////////////////////////////////////////////////////////////////

export default function* rootSaga() {
  yield all([
    fork(getAllComediensFork),
    fork(getDemandesInscriptionsFork),
    fork(getDemandeInscriptionComedienFork),
    fork(validationDemandeInscriptionComedienFork),
    fork(refusDemandeInscriptionComedienFork),
    fork(deleteDemandeInscriptionComedienFork),
    fork(validationPaiementChequeFork),
    fork(getListComediensFork),
    fork(deleteComedienFork),
    fork(activationComedienFork),
    fork(desactivationComedienFork),
    fork(sendSatsComedienFork),
    fork(updateNotesComedienFork),
    fork(getNotesComedienFork),
    fork(getAbonnementComedienFork),
    fork(updateAbonnementComedienFork),
    fork(validationPaiementCheckAbonnementComedienFork),
    fork(getListUtilisateursFork),
    fork(deleteUtilisateurFork),
    fork(activationUtilisateurFork),
    fork(verificationEmailUtilisateurFork),
    fork(desactivationUtilisateurFork),
    fork(switchUtilisateurFork),
    fork(getListNewsComediensFork),
    fork(deleteNewsComedienFork),
    fork(getNewsFork),
    fork(updateNewsFork),
    fork(getMP3ComediensFork),
    fork(getListDemandesPhotosXLFork),
    fork(addPhotoXLFork),
    fork(getListePhotosXLFork),
    fork(getPhotoXLFork),
    fork(deletePhotoXLFork),
    fork(updatePhotoXLFork),
    fork(validationDemandePhotoXLFork),
    fork(RefusDemandePhotoXLFork),
    fork(addBlogFork),
    fork(getListeBlogsFork),
    fork(getBlogFork),
    fork(updateBlogFork),
    fork(deleteBlogFork),
    fork(addTemoignageComedienFork),
    fork(getListeTemoignagesComediensFork),
    fork(getTemoignageComedienFork),
    fork(deleteTemoignageComedienFork),
    fork(updateTemoignageComedienFork),
    fork(addAideFork),
    fork(getListeAidesFork),
    fork(deleteAideFork),
    fork(getAideFork),
    fork(updateAideFork),
    fork(sortAidesFork),
    fork(getAdminStatsFork),
    fork(getAdminHistoriqueStatsFork),
    fork(updateConfigRankingAlgoliaFork),
    fork(getConfigRankingAlgoliaFork),
    fork(getAdminProfilFork),
    fork(updateAdminProfilFork),
    fork(getListeCampagnesComFork),
    fork(deleteCampagneComFork),
    fork(activationCompagneComFork),
    fork(desactivationCompagneComFork),
    fork(addCampagneComFork),
    fork(getCampagneComFork),
    fork(updateCampagneComFork),
    fork(getCampagneComByUserFork),
    fork(addSupportComedienFork),
    fork(getListeSupportsByComedienFork),
    fork(deleteSupportComedienFork),
    fork(getSupportComedienFork),
    fork(updateSupportComedienFork),
    fork(getListeArticlesFork),
    fork(addArticleFork),
    fork(getArticleFork),
    fork(updateArticleFork),
    fork(deleteArticleFork),
    fork(addMediaFork),
    fork(getListeMediasFork),
    fork(deleteMediaFork)
  ])
}