import {
  ADMIN_GET_LIST_UTILISATEURS,
  ADMIN_SHOW_LIST_UTILISATEURS,
  ADMIN_GET_LIST_COMEDIENS,
  ADMIN_SHOW_LIST_COMEDIENS,
  ADMIN_GET_ALL_COMEDIENS,
  ADMIN_SHOW_ALL_COMEDIENS,
  ADMIN_GET_DEMANDES_INSCRIPTIONS_COMEDIENS,
  ADMIN_SHOW_DEMANDES_INSCRIPTIONS_COMEDIENS,
  ADMIN_GET_DEMANDE_INSCRIPTION_COMEDIEN,
  ADMIN_SHOW_DEMANDE_INSCRIPTION_COMEDIEN,
  ADMIN_GET_LIST_NEWS_COMEDIENS,
  ADMIN_SHOW_LIST_NEWS_COMEDIENS,
  ADMIN_GET_NEWS,
  ADMIN_SHOW_NEWS,
  ADMIN_GET_MP3_COMEDIENS,
  ADMIN_SHOW_MP3_COMEDIENS,
  ADMIN_GET_LIST_DEMANDES_PHOTOS_XL,
  ADMIN_SHOW_LIST_DEMANDES_PHOTOS_XL,
  ADMIN_GET_LISTE_PHOTOS_XL,
  ADMIN_SHOW_LISTE_PHOTOS_XL,
  ADMIN_SHOW_PHOTO_XL,
  ADMIN_GET_LISTE_BLOGS,
  ADMIN_SHOW_LISTE_BLOGS,
  ADMIN_GET_BLOG,
  ADMIN_SHOW_BLOG,
  ADMIN_GET_LISTE_TEMOIGNAGES_COMEDIENS,
  ADMIN_SHOW_LISTE_TEMOIGNAGES_COMEDIENS,
  ADMIN_GET_TEMOIGNAGE_COMEDIEN,
  ADMIN_SHOW_TEMOIGNAGE_COMEDIEN,
  ADMIN_SHOW_LISTE_AIDES,


  ADMIN_SET_REFRESH_ABONNEMENT_COMEDIEN,
  ADMIN_UNSET_REFRESH_ABONNEMENT_COMEDIEN,


  ADMIN_SHOW_ABONNEMENT_COMEDIEN,

  ADMIN_SHOW_CONFIG_RANKING_ALGOLIA,



  ADMIN_SHOW_AIDE,

  ADMIN_SHOW_STATS,
  ADMIN_SHOW_HISTORIQUE_STATS,
  ADMIN_SHOW_PROFIL,
  ADMIN_SHOW_NOTES_COMEDIEN,
  ADMIN_GET_LISTE_CAMPAGNES_COM,
  ADMIN_SHOW_LISTE_CAMPAGNES_COM,
  ADMIN_GET_CAMPAGNE_COM,
  ADMIN_SHOW_CAMPAGNE_COM,
  ADMIN_GET_CAMPAGNE_COM_BY_USER,
  ADMIN_SHOW_CAMPAGNE_COM_BY_USER,

  ADMIN_GET_LISTE_SUPPORTS_BY_COMEDIEN,
  ADMIN_SHOW_LISTE_SUPPORTS_BY_COMEDIEN,
  ADMIN_GET_SUPPORT_COMEDIEN,
  ADMIN_SHOW_SUPPORT_COMEDIEN,

  ADMIN_SHOW_LISTE_ARTICLES,
  ADMIN_SHOW_ARTICLE,

  ADMIN_SHOW_LISTE_MEDIAS,


} from 'constants/ActionTypes';

const INIT_STATE = {

  listUtilisateurs: [],
  listComediens: [],
  listDemandesInscriptionsComediens: [],
  dataNewsComedien: null,
  listDemandesPhotosXL: [],
  dataListPhotosXL: [],
  dataPhotoXL: null,
  dataListBlogs: null,
  dataBlog: null,
  dataTemoignage: null,
  dataConfigRankingAlgolia: null,
  dataAdminProfil: null,
  dataNotesComedien: null,
  dataListCampagnesCom: null,
  dataCampagneCom: null,
  dataCampagneComByUser: null,
  ///////////////////////////////////////





  dataListTemoignages: null,
  dataAbonnementComedien: null,
  refreshAbonnementComedien: false,
  demandeInscriptionComedien: null,
  dataAllComediens: [],
  //////////////////////





  listNewsComediens: [],
  listExtraitsMP3: [],
  dataListeAides: [],
  dataAide: null,
  dataAdminStats: null,
  dataAdminHistoriqueStats: null,

  dataListeSuportsByComedien: null,
  dataSupportComedien: null,

  dataListeArticles: [],
  dataArticle: null,
  dataListMedias: [],

}

export default (state = INIT_STATE, action) => {
  switch (action.type) {

    ////////////////////////////////////////////////////////////////////////////
    // Gestion des comediens
    ////////////////////////////////////////////////////////////////////////////

    //List des comediens
    case ADMIN_GET_ALL_COMEDIENS: {
      return {
        ...state,
      }
    }
    case ADMIN_SHOW_ALL_COMEDIENS: {
      return {
        ...state,
        dataAllComediens: action.dataAllComediens
      }
    }

    ////////////////////////////////////////////////////////////////////////////
    // Gestion des utilisateurs
    ////////////////////////////////////////////////////////////////////////////

    //List des utilisateurs

    case ADMIN_GET_LIST_UTILISATEURS: {
      return {
        ...state
      }
    }

    case ADMIN_SHOW_LIST_UTILISATEURS: {
      return {
        ...state,
        listUtilisateurs: action.listUtilisateurs
      }
    }

    ////////////////////////////////////////////////////////////////////////////
    // Gestion des comediens
    ////////////////////////////////////////////////////////////////////////////

    //List des comediens
    case ADMIN_GET_LIST_COMEDIENS: {
      return {
        ...state,
      }
    }
    case ADMIN_SHOW_LIST_COMEDIENS: {
      return {
        ...state,
        listComediens: action.listComediens
      }
    }

    //Abonnement d'un comédien
    case ADMIN_SHOW_ABONNEMENT_COMEDIEN: {
      return {
        ...state,
        dataAbonnementComedien: action.dataAbonnementComedien
      }
    }
    case ADMIN_SET_REFRESH_ABONNEMENT_COMEDIEN: {
      return {
        ...state,
        refreshAbonnementComedien: true,
      }
    }
    case ADMIN_UNSET_REFRESH_ABONNEMENT_COMEDIEN: {
      return {
        ...state,
        refreshAbonnementComedien: false,
      }
    }

    ////////////////////////////////////////////////////////////////////////////
    // Demandes d'inscriptions comediens
    ////////////////////////////////////////////////////////////////////////////

    //List des demandes

    case ADMIN_GET_DEMANDES_INSCRIPTIONS_COMEDIENS: {
      return {
        ...state,
      }
    }
    case ADMIN_SHOW_DEMANDES_INSCRIPTIONS_COMEDIENS: {
      return {
        ...state,
        listDemandesInscriptionsComediens: action.listDemandesInscriptionsComediens
      }
    }

    // Récupération d'une demanden

    case ADMIN_GET_DEMANDE_INSCRIPTION_COMEDIEN: {
      return {
        ...state,
      }
    }
    case ADMIN_SHOW_DEMANDE_INSCRIPTION_COMEDIEN: {
      return {
        ...state,
        demandeInscriptionComedien: action.demandeInscriptionComedien
      }
    }

    ////////////////////////////////////////////////////////////////////////////
    // News comediens
    ////////////////////////////////////////////////////////////////////////////

    //List des News
    case ADMIN_GET_LIST_NEWS_COMEDIENS: {
      return {
        ...state
      }
    }
    case ADMIN_SHOW_LIST_NEWS_COMEDIENS: {

      return {
        ...state,
        listNewsComediens: action.listNewsComediens
      }
    }
    //News
    case ADMIN_GET_NEWS: {
      return {
        ...state,
      }
    }
    case ADMIN_SHOW_NEWS: {
      return {
        ...state,
        dataNewsComedien: action.dataNewsComedien
      }
    }

    ////////////////////////////////////////////////////////////////////////////
    // Extraits MP3
    ////////////////////////////////////////////////////////////////////////////

    //List des extraits MP3

    case ADMIN_GET_MP3_COMEDIENS: {
      return {
        ...state,
      }
    }

    case ADMIN_SHOW_MP3_COMEDIENS: {
      return {
        ...state,
        listExtraitsMP3: action.listExtraitsMP3
      }
    }

    ////////////////////////////////////////////////////////////////////////////
    // Demandes de photo XL
    ////////////////////////////////////////////////////////////////////////////

    //List des demandes

    case ADMIN_GET_LIST_DEMANDES_PHOTOS_XL: {
      return {
        ...state
      }
    }

    case ADMIN_SHOW_LIST_DEMANDES_PHOTOS_XL: {

      return {
        ...state,
        listDemandesPhotosXL: action.listDemandesPhotosXL
      }
    }

    ////////////////////////////////////////////////////////////////////////////
    // Photos XL
    ////////////////////////////////////////////////////////////////////////////

    //List des photos XL

    case ADMIN_GET_LISTE_PHOTOS_XL: {
      return {
        ...state
      }
    }
    case ADMIN_SHOW_LISTE_PHOTOS_XL: {
      return {
        ...state,
        dataListPhotosXL: action.dataListPhotosXL
      }
    }
    case ADMIN_SHOW_PHOTO_XL: {
      return {
        ...state,
        dataPhotoXL: action.dataPhotoXL
      }
    }

    ////////////////////////////////////////////////////////////////////////////
    // Blogs
    ////////////////////////////////////////////////////////////////////////////

    //List des blogs

    case ADMIN_GET_LISTE_BLOGS: {
      return {
        ...state
      }
    }
    case ADMIN_SHOW_LISTE_BLOGS: {
      return {
        ...state,
        dataListBlogs: action.dataListBlogs
      }
    }
    case ADMIN_GET_BLOG: {
      return {
        ...state
      }
    }
    case ADMIN_SHOW_BLOG: {
      return {
        ...state,
        dataBlog: action.dataBlog
      }
    }

    ////////////////////////////////////////////////////////////////////////////
    // Temoignages
    ////////////////////////////////////////////////////////////////////////////

    //List des temoignages

    case ADMIN_GET_LISTE_TEMOIGNAGES_COMEDIENS: {
      return {
        ...state
      }
    }
    case ADMIN_SHOW_LISTE_TEMOIGNAGES_COMEDIENS: {
      return {
        ...state,
        dataListTemoignages: action.dataListTemoignages
      }
    }
    //Temoignage
    case ADMIN_GET_TEMOIGNAGE_COMEDIEN: {
      return {
        ...state
      }
    }
    case ADMIN_SHOW_TEMOIGNAGE_COMEDIEN: {
      return {
        ...state,
        dataTemoignage: action.dataTemoignage
      }
    }

    ////////////////////////////////////////////////////////////////////////////
    // Aide
    ////////////////////////////////////////////////////////////////////////////

    case ADMIN_SHOW_LISTE_AIDES: {
      return {
        ...state,
        dataListeAides: action.dataListeAides
      }
    }
    case ADMIN_SHOW_AIDE: {
      return {
        ...state,
        dataAide: action.dataAide
      }
    }


    ////////////////////////////////////////////////////////////////////////////
    // Articles
    ////////////////////////////////////////////////////////////////////////////

    case ADMIN_SHOW_LISTE_ARTICLES: {
      return {
        ...state,
        dataListeArticles: action.dataListeArticles
      }
    }
    case ADMIN_SHOW_ARTICLE: {
      return {
        ...state,
        dataArticle: action.dataArticle
      }
    }

    ////////////////////////////////////////////////////////////////////////////
    // Medias
    ////////////////////////////////////////////////////////////////////////////

    case ADMIN_SHOW_LISTE_MEDIAS: {
      return {
        ...state,
        dataListMedias: action.dataListMedias
      }
    }

    ////////////////////////////////////////////////////////////////////////////
    // Stats
    ////////////////////////////////////////////////////////////////////////////

    case ADMIN_SHOW_STATS: {
      return {
        ...state,
        dataAdminStats: action.dataAdminStats
      }
    }

    case ADMIN_SHOW_HISTORIQUE_STATS: {
      return {
        ...state,
        dataAdminHistoriqueStats: action.dataAdminHistoriqueStats
      }
    }

    //////////////////////////////////////
    //Config / Ranking Algolia
    //////////////////////////////////////

    case ADMIN_SHOW_CONFIG_RANKING_ALGOLIA: {
      return {
        ...state,
        dataConfigRankingAlgolia: action.dataConfigRankingAlgolia
      }
    }

    ///////////////////////////////////////////////////////////////////////
    // Mon Compte
    ///////////////////////////////////////////////////////////////////////

    //Profil

    case ADMIN_SHOW_PROFIL:
      return {
        ...state,
        dataAdminProfil: action.dataAdminProfil
      }

    ///////////////////////////////////////////////////////////////////////
    // Notes de comédien
    ///////////////////////////////////////////////////////////////////////

    case ADMIN_SHOW_NOTES_COMEDIEN:
      return {
        ...state,
        dataNotesComedien: action.dataNotesComedien
      }

    ////////////////////////////////////////////////////////////////////////////
    // Campagnes de Com
    ////////////////////////////////////////////////////////////////////////////

    //List des campagnes

    case ADMIN_GET_LISTE_CAMPAGNES_COM: {
      return {
        ...state
      }
    }
    case ADMIN_SHOW_LISTE_CAMPAGNES_COM: {
      return {
        ...state,
        dataListCampagnesCom: action.dataListCampagnesCom
      }
    }

    //Campagne
    case ADMIN_GET_CAMPAGNE_COM: {
      return {
        ...state
      }
    }
    case ADMIN_SHOW_CAMPAGNE_COM: {
      return {
        ...state,
        dataCampagneCom: action.dataCampagneCom
      }
    }

    //Campagne Utilisateur/Comedien
    case ADMIN_GET_CAMPAGNE_COM_BY_USER: {
      return {
        ...state
      }
    }
    case ADMIN_SHOW_CAMPAGNE_COM_BY_USER: {
      return {
        ...state,
        dataCampagneComByUser: action.dataCampagneComByUser
      }
    }

    ////////////////////////////////////////////////////////////////////////////
    // Support Comedien
    ////////////////////////////////////////////////////////////////////////////

    //List des photos XL

    case ADMIN_GET_LISTE_SUPPORTS_BY_COMEDIEN: {
      return {
        ...state
      }
    }

    case ADMIN_SHOW_LISTE_SUPPORTS_BY_COMEDIEN: {
      return {
        ...state,
        dataListeSuportsByComedien: action.dataListeSuportsByComedien
      }
    }

    case ADMIN_GET_SUPPORT_COMEDIEN: {
      return {
        ...state
      }
    }
    case ADMIN_SHOW_SUPPORT_COMEDIEN: {
      return {
        ...state,
        dataSupportComedien: action.dataSupportComedien
      }
    }


    default:
      return state;
  }
}